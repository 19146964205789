<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <div class="box_title" style="">
          <div class="box_title_font">添加文档</div>
          <div class="box_title_button">
            <!--可添加按钮-->
            <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
          </div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin-top: 20px">
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="7">
              <div style="width: 100%"></div>
            </el-col>
            <!--主表样式-->
            <el-col :span="24" :xs="24" :sm="24" :lg="9">
              <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                label-width="140px"
                class="demo-ruleForm"
              >
                <!-- 分类 -->
                <el-form-item label="分类：" :required="true">
                  <div v-for="(datas, l) in ruleForm.cate_info" :key="l">
                    <el-row
                      :gutter="10"
                      :style="{ 'margin-top': l == 0 ? 0 : '20px' }"
                    >
                      <!-- 平台来源 -->
                      <el-col :span="24" :xs="24" :sm="24" :lg="10">
                        <el-form-item
                          :prop="'cate_info.' + l + '.first_cate'"
                          :rules="rules.first_cate"
                        >
                          <el-select
                            v-model="datas.first_cate"
                            placeholder="请选择"
                          >
                            <el-option
                              :label="getsales.cate_name"
                              :value="getsales.id"
                              v-for="(getsales, chicundex) in this.getmytype[l]
                                .gettype"
                              :key="chicundex"
                              @click="finduserSaleList(getsales, l)"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <!-- 子分类-多级联选 -->
                      <!-- :props="{value:'id',label:'name'}" 自定义字段-->
                      <el-col :span="24" :xs="24" :sm="24" :lg="10">
                        <el-form-item
                          :prop="'cate_info.' + l + '.tail_cate'"
                          :rules="rules.tail_cate"
                        >
                          <el-cascader
                            v-model="datas.tail_cate"
                            :options="this.getmytype[l].gettypechild"
                            :show-all-levels="false"
                            :props="{
                              value: 'id',
                              label: 'cate_name',
                              children: 'child',
                            }"
                            clearable
                            :disabled="datas.disabled"
                            @change="handleChange"
                          ></el-cascader>
                        </el-form-item>
                      </el-col>
                      <!-- 添加&删除按钮 -->
                      <el-col :span="24" :xs="24" :sm="24" :lg="4">
                        <el-button
                          type="text"
                          v-if="datas.addisshow"
                          @click="addRechargeEvent(ruleForm.cate_info)"
                          >添加</el-button
                        >
                        <el-button
                          type="text"
                          v-if="!datas.addisshow"
                          @click="delRechargeEvent(l, ruleForm.cate_info)"
                          >删除</el-button
                        >
                      </el-col>
                    </el-row>
                  </div>
                </el-form-item>
                <el-form-item
                  label="文档："
                  prop="sourcefile"
                  :rules="rules.sourcefile"
                >
                  <mainimgesup
                    v-model="ruleForm.sourcefile"
                    :myimgesup="myimgesup"
                    :myimgesupisshow="myimgesupisshow"
                    :filelist="ruleForm.sourcefile_list"
                    v-on:getuploadfile="
                      (data) => getUploadFileEvent(data, i, 'sourcefile')
                    "
                  ></mainimgesup>
                </el-form-item>
                <el-form-item label="文档名称：" prop="sourcename">
                  <el-input
                    v-model="ruleForm.sourcename"
                    placeholder="请重命文档名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="选择标签：" prop="tabinfo">
                  <el-select
                    v-model="ruleForm.tab_info"
                    multiple
                    placeholder="请选择标签"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in tabinfo.options"
                      :key="item.id"
                      :label="item.sort_key"
                      :value="item.sort_val"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="收益类型：" prop="income_type">
                  <el-radio-group
                    v-model="ruleForm.income_type"
                    @change="changeRadioEvent"
                  >
                    <el-radio label="1">共享文档</el-radio>
                    <el-radio label="2">收费文档</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="文档价格："
                  prop="print_price"
                  v-if="ruleForm.income_type === '2'"
                >
                  <el-input v-model="ruleForm.print_price"></el-input>
                </el-form-item>
                <el-form-item label="角标：" prop="corner">
                  <el-radio-group v-model="ruleForm.corner">
                    <el-radio label="1">热门</el-radio>
                    <el-radio label="2">精选</el-radio>
                    <el-radio label="3">推荐</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="排序：" prop="weight">
                  <el-input
                    v-model="ruleForm.weight"
                    placeholder="数字越大排序越高"
                  ></el-input>
                </el-form-item>
                <el-form-item label="虚拟打印次数：" prop="fictitious">
                  <el-input v-model="ruleForm.fictitious"></el-input>
                </el-form-item>
                <el-form-item label="虚拟收藏数：" prop="collect_fictitious">
                  <el-input v-model="ruleForm.collect_fictitious"></el-input>
                </el-form-item>
                <el-form-item label="打印模式：" prop="print_mode">
                  <el-radio-group v-model="ruleForm.print_mode">
                    <el-radio label="1">黑白</el-radio>
                    <el-radio label="2">彩色</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="状态：" prop="status">
                  <el-radio-group v-model="ruleForm.status">
                    <el-radio label="1">启用</el-radio>
                    <el-radio label="0">禁用</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="submitForm('ruleForm')"
                    style="width: 40%; margin-right: 10px"
                    >提交
                  </el-button>
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="8">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import mainimgesup from "@/components/Mainimgesup.vue";
// import { ElLoading} from "element-plus";
import { ElMessage } from "element-plus";
import { ref } from "vue";
import axios from "axios";

export default {
  name: "vprintresourcesadd",
  data() {
    return {
      mainonebread: [
        {
          name: "教育",
        },
        {
          name: "文档管理",
        },
        {
          name: "打印资源库",
          path: "/printresources",
        },
        {
          name: "添加文档",
          path: "/printresourcesadd",
        },
      ], // 当前页-【面包屑导航】

      myselectcityisshow: true, //form表单-selectcity选框
      myselectcity: {
        selectwidth: "100%",
        select: {
          ismust: true,
          name: "area_code",
          valuesheng: "",
          valueshi: "",
          valuequ: "",
          disshi: true,
          disqu: true,
        },
      },
      myimgesupisshow: true, //form表单-imgesuploade上传图片
      myimgesup: {
        uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "text", //list展示类型，text/picture/picture-card；默认不填为text
        ismust: true, //是否必填，true表示必填
        action: "/api/admin/upload/file", //upload上传API地址
        headers: { Authentication: localStorage.getItem("token") }, //upload上传headers
        title: "点击上传", //button-按钮文字
        // tips: "建议尺寸46px*46px", //提示&建议文字
        limit: "1", //上传数量限制
        target_id: 3, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
        rules: {
          files: {
            size: 10000, //文件大小（单位kb）
            type: ["text/plain", "application/pdf", "application/msword"], //文件格式
          },
        },
      },

      gettypeall: [], //接收总分类
      getmytype: [
        {
          gettype: [], //分类-平台
          gettypechild: [], // 分类-子分类
        },
      ], //分类-配置

      tabinfo: {
        options: ref([
          {
            value: "Option1",
            label: "Option1",
          },
        ]),
        value1: ref([]),
      }, //标签
      ruleForm: {
        cate_info: [
          {
            first_cate: null, //来源平台
            tail_cate: null, //子分类
            addisshow: true,
            disabled: true,
          },
        ],
        sourcefile: "",
        sourcefile_list: [],
        sourcename: "",
        tab_info: "",
        income_type: ref("1"),
        print_price: "0.00",
        corner: ref("1"),
        weight: "",
        fictitious: "",
        collect_fictitious: "",
        print_mode: ref("1"),
        status: ref("1"),
      }, //form表单
      rules: {
        first_cate: [
          {
            required: true,
            message: "平台不能为空",
            trigger: "change",
          },
        ],
        tail_cate: [
          {
            required: true,
            message: "分类不能为空",
            trigger: "change",
          },
        ],
        sourcefile: [
          {
            required: true,
            message: "文档不能为空",
            trigger: "change",
          },
        ],
        sourcename: [
          {
            required: true,
            message: "文档名称不能为空",
            trigger: "change",
          },
        ],
        income_type: [
          {
            required: true,
            message: "收益类型不能为空",
            trigger: "blur",
          },
        ],
        print_price: [
          {
            required: true,
            message: "收费文档价格不能为空",
            trigger: "blur",
          },
        ],
        weight: [
          {
            required: true,
            message: "排序不能为空",
            trigger: "blur",
          },
        ],
        fictitious: [
          {
            required: true,
            message: "虚拟打印次数不能为空",
            trigger: "blur",
          },
        ],
        collect_fictitious: [
          {
            required: true,
            message: "虚拟收藏次数不能为空",
            trigger: "blur",
          },
        ],
        print_mode: [
          {
            required: true,
            message: "打印模式不能为空",
            trigger: "blur",
          },
        ],
      }, //form表单-必填规则

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token

      // fullscreenLoading:ref(false), //全屏加载显示
    };
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //通过API获取配置数据-无限级分类列表
      axios
        .post(
          "/admin/SharePrintSource/sourceCateInfo",
          {
            id: -1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.gettypeall = response.data;
          this.getmytype[0].gettype = this.gettypeall;
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });

      //通过API获取配置数据-标签
      axios
        .post(
          "/admin/SharePrintSource/sourceTabInfo",
          {},
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.tabinfo.options = response.data;
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    }
  },
  methods: {
    // 收益类型radio选择
    changeRadioEvent(data) {
      // console.log(data)
      if (data == 1) {
        this.ruleForm.print_price = "0.00";
      }
    },
    //获取upload子组件回传的数据
    getUploadFileEvent(data, idex, keys) {
      console.log("当前获取的回传数据：" + JSON.stringify(data), idex, keys);
      this.ruleForm.sourcefile = data.url;
      this.ruleForm.sourcename = data.name;
    },
    //关联价格-添加事件
    addRechargeEvent(fromdata, times) {
      if (times) {
        if (fromdata.length < times) {
          // js中通过push新增分类的数据列
          var getmytype = {
            gettype: this.gettypeall, //分类-平台
            gettypechild: [], // 分类-子分类
          };
          this.getmytype.push(getmytype);
          // js中通过push向数组最后新增指定的元素
          var oneAddChat = {
            addisshow: false,
            disabled: true,
          };
          fromdata.push(oneAddChat);
        } else {
          ElMessage({
            type: "warning",
            message: "最多增加" + times + "条！",
          });
        }
      } else {
        // js中通过push新增分类的数据列
        var getmytypes = {
          gettype: this.gettypeall, //分类-平台
          gettypechild: [], // 分类-子分类
        };
        this.getmytype.push(getmytypes);
        // js中通过push向数组最后新增指定的元素
        var oneAddChats = {
          addisshow: false,
          disabled: true,
        };
        fromdata.push(oneAddChats);
      }
    },
    //关联价格-删除事件
    delRechargeEvent(ide, formdata) {
      console.log("获取的删除id为：" + ide);
      // js中通过splice删除指定的元素
      formdata.splice(ide, 1);
      this.getmytype.splice(ide, 1);
    },
    //用于下拉框-点击&选择事件-控制框是否可选择
    finduserSaleList(data, ldex) {
      if (data.child) {
        this.ruleForm.cate_info[ldex].disabled = false;
        this.getmytype[ldex].gettypechild = data.child;
      } else {
        this.ruleForm.cate_info[ldex].disabled = true;
        this.ruleForm.cate_info[ldex].tail_cate = "";
      }
    },
    // 多级联选-点击事件
    handleChange(value) {
      console.log(value);
    },
    // 提交
    submitForm(formName) {
      var formdatas = this.$refs[formName].model;
      console.log("提交的数据集：" + JSON.stringify(formdatas));
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var getcate = [];
          for (var i = 0; i < formdatas.cate_info.length; i++) {
            getcate[i] =
              formdatas.cate_info[i].tail_cate[
                formdatas.cate_info[i].tail_cate.length - 1
              ];
          }
          let getfile = getcate.filter((n) => n); //去除数组中的null值
          var cate = getfile.join(","); //将数组的值组合成字符串并用（，逗号）隔开
          var tabinfo = formdatas.tab_info.join(",");
          // console.log(formdatas, cate, tabinfo, formdatas.income_type);

          // // 全屏提交缓冲
          // var loading = ElLoading.service({
          //   lock: true,
          //   text: "Loading",
          //   background: "rgba(0, 0, 0, 0.7)",
          // });

          axios
            .post(
              "/admin/sharePrintSource/addSource",
              {
                cate: cate,
                sourcefile: formdatas.sourcefile + "---" + formdatas.sourcename,
                sourcename: formdatas.sourcename,
                tab_info: tabinfo,
                income_type: formdatas.income_type,
                print_price: formdatas.print_price,
                corner: formdatas.corner,
                weight: formdatas.weight,
                fictitious: formdatas.fictitious,
                collect_fictitious: formdatas.collect_fictitious,
                print_mode: formdatas.print_mode,
                status: formdatas.status,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                });
                // loading.close();
              }
            })
            .catch(function (error) {
              // 请求失败处理
              console.log(error);
              // loading.close();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  components: {
    mainone,
    mainimgesup,
  },
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
}
</style>