// tableHeight.js
import { ref, onBeforeUnmount, nextTick } from "vue";
import _ from "lodash";


export default function (opts) {
  const {
    subtractHeight = 200,
  } = opts;

  const tableHeight = ref(500)

  // 计算高度
  const getHeight = _.debounce(function () {
    const height = document.documentElement.clientHeight - subtractHeight;
    tableHeight.value = height > 200 ? height : 200;
  }, 100)

  // 监听窗口变化，触发高度计算
  window.addEventListener('resize', getHeight);
  onBeforeUnmount(() => {
    window.removeEventListener('resize', getHeight);
  })

  // 初始化高度
  nextTick(() => {
    getHeight()
  })
  return {
    tableHeight
  };
}
