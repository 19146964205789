<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            v-on:getsearchinfo="getSeachinfoEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <el-button type="primary" plain @click="routeraddrClick('/appletadvertadd')" v-if="this.mypowerlimits.ggwlb_tj"> 添加 </el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                            :formatter="mytabletitle.formatter"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'status' || mytabletitle.prop === 'begin_time'">
                                <!-- 状态-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <div v-if="scope.row.switchstatus === 4" id="switch">
                                        <el-switch
                                            v-model="scope.row.status"
                                            active-text="开启"
                                            inactive-text="关闭"
                                            :active-value="1"
                                            :inactive-value="-1"
                                            active-color="#13ce66"
                                            inactive-color="#cbcbcb"
                                            @click="this.mypowerlimits.ggwlb_qy === true ? changeStatus(scope.row) : ''"
                                            :disabled="!this.mypowerlimits.ggwlb_qy"
                                        >
                                        </el-switch>
                                    </div>
                                </div>
                                <!-- 广告时间-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'begin_time'">
                                    <div>开始时间：{{ scope.row.begin_time !== 0 && scope.row.begin_time !== null ? this.$utils.formatDate(scope.row.begin_time) : '永久' }}</div>
                                    <div>结束时间：{{ scope.row.end_time !== 0 && scope.row.end_time !== null ? this.$utils.formatDate(scope.row.end_time) : '永久' }}</div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-运营-小程序广告位-->
                                <div v-if="mainthreetableope === 22">
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/appletadvertedit', scope.row)" v-if="this.mypowerlimits.ggwlb_bj"
                                        >编辑
                                    </el-button>
                                    <el-button
                                        @click.prevent="deleteRow(scope.row, scope.$index)"
                                        type="danger"
                                        size="mini"
                                        icon="el-icon-delete"
                                        v-if="this.mypowerlimits.ggwlb_sc"
                                    ></el-button>
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        v-if="(scope.row.type_id == 2 || scope.row.type_id == 5) && this.mypowerlimits.ggwlb_zd"
                                        @click="toTopClick(scope.row)"
                                        >置顶<i class="el-icon-top"></i
                                    ></el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'vappletadvert',
    inject: ['reload'], //刷新引用
    components: {
        mainone,
        maintwo
    },
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '小程序广告位管理'
                },
                {
                    name: '广告位列表'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '广告名称',
                            name: 'name',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '广告位置：',
                            placeholder: '全部',
                            name: 'type_id',
                            value: '',
                            options: []
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '状态：',
                            placeholder: '全部',
                            name: 'status',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: '0'
                                },
                                {
                                    label: '启用',
                                    value: '1'
                                },
                                {
                                    label: '禁止',
                                    value: '-1'
                                }
                            ]
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: true, // 数据列表中-【添加按钮】
            mainthreeadddata: '/appletadvertadd', //点击添加按钮-跳转路由
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 22, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 180, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'id',
                    label: 'ID',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '广告名',
                    width: '200'
                },
                {
                    prop: 'source',
                    label: '来源类型',
                    width: '120',
                    formatter: (row) => {
                        return row['source'] === 1 ? '自营广告' : '小程序流量主'
                    }
                },
                {
                    fixedstatu: false,
                    prop: 'my_type_id',
                    label: '广告位置',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'add_user',
                    label: '添加人',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '添加时间',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'up_user',
                    label: '修改人',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'begin_time',
                    label: '广告时间',
                    width: '250'
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: ''
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: JSON.parse(localStorage.getItem('userinfo')), //获取存储的用户信息
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            adLocationOptions: []
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        //设置-小程序广告-置顶
        toTopClick(data) {
            axios
                .post(
                    '/admin/advert/toTop',
                    {
                        id: data.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message,
                            duration: 1000,
                            onClose: () => {
                                this.reload() //刷新
                            }
                        })
                    } else {
                        setTimeout(() => {
                            this.reload() //刷新
                        }, 1000)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //table表单中-状态改变-开启/关闭
        changeStatus(data) {
            axios
                .post(
                    '/admin/advert/disable',
                    {
                        id: data.id,
                        status: data.status
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message
                        })
                    } else {
                        // 切换状态失败-复原状态
                        if (data.status == 1) {
                            data.status = -1
                        } else {
                            data.status = 1
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //table表-操作中-删除按钮（函数）
        deleteRow(rowdata, index) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            ElMessageBox.confirm("确定删除该条 ' " + rowdata.name + " ' 数据吗?", '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/advert/delete',
                            {
                                id: rowdata.id
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '删除成功'
                                })
                                this.mainthreetableData.splice(index, 1)
                            }
                        })
                        .catch(function (error) {
                            // 请求失败处理
                            ElMessage({
                                type: 'error',
                                message: '删除失败！' + error
                            })
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消删除'
                    })
                })
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/advert/index',
                    {
                        name: this.pagesdatas.name,
                        type_id: this.pagesdatas.type_id,
                        status: this.pagesdatas.status,
                        page: 1,
                        page_size: val
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/advert/index',
                    {
                        name: this.pagesdatas.name,
                        type_id: this.pagesdatas.type_id,
                        status: this.pagesdatas.status,
                        page: val,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, data) {
            if (data) {
                this.$router.push({ path: routeraddr, query: { id: data.id } }).catch((error) => error)
            } else {
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas(tableData) {
            console.log(tableData)
        },
        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/advert/index',
                    {
                        name: data.name,
                        type_id: data.type_id,
                        status: data.status,
                        page: 1,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //渲染-状态-该字段表示search按钮，且status为1表示启用，2表示禁用
                newdata[i].switchstatus = 4

                // 广告位置-渲染
                if (newdata[i].type_id) {
                    this.maintwoforminfo.selectleft.select[0].options.forEach((element) => {
                        if (newdata[i].type_id == element.value) {
                            newdata[i].my_type_id = element.label
                        }
                    })
                } else {
                    newdata[i].my_type_id = ''
                }

                //转换-添加时间
                if (newdata[i].addtime) {
                    if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                        newdata[i].addtime = '无添加时间'
                    } else {
                        newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
                    }
                } else {
                    newdata[i].addtime = '无添加时间'
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        getconfiginfo(configinfo) {
            //获取api-加载的广告位位置信息
            var arraydata = Object.values(configinfo)
            var alldatas = Array()
            for (var i = 0; i <= arraydata.length; i++) {
                if (i === 0) {
                    alldatas[i] = { value: i, label: '全部' }
                } else {
                    alldatas[i] = { value: arraydata[i - 1].id, label: arraydata[i - 1].name }
                }
            }
            //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
            this.maintwoforminfo.selectleft.select[0].options = alldatas
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            //获取广告位置-配置
            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/config/info',
                    {
                        key_name: 'advert_position',
                        id: this.myuserinfo.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.adLocationOptions = response.data.result
                    this.getconfiginfo(response.data.result)

                    //通过Ajax向后台获取数据
                    axios
                        .post(
                            '/admin/advert/index',
                            {
                                page: 1,
                                page_size: this.PageSize
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            this.myaxiosfunc(response.data.result)
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }
})
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>
