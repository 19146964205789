<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <el-button type="primary" @click="submitForm(this.multipleSelection, 7)" v-if="mypowerlimits.jqkz_smggkg">扫码广告开关 </el-button>
                        <el-button type="primary" @click="open(1, '更新程序', '更新程序包路径：')" v-if="mypowerlimits.jqkz_gxcx">更新程序 </el-button>
                        <el-button type="primary" @click="open(2, '更新辅程序', '更新辅程序包路径：')" v-if="mypowerlimits.jqkz_gxfcx">更新辅程序 </el-button>
                        <el-button type="primary" @click="submitForm(this.multipleSelection, 4)" v-if="mypowerlimits.jqkz_yccq">远程重启 </el-button>
                        <el-button type="primary" @click="submitForm(this.multipleSelection, 5)" v-if="mypowerlimits.jqkz_zthf">状态恢复 </el-button>
                        <el-button type="primary" @click="submitForm(this.multipleSelection, 6)" v-if="mypowerlimits.jqkz_zxsz">在线时长 </el-button>
                        <el-button type="primary" @click="open(3, '添加云打印设备', '打印机序列号：')" v-if="mypowerlimits.jqkz_tjydysb">添加云打印设备 </el-button>
                        <!--导出按钮-点击导出数据-->
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="this.realtabledata"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @select-all="handleSelectionChange"
                        @select="handleSelectionChange"
                        @cell-click="handleOneData"
                        :default-sort="{ prop: 'date', order: 'descending' }"
                        @sort-change="sortchangeEvent"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                            :sortable="mytabletitle.prop == 'putintimeStr' || mytabletitle.prop == 'ver' || mytabletitle.prop == 'uptimeStr' ? 'custom' : false"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="
                                    mytabletitle.prop == 'status' || mytabletitle.prop == 'shop_code_name' || mytabletitle.prop == 'orderNum' || mytabletitle.prop == 'putintimeStr'
                                "
                            >
                                <!-- 投放时间 -->
                                <div v-if="mytabletitle.prop == 'putintimeStr'">
                                    <div>
                                        {{ scope.row.putintime == 0 ? '' : scope.row.putintimeStr.substr(0, 10) }}
                                    </div>
                                </div>
                                <!-- 代理商 -->
                                <div v-if="mytabletitle.prop == 'shop_code_name'">
                                    <div
                                        :class="this.mypowerlimits.dlslb == true ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.dlslb == true ? routeraddrClick('/agentlist', scope.row) : ''"
                                    >
                                        <span style="">{{ scope.row.shop_code_name }}</span>
                                    </div>
                                </div>
                                <!-- 订单量 -->
                                <div v-if="mytabletitle.prop == 'orderNum'">
                                    <div
                                        :class="this.mypowerlimits.ddlb == true ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.ddlb == true ? routeraddrClick('/orderlist', scope.row) : ''"
                                    >
                                        <span style="">{{ scope.row.orderNum }}</span>
                                    </div>
                                </div>
                                <!-- 设备状态 -->
                                <div v-if="mytabletitle.prop == 'status'">
                                    <el-button :type="scope.row.printstatus == 0 ? 'primary' : scope.row.printstatus == 1 ? 'success' : 'danger'" size="mini"
                                        >{{ this.$utils.printStatusData(this.myselect.select[7].options, scope.row.printstatus != 0 ? scope.row.printstatus : '0') }}
                                    </el-button>
                                    <el-button :type="scope.row.is_online == 1 ? 'success' : 'danger'" size="mini">{{ scope.row.is_online == 1 ? '在线' : '离线' }}</el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column :fixed="false" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-机器控制-->
                                <div v-if="mainthreetableope === 8">
                                    <!-- 兼容（家庭版）新增家庭成员添加按钮【用is_family字段判断 1显示 2不显示】&& mypowerlimits.jqkz_jtcy -->
                                    <el-button type="primary" size="mini" v-if="scope.row.is_family == 1" @click="routeraddrClick('/sharedvccontrolfamilyedit', scope.row)"
                                        >家庭成员
                                    </el-button>
                                    <el-button
                                        type="success"
                                        size="mini"
                                        v-if="scope.row.run_type != 2 && mypowerlimits.jqkz_cc"
                                        @click="routeraddrClick('/sharedvccontroledit', scope.row, 1)"
                                        >出厂
                                    </el-button>
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        v-if="scope.row.run_type == 2 && mypowerlimits.jqkz_th"
                                        @click="gobackgoodsEvent(scope.row.id, (dialogVisible1 = true))"
                                        >退货
                                    </el-button>
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="routeraddrClick('/sharedvccontroledit', scope.row)"
                                        v-if="
                                            mypowerlimits.jqkz_bjsbxq == true ||
                                            mypowerlimits.jqkz_bjccazsz == true ||
                                            mypowerlimits.jqkz_cc == true ||
                                            mypowerlimits.jqkz_bjggsz == true ||
                                            mypowerlimits.jqkz_bjdyjpzx == true ||
                                            mypowerlimits.jqkz_bjtsjbjpz == true ||
                                            mypowerlimits.jqkz_bjjgszx == true ||
                                            mypowerlimits.jqkz_ccthjl == true ||
                                            mypowerlimits.jqkz_czjl == true
                                        "
                                        >编辑
                                    </el-button>
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="updateGetdvcidEvent(scope.row.id, (dialogVisible = true))"
                                        v-if="
                                            mypowerlimits.jqkz_gxgg == true ||
                                            mypowerlimits.jqkz_gxjg == true ||
                                            mypowerlimits.jqkz_gxzczy == true ||
                                            mypowerlimits.jqkz_gxdyfs == true ||
                                            mypowerlimits.jqkz_gxjzqzzlx == true ||
                                            mypowerlimits.jqkz_gxdyjpz == true ||
                                            mypowerlimits.jqkz_gxdyjpzwj == true ||
                                            mypowerlimits.jqkz_gxdyjncjcpz == true || mypowerlimits.jqkz_lksbsx == true || mypowerlimits.jqkz_lksbjcycxz == true || mypowerlimits.jqkz_lksbcq == true || mypowerlimits.jqkz_lksbqkpdrw == true
                                        "
                                        >更新</el-button
                                    >
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/sharedvccontrolseelog', scope.row)" v-if="mypowerlimits.jqkz_ckrz"
                                        >查看日志</el-button
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>

                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 弹窗-table-更新按钮 -->
        <el-dialog v-model="dialogVisible" title="更新" :width="searchwidth" :before-close="handleCloseSenior">
            <div class="mydialog-box">
                <div class="mydialog-box-button" v-for="(update, index) in this.updatebutton" :key="index">
                    <el-button v-if="mypowerlimits[update.powerlimits] == true" type="primary" @click="updateEvent(update.ajaxurl, this.currentId, update.method)">
                        {{ update.btnname }}
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <!-- 弹窗-table-退货按钮 -->
        <el-dialog v-model="dialogVisible1" title="退货" :width="this.searchwidth" :before-close="handleCloseSenior">
            <div>
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                    <div class="my-tan-boxs">
                        <el-form-item label="退货时间：" prop="time">
                            <el-date-picker v-model="ruleForm.time" type="date" placeholder="请选择日期" style="width: 100%" />
                        </el-form-item>
                        <el-form-item label="备注：">
                            <el-input v-model="ruleForm.remark" :autosize="{ minRows: 2 }" type="textarea" placeholder="请填写备注"> </el-input>
                        </el-form-item>
                        <el-form-item>
                            <span style="color: red">退货将清空【设备详情】及【出厂安装设置】等绑定信息</span>
                        </el-form-item>
                        <el-form-item>
                            <div class="mydialog-box">
                                <div class="mydialog-box-button">
                                    <el-button type="success" @click="gobackEvent('ruleForm', 1)">退回京硕总部</el-button>
                                </div>
                                <div class="mydialog-box-button">
                                    <el-button type="primary" @click="gobackEvent('ruleForm', 2)">退回本公司</el-button>
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import { ElMessageBox, ElMessage } from 'element-plus'
// import type { Action } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vsharedvccontrol',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '打印'
                },
                {
                    name: '共享打印'
                },
                {
                    name: '机器控制',
                    path: '/sharedvccontrol'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: true, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '设备编号/代理商',
                            name: 'keywords',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '设备ID：',
                            placeholder: '设备ID',
                            name: 'serial_number',
                            content: ''
                        },
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '状态：',
                            placeholder: '全部',
                            name: 'is_online',
                            value: '',
                            options: [
                                {
                                    value: '0',
                                    label: '全部'
                                },
                                {
                                    value: '1',
                                    label: '在线'
                                },
                                {
                                    value: '-1',
                                    label: '离线'
                                }
                            ]
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '运营状态：',
                            placeholder: '全部',
                            name: 'run_type',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: '0'
                                },
                                {
                                    label: '未出厂',
                                    value: '1'
                                },
                                {
                                    label: '运营中',
                                    value: '2'
                                },
                                {
                                    label: '已返厂',
                                    value: '3'
                                }
                            ]
                        }
                    ]
                }
            }, // 筛选-【筛选查询】-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: true, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 8, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 320, // 数据列表中-操作-宽度-250
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'client',
                    label: '设备编号',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'shop_code_name',
                    label: '代理商',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'printname',
                    label: '打印机类型',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'orderNum',
                    label: '订单量',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'is_buy_name',
                    label: '投放方式',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'is_partner_name',
                    label: '认购状态',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'run_type_name',
                    label: '运营状态',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: '160'
                },
                {
                    fixedstatu: false,
                    prop: 'putintimeStr',
                    label: '投放时间',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'ver',
                    label: '版本号',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'serial_number',
                    label: '设备ID',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'uptimeStr',
                    label: '版本更新时间',
                    width: '180'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //高级筛选
            myinputishow: true, //高级筛选-input单框
            myinput: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%',
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填 - 设备编号/代理商
                        title: '输入搜索：',
                        placeholder: '设备编号/代理商',
                        name: 'keywords',
                        content: ''
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '版本号：',
                        placeholder: '版本号',
                        name: 'ver',
                        content: ''
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '设备ID：',
                        placeholder: '设备ID',
                        name: 'serial_number',
                        content: ''
                    },
                ]
            },
            myselectisshow: true, //高级筛选-select单框
            myselect: {
                divinterval: '3%', //框右侧距离
                selectwidth: '47%',
                selectmaxwidth: '100%',
                select: [
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '状态：',
                        placeholder: '全部',
                        name: 'is_online',
                        value: '',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '1',
                                label: '在线'
                            },
                            {
                                value: '-1',
                                label: '离线'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '运营状态：',
                        placeholder: '全部',
                        name: 'run_type',
                        value: '0',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '1',
                                label: '未出厂'
                            },
                            {
                                value: '2',
                                label: '运营中'
                            },
                            {
                                value: '3',
                                label: '已返厂'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '投放方式:',
                        placeholder: '全部',
                        name: 'is_buy',
                        value: '',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '1',
                                label: '自投'
                            },
                            {
                                value: '2',
                                label: '购买'
                            },
                            {
                                value: '3',
                                label: '租赁'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '投放场景:',
                        placeholder: '全部',
                        name: 'push_type',
                        value: '',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '1',
                                label: '场景01'
                            },
                            {
                                value: '2',
                                label: '场景02'
                            },
                            {
                                value: '3',
                                label: '场景03'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '认购状态:',
                        placeholder: '全部',
                        name: 'is_partner',
                        value: '',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '-1',
                                label: '未认购'
                            },
                            {
                                value: '1',
                                label: '已认购'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '是否绑定代理商:',
                        placeholder: '全部',
                        name: 'isbind',
                        value: '',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '1',
                                label: '是'
                            },
                            {
                                value: '-1',
                                label: '否'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '打印机类型:',
                        placeholder: '全部',
                        name: 'printname',
                        value: '',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '30102311',
                                label: 'Canon G5080 series'
                            },
                            {
                                value: '100021211',
                                label: 'Canon G5034 series'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '打印机状态:',
                        placeholder: '全部',
                        name: 'printstatus',
                        value: '',
                        options: componentProperties.get('printstatusoption').options
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '资金方:',
                        placeholder: '全部',
                        name: 'fund',
                        value: '',
                        options: []
                    },
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '版本更新时间范围：',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'uptime', //参数名
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    },
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '投放时间范围：',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'putintime', //参数名
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    }
                ]
            },

            // table表单的数据
            // 由于table表单与分页结合，导致点击单选框或者其余弹窗事件时，多选框状态会被清空！
            // 因此将双向绑定的动态数据重新赋值给realtabledata做数据断离层，即可解决选中状态被清空问题！！！
            realtabledata: [],

            // 更新按钮
            updatebutton: [
                {
                    btnname: '更新广告',
                    ajaxurl: '/admin/Equipment/updateE',
                    powerlimits: 'jqkz_gxgg'
                },
                {
                    btnname: '更新价格',
                    ajaxurl: '/admin/Equipment/updateF',
                    powerlimits: 'jqkz_gxjg'
                },
                {
                    btnname: '更新支持资源',
                    ajaxurl: '/admin/Equipment/updateG',
                    powerlimits: 'jqkz_gxzczy'
                },
                {
                    btnname: '更新pdf打印方式',
                    ajaxurl: '/admin/Equipment/updateH',
                    powerlimits: 'jqkz_gxdyfs'
                },
                {
                    btnname: '更新进纸器纸张类型',
                    ajaxurl: '/admin/Equipment/updateI',
                    powerlimits: 'jqkz_gxjzqzzlx'
                },
                {
                    btnname: '更新打印机配置',
                    ajaxurl: '/admin/Equipment/updateJ',
                    powerlimits: 'jqkz_gxdyjpz'
                },
                {
                    btnname: '更新打印机配置文件',
                    ajaxurl: '/admin/Equipment/updateK',
                    powerlimits: 'jqkz_gxdyjpzwj'
                },
                {
                    btnname: '更新打印机内存监测配置',
                    ajaxurl: '/admin/Equipment/updateL',
                    powerlimits: 'jqkz_gxdyjncjcpz'
                },
                {
                    btnname: '异步刷新设备最新信息',
                    ajaxurl: '/admin/Equipment/asyncRefreshDeviceInfo',
                    powerlimits: 'jqkz_lksbsx',
                    method: 'get'
                },
                {
                    btnname: '解除打印失败过多的异常限制',
                    ajaxurl: '/admin/Equipment/resetPrintingErrorLimit',
                    powerlimits: 'jqkz_lksbjcycxz'
                },
                {
                    btnname: '重启设备接口',
                    ajaxurl: '/admin/Equipment/asyncDeviceReboot',
                    powerlimits: 'jqkz_lksbcq'
                },
                {
                    btnname: '清空排队中的任务',
                    ajaxurl: '/admin/Equipment/flushJobs',
                    powerlimits: 'jqkz_lksbqkpdrw'
                },
            ],
            // 需要更新的设备id
            currentId: '',

            // 弹窗相关数据
            ruleForm: {
                id: '',
                time: '',
                remark: '',
                type: ''
            }, //弹窗中-form表单
            rules: {
                time: [
                    {
                        required: true,
                        message: '退货日期为必填项',
                        trigger: 'blur'
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '请输入手机号',
                        trigger: 'change'
                    }
                ]
            }, //弹窗中-form表单-必填规则

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100, 1000],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '550px',
            // 控制弹窗-更新-相关显示
            dialogVisible: ref(false),
            // 控制弹窗-退货-相关显示
            dialogVisible1: ref(false),

            printstatusname: '', // 打印状态-前端维护

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },

    setup() {
        const handleCloseSenior = (done) => {
            done()
        }
        return {
            handleCloseSenior
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // 按钮-[更新程序][更新辅程序][添加云打印设备]-点击按钮-打开弹窗
        open(flag, title, label) {
            ElMessageBox.prompt(label, title, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /\S/,
                inputErrorMessage: this.dialoglabel + '不能为空'
            })
                .then(({ value }) => {
                    console.log('当前选中的数据集：', this.multipleSelection)
                    this.submitForm(this.multipleSelection, flag, value)
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: title + '操作，已取消'
                    })
                })
        },
        // 按钮-[更新程序][更新辅程序][添加云打印设备][远程重启][状态恢复]-提交表单
        submitForm(datas, flag, inputval) {
            // 获取并转换批量选中的设备数据
            var selectedIds = []
            for (var m = 0; m < datas.length; m++) {
                selectedIds.push(datas[m].id)
            }
            //更新程序包
            if (flag == 1) {
                var data = {
                    id: selectedIds,
                    file: inputval
                }
                this.openAjaxEvent('/admin/Equipment/updateA', data)
            }
            //更新辅导程序包
            if (flag == 2) {
                var data1 = {
                    id: selectedIds,
                    file: inputval
                }
                this.openAjaxEvent('/admin/Equipment/updateB', data1)
            }
            //添加云打印
            if (flag == 3) {
                var data2 = {
                    serial_number: inputval
                }
                this.openAjaxEvent('/admin/Equipment/clientAdd', data2)
            }
            //  远程重启
            if (flag == 4) {
                var data3 = {
                    id: selectedIds
                }
                this.openAjaxEvent('/admin/Equipment/updateC', data3)
            }
            //  状态恢复
            if (flag == 5) {
                var data4 = {
                    id: selectedIds
                }
                this.openAjaxEvent('/admin/Equipment/updateD', data4)
            }
            // 在线时长
            if (flag == 6) {
                if (selectedIds.length > 0 && selectedIds.length < 11) {
                    var data5 = datas.map((n) => {
                        return n.id
                    })
                    console.log(data5)
                    this.routeraddrClick('/dvcisonline', data5.join(','))
                } else {
                    if (selectedIds.length < 1) {
                        ElMessage({
                            type: 'error',
                            message: '至少选择一个设备,再查看在线时长'
                        })
                    }
                    if (selectedIds.length > 10) {
                        ElMessage({
                            type: 'error',
                            message: '最多选择10个设备,再查看在线时长'
                        })
                    }
                }
            }
            // 扫码广告开关
            if (flag == 7) {
                if (selectedIds.length < 1) {
                    ElMessage({
                        type: 'error',
                        message: '至少选择一个设备,再点击扫码广告开关'
                    })
                } else {
                    var data6 = datas.map((n) => {
                        return n.id
                    })

                    ElMessageBox.confirm('扫设备二维码打印时，是否跳转关注公众号广告位', '扫码广告', {
                        distinguishCancelAndClose: true,
                        confirmButtonText: '开启',
                        cancelButtonText: '禁用'
                    })
                        .then(() => {
                            this.openAjaxEvent('/admin/Equipment/updateM', { id: data6, is_skip: 1 })
                        })
                        .catch((action) => {
                            if (action === 'cancel') {
                                this.openAjaxEvent('/admin/Equipment/updateM', { id: data6, is_skip: -1 })
                            } else {
                                ElMessage({
                                    type: 'info',
                                    message: '取消'
                                })
                            }
                        })
                }
            }
        },
        // 按钮-[更新程序][更新辅程序][添加云打印设备][远程重启][状态恢复]-Ajax传输数据
        openAjaxEvent(ajaxurl, data) {
            axios
                .post(ajaxurl, data, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message
                        })
                    }
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },

        // ---------------更新----------------
        // table表单中-更新按钮-点击事件
        updateGetdvcidEvent(id) {
            this.currentId = id
            console.log(this.currentId)
        },
        // table表中-更新按钮-相关更新Ajax传输数据
        updateEvent(ajaxurl, id, method) {
            if(method === 'get') {
                axios.get(ajaxurl, { params: { id }, headers: { Authentication: localStorage.getItem('token') } } )
                    .then((response) => {
                        if (response.data.code == 0) {
                            ElMessage({
                                type: 'success',
                                message: response.data.message
                            })
                        }
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                    })
            } else {
                axios.post(ajaxurl, { id }, { headers: { Authentication: localStorage.getItem('token') }})
                    .then((response) => {
                        if (response.data.code == 0) {
                            ElMessage({
                                type: 'success',
                                message: response.data.message
                            })
                        }
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                    })
            }
        },

        // --------退货-----------------
        // table表单中-退货按钮-点击事件
        gobackgoodsEvent(id) {
            this.ruleForm.id = id
            console.log(this.ruleForm)
        },
        // table表中-退货按钮-相关更新Ajax传输数据
        gobackEvent(formName, type) {
            this.ruleForm.type = type
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var getdatas = this.$refs[formName].model
                    console.log(getdatas)
                    axios
                        .post(
                            '/admin/Equipment/clientRejected',
                            {
                                id: getdatas.id,
                                time: getdatas.time.valueOf() / 1000,
                                remark: getdatas.remark,
                                type: getdatas.type
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible1 = ref(false) //关闭弹窗
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.pagesdatas['page'] = this.currentPage
                                        this.pagesdatas['PageSize'] = this.PageSize

                                        this.getAjaxDataEvent(this.pagesdatas)
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        // ------------排序--------------------------
        sortchangeEvent(e) {
            console.log(e)
            if (e.prop == 'putintimeStr') {
                e.prop = 'putintime'
            }
            if (e.prop == 'uptimeStr') {
                e.prop = 'uptime'
            }

            let orderval = ''
            if (e.order == 'ascending') {
                orderval = 1
            } else {
                orderval = -1
            }

            this.pagesdatas.orderkey = e.prop
            this.pagesdatas.orderval = orderval
            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Equipment/clientList', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        name: this.pagesdatas.keywords,
                        serial_number: this.pagesdatas.serial_number,
                        is_online: this.pagesdatas.is_online,
                        run_type: this.pagesdatas.run_type,
                        is_buy: this.pagesdatas.is_buy,
                        push_type: this.pagesdatas.push_type,
                        // fund: this.pagesdatas.fund,
                        is_partner: this.pagesdatas.is_partner,
                        isbind: this.pagesdatas.isbind,
                        companyid: this.pagesdatas.companyid,
                        ver: this.pagesdatas.ver,
                        putintime: this.pagesdatas.putintime,
                        uptime: this.pagesdatas.uptime,
                        printname: this.pagesdatas.printname,
                        printstatus: this.pagesdatas.printstatus,
                        fund: this.pagesdatas.fund,
                        orderkey: e.prop,
                        orderval: orderval,
                        page: 1,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data)
                })
                .catch(function (error) {
                    console.log(error)
                })
        },

        //table表单-点击单位格-带参跳转等
        handleOneData(row, column, cell, event) {
            //   console.log(row); //获取当前行数据
            //   console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
            //   console.log(event); //获取当前指针事件
            console.log(event.path[1].id) //获取当前指针所指向的单元格id值，配合标签的id使用
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:', val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1
            this.pagesdatas['page'] = this.currentPage
            this.pagesdatas['PageSize'] = this.PageSize

            var queryParam = {}
            queryParam.PageSize = val
            queryParam.page = 1
            queryParam.name = this.pagesdatas.keywords
            queryParam.serial_number = this.pagesdatas.serial_number
            queryParam.is_online = this.pagesdatas.is_online
            queryParam.run_type = this.pagesdatas.run_type
            queryParam.is_buy = this.pagesdatas.is_buy
            queryParam.push_type = this.pagesdatas.push_type
            // queryParam.fund = this.pagesdatas.fund;
            queryParam.is_partner = this.pagesdatas.is_partner
            queryParam.isbind = this.pagesdatas.isbind
            queryParam.ver = this.pagesdatas.ver
            queryParam.putintime = this.pagesdatas.putintime
            queryParam.uptime = this.pagesdatas.uptime
            queryParam.printname = this.pagesdatas.printname
            queryParam.printstatus = this.pagesdatas.printstatus
            queryParam.fund = this.pagesdatas.fund
            queryParam.orderkey = this.pagesdatas.orderkey
            queryParam.orderval = this.pagesdatas.orderval
            this.getAjaxDataEvent(queryParam)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas['page'] = this.currentPage
            this.pagesdatas['PageSize'] = this.PageSize

            var queryParam = {}
            queryParam.PageSize = this.PageSize
            queryParam.page = val
            queryParam.name = this.pagesdatas.keywords
            queryParam.serial_number = this.pagesdatas.serial_number
            queryParam.is_online = this.pagesdatas.is_online
            queryParam.run_type = this.pagesdatas.run_type
            queryParam.is_buy = this.pagesdatas.is_buy
            queryParam.push_type = this.pagesdatas.push_type
            // queryParam.fund = this.pagesdatas.fund;
            queryParam.is_partner = this.pagesdatas.is_partner
            queryParam.isbind = this.pagesdatas.isbind
            queryParam.ver = this.pagesdatas.ver
            queryParam.putintime = this.pagesdatas.putintime
            queryParam.uptime = this.pagesdatas.uptime
            queryParam.printname = this.pagesdatas.printname
            queryParam.printstatus = this.pagesdatas.printstatus
            queryParam.fund = this.pagesdatas.fund
            queryParam.orderkey = this.pagesdatas.orderkey
            queryParam.orderval = this.pagesdatas.orderval
            this.getAjaxDataEvent(queryParam)
        },
        //table表中-表格带状态-可扩展
        tableRowClassName({ rowIndex }) {
            //隔行显示
            if ((rowIndex + 1) % 2 === 0) {
                return 'warning-row'
            }
            return ''
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(flag) {
            console.log(flag)
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata, ide) {
            if (rowdata) {
                // 携带参数跳转
                if (routeraddr === '/sharedvccontroledit' || routeraddr === '/sharedvccontrolseelog') {
                    // 出厂-按钮跳转
                    if (ide) {
                        this.$router
                            .push({
                                path: routeraddr,
                                query: {
                                    id: rowdata.id,
                                    goout: ide,
                                    pagesdatas: JSON.stringify(this.pagesdatas)
                                }
                            })
                            .catch((error) => error)
                    } else {
                        // 编辑-按钮跳转
                        this.$router
                            .push({
                                path: routeraddr,
                                query: {
                                    id: rowdata.id,
                                    runtype: rowdata.run_type,
                                    pagesdatas: JSON.stringify(this.pagesdatas)
                                }
                            })
                            .catch((error) => error)
                    }
                } else {
                    if (routeraddr === '/dvcisonline') {
                        this.$router
                            .push({
                                path: routeraddr,
                                query: {
                                    ids: rowdata
                                }
                            })
                            .catch((error) => error)
                    } else {
                        if (routeraddr === '/agentlist') {
                            this.$router
                                .push({
                                    path: routeraddr,
                                    query: {
                                        agentname: rowdata.shop_code_end
                                    }
                                })
                                .catch((error) => error)
                        } else {
                            // 跳转-订单列表
                            if (routeraddr === '/orderlist') {
                                this.$router
                                    .push({
                                        path: routeraddr,
                                        query: {
                                            client: rowdata.client
                                        }
                                    })
                                    .catch((error) => error)
                            } else {
                                // 跳转-家庭成员
                                if (routeraddr === '/sharedvccontrolfamilyedit') {
                                    this.$router
                                        .push({
                                            path: routeraddr,
                                            query: {
                                                cid: rowdata.id,
                                                client: rowdata.client
                                            }
                                        })
                                        .catch((error) => error)
                                } else {
                                    this.$router
                                        .push({
                                            path: routeraddr,
                                            query: {
                                                id: rowdata.id
                                            }
                                        })
                                        .catch((error) => error)
                                }
                            }
                        }
                    }
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas() {
            axios
                .get('/admin/Equipment/clientList', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        name: this.pagesdatas.keywords,
                        serial_number: this.pagesdatas.serial_number,
                        is_online: this.pagesdatas.is_online,
                        run_type: this.pagesdatas.run_type,
                        is_buy: this.pagesdatas.is_buy,
                        push_type: this.pagesdatas.push_type,
                        is_partner: this.pagesdatas.is_partner,
                        isbind: this.pagesdatas.isbind,
                        ver: this.pagesdatas.ver,
                        putintime: this.pagesdatas.putintime,
                        uptime: this.pagesdatas.uptime,
                        printname: this.pagesdatas.printname,
                        printstatus: this.pagesdatas.printstatus,
                        fund: this.pagesdatas.fund,
                        orderkey: this.pagesdatas.orderkey,
                        orderval: this.pagesdatas.orderval,
                        export: 1
                    }
                })
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '550px'
            }
        },
        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
        searchFormEvent(data) {
            console.log('高级筛选-开始检索返回数据(页面):' + JSON.stringify(data))
            //加载loading
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用

            //判断-版本更新时间
            var getuptime = null
            if (data.uptime.start == '') {
                getuptime = ''
            } else {
                if (data.uptime.end == '') {
                    getuptime = data.uptime.start + ' ~ ' + (data.uptime.start + 86400 - 1)
                } else {
                    getuptime = data.uptime.start + ' ~ ' + (data.uptime.end + 86400 - 1)
                }
            }
            //判断-投放时间
            var getputintime = null
            if (data.putintime.start == '') {
                getputintime = ''
            } else {
                if (data.putintime.end == '') {
                    getputintime = data.putintime.start + ' ~ ' + (data.putintime.start + 86400 - 1)
                } else {
                    getputintime = data.putintime.start + ' ~ ' + (data.putintime.end + 86400 - 1)
                }
            }
            this.pagesdatas.uptime = getuptime
            this.pagesdatas.putintime = getputintime

            this.pagesdatas['page'] = this.currentPage
            this.pagesdatas['PageSize'] = this.PageSize

            var queryParam = {}
            queryParam.PageSize = this.PageSize
            queryParam.page = 1
            queryParam.name = data.keywords
            queryParam.serial_number= data.serial_number
            queryParam.is_online = data.is_online
            queryParam.run_type = data.run_type
            queryParam.is_buy = data.is_buy
            // queryParam.fund = data.fund;
            queryParam.push_type = data.push_type
            queryParam.is_partner = data.is_partner
            queryParam.isbind = data.isbind
            queryParam.ver = data.ver
            queryParam.putintime = getputintime
            queryParam.uptime = getuptime
            queryParam.printname = data.printname
            queryParam.printstatus = data.printstatus
            queryParam.fund = data.fund
            queryParam.orderkey = this.pagesdatas.orderkey
            queryParam.orderval = this.pagesdatas.orderval
            this.getAjaxDataEvent(queryParam)
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用

            this.pagesdatas['page'] = this.currentPage
            this.pagesdatas['PageSize'] = this.PageSize

            var queryParam = {}
            queryParam.PageSize = this.PageSize
            queryParam.page = 1
            queryParam.name = data.keywords
            queryParam.serial_number = data.serial_number
            queryParam.is_online = data.is_online
            queryParam.run_type = data.run_type
            queryParam.orderkey = this.pagesdatas.orderkey
            queryParam.orderval = this.pagesdatas.orderval
            this.getAjaxDataEvent(queryParam)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            if (successdata.code == 0) {
                var newdata = successdata.result.data
                // console.log('当前页面的data:' + JSON.stringify(newdata))
                this.mainthreetableData = newdata
                this.totalCount = successdata.result.total
                this.currentpage = successdata.result.current_page //当前页面页数
                // this.currentPage = successdata.current_page; //当前页面页数
                this.lastpage = successdata.result.last_page
                this.loading = false
                // 将双向绑定的值赋值给realtabledata做断离层，避免弹窗清空多选被选中的状态！
                this.realtabledata = this.mainthreetableData.slice((this.currentPage - this.lastpage) * this.PageSize, this.currentPage * this.PageSize)
            } else {
                ElMessage({
                    type: 'error',
                    message: successdata.message,
                    offset: 300
                })
                this.loading = false
            }
        },
        // 获取-投放场景-下拉信息
        getPushType(configinfo) {
            //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
            var alldatas = Array()
            var num = 0
            alldatas[num] = { value: num, label: '全部' }
            for (var key in configinfo) {
                num = num + 1
                alldatas[num] = {
                    value: configinfo[key].id,
                    label: configinfo[key].sort_key
                }
            }
            //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
            this.myselect.select[3].options = alldatas
        },
        // 获取-打印机类型-下拉信息
        getPrintName(configinfo) {
            //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
            var alldatas = Array()
            var num = 0
            alldatas[num] = { value: num, label: '全部' }
            for (var key in configinfo) {
                num = num + 1
                alldatas[num] = { value: configinfo[key], label: configinfo[key] }
            }
            //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
            this.myselect.select[6].options = alldatas
        },
        // 获取-资金方-下拉信息
        getZijinFun(configinfo) {
            //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
            var alldatas = Array()
            var num = 0
            alldatas[num] = { value: '', label: '全部' }
            for (var key in configinfo) {
                num = num + 1
                alldatas[num] = {
                    value: configinfo[key].sort_val,
                    label: configinfo[key].sort_key
                }
            }
            //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
            this.myselect.select[8].options = alldatas
        },
        // ajax获取当前页面的list数据
        getAjaxDataEvent(searchdatas) {
            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Equipment/clientList', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        name: searchdatas.name,
                        serial_number: searchdatas.serial_number,
                        is_online: searchdatas.is_online,
                        run_type: searchdatas.run_type,
                        is_buy: searchdatas.is_buy,
                        push_type: searchdatas.push_type,
                        is_partner: searchdatas.is_partner,
                        isbind: searchdatas.isbind,
                        // fund: searchdatas.fund,
                        ver: searchdatas.ver,
                        putintime: searchdatas.putintime,
                        uptime: searchdatas.uptime,
                        printname: searchdatas.printname,
                        printstatus: searchdatas.printstatus,
                        fund: searchdatas.fund,
                        orderkey: searchdatas.orderkey,
                        orderval: searchdatas.orderval,
                        page: searchdatas.page,
                        page_size: searchdatas.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data)
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            // 投放场景-下拉-数据
            axios
                .get('/admin/Equipment/getPushTypeSelect', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    this.getPushType(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })

            // 打印机类型-下拉-数据
            axios
                .get('/admin/Equipment/getPrintName', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: this.queryParam
                })
                .then((response) => {
                    this.getPrintName(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })

            // 资金方-下拉选项加载
            axios
                .get('/admin/Equipment/fundSelect', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    this.getZijinFun(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })

            // 数据列表-table表单数据
            // 定义
            this.pagesdatas = {
                page: this.currentPage,
                PageSize: this.PageSize
            }
            // console.log(this.PageSize)
            // 判断-路由携带-跳转数据
            let routerinfo = this.$route.query
            if (routerinfo.clientnumber) {
                this.pagesdatas = {
                    name: routerinfo.clientnumber,
                    PageSize: this.PageSize
                }
                this.maintwoforminfo.myinputone.input[0].content = routerinfo.clientnumber
            }
            // 兼容-跳转返回该页面-筛选条件不变！
            if (routerinfo.pagesdatas) {
                let mypagesdatas = JSON.parse(routerinfo.pagesdatas)
                this.pagesdatas = mypagesdatas
                this.currentPage = mypagesdatas['page']

                if (!this.pagesdatas['push_type']) {
                    // 普通筛选
                    this.maintwoforminfo.myinputone.input[0].content = mypagesdatas['keywords']
                    this.maintwoforminfo.myinputone.input[1].content = mypagesdatas['serial_number']
                    this.maintwoforminfo.selectleft.select[0].value = mypagesdatas['is_online']
                    this.maintwoforminfo.selectleft.select[1].value = mypagesdatas['run_type']
                } else {
                    // 来自高级筛选
                    this.myinput.input[0].content = mypagesdatas['keywords']
                    this.myinput.input[1].content = mypagesdatas['ver']
                    this.myinput.input[2].content = mypagesdatas['serial_number']
                    this.myselect.select[0].value = mypagesdatas['is_online']
                    this.myselect.select[1].value = mypagesdatas['run_type']
                    this.myselect.select[2].value = mypagesdatas['is_buy']
                    this.myselect.select[3].value = mypagesdatas['push_type']
                    this.myselect.select[4].value = mypagesdatas['is_partner']
                    this.myselect.select[5].value = mypagesdatas['isbind']
                    this.myselect.select[6].value = mypagesdatas['printname']
                    this.myselect.select[7].value = mypagesdatas['printstatus']
                    this.myselect.select[8].value = mypagesdatas['fund']
                }
            }

            this.getAjaxDataEvent(this.pagesdatas)
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>
<style scoped>
.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

/* table表中-更新&退货按钮-相关 */
.mydialog-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mydialog-box-button {
    margin: 0 10px 10px 0;
}

.my-tan-boxs {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 5% 0 0;
}
</style>
