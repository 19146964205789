<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <div class="box_title" style="">
          <div class="box_title_font">设备会员系统默认值</div>
          <div class="box_title_button"></div>
        </div>
        <!--内容-->
        <div class="box_content" id="switch">
          <div style="width: 100%; margin-top: 10px">
            <el-row>
              <el-col :span="24">
                <el-form ref="tableData" :model="options" class="demo-ruleForm">
                  <!-- 暂时禁用 -->
                  <!-- <el-form-item>
                    <el-select v-model="changevalue" placeholder="请选择">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-button
                      type="primary"
                      @click="submitChange(changevalue)"
                      style="margin-left: 10px"
                      >添加尺寸
                    </el-button>
                  </el-form-item> -->
                </el-form>
              </el-col>
              <el-col :span="24">
                <el-form ref="tableData" :model="tableData" :rules="ruleData" class="demo-ruleForm">
                  <el-form-item>
                    <el-table :header-cell-style="{ background: '#F5F7FA' }" :row-class-name="tableRowClassName"
                      :data="tableData" style="width: 100%" border v-loading="loading"
                      element-loading-text="正在飞速加载中...">
                      <el-table-column v-for="(mytabletitle, i) in tableTitle" :key="i" :fixed="mytabletitle.fixedstatu"
                        :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
                        <template #default="scope" v-if="
                          mytabletitle.prop === 'unit' ||
                          mytabletitle.prop === 'num' ||
                          mytabletitle.prop === 'price' ||
                          mytabletitle.prop === '2' ||
                          mytabletitle.prop === '3' ||
                          mytabletitle.prop === '1'
                        ">
                          <div v-if="mytabletitle.prop === 'unit'" class="table-line-box">
                            <el-form-item :prop="scope.$index + '.unit'" :rules="ruleData.unit">
                              <el-select v-model="scope.row.unit" placeholder="请选择">
                                <el-option label="年" value="年"></el-option>
                                <el-option label="月" value="月"></el-option>
                              </el-select>
                            </el-form-item>
                          </div>
                          <div v-if="mytabletitle.prop === 'num'" class="table-line-box">
                            <el-form-item :prop="scope.$index + '.num'" :rules="ruleData.num">
                              <el-input v-model="scope.row.num" placeholder="请输入..." clearable />
                            </el-form-item>
                          </div>
                          <div v-if="mytabletitle.prop === 'price'" class="table-line-box">
                            <el-form-item :prop="scope.$index + '.price'" :rules="ruleData.price">
                              <el-input v-model="scope.row.price" placeholder="请输入..." clearable />
                            </el-form-item>
                          </div>
                          <div v-if="mytabletitle.prop === '1'" class="table-line-box">
                            <el-form-item :prop="scope.$index + '.1'" :rules="ruleData['1']">
                              <el-input v-model="scope.row['1']" placeholder="请输入..." clearable />
                            </el-form-item>
                          </div>
                          <div v-if="mytabletitle.prop === '3'" class="table-line-box">
                            <el-form-item :prop="scope.$index + '.3'" :rules="ruleData['3']">
                              <el-input v-model="scope.row['3']" placeholder="请输入..." clearable />
                            </el-form-item>
                          </div>
                          <div v-if="mytabletitle.prop === '2'" class="table-line-box">
                            <el-form-item :prop="scope.$index + '.2'" :rules="ruleData['2']">
                              <el-input v-model="scope.row['2']" placeholder="请输入..." clearable />
                            </el-form-item>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="caozuo" label="操作" width="80" v-if="this.mypowerlimits.sbhyxtmrz_bc">
                        <template #default="scope">
                          <el-button type="text" v-if="scope.row.addisshow" @click="addRechargeEvent(tableData)">增加
                          </el-button>
                          <el-button type="text" v-if="!scope.row.addisshow"
                            @click="delRechargeEvent(scope.$index, tableData)">删除</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-form-item>
                  <el-form-item style="text-align: center" v-if="this.mypowerlimits.sbhyxtmrz_bc">
                    <el-button type="primary" @click="
                      submitForm('tableData', 'client_monthly_set', tableData)
                    " style="width: 150px" :disabled="buttondisable">保存</el-button>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import { defineComponent, ref } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";

export default defineComponent({
  name: "vdvcplusset",
  inject: ["reload"], //刷新引用
  data() {
    // 数字input框-校验（可小数）【价格||首充价格】
    var checkInputEvent = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("必填项不能为空"));
      }
      if (value.toString().indexOf(" ") != -1) {
        return callback(new Error("值中不能有空格"));
      }
      setTimeout(() => {
        // 判断字符串是否为文字
        if (isNaN(value)) {
          callback(new Error("请输入数字"));
        } else {
          // 判断前两位是否为0开头的纯数字
          let arrydata = value.toString().length;
          if (arrydata > 1) {
            if (Number.parseInt(value.toString().substr(0, 1)) == 0) {
              if (
                Number.parseInt(value.toString().substr(1, 1)) ||
                Number.parseInt(value.toString().substr(1, 1)) == 0
              ) {
                callback(new Error("输入有误"));
              }
            }
          }
          let result = value.toString().indexOf(".");
          if (result != -1) {
            let arry = value.toString().split(".");
            if (arry[arry.length - 1].length > 2) {
              callback(new Error("保留小数点后两位"));
            } else {
              if (Number.parseInt(arry[arry.length - 1]) == 0) {
                callback(new Error("请输入完整小数"));
              } else {
                callback();
              }
            }
          }
          if (value < 0) {
            callback(new Error("值需大于0"));
          } else {
            callback();
          }
        }
      }, 500);
    };
    // 数字input框-校验-(仅整数)【会员有效期||】
    var checkInputEventInt = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("必填项不能为空"));
      }
      if (value.toString().indexOf(" ") != -1) {
        return callback(new Error("值中不能有空格"));
      }
      setTimeout(() => {
        // 判断前两位是否为0开头的纯数字
        let arrydata = value.toString().length;
        if (arrydata > 1) {
          if (Number.parseInt(value.toString().substr(0, 1)) == 0) {
            if (
              Number.parseInt(value.toString().substr(1, 1)) ||
              Number.parseInt(value.toString().substr(1, 1)) == 0
            ) {
              callback(new Error("输入有误"));
            }
          }
        }
        // 判断字符串是否存在小数点
        let result = value.toString().indexOf(".");
        if (result != -1) {
          callback(new Error("请输入整数"));
        } else {
          // 判断字符串是否为文字
          if (isNaN(value)) {
            callback(new Error("请输入整数"));
          } else {
            // 判断字符串是否为数字
            if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
              callback(new Error("请输入整数"));
            } else {
              if (value < 0) {
                callback(new Error("值需大于0"));
              } else {
                callback();
              }
            }
          }
        }
      }, 500);
    };
    return {
      mainonebread: [
        {
          name: "设置",
        },
        {
          name: "会员设置",
        },
        {
          name: "设备会员系统默认值",
          path: "/dvcplusset",
        },
      ], // 当前页-【面包屑导航】
      activeName: "one", //tabs标签-默认显示页

      // 控制提交保存按钮-防止多次点击-true禁用；false-启用
      buttondisable: false,

      //选择尺寸-来源于后端-写死数据
      options: ref([
        {
          value: "1",
          label: "A4",
        },
        {
          value: "2",
          label: "A3",
        },
        {
          value: "3",
          label: "6寸",
        },
      ]),
      changevalue: ref(""),

      //表单中的title
      tableTitle: [
        {
          fixedstatu: false,
          prop: "num",
          label: "数量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "unit",
          label: "单位",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "price",
          label: "价格（元）",
          width: "",
        },
      ], // 数据列表中-数据表头

      // PLUS设置
      tableData: [
        {
          2: "3",
          1: "1",
          3: "2",
          id: "3",
          num: "3",
          unit: "月",
          price: "10",
          addisshow: true,
        },
        {
          2: "3",
          1: "1",
          3: "2",
          id: "2",
          num: "3",
          unit: "季",
          price: "50",
        },
        {
          2: "3",
          1: "1",
          3: "2",
          id: "3",
          num: "3",
          unit: "年",
          price: "100",
        },
      ],
      ruleData: {
        num: [
          {
            required: true,
            validator: checkInputEventInt,
            trigger: "change",
          },
        ],
        unit: [
          {
            required: true,
            message: "单位不能为空",
            trigger: "change",
          },
        ],
        price: [
          {
            required: true,
            validator: checkInputEvent,
            trigger: "change",
          },
        ],
        "1": [
          {
            required: true,
            validator: checkInputEventInt,
            trigger: "change",
          },
        ],
        "2": [
          {
            required: true,
            validator: checkInputEventInt,
            trigger: "change",
          },
        ],
        "3": [
          {
            required: true,
            validator: checkInputEventInt,
            trigger: "change",
          },
        ],
      },

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
      isshow: true, //用于判断页面模块是否存在
    };
  },
  methods: {
    // 点击-添加尺寸
    submitChange(data) {
      console.log("当前获取的尺寸值为：" + data);
      if (data != "") {
        var hasid = 0;
        for (var i = 0; i < this.tableTitle.length; i++) {
          if (this.tableTitle[i].prop === data) {
            ElMessage({
              type: "warning",
              message: "已有该尺寸，无需重复添加！",
            });
            hasid = hasid + 1;
            return;
          }
        }
        if (hasid == 0) {
          var addtabledatas = {
            fixedstatu: false,
            prop: data,
            label: data + "（页数）",
            width: "",
          };
          this.tableTitle.push(addtabledatas);
          console.log(this.tableData);
        }
      } else {
        ElMessage({
          type: "warning",
          message: "请先选择尺寸再添加！",
        });
      }
    },
    //操作-增加事件
    addRechargeEvent(fromdata, times) {
      if (times) {
        if (fromdata.length < times) {
          var oneAddChat = {
            2: "",
            1: "",
            3: "",
            id: "",
            num: "",
            unit: "",
            price: "",
          };
          // js中通过push向数组最后新增指定的元素
          fromdata.push(oneAddChat);
        } else {
          ElMessage({
            type: "warning",
            message: "最多增加6条！",
          });
        }
      } else {
        var oneAddChats = {
          2: "",
          1: "",
          3: "",
          id: "",
          num: "",
          unit: "",
          price: "",
        };
        // js中通过push向数组最后新增指定的元素
        fromdata.push(oneAddChats);
      }
    },
    //操作-删除事件
    delRechargeEvent(ide, formdata) {
      // console.log("获取的删除id为：" + JSON.stringify(ide));
      // js中通过splice删除指定的元素
      formdata.splice(ide, 1);
    },
    //from表单-提交表单
    submitForm(formName, keyname, formdata) {
      // 控制提交保存按钮-防止多次点击-true禁用；false-启用
      this.buttondisable = true;
      // console.log("当前获取的formdata：" + JSON.stringify(formdata));
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 由于最初是用的id，非num,因此为兼容之前的，前端将num与id值统一
          formdata.forEach((element, key) => {
            // id与键关联-保证唯一
            element.id = key + 1;
            // (元)转（分）- 价格
            element.price = element.price * 100;
          });
          let getdatas = formdata;
          axios
            .post(
              "/admin/config/update",
              {
                key_name: keyname,
                contents: getdatas,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code == 0) {
                ElMessage({
                  type: "success",
                  message: response.data.message,
                  duration: 1000,
                  onClose: () => {
                    // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                    this.buttondisable = false;
                    this.reload(); //刷新
                  },
                });
              } else {
                setTimeout(() => {
                  // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                  this.buttondisable = false;
                  this.reload(); //刷新
                }, 1000);
              }
            })
            .catch(function (error) {
              // console.log(error);
              ElMessage({
                type: "warning",
                message: error,
                duration: 1000,
                onClose: () => {
                  // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                  this.buttondisable = false;
                  this.reload(); //刷新
                },
              });
            });
        } else {
          console.log("error submit!!");
          // 控制提交保存按钮-防止多次点击-true禁用；false-启用
          this.buttondisable = false;
          this.reload(); //刷新
          return false;
        }
      });
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true;
      //首次加载-PLUS设置
      axios
        .post(
          "/admin/config/info",
          {
            key_name: "client_monthly_set",
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          var getdata = response.data.result;

          // (分)转（元）- 价格
          getdata.forEach((element) => {
            element.price = element.price / 100;
          });
          // console.log(Object.keys(getdata[0]));
          // 通过Object.keys(getdata[0])获取key值，并将新数组进行凭借
          var arrary = [];
          Object.keys(getdata[0]).forEach(function (value) {
            if (value != "addisshow") {
              if (
                value != "num" &&
                value != "unit" &&
                value != "price" &&
                value != "id"
              ) {
                if (value == "3") {
                  let addtabledatas = {
                    fixedstatu: false,
                    prop: value,
                    label: "6寸照片（页数）",
                    width: "",
                  };
                  arrary.push(addtabledatas);
                } else {
                  if (value == "2") {
                    let addtabledatas2 = {
                      fixedstatu: false,
                      prop: value,
                      label: "A3（页数）",
                      width: "",
                    };
                    arrary.push(addtabledatas2);
                  } else {
                    if (value == "1") {
                      let addtabledatas1 = {
                        fixedstatu: false,
                        prop: value,
                        label: "A4（页数）",
                        width: "",
                      };
                      arrary.push(addtabledatas1);
                    }
                  }
                }
              }
            }
          });
          this.tableTitle = this.tableTitle.concat(arrary);
          getdata[0].addisshow = true;
          this.tableData = getdata;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
  },
});
</script>
<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
}

/* table框-高度 */
.table-line-box {
  height: 80px;
  display: flex;
  align-items: center;
}
</style>