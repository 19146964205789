<template>
    <div id="mymain">
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">申诉详情</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <el-button
                            type="primary"
                            @click="chuliTanClick(tancontent, (dialogVisible = true)), resetFormSenior('seniorform')"
                            v-if="getdatas.status == -1 && mypowerlimits.ggss_sh"
                            >审核</el-button
                        >
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <maindetails :detailsdata="details" style="margin-top: 20px"></maindetails>
                    <div style="margin-bottom: 20px; margin-top: 10px">
                        <div style="min-width: 80px; float: left; margin-right: 20px">
                            <span style="font-size: 14px; color: #333333; margin-left: 38px">凭证照片:</span>
                        </div>
                        <div>
                            <mainimgesshow :imgesdata="imgesdata"></mainimgesshow>
                        </div>
                    </div>

                    <el-divider></el-divider>
                    <maindetails :detailsdata="details1"></maindetails>
                </div>
            </div>
        </div>

        <!--弹出框-->
        <el-dialog v-model="dialogVisible" :title="tancontent.title" :width="this.searchwidth" :before-close="handleCloseSenior">
            <el-form ref="seniorform" :model="seniorform" :rules="rules" label-width="100px">
                <el-form-item label="申诉原因：" prop="name">
                    <span style="margin-right: 10px">{{ (seniorform.name = getdatas.reason) }}</span>
                </el-form-item>
                <el-form-item label="审核状态：" prop="status">
                    <el-radio-group v-model="seniorform.status">
                        <el-radio :label="1">审核通过</el-radio>
                        <el-radio :label="2">审核不通过</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div v-if="seniorform.status == 1">
                    <el-form-item label="是否退款：" prop="is_refund">
                        <el-radio-group v-model="seniorform.is_refund" @change="changeRadioEvent">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="-1">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="退款金额：" prop="refund_money">
                        <el-input
                            :disabled="seniorform.is_refund == 0"
                            type="number"
                            step="0.01"
                            min="0"
                            :max="seniorform.max_refund_money"
                            v-model="seniorform.refund_money"
                            clearable
                            placeholder="请输入退款金额"
                            @change="changeInputEvent"
                            @input="inputThisEvent"
                        ></el-input>
                        <div>
                            本单最大可退
                            <span style="color: red">￥{{ (seniorform.max_refund_money = getdatas.final_price / 100) }}</span>
                        </div>
                    </el-form-item>
                </div>
                <el-form-item label="申述回复：" prop="remark">
                    <el-input v-model="seniorform.remark" type="textarea"></el-input>
                </el-form-item>
                <el-form-item>
                    <div style="text-align: right">
                        <el-button @click="resetFormSenior('seniorform', (dialogVisible = false))">取消</el-button>
                        <el-button type="primary" class="main_two_search" @click="onSubmitSenior('seniorform')" :disabled="mydisabled">确定</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import maindetails from '@/components/Maindetails'
import mainimgesshow from '@/components/Mainimgesshow'
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'appealinfo',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '设备广告管理'
                },
                {
                    name: '广告申诉',
                    path: '/advertappeal'
                },
                {
                    name: '申诉详情',
                    path: '/advertappealinfo'
                }
            ], // 当前页-【面包屑导航】

            details: {
                showstatus: 1, //确定样式模块
                title: '广告信息',
                button: {
                    isshow: false,
                    name: '',
                    jump: '' //点击跳转
                },
                content: [
                    {
                        name: '申诉广告编号:',
                        value: '',
                        jumpbt: '',
                        jump: ''
                    },
                    {
                        name: '申诉原因:',
                        value: ''
                    },
                    {
                        name: '联系人:',
                        value: ''
                    },
                    {
                        name: '联系方式:',
                        value: ''
                    }
                ]
            }, //关于详情信息的组件
            details1: {
                showstatus: 1, //确定样式模块
                title: '审核信息',
                button: {
                    isshow: false,
                    name: ''
                },
                content: [
                    {
                        name: '审核人:',
                        value: ''
                    },
                    {
                        name: '审核结果:',
                        value: ''
                    },
                    {
                        name: '是否退款:',
                        value: ''
                    },
                    {
                        name: '审核备注:',
                        value: ''
                    },
                    {
                        name: '审核时间:',
                        value: ''
                    }
                ]
            }, //关于详情信息的组件
            imgesdata: {
                showstatus: 2,
                content: [
                    {
                        url: '',
                        preview: ['']
                    }
                ]
            }, //关于图片展示的组件
            getdatas: {
                id: null,
                status: null
            }, //接口获取的总数据

            tancontent: {
                title: '申述审核' //弹框-标题
            },
            seniorform: {
                name: '',
                status: 1,
                is_refund: 1,
                refund_money: '',
                max_refund_money: 0,
                remark: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '申诉原因为必填项',
                        trigger: 'blur'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '状态为必选项',
                        trigger: 'blur'
                    }
                ],
                is_refund: [
                    {
                        required: true,
                        message: '退款为必选项',
                        trigger: 'blur'
                    }
                ],
                refund_money: [
                    {
                        required: true,
                        message: '退款金额为必填项',
                        trigger: 'blur'
                    }
                ],
                remark: [
                    {
                        required: true,
                        message: '申述回复为必填项',
                        trigger: 'blur'
                    }
                ]
            },
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

            dialogVisible: false,
            searchwidth: '580px',

            mydisabled: false,

            loading: false
        }
    },
    methods: {
        //弹出框-审核-提交表单
        onSubmitSenior(formName) {
            // 前端防抖
            this.mydisabled = true

            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var postdata = {}
                    if (getdatas.status == 1) {
                        postdata = {
                            id: this.getdatas.id,
                            status: getdatas.status,
                            is_refund: getdatas.is_refund,
                            refund_money: getdatas.refund_money,
                            remark: getdatas.remark
                        }
                    } else {
                        console.log(getdatas.status)
                        postdata = {
                            id: this.getdatas.id,
                            status: getdatas.status,
                            remark: getdatas.remark
                        }
                    }

                    axios
                        .post('/admin/ClientAdvert/appealAudit', postdata, {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible = false //关闭弹窗
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.mydisabled = false
                                        this.reload() //刷新
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },
        //弹出框-审核-取消
        resetFormSenior(formName) {
            this.$refs[formName].resetFields()
        },
        //弹窗中-退款金额-是否退款-选择事件
        changeRadioEvent(val) {
            if (val == -1) {
                this.seniorform.refund_money = null
            }
        },
        //弹窗中-退款金额-input输入事件
        inputThisEvent(val) {
            if (val > this.seniorform.max_refund_money) {
                this.seniorform.refund_money = this.seniorform.max_refund_money
            } else {
                if (val < 0) {
                    this.seniorform.refund_money = 0
                }
            }
            if (val.split('.')[1]) {
                if (val.split('.')[1].length > 2) {
                    this.seniorform.refund_money = val.split('.')[0] + '.' + val.split('.')[1].substr(0, 2)
                }
            }
        },
        //点击进入路由页面
        routeraddrClick(routeraddr) {
            this.$router.push(routeraddr).catch((error) => error)
            // console.log(routeraddr);
        },
        //点击按钮-弹出弹框
        chuliTanClick(taninfo) {
            // console.log(taninfo);
            this.tancontent.content = taninfo.content
            this.tancontent.title = taninfo.title
        },
        //获取当前windows页面信息-实时修改【高级筛选】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            this.loading = true

            //通过Ajax向后台获取数据 this.$route.query.id
            axios
                .post(
                    '/admin/ClientAdvert/appealInfo',
                    {
                        id: this.$route.query.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.getdatas = response.data.result
                    var getdatas = response.data.result

                    // 渲染-广告信息
                    this.details.content = [
                        {
                            name: '申诉广告编号:',
                            value: getdatas.ad_code,
                            jumpbt: '[查看详情]',
                            jump: '/advertlistinfo',
                            jumpdatas: { id: getdatas.ad_id }
                        },
                        {
                            name: '申诉原因:',
                            value: getdatas.reason
                        },
                        {
                            name: '联系人:',
                            value: getdatas.guest_name
                        },
                        {
                            name: '联系方式:',
                            value: getdatas.guest_mobile
                        }
                    ]
                    // 渲染-审核信息
                    this.details1.content = [
                        {
                            name: '审核人:',
                            value: getdatas.admin_name
                        },
                        {
                            name: '审核结果:',
                            value: getdatas.status_name
                        },
                        {
                            name: '是否退款:',
                            value: getdatas.status != -1 ? getdatas.is_refund_name + ' ￥' + getdatas.refund_price / 100 : ''
                        },
                        {
                            name: '审核备注:',
                            value: getdatas.remark
                        },
                        {
                            name: '审核时间:',
                            value: getdatas.status != -1 ? this.$utils.formatDate(getdatas.uptime) : ''
                        }
                    ]
                    //渲染-图片
                    var getimages = []
                    getdatas.image.forEach((element) => {
                        if (element != '' && element != null) {
                            getimages.push({
                                url: element,
                                preview: [element]
                            })
                        }
                    })
                    this.imgesdata.content = getimages

                    this.loading = false
                })
                .catch(function(error) {
                    console.log(error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        maindetails,
        mainimgesshow
    }
})
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
