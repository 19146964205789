<template>
  <div>
    <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <div class="box_title" style="">
          <div class="box_title_font">
            <i class="el-icon-s-operation"></i> 推荐位配置
          </div>
          <div class="box_title_button"></div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row>
            <el-col :span="3">
              <div style="width: 100%"></div>
            </el-col>
            <el-col :span="18" style="margin-top: 20px">
              <el-form
                ref="ruleForm"
                :model="ruleForm"
                label-width="140px"
                class="demo-ruleForm"
              >
                <el-form-item label="文库推荐位：" prop="name">
                  <el-row>
                    <!-- 操作按钮 -->
                    <el-col :span="24">
                      <el-button
                        type="primary"
                        icon="el-icon-circle-plus-outline"
                        style="margin-right: 10px"
                        @click="addSeatEvent"
                      >
                        添加文库推荐位</el-button
                      >
                      <el-button
                        type="primary"
                        plain
                        @click="submitForm('ruleForm')"
                        >提交</el-button
                      >
                    </el-col>
                    <!-- 文库推荐位 -->
                    <el-col
                      :span="24"
                      style="margin: 20px 0 0 20px"
                      v-for="(getseat, i) in ruleForm"
                      :key="i"
                    >
                      <el-row :gutter="40">
                        <!-- 左侧-框 -->
                        <el-col
                          :span="20"
                          style="
                            border: 1px dashed #94a3d2;
                            border-radius: 2px;
                            padding: 20px;
                          "
                        >
                          <el-row :gutter="20">
                            <!-- 导航等form信息 -->
                            <el-col :span="12">
                              <el-form-item
                                label="导航标题："
                                :prop="i + '.title'"
                                :rules="rules.name"
                              >
                                <el-input
                                  v-model="getseat.title"
                                  maxlength="12"
                                  show-word-limit
                                  placeholder="请输入标题"
                                  clearable
                                ></el-input>
                              </el-form-item>
                              <el-form-item
                                label="导航标题颜色："
                                style="margin-top: 20px"
                                :prop="i + '.titlecolor'"
                                :rules="rules.name"
                              >
                                <el-input
                                  v-model="getseat.titlecolor"
                                  placeholder="#000000"
                                  clearable
                                >
                                  <template #append>
                                    <el-color-picker
                                      v-model="getseat.titlecolor"
                                      size="mini"
                                    />
                                  </template>
                                </el-input>
                              </el-form-item>
                              <el-form-item
                                label="更多跳转分类："
                                style="margin-top: 20px"
                              >
                                <el-input
                                  v-model="getseat.link_url"
                                  placeholder="请输入跳转分类ID"
                                  clearable
                                ></el-input>
                              </el-form-item>
                              <el-form-item
                                label="导航图标："
                                style="margin-top: 20px"
                                :prop="i + '.icon_img'"
                                :rules="rules.name"
                              >
                                <div>
                                  <mainimgesup
                                    v-model="getseat.icon_img"
                                    :myimgesup="myimgesup"
                                    :myimgesupisshow="myimgesupisshow"
                                    :filelist="getseat.icon_img_list"
                                    v-on:getuploadfile="
                                      (data) =>
                                        getUploadFileEvent(
                                          data,
                                          i,
                                          'icon_img',
                                          'icon_img_list'
                                        )
                                    "
                                  ></mainimgesup>
                                </div>
                              </el-form-item>
                              <el-form-item
                                label="底部背景图边框："
                                style="margin-top: 20px"
                                :prop="i + '.back_img'"
                                :rules="rules.name"
                              >
                                <div>
                                  <mainimgesup
                                    v-model="getseat.back_img"
                                    :myimgesup="myimgesup2"
                                    :myimgesupisshow="myimgesupisshow"
                                    :filelist="getseat.back_img_list"
                                    v-on:getuploadfile="
                                      (data) =>
                                        getUploadFileEvent(
                                          data,
                                          i,
                                          'back_img',
                                          'back_img_list'
                                        )
                                    "
                                  ></mainimgesup>
                                </div>
                              </el-form-item>
                            </el-col>
                            <!-- 效果图 -->
                            <!-- :style="{'background': 'url('+'../../assets/imgs/loginright.png'+') center center no-repeat'}" -->
                            <el-col :span="12">
                              <div
                                style="
                                  border-radius: 8px;
                                  width: 95%;
                                  min-height: 220px;
                                  background-size: 100% 100%;
                                  background-color: #d8d8d880;
                                  padding: 5px 10px 10px 10px;
                                "
                                :style="{
                                  background:
                                    '#d8d8d880 url(' +
                                    getseat.back_img +
                                    ') center center no-repeat',
                                }"
                              >
                                <div style="height: 30px; line-height: 24px">
                                  <div style="float: left; margin-right: 5px">
                                    <el-image
                                      :src="getseat.icon_img"
                                      style="
                                        line-height: 30px;
                                        width: 25px;
                                        height: 25px;
                                      "
                                    >
                                      <template #error>
                                        <div
                                          style="
                                            height: 24px;
                                            width: 24px;
                                            background-color: #b1b1b1a6;
                                          "
                                        ></div>
                                      </template>
                                    </el-image>
                                  </div>
                                  <div
                                    :style="{
                                      color: getseat.titlecolor
                                        ? getseat.titlecolor
                                        : '#e4bd62',
                                    }"
                                  >
                                    <span style="font-size: 14px">
                                      {{
                                        getseat.title
                                          ? getseat.title
                                          : "请输入标题"
                                      }}</span
                                    >
                                    <span style="float: right; font-size: 14px"
                                      >更多<i class="el-icon-arrow-right"></i
                                    ></span>
                                  </div>
                                </div>
                                <div
                                  style="
                                    height: 200px;
                                    text-align: center;
                                    line-height: 200px;
                                    background-color: #ffffff;
                                    border-radius: 8px;
                                  "
                                >
                                  <span
                                    style="font-size: 40px; color: #d4d4d463"
                                    >效果图</span
                                  >
                                </div>
                              </div>
                            </el-col>
                          </el-row>
                        </el-col>
                        <!-- 右侧-删除按钮 -->
                        <el-col :span="4">
                          <el-button type="danger" @click="delSeatEvent(i)"
                            >删除</el-button
                          >
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="3">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone.vue";
import mainimgesup from "@/components/Mainimgesup.vue";
import axios from "axios";
import { ElMessage } from "element-plus";

export default {
  name: "recposition",
  data() {
    return {
      mainpagedata: {
        mainonebread: [
          {
            name: "教育",
          },
          {
            name: "文档管理",
          },
          {
            name: "推荐位配置",
            path: "/recommendposition",
          },
        ], // 当前页-【面包屑导航】
      },
      mytuijianimg: require("@/assets/imgs/upload/upload.png"),

      myimgesupisshow: true, //form表单-imgesuploade上传图片
      //上传-导航图标
      myimgesup: {
        uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        ismust: true, //是否必填，true表示必填
        action: "/api/admin/upload/file", //upload上传API地址
        headers: { Authentication: localStorage.getItem("token") }, //upload上传headers
        title: "点击上传", //button-按钮文字
        tips: "建议尺寸46px*46px", //提示&建议文字
        limit: "1", //上传数量限制
        target_id: 3, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
        rules: {
          images: {
            image_width: 46, //图片宽度
            image_height: 46, //图片高度
            size: 200, //文件大小（单位kb）
            type: [
              "image/jpeg",
              "image/jpg",
              "image/gif",
              "image/bmp",
              "image/png",
            ], //文件格式
          },
        },
      },
      //上传-底部背景图边框
      myimgesup2: {
        uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        ismust: true, //是否必填，true表示必填
        action: "/api/admin/upload/file", //upload上传API地址
        headers: { Authentication: localStorage.getItem("token") }, //upload上传headers
        title: "点击上传", //button-按钮文字
        tips: "建议尺寸690px*440px", //提示&建议文字
        limit: "1", //上传数量限制
        target_id: 3, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
        rules: {
          images: {
            image_width: 690, //图片宽度 690
            image_height: 440, //图片高度 440
            size: 200, //文件大小（单位kb）
            type: [
              "image/jpeg",
              "image/jpg",
              "image/gif",
              "image/bmp",
              "image/png",
            ], //文件格式
          },
        },
      },
      // filelist: [
      //   {
      //     name: "food.jpeg",
      //     url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
      //   },
      // ],

      ruleForm: [
        {
          title: "",
          titlecolor: "",
          link_url: "",
          icon_img: "",
          back_img: "",
        },
      ],
      rules: {
        name: [
          {
            required: true,
            message: "必填项",
            trigger: "change",
          },
        ],
      },
      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
    };
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/config/info",
          {
            key_name: "mini_recommend",
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          var newdatas = response.data.result;
          // for (var i = 0; i < newdatas.length; i++) {
          //   newdatas[i].icon_img_list = [
          //     { name: null, url: newdatas[i].icon_img },
          //   ];
          //   newdatas[i].back_img_list = [
          //     { name: null, url: newdatas[i].back_img },
          //   ];
          // }
          this.ruleForm = newdatas;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  mounted() {},
  methods: {
    //获取upload子组件回传的数据
    getUploadFileEvent(data, idex, keys) {
      console.log("当前获取的回传数据：" + JSON.stringify(data), idex, keys);
      this.ruleForm[idex][keys] = data.url;
      var keylist = keys + "_list";
      console.log(keylist, data.name);
      this.ruleForm[idex][keylist][0].name = data.name;
    },
    //点击添加-文库推荐位
    addSeatEvent() {
      var data = {
        title: "",
        titlecolor: "",
        link_url: "",
        icon_img: "",
        back_img: "",
      };
      this.ruleForm.unshift(data); //在数组起始位置插入数据
      // this.ruleForm.push(data); //在数组尾部位置插入数据
    },
    //点击删除-推荐位
    delSeatEvent(idex) {
      this.ruleForm.splice(idex, 1);
    },
    //提交
    submitForm(formName) {
      if (this.$refs[formName].model[0]) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log(
              "提交的文库位置信息：" +
                JSON.stringify(this.$refs[formName].model)
            );
            axios
              .post(
                "/admin/config/update",
                {
                  key_name: "mini_recommend",
                  contents: this.$refs[formName].model,
                },
                {
                  headers: {
                    Authentication: localStorage.getItem("token"),
                  },
                }
              )
              .then((response) => {
                if (response.data.code === 0) {
                  ElMessage({
                    type: "success",
                    message: "提交成功！",
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
        console.log("提交文库推荐位为空！");
      }
    },
  },
  components: {
    mainone,
    mainimgesup,
  },
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #97a0c3;
  padding: 5px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
}
</style>