<template>
    <div>
        <!-- 弹窗 -->
        <div>
            <modelDialog :isshow="dialogVisible" title="添加" searchWidth="1000px" v-on:beforeclose="handleClose">
                <template v-slot:search>
                    <modelSearch :gaoisshow="false" :forminfo="maintwoforminfo" v-on:getsearchinfo="getSeachinfoEvent" />
                </template>
                <template v-slot:table>
                    <modelTable
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        ref="mymultipleTableRef"
                        :tabletitle="mainthreetabletitle"
                        :tabledata="mainthreetableData"
                        :totalcount="totalCount"
                        :currentpage="currentPage"
                        :pagesize="PageSize"
                        :Lastpage="lastpage"
                        :selectionIsShow="true"
                        @handleSelection="handleSelectionEvent"
                        @handleSelectionOnline="handleSelectionChangeOnline"
                    >
                        <template #content="{ scope, prop }">
                            <div>
                                <span>{{ scope.row[prop] }}</span>
                            </div>
                        </template>
                    </modelTable>
                </template>
                <template v-slot:pages>
                    <modelPages
                        :totalCount="totalCount"
                        :currentPage="currentPage"
                        :pageSizes="pageSizes"
                        :PageSize="PageSize"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    />
                </template>
                <template v-slot:footbtn>
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="submitEvent(this.multipleSelection)">确定</el-button>
                </template>
            </modelDialog>
        </div>
    </div>
</template>

<script>
import modelDialog from '@/vspecial/operate/wecom/minipluswecom/modelDialog.vue'
import modelSearch from '@/vspecial/operate/wecom/minipluswecom/modelSearch.vue'
import modelTable from '../../../operate/memberBenefits/memberSet/dpw-table.vue'
import modelPages from '@/vspecial/operate/wecom/minipluswecom/modelPages.vue'
import { defineComponent } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default defineComponent({
    name: 'addUserOpenLock',
    inject: ['reload'],
    props: ['ModelValue', 'SearchModel', 'TableTitle', 'PostUrl', 'PostAxios'],
    watch: {
        ModelValue: {
            handler(newVal) {
                this.modelvalue = newVal
            },
            deep: true,
            immediate: true
        },
        PostUrl: {
            handler(newVal) {
                this.posturl = newVal
            },
            deep: true,
            immediate: true
        },
        PostAxios: {
            handler(newVal) {
                this.postaxios = newVal
            },
            deep: true,
            immediate: true
        },
        SearchModel: {
            handler(newVal) {
                this.maintwoforminfo = newVal
            },
            deep: true,
            immediate: true
        },
        TableTitle: {
            handler(newVal) {
                this.mainthreetabletitle = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            modelvalue: {},
            dialogVisible: false,
            maintwoforminfo: {},
            mainthreetabletitle: [],
            mainthreetableData: [],
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            currentPage: 1,
            pageSizes: [10, 20, 50, 100],
            PageSize: 10,
            multipleSelection: [], //多选的数据
            posturl: '',
            postaxios: 'post',
            pagesdatas: ''
        }
    },
    methods: {
        // 清除-单个tag
        closeEvent(data, index) {
            delete this.modelvalue[index]

            if (JSON.stringify(this.modelvalue) == '{}') {
                this.$emit('modelValue', '')
            } else {
                this.$emit('modelValue', this.modelvalue)
            }
            this.multipleSelection = this.multipleSelection.filter((n) => n.id != index)
            this.moreDataBackShow(this.multipleSelection)
        },
        // 点击-全部删除-按钮
        delAllEvent() {
            this.modelvalue = ''
            this.$emit('modelValue', this.modelvalue)
            this.multipleSelection = []
            this.moreDataBackShow(this.multipleSelection)
        },
        // 点击-添加更多按钮-打开弹窗
        clickAddEvent() {
            this.postAxios(this.pagesdatas)
            this.multipleSelection = []
            this.dialogVisible = true
        },
        // 弹窗-关闭弹窗
        handleClose(done) {
            // done()
            this.dialogVisible = false
        },
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },
        // 去重
        reduce(datas) {
            let obj = {}
            let reduce = []
            reduce = datas.reduce(function (item, next) {
                //item为没有重复id的数组，next为当前对象
                obj[next.id] ? '' : (obj[next.id] = true && item.push(next))
                return item
            }, [])

            return reduce
        },
        // 弹窗-多选数据-选中
        handleSelectionEvent(datas) {
            let all_datas = [...this.multipleSelection, ...this.deepcopy(datas)]
            // 去重
            this.multipleSelection = this.reduce(all_datas)
        },
        // 弹窗-多选数据-取消
        handleSelectionChangeOnline({ datas, row }) {
            this.multipleSelection = this.multipleSelection.filter((n) => n.id != row.id)
            this.handleSelectionEvent(datas)
        },
        // 弹窗-确定
        submitEvent(datas) {
            let data = {}
            if (!datas || datas.length == 0) {
                ElMessage({ type: 'error', message: '请选择代理商', duration: 3000 })
                return
            }
            datas.map((n) => {
                data[n.id] = n
                return
            })
            this.modelvalue = data
            this.dialogVisible = false
            this.$emit('modelValue', this.modelvalue)
        },

        // 弹窗-多选-检查反显
        moreDataBackShow(datas) {
            console.log(this.$refs.mymultipleTableRef)
            if (this.$refs.mymultipleTableRef) {
                console.log('fanxian:', datas)
                this.$refs.mymultipleTableRef.backShow(datas, this.mainthreetableData)
            }
        },

        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            this.pagesdatas.page = 1
            this.pagesdatas.page_size = val

            this.postAxios(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            //加载loading
            this.loading = true
            this.pagesdatas.page = val
            this.pagesdatas.page_size = this.PageSize

            this.postAxios(this.pagesdatas)
        },

        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // this.pagesdatas = data
            this.loading = true
            this.pagesdatas = {
                ...data,
                page: 1,
                page_size: this.PageSize
            }

            this.postAxios(this.pagesdatas)
        },
        // 接口
        postAxios(datas, index) {
            if (this.postaxios) {
                axios
                    .get(this.posturl, {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: datas
                    })
                    .then((response) => {
                        if (!index) {
                            this.myaxiosfunc(response.data.result)
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            } else {
                axios
                    .post(this.posturl, datas, {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    })
                    .then((response) => {
                        if (!index) {
                            this.myaxiosfunc(response.data.result)
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page
            this.lastpage = successdata.last_page
            this.loading = false

            this.moreDataBackShow(this.multipleSelection)
        }
    },
    created() {
        this.loading = true
        this.pagesdatas = {
            page: 1,
            page_size: this.PageSize
        }

        // this.postAxios(this.pagesdatas)
    },
    components: {
        modelDialog,
        modelSearch,
        modelTable,
        modelPages
    }
})
</script>

<style scoped>
.model-class {
    width: 100%;
    min-height: 40px;
    border: 1px solid var(--el-border-color-base);
    border-radius: 4px;
    background-color: #fff;
}

.model-class:hover {
    cursor: pointer;
    border-color: var(--el-text-color-placeholder);
}
.model-class-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;

    padding: 0px 5px;
}
.mx-1 {
    margin-right: 8px;
}
</style>
