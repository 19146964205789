<template>
  <div class="page-sim">
    <el-breadcrumb>
      <el-breadcrumb-item>运营</el-breadcrumb-item>
      <el-breadcrumb-item>营销玩法</el-breadcrumb-item>
      <el-breadcrumb-item>电信手机卡</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form
      class="search-form"
      ref="searchFormELe"
      :model="searchForm"
      inline
      :disabled="loading"
      @keydown.enter="queryList"
    >
      <el-form-item label="姓名" prop="realname">
        <el-input v-model="searchForm.realname" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="手机号" prop="telphone">
        <el-input v-model="searchForm.telphone" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="自选手机号" prop="phonenumber">
        <el-input v-model="searchForm.phonenumber" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="loading" @click="onSearch">查询</el-button>
        <el-button @click="onReset">重置</el-button>
      </el-form-item>
    </el-form>
    
    <div class="page-container">
      <div class="t-line">
        <h4>数据列表</h4>
        <div>
          <el-button v-if="mypowerlimits['dxsjk_yhqck']" type="primary" @click="openSettingDialog">优惠券设置</el-button>
          <el-button type="warning" plain @click="queryList(1)">导出</el-button>
        </div>
      </div>
      
      <el-table
        :data="tableData"
        v-loading="loading"
        border
      >
        <template v-for="(column, index) in tableJSON" :key="index">
          <el-table-column
            v-if="column.prop !== 'coupon'"
            :label="column.label"
            :prop="column.prop"
            :min-width="column.minWidth"
          />
          <el-table-column
            v-else
            :label="column.label"
            :prop="column.prop"
            :min-width="column.minWidth"
            :fixed="column.fixed"
          >
            <template #default="{row}">
              <el-button type="text" link @click="viewCoupon(row)">查看</el-button>
            </template>
          </el-table-column>
        </template>
      </el-table>
      
      <div class="pagination-box">
        <el-pagination
          v-model:page-size="pagination.page.page_size"
          v-model:current-page="pagination.page.page"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :total="pagination.total"
          @currentChange="queryList"
          @sizeChange="queryList"
        />
      </div>
      
    </div>
    <CouponSetting ref="couponSettingEle" @choice="onChoice" />
    <ChoiceCoupon ref="choiceCouponEle" @submit="setChoiceCoupons" />
    <IssueCoupon ref="issueCouponEle" />
  </div>
</template>

<script setup>
import {reactive, ref} from 'vue'
import http from '@/assets/js/http'
import CouponSetting from './dialog/couponSetting.vue'
import ChoiceCoupon from './dialog/choiceCoupon.vue'
import IssueCoupon from './dialog/issueCoupon.vue'
import {ElMessage} from "element-plus";

const mypowerlimits = reactive(JSON.parse(localStorage.getItem('powerlimits')))

const searchFormELe = ref()
const searchForm = ref({
  realname: '',
  telphone: '',
  phonenumber: ''
})
const pagination = ref({
  page: {
    page: 1,
    page_size: 10
  },
  total: 0
})

const tableData = ref([1])
const tableJSON = ref([
  {
    label: '姓名',
    prop: 'realname',
    minWidth: 100
  },
  {
    label: '手机号',
    prop: 'telphone',
    minWidth: 100
  },
  {
    label: '身份证号',
    prop: 'cardno',
    minWidth: 140
  },
  {
    label: '收货地址',
    prop: 'address',
    minWidth: 180
  },
  {
    label: '自选手机号',
    prop: 'phonenumber',
    minWidth: 140
  },
  {
    label: '营业厅',
    prop: 'type_name',
    minWidth: 100
  },
  {
    label: '运营商订单号',
    prop: 'orderid',
    minWidth: 160
  },
  {
    label: '申请时间',
    prop: 'addtime',
    minWidth: 140
  },
  {
    label: '首充金额',
    prop: 'recharge',
    minWidth: 100
  },
  {
    label: '订单状态',
    prop: 'status_name',
    minWidth: 100
  },
  {
    label: '优惠券',
    prop: 'coupon',
    minWidth: 80,
    fixed: 'right'
  },
])

const loading = ref(false)
const queryList = (isExport = 0) => {
  const data = {
    ...searchForm.value,
    ...pagination.value.page,
    export: isExport
  }
  loading.value = true
  
  http.post(`/mobileCard/telecomIndex`, data).then(res => {
    if(!isExport) {
      const {total, data} = res
      pagination.value.total = total
      tableData.value = data
    } else {
      console.log(res)
      if(res.code === 0) ElMessage.warning(res.message)
      else ElMessage.error(res.message)
    }
  }).finally(() => {
    loading.value = false
  })
}
queryList()

const onSearch = () => {
  queryList(0)
}
const onReset = () => {
  searchFormELe.value.resetFields()
  pagination.value.page.page = 1
  pagination.value.page.page_size = 10
  pagination.value.total = 0
  queryList(0)
}

// 设置优惠券
const couponSettingEle = ref()
const openSettingDialog = () => {
  couponSettingEle.value.open()
}
// 选择优惠券
const choiceCouponEle = ref()
const onChoice = (row) => {
  choiceCouponEle.value.open(row)
}
const setChoiceCoupons = (row) => {
  couponSettingEle.value.setCoupons(row)
}
// 查看优惠券发放
const issueCouponEle = ref()
const viewCoupon = (row) => {
  issueCouponEle.value.open(row)
}
</script>

<style lang="scss" scoped>
@use "./style.scss";
</style>
