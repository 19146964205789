<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <!--内容-->
        <div class="box_content" id="switch">
          <el-tabs v-model="activeName" @tab-click="
            (tab, event) => {
              handleClick(tab, event);
            }
          ">
            <!-- PLUS设置 -->
            <el-tab-pane label="PLUS设置" name="one">
              <div style="width: 100%; margin-top: 10px">
                <el-form ref="tableData" :model="tableData" :rules="ruleData" class="demo-ruleForm" v-loading="loading"
                  element-loading-text="正在飞速加载中...">
                  <el-form-item>
                    <el-table :header-cell-style="{ background: '#F5F7FA' }" :data="tableData" style="width: 100%">
                      <el-table-column prop="name" label="名称" width="180">
                        <template #default="scope">
                          <el-form-item :prop="scope.$index + '.name'" :rules="ruleData.name">
                            <el-input v-model="scope.row.name" placeholder="最多6个中文" maxlength="6" show-word-limit />
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="code" label="会员有效期" min-width="100">
                        <template #default="scope">
                          <el-form-item :prop="scope.$index + '.code'" :rules="ruleData.code">
                            <el-input v-model="scope.row.code" placeholder="请输入会员有效期" clearable />
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="price" label="价格（元）" min-width="100">
                        <template #default="scope">
                          <el-form-item :prop="scope.$index + '.price'" :rules="ruleData.price">
                            <el-input v-model="scope.row.price" placeholder="请输入价格" clearable />
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="first_price" label="首充价格（元）" min-width="100">
                        <template #default="scope">
                          <el-form-item :prop="scope.$index + '.first_price'" :rules="ruleData.first_price">
                            <el-input v-model="scope.row.first_price" placeholder="请输入首充价格" clearable />
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column label="打印折扣（折）" min-width="100">
                        <template #default="scope">
                          <el-form-item :prop="scope.$index + '.discount'" :rules="ruleData.discount">
                            <el-input step="0.1" maxlength="3" clearable v-model="scope.row.discount"
                              placeholder="请输入折扣" />
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="plusicon" label="会员图标" :width="120">
                        <!-- uploadParams -->
                        <template #default="scope">
                          <el-upload class="upload-demo" :list-type="myimgesup.listtype" :action="
                            this.$baseurl +
                            myimgesup.action +
                            '?target_id=' +
                            myimgesup.target_id
                          " :headers="myimgesup.headers" :method="myimgesup.method" :data="uploadParams"
                            :file-list="scope.row.plusicon_file" :limit="myimgesup.limit" :on-exceed="onExceedEvent"
                            :on-preview="handlePreview" :on-remove="
                              (file, fileList) =>
                                handleRemove(
                                  file,
                                  fileList,
                                  scope.$index,
                                  'plusicon'
                                )
                            " :before-remove="beforeRemove" :before-upload="
  (file) =>
    beforeUpload(
      file,
      myimgesup.target_id,
      myimgesup.rules
    )
" :on-progress="uploadChange" :on-success="
  (response, file, fileList) =>
    uploadSuccess(
      response,
      file,
      fileList,
      scope.$index,
      'plusicon'
    )
" :on-error="uploadError">
                            <el-button type="text" v-if="
                              scope.row.plusicon == '' ||
                              scope.row.plusicon == null
                            ">添加</el-button>
                          </el-upload>
                        </template>
                      </el-table-column>
                      <el-table-column prop="content" label="赠送文案" min-width="180">
                        <template #default="scope">
                          <el-input v-model="scope.row.content" placeholder="最多30个中文" maxlength="30" show-word-limit
                            type="textarea" />
                        </template>
                      </el-table-column>
                      <el-table-column prop="giveimg" label="赠送图片" :width="120">
                        <template #default="scope">
                          <el-upload class="upload-demo" :list-type="myimgesup.listtype" :action="
                            this.$baseurl +
                            myimgesup.action +
                            '?target_id=' +
                            myimgesup.target_id
                          " :headers="myimgesup.headers" :data="uploadParams" :file-list="scope.row.giveimg_file"
                            :limit="myimgesup.limit" :on-exceed="onExceedEvent" :on-preview="handlePreview" :on-remove="
                              (file, fileList) =>
                                handleRemove(
                                  file,
                                  fileList,
                                  scope.$index,
                                  'giveimg'
                                )
                            " :before-remove="beforeRemove" :before-upload="
  (file) =>
    beforeUpload(
      file,
      myimgesup.target_id,
      myimgesup.rules
    )
" :on-progress="uploadChange" :on-success="
  (response, file, fileList) =>
    uploadSuccess(
      response,
      file,
      fileList,
      scope.$index,
      'giveimg'
    )
" :on-error="uploadError">
                            <el-button type="text" v-if="
                              scope.row.giveimg == '' ||
                              scope.row.giveimg == null
                            ">添加</el-button>
                          </el-upload>
                        </template>
                      </el-table-column>
                      <el-table-column label="赠送平台会员">
                        <el-table-column prop="send_family_plus" label="微信家庭版" width="100">
                          <template #default="scope">
                            <el-switch v-model="scope.row.send_family_plus" active-text="开启" inactive-text="关闭"
                              :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#cbcbcb">
                            </el-switch>
                          </template>
                        </el-table-column>
                        <el-table-column prop="send_enterprise_plus" label="微信企业版" width="100">
                          <template #default="scope">
                            <el-switch v-model="scope.row.send_enterprise_plus" active-text="开启" inactive-text="关闭"
                              :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#cbcbcb">
                            </el-switch>
                          </template>
                        </el-table-column>
                      </el-table-column>
                      <el-table-column prop="caozuo" label="操作" width="80">
                        <template #default="scope">
                          <el-button type="text" v-if="scope.row.addisshow" @click="addRechargeEvent(tableData)">增加
                          </el-button>
                          <el-button type="text" v-if="!scope.row.addisshow"
                            @click="delRechargeEvent(scope.$index, tableData)">删除</el-button>
                        </template>
                      </el-table-column>
                      <el-table-column prop="is_first" label="首充设置" width="100">
                        <template #default="scope">
                          <el-switch v-model="scope.row.is_first" active-text="开启" inactive-text="关闭" :active-value="1"
                            :inactive-value="2" active-color="#13ce66" inactive-color="#cbcbcb" @click="
                              changeSwitchEvent(
                                scope.row.is_first,
                                scope.$index
                              )
                            ">
                          </el-switch>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-form-item>
                  <el-form-item style="text-align: center" v-if="this.mypowerlimits.hysz_bc">
                    <el-button type="primary" @click="submitForm('tableData', 'coin_set', tableData)"
                      style="width: 150px" :disabled="buttondisable">保存</el-button>
                    <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                  </el-form-item>
                </el-form>
              </div>
            </el-tab-pane>
            <!-- 文库配置 -->
            <el-tab-pane label="文库配置" name="three">
              <el-row>
                <!-- 占位符 -->
                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="5">
                  <div style="width: 100%"></div>
                </el-col>
                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="14" :xl="11">
                  <el-form ref="memberDatafile" :model="memberDatafile" class="demo-ruleForm" label-width="120px">
                    <el-form-item label="权益说明：" prop="interestsfile" :rules="rulesMember.interestsfile">
                      <TEditor ref="editorfile" v-model="memberDatafile.interestsfile" v-on:geteditordatas="
                        (data) => {
                          getEditorDatasEvent(data, 2);
                        }
                      " />
                    </el-form-item>
                    <el-form-item v-if="this.mypowerlimits.hysz_bc">
                      <el-button type="primary" @click="
                        submitFormFull(
                          'memberDatafile',
                          'member_plus_document',
                          'interestsfile'
                        )
                      " style="width: 150px; margin-right: 10px">保存
                      </el-button>
                      <el-button @click="resetFormFull('memberDatafile', 2)">重置</el-button>
                    </el-form-item>
                  </el-form>
                </el-col>
                <!-- 占位符 -->
                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="4" :xl="8">
                  <div style="width: 100%"></div>
                </el-col>
              </el-row>
            </el-tab-pane>
            <!-- 课程配置 -->
            <el-tab-pane label="课程配置" name="four">
              <el-row>
                <!-- 占位符 -->
                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="5">
                  <div style="width: 100%"></div>
                </el-col>
                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="14" :xl="11">
                  <el-form ref="memberDataplus" :model="memberDataplus" class="demo-ruleForm" label-width="120px">
                    <el-form-item label="权益说明：" prop="interestsplus" :rules="rulesMember.interestsplus">
                      <TEditor ref="editormyplus" v-model="memberDataplus.interestsplus" v-on:geteditordatas="
                        (data) => {
                          getEditorDatasEvent(data, 3);
                        }
                      " />
                    </el-form-item>
                    <el-form-item v-if="this.mypowerlimits.hysz_bc">
                      <el-button type="primary" @click="
                        submitFormFull(
                          'memberDataplus',
                          'member_plus_course',
                          'interestsplus'
                        )
                      " style="width: 150px; margin-right: 10px">保存
                      </el-button>
                      <el-button @click="resetFormFull('memberDataplus', 3)">重置</el-button>
                    </el-form-item>
                  </el-form>
                </el-col>
                <!-- 占位符 -->
                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="4" :xl="8">
                  <div style="width: 100%"></div>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import TEditor from "@/components/Maineditor.vue";
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";

export default defineComponent({
  name: "vplusset",
  inject: ["reload"], //刷新引用
  data() {
    // 数字input框-校验（可小数）【价格||首充价格】
    var checkInputEvent = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("必填项不能为空"));
      }
      if (value.toString().indexOf(" ") != -1) {
        return callback(new Error("值中不能有空格"));
      }
      setTimeout(() => {
        // 判断字符串是否为文字
        if (isNaN(value)) {
          callback(new Error("请输入数字"));
        } else {
          // 判断前两位是否为0开头的纯数字
          let arrydata = value.toString().length;
          if (arrydata > 1) {
            if (Number.parseInt(value.toString().substr(0, 1)) == 0) {
              if (
                Number.parseInt(value.toString().substr(1, 1)) ||
                Number.parseInt(value.toString().substr(1, 1)) == 0
              ) {
                callback(new Error("输入有误"));
              }
            }
          }
          let result = value.toString().indexOf(".");
          if (result != -1) {
            let arry = value.toString().split(".");
            if (arry[arry.length - 1].length > 2) {
              callback(new Error("保留小数点后两位"));
            } else {
              if (Number.parseInt(arry[arry.length - 1]) == 0) {
                callback(new Error("请输入完整小数"));
              } else {
                callback();
              }
            }
          }
          if (value < 0) {
            callback(new Error("值需大于0"));
          } else {
            callback();
          }
        }
      }, 500);
    };
    // 数字input框-校验-(仅整数)【会员有效期||】
    var checkInputEventInt = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("必填项不能为空"));
      }
      if (value.toString().indexOf(" ") != -1) {
        return callback(new Error("值中不能有空格"));
      }
      setTimeout(() => {
        // 判断前两位是否为0开头的纯数字
        let arrydata = value.toString().length;
        if (arrydata > 1) {
          if (Number.parseInt(value.toString().substr(0, 1)) == 0) {
            if (
              Number.parseInt(value.toString().substr(1, 1)) ||
              Number.parseInt(value.toString().substr(1, 1)) == 0
            ) {
              callback(new Error("输入有误"));
            }
          }
        }
        // 判断字符串是否存在小数点
        let result = value.toString().indexOf(".");
        if (result != -1) {
          callback(new Error("请输入整数"));
        } else {
          // 判断字符串是否为文字
          if (isNaN(value)) {
            callback(new Error("请输入整数"));
          } else {
            // 判断字符串是否为数字
            if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
              callback(new Error("请输入整数"));
            } else {
              if (value < 0) {
                callback(new Error("值需大于0"));
              } else {
                callback();
              }
            }
          }
        }
      }, 500);
    };

    // PLUS设置-打印折扣-效验
    var checkRebate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("折扣不能为空"));
      }
      if (value.toString().indexOf(" ") != -1) {
        return callback(new Error("值中不能有空格"));
      }
      if (
        !Number.parseInt(value.toString().substr(0, 1)) &&
        value.toString().substr(0, 1) != 0
      ) {
        return callback(new Error("首位必须为数字"));
      }
      if (
        !Number.parseInt(
          value.toString().substr(value.toString().length - 1, 1)
        ) &&
        value.toString().substr(value.toString().length - 1, 1) != 0
      ) {
        return callback(new Error("请输入完整小数"));
      }
      setTimeout(() => {
        // 判断前两位是否为0开头的纯数字
        let arrydata = value.toString().length;
        if (arrydata > 1) {
          if (Number.parseInt(value.toString().substr(0, 1)) == 0) {
            if (
              Number.parseInt(value.toString().substr(1, 1)) ||
              Number.parseInt(value.toString().substr(1, 1)) == 0
            ) {
              callback(new Error("输入有误"));
            }
          }
        }
        if (value.toString().indexOf(".") == -1) {
          if (
            value == "0" ||
            value == "1" ||
            value == "2" ||
            value == "3" ||
            value == "4" ||
            value == "5" ||
            value == "6" ||
            value == "7" ||
            value == "8" ||
            value == "9" ||
            value == "10"
          ) {
            callback();
          } else {
            if (isNaN(value)) {
              callback(new Error("请输入小数"));
            } else {
              if (value > 10) {
                callback(new Error("值小于等于10"));
              } else {
                if (value < 0) {
                  callback(new Error("值大于等于0"));
                } else {
                  callback();
                }
              }
            }
          }
        } else {
          // 当输入为整数的判断
          if (!Number.parseFloat(value)) {
            if (
              value == "0" ||
              value == "1" ||
              value == "2" ||
              value == "3" ||
              value == "4" ||
              value == "5" ||
              value == "6" ||
              value == "7" ||
              value == "8" ||
              value == "9" ||
              value == "10"
            ) {
              callback();
            } else {
              callback(new Error("请输入完整小数"));
            }
          } else {
            if (isNaN(value)) {
              callback(new Error("请输入小数"));
            } else {
              if (value > 10) {
                callback(new Error("值小于等于10"));
              } else {
                if (value < 0) {
                  callback(new Error("值大于等于0"));
                } else {
                  callback();
                }
              }
            }
          }
        }
      }, 500);
    };
    return {
      mainonebread: [
        {
          name: "设置",
        },
        {
          name: "会员设置",
        },
        {
          name: "PLUS会员设置",
          path: "/plusset",
        },
      ], // 当前页-【面包屑导航】
      activeName: "one", //tabs标签-默认显示页

      // 控制提交保存按钮-防止多次点击-true禁用；false-启用
      buttondisable: false,

      myimgesupisshow: true, //form表单-imgesuploade上传图片
      myimgesup: {
        uploadsize: 2, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        ismust: true, //是否必填，true表示必填
        method: "post", //设置上传请求方式
        action: "/admin/upload/file", //upload上传API地址
        headers: { Authentication: localStorage.getItem("token") }, //upload上传headers
        title: "添加", //button-按钮文字
        title_type: "text", //button-按钮显示样式
        isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
        // tips: "(1)建议尺寸：1070*1830像素; (2)视频仅持格式：*.mp4; (3)图片支持格式：*.jpg; (4)最大支持上传20M文件", //提示&建议文字
        limit: "1", //上传数量限制
        target_id: 101, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用 ; 101-plus设置图片；
        rules: {
          images: {
            image_width: 0, //图片宽度
            image_height: 0, //图片高度
            size: 2000, //文件大小（单位kb）
            type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
            typechart: "jpg/png", //文件格式提示规则
          },
        },
      },

      // PLUS设置
      tableData: [
        // {
        //   code: "30",
        //   name: "月卡",
        //   price: "2.00",
        //   content: "优秀优秀啦啦啦",
        //   discount: "0.1",
        //   is_first: 1,
        //   shop_gift: "50",
        //   user_gift: "20",
        //   first_price: "1.00",
        //   send_family_plus: 0,
        //   send_enterprise_plus: 1,
        //   addisshow: true,
        //   plusicon:
        //     "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100", //会员图标
        //   plusicon_file: [
        //     {
        //       name: "food.jpeg",
        //       url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        //     },
        //   ],
        //   giveimg:
        //     "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100", //赠送图片
        //   giveimg_file: [
        //     {
        //       name: "food.jpeg",
        //       url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        //     },
        //   ],
        // },
      ],
      ruleData: {
        name: [
          {
            required: true,
            message: "名称不能为空",
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            validator: checkInputEventInt,
            trigger: "change",
          },
        ],
        price: [
          {
            required: true,
            validator: checkInputEvent,
            trigger: "change",
          },
        ],
        first_price: [
          {
            required: true,
            validator: checkInputEvent,
            trigger: "change",
          },
        ],
        discount: [
          {
            required: true,
            validator: checkRebate,
            trigger: "change",
          },
        ],
      },

      //文库配置-富文本
      memberDatafile: {
        interestsfile: "",
      },
      //课程配置-富文本
      memberDataplus: {
        interestsplus: "",
      },

      rulesMember: {
        interestsfile: [
          {
            required: true,
            message: "权益说明必填",
            trigger: "change",
          },
        ],
        interestsplus: [
          {
            required: true,
            message: "权益说明必填",
            trigger: "change",
          },
        ],
      },

      uploadParams: {},

      loading: false, //初始化-loading加载动效，默认是false关闭
      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
      isshow: true, //用于判断页面模块是否存在
    };
  },
  methods: {
    // 图片上传函数 -------------------（以下）-------------------
    //点击图片filelist时，触发函数，且返回file数据
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `The limit is 3, you selected ${files.length
        } files this time, add up to ${files.length + fileList.length} totally`
      );
    },

    // 上传文件超出数量限制时的函数
    onExceedEvent(file, fileList) {
      ElMessage({
        type: "warning",
        message: "超出文件上传限制！最多为" + fileList.length + "个！",
      });
    },
    //上传图片-预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //上传图片||文件-上传前（准备）-传参
    beforeUpload(file, targetid, rules) {
      // console.log("当前上传的文件信息：" + file.type);

      // this.uploadParams =
      console.log(rules);

      // if (rules) {
      //   if (file.type == "video/mp4") {
      //     if (rules.video) {
      //       // 判断文件 - 格式&大小等
      //       let types = rules.video.type;
      //       const isImage = types.includes(file.type);
      //       if (!isImage) {
      //         this.$message.error(
      //           "上传文件只能是 " + rules.video.typechart + " 文件格式!"
      //         );
      //         return false;
      //       }
      //       const isLtSize = file.size / 1024 < rules.video.size;
      //       if (!isLtSize) {
      //         this.$message.error(
      //           "上传文件大小不能超过 " + rules.video.size + " KB!"
      //         );
      //         return false;
      //       }
      //       if (targetid) {
      //         this.uploadParams = { file: file, target_id: targetid };
      //         return isImage && isLtSize;
      //       } else {
      //         this.uploadParams = { file: file, target_id: 7 };
      //         return isImage && isLtSize;
      //       }
      //     }
      //   } else {
      //     // rules.images图片-规则判断
      //     if (rules.images) {
      //       // 判断图片 - 格式&大小&图片宽高等
      //       let types = rules.images.type;
      //       const isImage = types.includes(file.type);
      //       if (!isImage) {
      //         if (rules.images.typechart) {
      //           this.$message.error(
      //             "上传图片只能是 " + rules.images.typechart + " 图片格式!"
      //           );
      //         } else {
      //           this.$message.error(
      //             "上传图片只能是 JPG/JPEG/gif/bmp/PNG  图片格式!"
      //           );
      //         }
      //         return false;
      //       }
      //       const isLtSize = file.size / 1024 < rules.images.size;
      //       if (!isLtSize) {
      //         this.$message.error(
      //           "上传图片大小不能超过 " + rules.images.size + " KB!"
      //         );
      //         return false;
      //       }
      //       if (
      //         rules.images.image_width != 0 &&
      //         rules.images.image_height != 0
      //       ) {
      //         const isSize = new Promise(function (resolve, reject) {
      //           let width = rules.images.image_width;
      //           let height = rules.images.image_height;
      //           let _URL = window.URL || window.webkitURL;
      //           let image = new Image();
      //           image.onload = function () {
      //             let valid = image.width == width && image.height == height;
      //             valid ? resolve() : reject();
      //           };
      //           image.src = _URL.createObjectURL(file);
      //         }).then(
      //           () => {
      //             return file;
      //           },
      //           () => {
      //             this.$message.error(
      //               "上传图片尺寸不符合,只能是" +
      //                 rules.images.image_width +
      //                 "*" +
      //                 rules.images.image_height +
      //                 "!"
      //             );
      //             return Promise.reject();
      //           }
      //         );
      //         if (targetid) {
      //           this.uploadParams = { file: file, target_id: targetid };
      //           return isImage && isLtSize && isSize;
      //         } else {
      //           this.uploadParams = { file: file, target_id: 7 };
      //           return isImage && isLtSize && isSize;
      //         }
      //       } else {
      //         if (targetid) {
      //           this.uploadParams = { file: file, target_id: targetid };
      //           return isImage && isLtSize;
      //         } else {
      //           this.uploadParams = { file: file, target_id: 7 };
      //           return isImage && isLtSize;
      //         }
      //       }
      //     } else {
      //       // rules.files文件-规则判断
      //       if (rules.files) {
      //         // 判断文件 - 格式&大小等
      //         let types = rules.files.type;
      //         const isImage = types.includes(file.type);
      //         if (!isImage) {
      //           this.$message.error(
      //             "上传文件只能是 text、pdf、word、xls等文件格式!"
      //           );
      //           return false;
      //         }

      //         const isLtSize = file.size / 1024 < rules.files.size;
      //         if (!isLtSize) {
      //           this.$message.error(
      //             "上传文件大小不能超过 " + rules.files.size + " KB!"
      //           );
      //           return false;
      //         }
      //         if (targetid) {
      //           this.uploadParams = { file: file, target_id: targetid };
      //           return isImage && isLtSize;
      //         } else {
      //           this.uploadParams = { file: file, target_id: 7 };
      //           return isImage && isLtSize;
      //         }
      //       }
      //     }
      //   }
      // } else {
      //   if (targetid) {
      //     this.uploadParams = { file: file, target_id: targetid };
      //     return true;
      //   } else {
      //     this.uploadParams = { file: file, target_id: 7 };
      //     return true;
      //   }
      // }
    },

    //移除文件前-触发的函数，可显示提示，待确定后移除文件
    beforeRemove(file) {
      console.log("get file status is:" + file.status);
      if (file.status === "success") {
        return this.$confirm(`确定移除该文件?`);
      } else {
        return;
      }
    },
    //上传图片-删除图片
    handleRemove(file, fileList, rowide, getide) {
      console.log("删除图片||文件：" + file, fileList, rowide);
      if (getide == "plusicon") {
        this.tableData[rowide].plusicon = "";
        this.tableData[rowide].plusicon_file = [];
      } else {
        this.tableData[rowide].giveimg = "";
        this.tableData[rowide].giveimg_file = [];
      }
    },
    //上传图片||文件-成功
    uploadSuccess(response, file, fileList, rowide, getide) {
      if (response.code === 0) {
        //上传成功的文件后缀
        var successfile = file.name.split(".");
        // console.log(successfile);
        //上传成功的文件后缀名比较，且返回图片logo显示
        if (
          ["jpg", "jpeg", "png", "gif"].indexOf(
            successfile[successfile.length - 1]
          ) === -1
        ) {
          //当文件非以上4中格式时，回显logo图片为以下图片(文件样式)
          fileList[
            fileList.length - 1
          ].url = require("@/assets/imgs/upload/upload.png");
        }
        //提示上传成功！
        ElMessage({
          type: "success",
          message: "上传成功！",
        });
        //上传成功后，将图片||文件地址，返回父组件
        response.result.name = successfile[0];
        response.result.type = successfile[successfile.length - 1]; //返回文件后缀-判断类型
        console.log("上传成功之后的图片数据" + JSON.stringify(response.result));

        if (getide == "plusicon") {
          this.tableData[rowide].plusicon = response.result.url;
          this.tableData[rowide].plusicon_file = [
            {
              url: response.result.url,
            },
          ];
        } else {
          this.tableData[rowide].giveimg = response.result.url;
          this.tableData[rowide].giveimg_file = [
            {
              url: response.result.url,
            },
          ];
        }
      } else {
        //提示上传失败！
        ElMessage({
          type: "error",
          message: "上传失败！" + response.message,
        });
        //删除当前队列中的最后一个
        fileList.splice(fileList.length - 1, 1);
      }
      // console.log("上传成功返回数据！" + response, file, fileList);
    },
    //上传图片&文件-失败
    uploadError(err, file, fileList) {
      //提示上传失败！
      ElMessage({
        type: "error",
        message: "上传失败！",
      });
      console.log("上传失败！" + err, file, fileList);
    },
    // 图片上传函数 -------------------（以上）-------------------
    //首充设置-点击切换-只保持一个开启，且可全部设置关闭
    changeSwitchEvent(val, ide) {
      // is_first: 1表示开启，2表示关闭
      if (val === 1) {
        this.tableData.forEach(function (value, index) {
          if (index !== ide) {
            value.is_first = 2;
          }
        });
      }
    },
    //操作-增加事件
    addRechargeEvent(fromdata, times) {
      if (times) {
        if (fromdata.length < times) {
          var oneAddChat = {
            code: "",
            name: "",
            price: "",
            content: "",
            discount: "",
            is_first: 2,
            shop_gift: "",
            user_gift: "",
            first_price: "",
            send_family_plus: 0,
            send_enterprise_plus: 0,
            plusicon: "", //会员图标
            plusicon_file: [],
            giveimg: "", //赠送图片
            giveimg_file: [],
          };
          // js中通过push向数组最后新增指定的元素
          fromdata.push(oneAddChat);
        } else {
          ElMessage({
            type: "warning",
            message: "最多增加6条！",
          });
        }
      } else {
        var oneAddChats = {
          code: "",
          name: "",
          price: "",
          content: "",
          discount: "",
          is_first: 2,
          shop_gift: "",
          user_gift: "",
          first_price: "",
          send_family_plus: 0,
          send_enterprise_plus: 0,
          plusicon: "", //会员图标
          plusicon_file: [],
          giveimg: "", //赠送图片
          giveimg_file: [],
        };
        // js中通过push向数组最后新增指定的元素
        fromdata.push(oneAddChats);
      }
    },
    //操作-删除事件
    delRechargeEvent(ide, formdata) {
      console.log("获取的删除id为：" + JSON.stringify(ide));
      // js中通过splice删除指定的元素
      formdata.splice(ide, 1);
    },
    // 切换tabs标签页面，点击触发事件
    handleClick(tab) {
      // PLUS设置
      if (tab.props.name === "one") {
        // //通过Ajax向后台获取数据
        // axios
        // .post(
        //   "/admin/config/info",
        //   {
        //     key_name: "member_plus_set",
        //   },
        //   {
        //     headers: {
        //       Authentication: localStorage.getItem("token"),
        //     },
        //   }
        // )
        // .then((response) => {
        //   var newdata = response.data.result;
        //   newdata[0].addisshow = true;
        //   // 数据-渲染
        //   for (var i = 0; i < newdata.length; i++) {
        //     // 操作-控制
        //     if (i == 0) {
        //       newdata[i].addisshow = true;
        //     } else {
        //       newdata[i].addisshow = false;
        //     }
        //     // 是否赠送企业会员 0否 1是
        //     newdata[i].send_enterprise_plus = parseInt(
        //       newdata[i].send_enterprise_plus
        //     );
        //     // 是否赠送家庭会员 0否 1是
        //     newdata[i].send_family_plus = parseInt(newdata[i].send_family_plus);
        //     // 是否首充
        //     newdata[i].is_first = parseInt(newdata[i].is_first);

        //     // 将（分）转换成（元）-价格
        //     newdata[i].price = parseInt(newdata[i].price) / 100;
        //     // 将（分）转换成（元）-首充价格
        //     newdata[i].first_price = parseInt(newdata[i].first_price) / 100;
        //   }
        //   // this.tableData = newdata;
        // })
        // .catch(function (error) {
        //   console.log(error);
        // });
      }

      //文库配置
      if (tab.props.name === "three") {
        //通过Ajax向后台获取数据
        axios
          .post(
            "/admin/config/info",
            {
              key_name: "member_plus_document",
            },
            {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.memberDatafile.interestsfile =
              response.data.result.interestsfile;
            this.$refs.editorfile.postValue(response.data.result.interestsfile);

            // this.memberDatafile.interestsfile = "<p>wwerwerwer</p>";
            // this.$refs.editorfile.postValue("<p>wwerwerwer</p>");
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      //课程配置
      if (tab.props.name === "four") {
        //通过Ajax向后台获取数据
        axios
          .post(
            "/admin/config/info",
            {
              key_name: "member_plus_course",
            },
            {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.memberDataplus.interestsplus =
              response.data.result.interestsplus;
            this.$refs.editormyplus.postValue(
              response.data.result.interestsplus
            );
            // this.memberDataplus.interestsplus = "<p>lalalalalal</p>";
            // this.$refs.editormyplus.postValue("<p>lalalalalal</p>");
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    //from表单-提交表单-PLUS设置（保存）
    submitForm(formName) {
      // 控制提交保存按钮-防止多次点击-true禁用；false-启用
      this.buttondisable = true;
      // console.log(this.$refs[formName].model);
      let getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 由于后续会使用id，因此在提交前保证id的唯一性
          if (getdatas.length > 0) {
            getdatas.forEach((element, key) => {
              element.id = key + 1;
              // 将输入的（元）转换成（分）-价格
              element.price = element.price * 100;
              // 将输入的（元）转换成（分）-首充价格
              element.first_price = element.first_price * 100;
            });
          } else {
            getdatas = [];
          }

          //保存-PLUS设置
          axios
            .post(
              "/admin/config/update",
              {
                contents: getdatas,
                key_name: "member_plus_set",
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              console.log(response.data);
              if (response.data.code == 0) {
                ElMessage({
                  type: "success",
                  message: "保存成功！",
                  duration: 1000,
                  onClose: () => {
                    // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                    this.buttondisable = false;
                    this.reload(); //刷新
                  },
                });
              } else {
                setTimeout(() => {
                  // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                  this.buttondisable = false;
                  this.reload(); //刷新
                }, 1000);
              }
            })
            .catch(function (error) {
              console.log(error);
              // 控制提交保存按钮-防止多次点击-true禁用；false-启用
              this.buttondisable = false;
              this.reload(); //刷新
            });
        } else {
          console.log("error submit!!");
          // 控制提交保存按钮-防止多次点击-true禁用；false-启用
          this.buttondisable = false;
          this.reload(); //刷新
          return false;
        }
      });
    },
    //from表单-重置表单-PLUS设置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 文库配置&课程配置-获取子组件数据并赋值给form表单-(富文本)
    getEditorDatasEvent(datas, idex) {
      if (idex == 2) {
        this.memberDatafile.interestsfile = datas;
      } else {
        if (idex == 3) {
          this.memberDataplus.interestsplus = datas;
        }
      }
    },
    //from表单-提交表单-(富文本)
    submitFormFull(formName, keyname, label) {
      let getdatas = {};
      if (label == "interestsplus") {
        getdatas = {
          interestsplus: this.$refs[formName].model[label],
        };
      } else {
        getdatas = {
          interestsfile: this.$refs[formName].model[label],
        };
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post(
              "/admin/config/update",
              {
                contents: getdatas,
                key_name: keyname,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "保存成功！",
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //from表单-重置表单-(富文本)
    resetFormFull(formName, idex) {
      this.$refs[formName].resetFields();
      if (idex === 2) {
        this.memberDatafile.interestsfile = null;
        this.$refs.editorfile.getClear();
      } else {
        if (idex === 3) {
          this.memberDataplus.interestsplus = null;
          this.$refs.editormyplus.getClear();
        }
      }
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true;
      //首次加载-PLUS设置
      axios
        .post(
          "/admin/config/info",
          {
            key_name: "member_plus_set",
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          var newdata = response.data.result;
          newdata[0].addisshow = true;
          // 数据-渲染
          for (var i = 0; i < newdata.length; i++) {
            // 操作-控制
            if (i == 0) {
              newdata[i].addisshow = true;
            } else {
              newdata[i].addisshow = false;
            }
            // 是否赠送企业会员 0否 1是
            newdata[i].send_enterprise_plus = parseInt(
              newdata[i].send_enterprise_plus
            );
            // 是否赠送家庭会员 0否 1是
            newdata[i].send_family_plus = parseInt(newdata[i].send_family_plus);
            // 是否首充
            newdata[i].is_first = parseInt(newdata[i].is_first);

            // 将（分）转换成（元）-价格
            newdata[i].price = parseInt(newdata[i].price) / 100;
            // 将（分）转换成（元）-首充价格
            newdata[i].first_price = parseInt(newdata[i].first_price) / 100;
          }
          this.tableData = newdata;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    TEditor,
  },
});
</script>
<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

.box_content :deep(.el-form-item__error) {
  position: static !important;
}

.box_content :deep(.el-upload) {
  display: block !important;
}

.box_content {
  border-top: 0px solid #eeeeee;
}
</style>