<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-click="(tab, event) => {
                handleTabsClick(tab, event)
            }">
            <el-tab-pane label="购物优惠" name="1">
                <ShoppingList />
            </el-tab-pane>
            <el-tab-pane label="打印优惠" name="2">
                <List />
            </el-tab-pane>
        </el-tabs>

    </div>
</template>

<script setup>
import mainone from '@/components/Mainone'
import List from './components/List'
import ShoppingList from './components/ShoppingList'
import { ArrowRight } from '@element-plus/icons'
import { ref } from 'vue';
let activeName = ref('1');
let mainonebread = ref([
    {
        name: '购物'
    },
    {
        name: '营销管理'
    },
    {
        name: '活动有效订单'
    }
]) // 当前页-【面包屑导航】

function handleTabsClick(tab,event){
    activeName.value =  tab.props.name;
}

</script>

<style lang="scss" scoped>
:deep(.el-tabs--card .el-tabs__nav-scroll) {
    background-color: rgb(255, 255, 255); border-radius: 4px; color: rgb(151, 160, 195); padding: 10px 20px 15px;
}
:deep(.el-tabs--card>.el-tabs__header .el-tabs__nav) {
    border: none;
    padding: 0 20px;
}
:deep(.el-tabs__header .el-tabs__item ) {
    width: 60px;
    margin-right: 60px;
    padding: 0 !important;
    border: none;
}
:deep(.el-tabs__header .is-active ) {
    border-bottom: 2px solid #465EFF !important;
}
</style>
