<template>
    <div>
        <!--table表单-->
        <div>
            <!--购物优惠 list列表-->
            <div style="background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 10px 20px">
                <div class="box-list-box" style="font-size: 14px; line-height: 60px; margin-bottom: 10px">
                    <!--左侧名称-->
                    <div><span>购物优惠-数据列表</span></div>
                    <!--右侧按钮-->
                    <div class="button-box">
                        <el-button v-if="mypowerlimits.hdlb_tj" type="primary" plain
                            @click="tanEvent(null, 1)">添加</el-button>
                        <!-- <el-button type="warning" plain @click="exportDatas()">导出</el-button> -->
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <dpwTable v-loading="loading" element-loading-text="正在飞速加载中..." ref="myTableDataRef"
                        :tabletitle="tabletitle" :tabledata="tableData" :totalcount="totalCount"
                        :currentpage="currentPage" :pagesize="PageSize" :Lastpage="lastpage"
                        :Operation="{ isshow: true, width: 360 }">
                        <template #content="{ scope, prop }">
                            <div v-if="prop == 'addtime'">
                                <span>{{ scope.row[prop] != 0 ? this.$utils.formatDate(scope.row[prop]) : '' }}</span>
                            </div>
                            <div v-else-if="prop == 'type'">
                                <span>{{ scope.row.type ==  '1' ?  '满额赠送' : scope.row.type ==  '2' ?  '按订单金额赠送': '-' }}</span>
                            </div>
                            <div v-else-if="prop == 'status'">
                                <div id="switch">
                                    <el-switch v-model="scope.row[prop]" active-text="开启" inactive-text="关闭"
                                        :active-value="1" :inactive-value="-1" active-color="#13ce66"
                                        inactive-color="#cbcbcb" :disabled="!mypowerlimits.hdlb_gbzt"
                                        @click="mypowerlimits.hdlb_gbzt ? changeStatus(scope.row) : ''">
                                    </el-switch>
                                </div>
                            </div>
                            <div v-else>
                                <span>{{ scope.row[prop] }}</span>
                            </div>
                        </template>
                        <template #special="{ scope }">
                            <div>
                                <el-button v-if="mypowerlimits.hdlb_bj" type="primary"
                                    size="mini" @click="tanEvent(scope.row, 2)">编辑</el-button>
                                <el-button type="primary" size="mini" @click="copyEvent(scope.row)"> 复制活动链接 </el-button>
                                <template v-if="scope.row.type == 1">
                                    <el-button v-if="scope.row.is_update == 1" type="success" size="mini" @click="generateCouponsFunc(scope.row)"> 生成联合优惠券
                                    </el-button>
                                    <el-button v-if="scope.row.is_update == 2" type="success" size="mini" @click="generateCouponsFunc(scope.row)"> 重新生成联合优惠券 </el-button>
                                </template>
                                <el-button v-if="scope.row.order_num == 0 && mypowerlimits.hdlb_sc" type="danger"
                                    size="mini" @click="delEvent(scope.row)">删除</el-button>
                            </div>
                        </template>
                    </dpwTable>

                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- 抽屉 -->
        <div id="mysections">
            <el-drawer :title="tanTitle[tanFlag]" v-model="drawer" direction="rtl" size="1400px" :z-index="1100">
                <div v-loading="loading_tan_flag2" element-loading-text="正在飞速加载中...">
                    <div v-if="tanFlag == 1 || tanFlag == 2">
                        <ShoppingActivityAddEdit :flag="tanFlag" :formData="formData"
                            :meizhiIdsOptions="meizhi_ids_options" @close="() => {
                                this.drawer = false
                                this.tanFlag = '';
                                this.$refs.ruleFormRef?.resetFields();
                                this.handleCurrentChange(this.currentPage)
                                this.formData = {}
                            }
                                " />
                    </div>
                    <div v-else-if="tanFlag == 3">
                        <ActivityInfo :details="details">
                            <template #valueSlot="{ scope }">
                                <div v-if="scope.value_slot == 'gift_info'" class="gift-info-box-all">
                                    <div v-for="(item, index) in JSON.parse(scope.value)" :key="index"
                                        class="gift-info-box">
                                        <div>
                                            <span>商品数量：</span><span>>= </span><span>{{ item.goods_num }}</span>
                                        </div>
                                        <div>
                                            <span>赠送打印余额：</span><span>{{ parseInt(item.gift_price) / 100 }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="scope.value_slot == 'web_url'" class="url-box">
                                    <span>{{ scope.value }}</span>
                                </div>
                                <div v-else-if="scope.value_slot == 'is_all'">
                                    <div>
                                        <span>{{ this.dict_options['shop_activity_list']['can_use_device'][scope.value]
                                            }}</span>
                                    </div>
                                    <div v-if="scope.value == 2" style="margin-top: 10px">
                                        <el-tag v-for="tag in scope.exmind" :key="tag" style="margin-right: 10px">
                                            {{ tag }}
                                        </el-tag>
                                    </div>
                                </div>
                            </template>
                        </ActivityInfo>
                    </div>
                </div>
            </el-drawer>
        </div>
    </div>
</template>

<script >
import maintwo from '@/components/Maintwo'
import dpwTable from '../../../operate/memberBenefits/memberSet/dpw-table.vue'
import ShoppingActivityAddEdit from './common/ShoppingActivityAddEdit.vue'
import ActivityInfo from './common/ActivityInfo.vue'
import axios from 'axios'
import { ElMessageBox, ElMessage } from 'element-plus'
import dict from '@/assets/js/dict.js'

export default {
    name: 'ShoppingActivityList',
    data() {
        return {
            mainonebread: [
                {
                    name: '购物'
                },
                {
                    name: '营销管理'
                },
                {
                    name: '活动列表'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            // maintwoforminfo: {
            //     myinputoneisshow: true, //筛选查询-左侧字+input
            //     myinputone: {
            //         divinterval: '0', //框右侧距离
            //         titlewidth: '110px', //标题宽度
            //         inputwidth: '200px', //input宽度
            //         inputinterval: '20px', //input右侧距离
            //         input: [
            //             {
            //                 ismust: false, //是否必填，true表示必填
            //                 title: '购物设备编号:',
            //                 placeholder: '请输入购物设备编号',
            //                 name: 'relate_id',
            //                 content: ''
            //             },
            //             {
            //                 ismust: false, //是否必填，true表示必填
            //                 title: '原始设备编号:',
            //                 placeholder: '请输入原始设备编号',
            //                 name: 'meizhi_id',
            //                 content: ''
            //             }
            //         ]
            //     }
            // }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 240, // 数据列表中-操作-宽度
            tabletitle: [
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '活动名称',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'type',
                    label: '活动类型',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'truename',
                    label: '添加人',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '添加时间',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: '80s'
                }
            ], // 数据列表中-数据表头
            tableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            loading: false,
            drawer: false,
            tanFlag: '',
            tanTitle: { 1: '添加活动', 2: '编辑活动', 3: '活动详情' },
            formData: {},
            // 活动详情
            details: [],
            dict_options: dict,

            loading_tan_flag2: false,
            meizhi_ids_options: {},
            activeName: 1,
        }
    },
    methods: {
        // 新增购物优惠
        handleTabsClick(tab) {
            this.activeName = tab.props.name;
            console.log(tab)
        },
        cancelDrawer() {
            this.drawer = false;
        },
        //table表单中-状态改变-开启/关闭
        changeStatus(data) {
            axios
                .post(
                    '/admin/MeizhiJicaiActivity/changeStatus',
                    {
                        id: data.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message ? response.data.message : '切换成功'
                        })
                    } else {
                        // 切换状态失败-复原状态
                        if (data.status == 1) {
                            data.status = -1
                        } else {
                            data.status = 1
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        // 删除
        delEvent(row) {
            ElMessageBox.confirm('确认删除当前活动么?', '删除活动', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/MeizhiJicaiActivity/del',
                            {
                                id: row.id
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '删除成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.handleCurrentChange(this.currentPage)
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            ElMessage({ type: 'error', message: '删除失败！' + error })
                        })
                })
                .catch(() => {
                    ElMessage({ type: 'info', message: '您取消了操作' })
                })
        },
        // 生成优惠券
        async generateCouponsFunc(row) {
            let res = await axios.post('/admin/MeizhiJicaiActivity/create', {
                id: row.id
            },
                {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
            if (res.data.code == 0) {
                ElMessage({ type: 'success', message: '操作成功！' })
                this.handleCurrentChange(this.currentPage)
            }
        },
        // 复制
        copyEvent(row) {
            let data = row.web_url

            let textarea = document.createElement('textarea')
            document.body.appendChild(textarea)
            // 隐藏此输入框
            textarea.style.position = 'absolute'
            textarea.style.clip = 'rect(0 0 0 0)'
            // 赋值
            textarea.value = data
            // 选中
            textarea.select()
            // 复制
            document.execCommand('copy', true)

            ElMessage({ type: 'success', message: '复制成功！' })
        },
        // 弹窗
        tanEvent(data, flag) {
            this.drawer = true
            this.tanFlag = flag
            if (flag == 1) {
                this.loading_tan_flag2 = false
                this.formData = {
                    name: '',
                    type: "1",
                    gift_info: [],
                    give_day: '',
                    coupon_day: '',
                    suit_goods: '1',
                    content: '',
                    shopping_back_img: '',
                    print_gift_img: '',
                    is_all: '1',
                    active_time: [],
                    status: 1,
                    meizhi_ids: []
                }
                this.meizhi_ids_options = {}
            } else if (flag == 2) {
                this.loading_tan_flag2 = true
                axios
                    .get('/admin/MeizhiJicaiActivity/view',{
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: {
                            id: data.id
                        }
                    })
                    .then((response) => {
                        if (response.data.code == 0) {
                            let getdatas = response.data.result;
                            this.formData = {
                                id: data.id,
                                name: getdatas.name,
                                type: getdatas.type ? getdatas.type.toString() : '1',
                                gift_info: this.getGiftInfoEvent(getdatas.gift_info),
                                give_day: getdatas?.give_day,
                                coupon_day: getdatas?.coupon_day,
                                suit_goods: getdatas.suit_goods,
                                content: getdatas.content,
                                shopping_back_img: getdatas.shopping_back_img,
                                print_gift_img: getdatas.print_gift_img,
                                is_all: getdatas.is_all.toString(),
                                active_time: getdatas.begin_time != 0 && getdatas.end_time != 0 ? [getdatas.begin_time * 1000, getdatas.end_time * 1000] : [],
                                status: getdatas.status,
                                order_num: getdatas.order_num,
                                meizhi_ids: getdatas.is_all.toString() == 2 ? getdatas.meizhi_ids : []
                            }

                            this.meizhi_ids_options = getdatas.is_all.toString() == 2 ? this.getMeizhiOptionsEvent(getdatas.meizhi_ids) : {}
                        }

                        this.loading_tan_flag2 = false
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                        this.loading_tan_flag2 = false
                    })
            } else if (flag == 3) {
                return false;
            }
        },
        // 获取meizhi部分设备下拉
        getMeizhiOptionsEvent(data_array) {
            if (!data_array) data_array = []

            let data = {}
            data_array.map((n) => {
                data[n] = n
                return
            })
            return data
        },
        // 获取元转分
        getGiftInfoEvent(data_array) {
            if (!data_array) {
                data_array = []
                return data_array;
            }
            
            let mergedArray = [];
            let jsonData = data_array;
            for (let key in jsonData) {
                if (jsonData.hasOwnProperty(key)) {
                    let arr = jsonData[key];
                    for (let i = 0; i < arr.length; i++) {
                        arr[i].order_amount = key;
                        arr[i].conditionVal = arr[i].condition > 0 ? '2' : '1';
                        arr[i].name = key;

                        mergedArray.push(arr[i]);
                    }
                }
            }
            mergedArray.forEach(item => {
                item.order_amount = Number(item.order_amount) / 100;
                item.value = Number(item.value) / 100;
                item.condition = Number(item.condition) / 100;
            })
            return mergedArray;
        },
        // 跳转
        routerEvent(path, data) {
            this.$router
                .push({
                    path: path,
                    query: { id: data.id, name: data.name }
                })
                .catch((error) => error)
        },

        // 导出
        exportDatas() {
            this.pagesdatas = {
                // relate_id: this.pagesdatas.relate_id,
                // meizhi_id: this.pagesdatas.meizhi_id,
                // clientid: this.pagesdatas.clientid,
                export: '1'
            }
            this.postAxiosEvent(this.pagesdatas, 1)
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                // relate_id: this.pagesdatas.relate_id,
                // meizhi_id: this.pagesdatas.meizhi_id,
                // clientid: this.pagesdatas.clientid,
                page: 1,
                page_size: val
            }
            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                // relate_id: this.pagesdatas.relate_id,
                // meizhi_id: this.pagesdatas.meizhi_id,
                // clientid: this.pagesdatas.clientid,
                page: val,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        },

        //提交等相关event事件函数
        // 获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组：', data)
            this.pagesdatas = {
                // relate_id: data.relate_id,
                // meizhi_id: data.meizhi_id,
                // clientid: data.clientid,
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            let newdata = successdata.data
            this.tableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        postAxiosEvent(data, flag) {
            if (!flag) this.loading = true
            axios
                .get('/admin/MeizhiJicaiActivity/index', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: data
                })
                .then((response) => {
                    if (!flag) this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        } else {
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        // maintwo,
        dpwTable,
        ShoppingActivityAddEdit,
        ActivityInfo
    }
}
</script>
<style scoped lang="scss">
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

#mysections :deep(.el-drawer__body) {
    overflow-y: auto;
}

.box-list-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.button-box {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

#mysections :deep(.el-drawer__body) {
    overflow-y: auto;
    width: 98%;
}

.router-pages {
    color: blue;
    cursor: pointer;
}

.gift-info-box-all {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .gift-info-box {
        display: flex;
        flex-direction: row;
        gap: 40px;
        padding: 10px;
        border-radius: 4px;
        border: 1px dashed #999;
    }
}

/* .url-box {
    color: blue;
    cursor: pointer;
    opacity: 1;
} */

/* .url-box:active {
    opacity: 0.7;
} */
</style>
