<template>
    <div id="mymain">
        <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">
                        <span style="margin-right: 40px"> 设备列表</span>
                        <span style="color: #ef2f6e" v-if="mainpagedata.dvc_numbers != 0"
                            >( 投放台数：{{ mainpagedata.dvc_numbers }} &nbsp;&nbsp;&nbsp;&nbsp; 投放区域：{{ mainpagedata.dvc_area }} )</span
                        >
                    </div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <div class="main_one_button">
                            <el-button type="primary" plain class="max_search main_two_search" @click="exportDatas(this.$route.query.id)">导出对账单 </el-button>
                        </div>
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <!--信息-->
                    <maindetails :detailsdata="mainpagedata.details" style="margin-top: 10px"></maindetails>

                    <!--table表单-->
                    <div class="mytable">
                        <el-table
                            :header-cell-style="{ background: '#F5F7FA' }"
                            :data="mainpagedata.tableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                            :row-class-name="tableRowClassName"
                            border
                            style="width: 100%; margin-top: 10px"
                            v-loading="loading"
                            element-loading-text="正在飞速加载中..."
                            @selection-change="handleSelectionChange"
                            @cell-click="handleOneData"
                        >
                            <el-table-column prop="client" label="设备编号" width="120">
                                <template #default="scope">
                                    <span :class="mypowerlimits.jqkz ? 'mytabletext' : ''" @click="mypowerlimits.jqkz ? routeraddrClick('/sharedvccontroledit', scope.row) : ''">{{
                                        scope.row.ShareClient.client
                                    }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="ShareClient.area_code_name" label="设备地址">
                                <template #default="scope">
                                    <span>{{ scope.row.ShareClient.area_code_name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="ShareClient.shop_code_name" label="商家信息">
                                <template #default="scope">
                                    <span>{{ scope.row.ShareClient.shop_code_name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="showtime" label="播放时间" width="400">
                                <template #default="scope">
                                    <span>{{ scope.row.showtime }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="status_name" label="状态">
                                <template #default="scope">
                                    <span>{{ scope.row.status_name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="playtimes" label="播放次数">
                                <template #default="scope">
                                    <div class="mytabletext" @click="routeraddrClick('/playinfo', scope.row)">
                                        <span>{{ scope.row.playtimes == null ? 0 : scope.row.playtimes }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!--分页器-->
                        <div class="block" style="text-align: center; margin: 30px 0">
                            <el-pagination
                                background
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="currentPage"
                                :page-sizes="pageSizes"
                                :page-size="PageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="totalCount"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maindetails from '@/components/Maindetails'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default {
    name: 'lookdeviceinfo',
    data() {
        return {
            mainpagedata: {
                mainonebread: [
                    {
                        name: '运营'
                    },
                    {
                        name: '设备广告管理'
                    },
                    {
                        name: '广告列表',
                        path: '/advertlist'
                    },
                    {
                        name: '查看广告详情'
                    },
                    {
                        name: '查看设备',
                        path: '/lookdevice'
                    }
                ], // 当前页-【面包屑导航】
                details: {
                    showstatus: 1, //确定样式模块
                    title: false,
                    titlewidth: '75px', //新增title最小宽度
                    button: {
                        isshow: false,
                        name: '',
                        jump: '' //点击跳转
                    },
                    content: [
                        // {
                        //   name: "投放时间:",
                        //   value: "2020-07-19 14:48 至 2020-08-19 14:48",
                        // },
                        // {
                        //   name: "总播放次数:",
                        //   value: "5233次",
                        // },
                        // {
                        //   name: "总播放时长:",
                        //   value: "1小时20分钟45秒",
                        // },
                    ]
                }, //关于详情信息的组件
                tableData: [], //协商详情table列表
                dvc_numbers: 0, //投放台数
                dvc_area: '' //投放区域
            }, // 当前Pagedata所需的data数据与控制

            // 投放区域-前端维护字段
            tf_area: [],

            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
        }
    },
    methods: {
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/ClientAdvert/deviceList',
                    {
                        id: this.$route.query.id,
                        page: 1,
                        page_size: val
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            console.log(`当前页: 第${val}页`)
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/ClientAdvert/deviceList',
                    {
                        id: this.$route.query.id,
                        page: val,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },

        exportDatas(ide) {
            //导出数据
            console.log(ide)
            ElMessage({
                type: 'warning',
                message: '正在导出，请勿离开本页面！！！',
                duration: 2000,
                onClose: () => {
                    axios
                        .post(
                            '/admin/ClientAdvert/exportWord',
                            {
                                id: ide
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            ElMessage({
                                type: 'success',
                                message: response.data.message
                            })
                            if (response.data.code == 0) {
                                const link = document.createElement('a')
                                link.style.display = 'none'
                                link.href = response.data.result.file_path
                                link.setAttribute('download', '文件名称')
                                document.body.appendChild(link)
                                link.click()
                            }
                        })
                        .catch(function(response) {
                            ElMessage({
                                type: 'warning',
                                message: response.data.message
                            })
                        })
                }
            })
        },
        // 表行-交替颜色
        tableRowClassName({ rowIndex }) {
            if ((rowIndex + 1) % 2 === 0) {
                return 'dabuleque'
            }
            return ''
        },
        // 路由跳转-传参
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 跳转-设备详情
                if (routeraddr === '/sharedvccontroledit') {
                    this.$router.push({ path: routeraddr, query: { id: rowdata.cid } }).catch((error) => error)
                }
                // 跳转-播放详情
                if (routeraddr === '/playinfo') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { id: this.$route.query.id, cid: rowdata.cid, client: rowdata.ShareClient.client }
                        })
                        .catch((error) => error)
                }
            } else {
                this.$router.push(routeraddr).catch((error) => error)
            }
        },

        //数据渲染
        myaxiosfunc(successdata) {
            if (successdata.data.length == 0) {
                this.loading = false
            }
            let getdatas = successdata.data
            for (let i = 0; i < getdatas.length; i++) {
                // 获取筛选到的第一个投放区域
                if (getdatas[i].ShareClient.area_code_name != null && getdatas[i].ShareClient.area_code_name != '') {
                    this.tf_area.push(getdatas[i].ShareClient.area_code_name)
                }
                // 播放时间-渲染
                getdatas[i].showtime =
                    (getdatas[i].begin_time ? this.$utils.formatDate(getdatas[i].begin_time) : '') +
                    ' - ' +
                    (getdatas[i].end_time ? this.$utils.formatDate(getdatas[i].end_time) : '')
            }
            this.mainpagedata.tableData = getdatas

            // 总投放台数&投放地址
            this.mainpagedata.dvc_numbers = successdata.total
            this.mainpagedata.dvc_area = this.tf_area.length > 0 ? this.tf_area[0] : '无'

            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据-设备详情
            axios
                .post(
                    '/admin/ClientAdvert/deviceList',
                    {
                        id: this.$route.query.id,
                        page: 1,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })

            // 获取投放时间||总播放次数||总播放时长等字段
            axios
                .post(
                    '/admin/ClientAdvert/info',
                    {
                        id: this.$route.query.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    let getdatas = response.data.result
                    console.log(getdatas)
                    // 总投放时间&总播放次数&总播放时长
                    let clienInfoArry = [
                        {
                            name: '投放时间:',
                            value: this.$utils.formatDate(getdatas.begin_time) + ' 至 ' + this.$utils.formatDate(getdatas.end_time)
                        },
                        {
                            name: '总播放次数:',
                            value: getdatas.playtimes
                        },
                        {
                            name: '总播放时长:',
                            value: getdatas.total_duration != '' && getdatas.total_duration != null ? getdatas.total_duration : '0'
                        }
                    ]
                    this.mainpagedata.details.content = clienInfoArry
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maindetails
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
    margin-bottom: 20px;
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}
</style>
