<template>
  <el-dialog
    v-model="visible"
    title="优惠券设置"
    width="700px"
  >
    <div class="dialog-form" v-loading="loading">
      <h4>目前仅电信【福兔卡】活动支持送优惠券</h4>
      <el-form
        ref="formEle"
        :model="form"
        label-width="150px"
        label-suffix=":"
        :disabled="!mypowerlimits['dxsjk_yhqsz']"
      >
        <el-form-item
          label="状态"
          prop="grant_status"
          :rules="[{required: true, message: '请选择'}]"
        >
          <el-radio-group v-model="form.grant_status">
            <el-radio :label="1">送优惠券</el-radio>
            <el-radio :label="-1">不送优惠券</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="首充金额大于等于"
          prop="price"
          :rules="[{required: true, message: '请输入'}]"
        >
          <el-input-number
            v-model="form.price"
            :min="0.01"
            :precision="2"
            controls-position="right"
          />
        </el-form-item>
        <el-form-item
          label="送优惠券"
          prop="content"
          :rules="[{required: true, message: '请选择'}]"
        >
          <div v-if="form.content" class="coupon-list flex-col">
            <div class="flex-row between">
              <span class="btn" :class="form.content.length < 12 ? '' : 'disabled'" @click="addCoupon">添加</span>
              <span class="text">最大增加12个月，每月最多添加50张优惠券</span>
            </div>
            <div v-if="form.content.length > 0" class="list flex-col">
              <div class="row hd flex-row">
                <div class="a">月份</div>
                <div class="b">优惠券（非必填）</div>
                <div class="c">操作</div>
              </div>
              <template v-for="(coupon, cIndex) in form.content" :key="cIndex">
                <div class="row flex-row">
                  <div class="a">第{{ cIndex + 1 }}个月</div>
                  <div class="b">
                    <div v-if="!coupon.coupon_names || (coupon.coupon_names && coupon.coupon_names.length === 0)">
                      <span>未配置，</span>
                      <span class="btn" @click="choiceCoupon(cIndex)">去选择</span>
                    </div>
                    <div v-else @click="choiceCoupon(cIndex)">{{ coupon.coupon_names.join(',') }}</div>
                  </div>
                  <div class="c">
                    <span class="btn-danger" @click="deleteCoupon(cIndex)">删除</span>
                  </div>
                </div>
              </template>
            
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button @click="closeDialog">返回</el-button>
      <el-button :disabled="!mypowerlimits['dxsjk_yhqsz']" type="primary" @click="storeCoupon">确定</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {reactive, ref} from 'vue'
import http from '@/assets/js/http'

const mypowerlimits = reactive(JSON.parse(localStorage.getItem('powerlimits')))
const emits = defineEmits([
  'choice',
  'refresh'
])

const visible = ref(false)
const loading = ref(false)
const formEle = ref()
const form = ref({
  grant_status: 1,
  price: 0,
  content: []
})

const addCoupon = () => {
  if(form.value.content.length < 12) {
    form.value.content.push({
      coupon_ids: [],
      coupon_names: []
    })
  }
}
const deleteCoupon = (index) => {
  form.value.content.splice(index, 1)
}
const storeCoupon = () => {
  formEle.value.validate(valid => {
    if(valid) {
      loading.value = true
      http.post(`/Futu/couponSetSave`, {
        ...form.value,
        price: form.value.price*100,
        content: form.value.content.map(i => {
          const obj = {
            coupon_ids: i.coupon_ids.join(',')
          }
          if(i.coupon_names) {
            obj['coupon_names'] = i.coupon_names.join(',')
          }
          return obj
        })
      }).then(res => {
        if(res) closeDialog()
      }).finally(() => {
        loading.value = false
      })
    }
  })
}

// 添加优惠券 - 选择
const contentCurrent = ref(0)
const choiceCoupon = (index) => {
  contentCurrent.value = index
  const current = form.value.content[index]
  if(!current.coupon_ids) current.coupon_ids = []
  if(!current.coupon_names) current.coupon_names = []
  emits('choice', current)
  // closeDialog()
}
// 设置优惠券
const setCoupons = (row) => {
  form.value.content[contentCurrent.value] = row
}

const openDialog = () => {
  visible.value = true
  loading.value = true
  http.post('/Futu/couponSetView').then(res => {
    const {grant_status, price, content} = res
    form.value.grant_status = grant_status
    form.value.price = price/100
    form.value.content = content.map(i => {
      const obj = {
        coupon_ids: i.coupon_ids.split(',')
      }
      if(i.coupon_names) {
        obj['coupon_names'] = i.coupon_names.split(',')
      }
      return obj
    })
  }).finally(() => {
    loading.value = false
  })
}
const closeDialog = () => {
  visible.value = false
  form.value.content = []
  form.value.grant_status = 1
  form.value.price = 0
}

defineExpose({
  open: openDialog,
  close: closeDialog,
  setCoupons
})
</script>

<style lang="scss" scoped>
@use "../style.scss";
</style>
