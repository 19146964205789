<template>
    <div id="mymain">
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">
                        <span style="margin-right: 40px">在线情况</span>
                    </div>
                </div>
                <div class="box_title" style="border-top: 1px solid #eeeeee; padding: 10px 0 20px 0;">
                    <div class=" box_title_button">
                        <div>
                            <el-radio-group v-model="radio" size="small" @change="changeButton" :disabled="button_disabled">
                                <el-radio-button :label="1">按周</el-radio-button>
                                <el-radio-button :label="2">按月</el-radio-button>
                                <!-- <el-radio-button :label="3">按年</el-radio-button> -->
                                <el-radio-button :label="4">自定义</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div style="margin-left: 20px;">
                            <el-date-picker
                                :disabled="button_disabled"
                                v-if="[1, 2, 3].indexOf(this.radio) > -1"
                                v-model="selectTimes"
                                :type="this.button_change.filter((n) => n.radio == this.radio)[0].type"
                                size="small"
                                :placeholder="'请选择' + this.button_change.filter((n) => n.radio == this.radio)[0].chat"
                                :picker-options="this.radio == 1 ? { firstDayOfWeek: 2 } : {}"
                                :format="this.button_change.filter((n) => n.radio == this.radio)[0].format"
                                :disabled-date="disabledDate"
                                @change="(data) => MychangeTimesEvent(data, this.radio)"
                            />
                            <el-date-picker
                                v-else
                                :disabled="button_disabled"
                                v-model="selectTimesCustom"
                                :type="this.button_change.filter((n) => n.radio == this.radio)[0].type"
                                size="small"
                                :placeholder="'请选择' + this.button_change.filter((n) => n.radio == this.radio)[0].chat"
                                start-placeholder="请选择开始时间"
                                end-placeholder="请选结束时间"
                                :format="this.button_change.filter((n) => n.radio == this.radio)[0].format"
                                :disabled-date="disabledDate"
                                @change="(data) => MychangeTimesEvent(data, this.radio)"
                            />
                        </div>
                    </div>
                </div>
                <!--内容-折线图-->
                <div id="main" style="width: 100%;height: 400px;" v-loading="loading" element-loading-text="正在飞速加载中..."></div>
                <!-- 内容-统计列表 -->
                <div v-loading="loading" element-loading-text="正在飞速加载中...">
                    <div class="my-export"><el-button type="warning" size="small" @click="exportEvent(this.export_data_times)">导出表格</el-button></div>
                    <el-table ref="multipleTable" :data="tableData" style="width: 100%" border :show-header="false" :cell-style="columnbackgroundStyle">
                        <el-table-column v-for="(mytabletitle, i) in tabletitle" :key="i" :prop="i">
                            <template #default="scope" v-if="i == 0">
                                <div v-if="i == 0">
                                    <span> {{ scope.row[0] }} </span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="my-beizhu"><span>注：平均时长计算，没数据那天也算在内！</span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import { defineComponent, ref } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'

import * as echarts from 'echarts'

export default defineComponent({
    name: 'dvcisonline',
    data() {
        return {
            mainonebread: [
                {
                    name: '打印'
                },
                {
                    name: '共享打印'
                },
                {
                    name: '机器控制',
                    path: '/sharedvccontrol'
                },
                {
                    name: '在线情况'
                }
            ], // 当前页-【面包屑导航】

            // 默认选中
            radio: ref(1),

            //获取相关的设备id
            clientids: [],

            // 选择周等按钮
            button_change: [
                { radio: 1, chat: '周', type: 'week', format: 'YYYY [年 第] ww [周]' },
                { radio: 2, chat: '月', type: 'month', format: 'YYYY [年 第] MM [月]' },
                { radio: 3, chat: '年', type: 'year', format: 'YYYY [年]' },
                { radio: 4, chat: '', type: 'daterange', format: 'YYYY-MM-DD' }
            ],
            // 选中时间
            selectTimes: '',
            // 自定义选中时间
            selectTimesCustom: '',

            // table列表
            tabletitle: [],
            tableData: [],

            // 导出-预备数据
            export_data_times: '',

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

            loading: false,

            // 请求时禁用
            button_disabled: false
        }
    },
    methods: {
        // 按周&月&自定义-大于今天的禁选
        disabledDate(time) {
            return time.getTime() > (Date.now() / 1000 + 0 * 86400) * 1000
        },
        // 导出table表格
        exportEvent(datas) {
            let mysearchtime = datas.join(' ~ ')
            axios
                .post(
                    '/admin/Equipment/onlinDuration',
                    {
                        id: this.clientids,
                        time: mysearchtime,
                        export: 1
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        // 修改table表中指定行样式
        columnbackgroundStyle({ rowIndex }) {
            if (rowIndex == 0) {
                //让下标为1的列数背景颜色显示为红色（颜色自定义根据大家需求来）
                return 'background:#F5F7FA;color: #606266;font-weight: 800;'
            }
        },
        // echarts
        echartCustom(datas) {
            // console.log('获取的饼状图自定义', datas)
            var myChart = echarts.init(document.getElementById('main'))
            var option = {
                title: {
                    // text: '在线情况图'
                    text: ''
                },
                tooltip: {
                    trigger: 'axis',
                    formatter(params) {
                        // 自定义修改-提示信息 ((((item.data.split('.')[1] / 100) * 60).toFixed(4).split('.')[1] / 1000000) * 60 ).toFixed(0)
                        let content = `<div style="margin:0 0 10px"><span style="color: #333333;font-size: 16px;">${params[0].name}</span></div>`
                        params.forEach((item) => {
                            content += `<div style="width: 245px;font-size: 14px;line-height:24px; display: flex;flex-direction: row;flex-wrap: nowrap; justify-content: space-between;align-items: center;">
                                <div style="font-weight: 600;width: 100px;height: 24px;display: flex;flex-direction: row;flex-wrap: nowrap; justify-content: space-between;align-items: center;">
                                    <div style="width: 10px;height: 10px;background-color: ${item.color};border-radius: 50%;"></div>
                                    <div><span>${item.seriesName}</span></div>
                                </div>
                                <div><span>${
                                    item.data != 0 ?  
                                    (item.data.split('.')[0] != 0 ? (item.data.split('.')[0] + '小时 ') : '') + 
                                    (item.data.split('.')[0] == 0 && parseInt((item.data.split('.')[1] / 10000) * 60) == 0 ? '' : (parseInt((item.data.split('.')[1] / 10000) * 60) + '分 ')) + 
                                    ((((item.data.split('.')[1] / 10000) * 60).toFixed(4).split('.')[1] / 10000) * 60).toFixed(0) + '秒'
                                    : '0秒'
                                }</span></div>
                            </div>`
                        })
                        return content
                    }
                },
                legend: {
                    data: datas.legend_data
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {
                            name: '下载图片' + new Date().valueOf()
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: datas.xAxis_data
                },
                yAxis: {
                    type: 'value',
                    name: '小时'
                },
                series: datas.series
            }

            option && myChart.setOption(option)
            window.onresize = myChart.resize
        },
        // ajax传输数据
        ajaxEvent(clientids, searchtime, myexport = 0) {
            let mysearchtime = searchtime.join(' ~ ')

            // 缓冲
            this.loading = true

            // 禁用-disabled
            this.button_disabled = true

            // 获取设备在线数据
            axios
                .post(
                    '/admin/Equipment/onlinDuration',
                    {
                        id: clientids,
                        time: mysearchtime,
                        export: myexport
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        this.isshow_linechart = true
                        let getdatas = response.data.result

                        // 获取table列表title
                        if (getdatas.table.length > 0) {
                            this.tabletitle = getdatas.table[0]
                            this.tableData = getdatas.table
                        }

                        // 获取line
                        if (getdatas.line) {
                            let line_keys = Object.keys(getdatas.line)

                            let firstdata = getdatas.line[line_keys[0]]
                            // 重组数据结构
                            let series_datas = []
                            line_keys.forEach((element) => {
                                // 前端转换:将后端的小时&分转成XX.XX小时
                                let transform = this.hourToNumberEvent(getdatas.line[element].ydate)

                                let onedata = {
                                    name: element,
                                    type: 'line',
                                    // stack: 'Total', // 堆叠折线图
                                    data: transform
                                }
                                series_datas.push(onedata)
                            })

                            let datas = {
                                legend_data: Object.keys(getdatas.line),
                                xAxis_data: firstdata.xdate,
                                series: series_datas
                            }
                            this.echartCustom(datas)
                        }
                    } else {
                        this.tableData = []
                        this.tabletitle = []
                    }
                    this.loading = false

                    this.button_disabled = false
                })
                .catch(function(error) {
                    console.log(error)
                    this.loading = false

                    this.button_disabled = false
                })
        },
        // 小时&分&秒转换数字
        hourToNumberEvent(data) {
            let newArray = data.map((n) => {
                // 前端特殊处理！
                if (n == 0) {
                    return n
                }

                let number = 0
                // 秒-分割
                let fenge = n.split('秒')
                // 分-分割
                let fenge_fen = fenge[0].split('分')
                let miao = 0
                if (fenge_fen.length > 1) {
                    // 秒
                    miao = parseFloat(fenge_fen[1] / 60 / 60)
                    // 转小时
                    let numberorchat = fenge_fen[0]
                    // 转分
                    let zhuanFen = numberorchat.split('小时')

                    if (zhuanFen.length > 1) {
                        // 拥有小时&分
                        let xiaoshi = parseInt(zhuanFen[0])
                        let fen = parseFloat(zhuanFen[1] / 60)
                        number = xiaoshi + fen + miao
                    } else {
                        // 有分有秒
                        let fen = parseFloat(zhuanFen[0] / 60)
                        number = fen + miao
                    }
                } else {
                    // 只有秒
                    miao = parseFloat(fenge_fen[0] / 60 / 60)
                    number = miao
                }

                return number.toFixed(4)
            })
            return newArray
        },
        // 获取对应时间戳范围
        getNowTime(flag, myDate) {
            const date = myDate
            const year = date.getFullYear()
            const month = date.getMonth()
            const day = date.getDate()
            let week = date.getDay() // 星期几

            // 获取当前-周-时间戳范围
            if (flag == 1) {
                // 兼容国际时间转中国时间：将周日的week=0时转成week=7
                if(week == 0){
                    week = 7;
                }
                let WeekStartDate = new Date(year, month, day - week + 1)
                let WeekEndDate = new Date(year, month, day - week + 7)

                return [WeekStartDate.valueOf() / 1000, WeekEndDate.valueOf() / 1000 + 86400 - 1]
            }

            // 获取当前-月-时间戳范围
            if (flag == 2) {
                let nowMonth = month < 10 ? '0' + (month + 1).toString() : (month + 1).toString()
                let startstringTime = year + '-' + nowMonth + '-01 00:00:00'
                let MonthStartTime = Date.parse(new Date(startstringTime)) / 1000
                let MonthDays = new Date(year, month + 1, 0).getDate()
                let endstringTime = year + '-' + nowMonth + '-' + MonthDays + ' 23:59:59'
                let MonthEndTime = Date.parse(new Date(endstringTime)) / 1000

                return [MonthStartTime, MonthEndTime]
            }

            // 获取当前-年-时间戳范围
            if (flag == 3) {
                let startstringTime = year + '-01-01 00:00:00'
                let YearStartTime = Date.parse(new Date(startstringTime)) / 1000
                let endstringTime = year + '-12-31 23:59:59'
                let YearEndTime = Date.parse(new Date(endstringTime)) / 1000

                return [YearStartTime, YearEndTime]
            }

            // 获取当前-天-时间戳范围
            if (flag == 4) {
                const start = new Date(new Date(myDate.toLocaleDateString()).getTime())
                // const end = new Date(new Date(myDate.toLocaleDateString()).getTime()+24*60*60*1000-1);

                return [start.valueOf() / 1000, start.valueOf() / 1000 + 86400 - 1]
            }
        },
        // 切换按钮
        changeButton(flag) {
            let datas = this.getNowTime(flag, new Date())

            let number = [1, 2, 3]
            if (number.indexOf(flag) > -1) {
                this.selectTimes = datas[0] * 1000
            } else {
                // 自定义
                this.selectTimesCustom = [datas[0] * 1000, datas[1] * 1000]
            }

            // 请求接口
            this.ajaxEvent(this.clientids, datas)
            // 预-存储-时间-用于导出table数据表
            this.export_data_times = datas
        },
        // 选中时间范围
        MychangeTimesEvent(data, flag) {
            let number = [1, 2, 3]
            if (number.indexOf(flag) > -1) {
                let datas = this.getNowTime(flag, data)
                // console.log('选中：', datas)
                // 请求接口
                this.ajaxEvent(this.clientids, datas)
                // 预-存储-时间-用于导出table数据表
                this.export_data_times = datas
            } else {
                let datas = [data[0].valueOf() / 1000, data[1].valueOf() / 1000 + 86400 - 1]
                // // console.log('选中自定义：', datas)
                // // 请求接口
                // this.ajaxEvent(this.clientids, datas)
                // // 预-存储-时间-用于导出table数据表
                // this.export_data_times = datas

                // 超过31天报-自定义时间差不能超过31天
                if (datas[1] - datas[0] > 2678400) {
                    ElMessage({
                        type: 'warning',
                        message: '自定义时间差不能超过31天'
                    })
                    this.selectTimesCustom = [this.export_data_times[0] * 1000, this.export_data_times[1] * 1000]
                } else {
                    // 请求接口
                    this.ajaxEvent(this.clientids, datas)
                    // 预-存储-时间-用于导出table数据表
                    this.export_data_times = datas
                }
            }
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token') || !this.$route.query.ids) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            let routerinfo = this.$route.query
            let ids = routerinfo.ids
            this.clientids = ids.split(',')

            // 默认选中当前周
            this.changeButton(this.radio)
        }
    },
    components: {
        mainone
    }
})
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
    min-height: 750px;
}

.box_title {
    line-height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.box_title_font {
    /* float: left; */
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
}
.box_title_button-radio {
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
    margin-right: 20px;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    margin-top: 10px;
}

.my-export {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    margin: 10px 0;
}

.my-beizhu {
    font-size: 14px;
    margin: 10px 0;
    color: #666666;
}
</style>
