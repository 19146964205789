<template>
	<el-drawer title="订单详情" v-model="dialogDisplay" @close="onCancel" direction="rtl" size="70%">
		<el-table :border="true" :data="detail.goods" v-loading="loading" :header-cell-style="{backgroundColor: 'rgb(245, 247, 250)'}" >
			<el-table-column prop="skuImg" label="商品图片" width="180" >
				<template #default="{ row }">
					<el-image style="width: 80px; height: 80px" :src="row.skuImg" :preview-src-list="[ row.skuImg ]" />
				</template>
			</el-table-column>
			<el-table-column prop="skuTitle" label="商品名称" />
			<el-table-column prop="skuPrice" label="售价">
				<template #default="{ row }">
					<span>{{ formatPrice(row.skuPrice) }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="skuNum" label="数量" />
		</el-table>
		<div class="line">商品数：{{ detail.goods_num }}</div>
		<div class="line">应收金额：￥{{ formatPrice(detail.orderSkuMoney) }}</div>
		<div class="line">实付金额：￥{{ formatPrice(detail.orderMoney) }}</div>
		<div class="line">购买时间：{{ $utils.formatDate(detail.payTime || '') }}</div>
	</el-drawer>
</template>

<script setup>

import { ref, watch, defineProps, defineEmits } from 'vue'
import http from '@/assets/js/http'

const dialogDisplay = ref(false)
const emit = defineEmits(['cancel'])
const loading = ref(false)

const props = defineProps({
	id: {
		type: Number,
		default: ()=> {
			return undefined
		}
	},
	dialog: {
		type: Boolean,
		default: ()=> {
			return false
		}
	},
	url: {
		type: String,
		default: ()=> {
			return 'meizhiOrder/info'
		}
	}
})

watch(props, async (newVal)=> {
	if(newVal.dialog) {
		dialogDisplay.value = true
		getDetail()
	} 
}, { deep: true })

const detail = ref({})

const getDetail = async ()=> {
	const { id, url } = props
    detail.value = await http.post(url, { id })
}

const formatPrice = (data)=> {
	return data ? (+data / 100).toFixed(2) : '0'
}

const onCancel = ()=> {
	emit('cancel')
}

</script>

<style lang="scss" scoped>
.line {
	margin-top: 20px;
}
</style>