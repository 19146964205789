<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <div class="box_title" style="">
          <div class="box_title_font">添加流水</div>
          <div class="box_title_button">
            <!--可添加按钮-->
            <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
          </div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin-top: 20px">
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="7">
              <div style="width: 100%"></div>
            </el-col>
            <!--主表样式-->
            <el-col :span="24" :xs="24" :sm="24" :lg="9">
              <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                label-width="120px"
                class="demo-ruleForm"
              >
                <el-form-item label="添加月份：" prop="month">
                  <el-date-picker
                    v-model="ruleForm.month"
                    type="month"
                    placeholder="请选择月份"
                    style="width: 100%"
                    value-format="YYYY-MM"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="月分红金额：" prop="amount">
                  <el-input v-model="ruleForm.amount"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="submitForm('ruleForm')"
                    style="width: 40%; margin-right: 10px"
                    >确认
                  </el-button>
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="8">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import axios from "axios";
import { ElMessage } from "element-plus";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vdvcflowlistadd",
  data() {
    return {
      mainonebread: [
        {
          name: "合作伙伴",
        },
        {
          name: "设备合伙人",
        },
        {
          name: "设备流水列表",
          path: "/dvcflowlist",
        },
        {
          name: "添加流水",
          path: "/dvcflowlistadd",
        },
      ], // 当前页-【面包屑导航】

      ruleForm: {
        month: "",
        amount: "",
      }, //form表单
      rules: {
        month: [
          {
            required: true,
            message: "月份为必填项",
            trigger: "blur",
          },
        ],
        amount: [
          {
            required: true,
            message: "月分红金额为必填项",
            trigger: "blur",
          },
        ],
      }, //form表单-必填规则

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
    };
  },

  methods: {
    //from表单-提交表单
    submitForm(formName) {
      var getdata = this.$refs[formName].model;
      // console.log(getdata.month.valueOf() / 1000);
      // console.log(getdata.month);
      // var monthdata = getdata.month.valueOf() / 1000;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post(
              "/admin/partner/addBonus",
              {
                month: getdata.month,
                amount: getdata.amount,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                  duration: 1000,
                  onClose: () => {
                    this.$router.go(-1); // 返回上一页
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //from表单-重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    }
  },
  components: {
    mainone,
  },
});
</script>
<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
}
</style>