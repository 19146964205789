<template>
    <div id="mymain">
        <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">广告详情</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <div class="main_one_button">
                            <el-button
                                type="primary"
                                class="max_search main_two_search"
                                v-if="getdatas.status === 2"
                                @click="chuliTanClick(getdatas, (dialogVisible = true)), resetFormSenior('seniorForm')"
                                >审核
                            </el-button>
                        </div>
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <maindetails :detailsdata="mainpagedata.details" style="margin-top: 20px"></maindetails>
                    <div style="margin-bottom: 50px">
                        <div style="min-width: 80px; float: left; margin-right: 20px">
                            <span style="font-size: 14px; color: #333333; margin-left: 38px">广告内容:</span>
                        </div>
                        <div v-if="mainpagedata.imgesdata.type == 'images'">
                            <mainimgesshow :imgesdata="mainpagedata.imgesdata"></mainimgesshow>
                        </div>
                        <div v-if="mainpagedata.videodata.type == 'video/mp4'">
                            <div
                                style="
                  border: 1px dashed #465eff;
                  display: table;
                  padding: 10px;
                  border-radius: 8px;
                "
                            >
                                <video
                                    :src="mainpagedata.videodata.url"
                                    controls
                                    width="150"
                                    poster="https://www.jingshuoprint.com/Static/ShopMange/static/h-ui.admin/images/video.png"
                                ></video>
                            </div>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <maindetails :detailsdata="mainpagedata.details1"></maindetails>
                    <el-divider></el-divider>
                    <maindetails :detailsdata="mainpagedata.details2"></maindetails>
                </div>
            </div>
        </div>

        <!--弹出框-->
        <el-dialog v-model="dialogVisible" title="广告审核" :width="searchwidth" :before-close="handleCloseSenior">
            <el-form ref="seniorForm" :model="seniorform" :rules="rules" label-width="100px" style="padding-right: 20px">
                <!--以下是左侧字-->
                <el-form-item label="广告审核：">
                    <span style="margin-right: 10px">{{ seniorform.name }}</span>
                </el-form-item>
                <el-form-item label="审核结果：" prop="status">
                    <el-radio-group v-model="seniorform.status">
                        <el-radio :label="3">审核通过 </el-radio>
                        <el-radio :label="4">审核不通过 </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="审核备注：" prop="remark">
                    <el-input v-model="seniorform.remark" type="textarea"></el-input>
                </el-form-item>
                <div style="text-align: right">
                    <el-button @click="resetFormSenior('seniorForm', (dialogVisible = false))">取消</el-button>
                    <el-button type="primary" @click="onSubmitSenior('seniorForm')" :disabled="mydisabled">确定</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import maindetails from '@/components/Maindetails'
import mainimgesshow from '@/components/Mainimgesshow'
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { ref } from 'vue'

export default {
    name: 'adverinfo',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainpagedata: {
                mainonebread: [
                    {
                        name: '运营'
                    },
                    {
                        name: '设备广告管理'
                    },
                    {
                        name: '查看广告详情',
                        path: '/advertlistinfo'
                    }
                ], // 当前页-【面包屑导航】

                details: {
                    showstatus: 1, //确定样式模块
                    title: '广告信息',
                    button: {
                        isshow: false,
                        name: '',
                        jump: '' //点击跳转
                    },
                    content: [
                        {
                            name: '广告编号:',
                            value: ''
                        },
                        {
                            name: '广告主题:',
                            value: ''
                        },
                        {
                            name: '广告来源:',
                            value: ''
                        },
                        {
                            name: '状态:',
                            value: ''
                        },
                        {
                            name: '广告时长:',
                            value: ''
                        },
                        {
                            name: '投放数量:',
                            value: ''
                        },
                        {
                            name: '总播放次数:',
                            value: ''
                        },
                        {
                            name: '投放设备:',
                            value: '',
                            jumpbt: '[查看设备]',
                            jump: '/lookdevice'
                        },
                        {
                            name: '投放时间:',
                            value: ''
                        },
                        {
                            name: '投放天数:',
                            value: ''
                        },
                        {
                            name: '联系人:',
                            value: ''
                        },
                        {
                            name: '联系方式:',
                            value: ''
                        }
                    ]
                }, //关于详情信息的组件
                details1: {
                    showstatus: 1, //确定样式模块
                    title: '订单信息',
                    button: {
                        isshow: false,
                        name: ''
                    },
                    content: [
                        {
                            name: '支付流水号:',
                            value: ''
                        },
                        {
                            name: '金额:',
                            value: ''
                        },
                        {
                            name: '支付方式:',
                            value: ''
                        },
                        {
                            name: '支付时间:',
                            value: ''
                        }
                    ]
                }, //关于详情信息的组件
                details2: {
                    showstatus: 1, //确定样式模块
                    title: '审核信息',
                    button: {
                        isshow: false,
                        name: ''
                    },
                    content: [
                        {
                            name: '审核人:',
                            value: ''
                        },
                        {
                            name: '审核结果:',
                            value: ''
                        },
                        {
                            name: '审核备注:',
                            value: ''
                        },
                        {
                            name: '审核时间:',
                            value: ''
                        }
                    ]
                }, //关于详情信息的组件
                imgesdata: {
                    showstatus: 2,
                    type: '',
                    content: [
                        // {
                        //   url: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
                        //   preview: [
                        //     "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
                        //   ],
                        // },
                    ]
                }, //关于图片展示的组件
                videodata: {
                    url: '',
                    type: ''
                },

                tancontent: {
                    title: '广告审核', //弹框-标题
                    content: {
                        myinputishow: true, //form表单-input单框
                        myinput: {
                            inputwidth: '95%',
                            input: [
                                {
                                    ismust: false, //是否必填，true表示必填
                                    title: '反馈详情：',
                                    placeholder: '请输入内容',
                                    name: 'keywords',
                                    content: '',
                                    textarea: 'textarea',
                                    textareaheight: 6
                                }
                            ]
                        },
                        myradioisshow: true, //form表单-radio单选框
                        myradio: {
                            radio: [
                                {
                                    titlewidth: '90px',
                                    ismust: false,
                                    title: '商户商户：',
                                    name: 'type',
                                    value: ref('1'),
                                    radio: [
                                        {
                                            content: '审核通过',
                                            label: '1'
                                        },
                                        {
                                            content: '审核不通过',
                                            label: '2'
                                        }
                                    ]
                                }
                            ]
                        } //form表单-radio选框
                    }
                },

                seniorform: {
                    name: '',
                    region: '',
                    date1: '',
                    date2: '',
                    delivery: false,
                    type: [],
                    resource: '',
                    desc: ''
                },
                searchwidth: '580px'
            }, // 当前Pagedata所需的data数据与控制
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

            getdatas: {
                status: null
            }, //接口获取的总数据

            //弹框-form表单数据
            seniorform: {
                id: '',
                name: '',
                status: 4,
                remark: ''
            },
            rules: {
                status: [
                    {
                        required: false,
                        message: '状态为必选项',
                        trigger: 'blur'
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '反馈详情为必填项',
                        trigger: 'blur'
                    }
                ]
            },
            dialogVisible: false,
            searchwidth: '580px',

            mydisabled: false,

            loading: false
        }
    },
    methods: {
        //弹出框-提交表单
        onSubmitSenior(formName) {
            // 前端防抖
            this.mydisabled = true

            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/admin/ClientAdvert/audit',
                            {
                                id: getdatas.id,
                                status: getdatas.status,
                                remark: getdatas.remark
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible = ref(false) //关闭弹窗
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.mydisabled = false
                                        this.reload() //刷新
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },
        //弹出框-取消
        resetFormSenior(formName) {
            this.$refs[formName].resetFields()
        },
        //点击按钮-弹出弹框
        chuliTanClick(taninfo) {
            this.seniorform.id = taninfo.id
            this.seniorform.name = taninfo.name
        },
        //点击关闭-弹窗
        handleCloseSenior(done) {
            done()
        },
        getwindowinfo() {
            //获取当前windows页面信息-实时修改【弹窗】的宽度
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            this.loading = true
            // console.log("this.$route.query.id:" + this.$route.query.id);

            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/ClientAdvert/info',
                    {
                        id: this.$route.query.id || this.$route.query.adid
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.getdatas = response.data.result
                    var getdatas = response.data.result

                    // 渲染-广告信息
                    this.mainpagedata.details.content = [
                        {
                            name: '广告编号:',
                            value: getdatas.code
                        },
                        {
                            name: '广告主题:',
                            value: getdatas.name
                        },
                        {
                            name: '广告来源:',
                            value: getdatas.thirdparty_name
                        },
                        {
                            name: '状态:',
                            value: getdatas.status_name
                        },
                        {
                            name: '广告时长:',
                            value: getdatas.duration + 's'
                        },
                        {
                            name: '投放数量:',
                            value: getdatas.push_num
                        },
                        {
                            name: '总播放次数:',
                            value: getdatas.playtimes
                        },
                        {
                            name: '投放设备:',
                            value: getdatas.push_type_name,
                            jumpbt: '[查看设备]',
                            jump: '/lookdevice',
                            jumpdatas: { id: getdatas.id, type: getdatas.push_type_name }
                        },
                        {
                            name: '投放时间:',
                            value: this.$utils.formatDate(getdatas.begin_time) + ' 至 ' + this.$utils.formatDate(getdatas.end_time)
                        },
                        {
                            name: '投放天数:',
                            value: getdatas.push_day
                        },
                        {
                            name: '联系人:',
                            value: getdatas.guest_name
                        },
                        {
                            name: '联系方式:',
                            value: getdatas.guest_mobile
                        }
                    ]
                    //渲染-图片 / 视频  type:1图片；2视频
                    if (getdatas.type == 2) {
                        this.mainpagedata.videodata.type = 'video/mp4'
                        this.mainpagedata.videodata.url = getdatas.file_url
                    } else {
                        this.mainpagedata.imgesdata.type = 'images'
                        this.mainpagedata.imgesdata.content = [
                            {
                                url: getdatas.file_url,
                                preview: [getdatas.file_url]
                            }
                        ]
                    }

                    // 渲染-订单信息
                    this.mainpagedata.details1.content = [
                        {
                            name: '支付流水号:',
                            value: getdatas.pay.ad_no
                        },
                        {
                            name: '金额:',
                            value: getdatas.pay.pay_amount / 100
                        },
                        {
                            name: '支付方式:',
                            value: getdatas.pay.pay_way_name
                        },
                        {
                            name: '支付时间:',
                            value: this.$utils.formatDate(getdatas.pay.uptime)
                        }
                    ]
                    // 渲染-审核信息
                    this.mainpagedata.details2.content = [
                        {
                            name: '审核人:',
                            value: getdatas.audit.process_name
                        },
                        {
                            name: '审核结果:',
                            value: getdatas.audit.status_name
                        },
                        {
                            name: '审核备注:',
                            value: getdatas.audit.remark
                        },
                        {
                            name: '审核时间:',
                            value: getdatas.audit.addtime ? this.$utils.formatDate(getdatas.audit.addtime) : null
                        }
                    ]

                    this.loading = false
                })
                .catch(function(error) {
                    console.log(error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        maindetails,
        mainimgesshow
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
