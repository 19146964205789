<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">家庭成员</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="tableData"
                        style="width: 100%"
                        min-height="900"
                        border
                    >
                        <el-table-column
                            v-for="(mytabletitle, i) in tabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                            :min-width="mytabletitle.minwidth"
                        >
                            <!--例如：status状态字段，判断最终的值是那种表现形式等 -->
                            <template #default="scope">
                                <!-- 用户ID -->
                                <div v-if="mytabletitle.prop === 'uid'" :class="scope.row.status != 1 ? 'del-change-status' : ''">
                                    <div
                                        :class="this.mypowerlimits.yhlb == true && scope.row.status == 1 ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.yhlb == true && scope.row.status == 1 ? routeraddrClick('/userinfolist', scope.row) : ''"
                                    >
                                        <span>{{ scope.row.uid }}</span>
                                    </div>
                                </div>
                                <!-- 用户昵称 -->
                                <div v-if="mytabletitle.prop === 'nickname'" :class="scope.row.status != 1 ? 'del-change-status' : ''">
                                    <span>{{ scope.row.nickname }}</span>
                                </div>
                                <!-- 手机号 -->
                                <div v-if="mytabletitle.prop === 'mobile'" :class="scope.row.status != 1 ? 'del-change-status' : ''">
                                    <span>{{ scope.row.mobile }}</span>
                                </div>
                                <!-- 实名认证 -->
                                <div v-if="mytabletitle.prop === 'is_real'" :class="scope.row.status != 1 ? 'del-change-status' : ''">
                                    <span>{{ scope.row.is_real == 1 ? '已认证' : '未认证' }}</span>
                                </div>
                                <!-- 关注公众号 -->
                                <div v-if="mytabletitle.prop === 'subscribe'" :class="scope.row.status != 1 ? 'del-change-status' : ''">
                                    <span>{{ scope.row.subscribe == 1 ? '已关注' : '未关注' }}</span>
                                </div>
                                <!-- 打印订单数 -->
                                <div v-if="mytabletitle.prop === 'order_num'" :class="scope.row.status != 1 ? 'del-change-status' : ''">
                                    <div
                                        :class="this.mypowerlimits.ddlb == true && scope.row.status == 1 ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.ddlb == true && scope.row.status == 1 ? routeraddrClick('/orderlist', scope.row) : ''"
                                    >
                                        <span>{{ scope.row.order_num }}</span>
                                    </div>
                                </div>
                                <!-- 成员类型 -->
                                <div v-if="mytabletitle.prop === 'user_type'">
                                    <el-radio-group v-model="scope.row.user_type" :disabled="scope.row.status != 1">
                                        <el-radio label="1">管理员</el-radio>
                                        <el-radio label="2">成员</el-radio>
                                    </el-radio-group>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :fixed="false" :width="handle" prop="myoperation" id="operation">
                            <template #header>
                                <span>操作&nbsp;</span>
                                <span style="cursor: pointer;"
                                    ><el-tooltip class="box-item" effect="dark" placement="top-end">
                                        <i class="el-icon-info"></i>
                                        <template #content>
                                            <span>此处删除为软删除，实际删除以保存结果为主</span>
                                        </template>
                                    </el-tooltip></span
                                >
                            </template>
                            <template #default="scope">
                                <el-button type="danger" size="mini" :disabled="scope.row.status != 1" @click="delEvent(scope.row, scope.$index)">{{
                                    scope.row.status == 1 ? '删除' : '已删除'
                                }}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="my-save-btn" v-if="this.tableData.length > 0">
                        <div style="min-width: 160px; max-width: 16%;flex-grow: 1;">
                            <el-button type="primary" style="width: 100%;" @click="saveEvent(this.tableData)">保存</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default {
    name: 'vsharedvccontrolfamilyedit',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '打印'
                },
                {
                    name: '共享打印'
                },
                {
                    name: '机器控制',
                    path: '/sharedvccontrol'
                },
                {
                    name: '家庭成员'
                }
            ], // 当前页-【面包屑导航】

            formhtml: '',
            formhtmlurl: '',

            handle: 150, // 数据列表中-操作-宽度
            tabletitle: [
                {
                    fixedstatu: false,
                    prop: 'uid',
                    label: '用户ID',
                    width: '',
                    minwidth: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'nickname',
                    label: '用户昵称',
                    width: '',
                    minwidth: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'mobile',
                    label: '手机号',
                    width: '',
                    minwidth: '140'
                },
                {
                    fixedstatu: false,
                    prop: 'is_real',
                    label: '实名认证',
                    width: '',
                    minwidth: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'subscribe',
                    label: '关注公众号',
                    width: '',
                    minwidth: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'order_num',
                    label: '打印订单数',
                    width: '',
                    minwidth: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'user_type',
                    label: '成员类型',
                    width: '',
                    minwidth: '120'
                }
            ], // 数据列表中-数据表头
            tableData: [],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            loading: false
        }
    },
    methods: {
        // 跳转
        routeraddrClick(routeraddr, rowdata) {
            // 跳转-用户详情
            if (routeraddr === '/userinfolist') {
                this.$router
                    .push({
                        path: routeraddr,
                        query: {
                            id: rowdata.uid,
                            thirdparty: 1 // 目前整体跳转平台来源1
                        }
                    })
                    .catch((error) => error)
            }

            // 跳转-订单列表
            if (routeraddr === '/orderlist') {
                this.$router
                    .push({
                        path: routeraddr,
                        query: {
                            id: rowdata.uid,
                            client: this.$route.query.client,
                            thirdparty: 1 // 目前整体跳转平台来源1
                        }
                    })
                    .catch((error) => error)
            }
        },
        // 删除
        delEvent(data) {
            data.status = -1
        },
        // 保存
        saveEvent(datas) {
            // console.log(datas)
            axios
                .post(
                    '/admin/Equipment/updateFamilyUserInfo',
                    {
                        data: datas
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message,
                            duration: 1000,
                            onClose: () => {
                                this.reload() //刷新
                            }
                        })
                    }
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            axios
                .post(
                    '/admin/Equipment/getFamilyUserInfo',
                    {
                        cid: this.$route.query.cid
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        let getdatas = response.data.result
                        this.tableData = getdatas.map((n) => {
                            n.user_type = n.user_type.toString()
                            return n
                        })
                    }
                    this.loading = false
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone
    }
}
</script>

<style scoped>
/* 以下是:当前页面整个box */
.box {
    background-color: #ffffff;
    border-radius: 4px;
}

.box_title {
    line-height: 50px;
    height: 50px;
    margin-left: 20px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    margin-right: 20px;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    padding: 20px 0;
}

.del-change-status {
    color: #999999;
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.my-save-btn {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>
