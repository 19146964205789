<template>
    <div>
        <el-dialog v-model="dialogVisible" :title="title" :width="searchwidth" :before-close="handleClose">
            <!-- 搜索 -->
            <div>
                <slot name="search"></slot>
            </div>
            <!-- table列表 -->
            <div>
                <slot name="table"></slot>
            </div>
            <!-- 分页 -->
            <div>
                <slot name="pages"></slot>
            </div>
            <!-- 底部-确定&取消 -->
            <template #footer>
                <span class="dialog-footer">
                    <slot name="footbtn"></slot>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'modelDialog',
    inject: ['reload'],
    props: ['isshow', 'title', 'searchWidth'],
    watch: {
        isshow: {
            handler(newVal) {
                this.dialogVisible = newVal
            },
            deep: true,
            immediate: true
        },
        searchWidth: {
            handler(newVal) {
                this.searchwidth = newVal ? newVal : '1460px'
                this.max_width = newVal ? parseInt(newVal.match(/\d+/)[0]) : 1460
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            dialogVisible: false,
            searchwidth: '1460px',
            max_width: 1460
        }
    },
    methods: {
        handleClose(done) {
            this.$emit('beforeclose', done)
        },
        // 弹窗收缩
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < this.max_width) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = parseInt(this.max_width - 60) + 'px'
            }
        }
    },
    created() {
        window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
        this.getwindowinfo()
    },
    components: {}
})
</script>

<style scoped></style>
