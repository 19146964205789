<template>
  <div class="user-exception">
    <el-breadcrumb>
      <el-breadcrumb-item>购物</el-breadcrumb-item>
      <el-breadcrumb-item>营销管理</el-breadcrumb-item>
      <el-breadcrumb-item>异常用户</el-breadcrumb-item>
    </el-breadcrumb>
    
    <el-form
      ref="searchFormEle"
      class="search-form"
      :model="searchForm"
      :inline-message="false"
      :show-message="false"
      inline
    >
      <div class="search-container">
        <div class="form">
<!--          <el-form-item label="异常订单编号" prop="orderNo">-->
<!--            <el-input v-model="searchForm.orderNo" placeholder="订单编号" clearable />-->
<!--          </el-form-item>-->
          <el-form-item label="输入搜索" prop="keywords">
            <el-input v-model="searchForm.keywords" placeholder="用户ID、用户昵称、手机号" clearable />
          </el-form-item>
          <el-form-item label="异常时间" prop="addtime">
            <el-date-picker
              v-model="searchForm.addtime"
              type="daterange"
              start-placeholder="起始"
              end-placeholder="结束"
              value-format="x"
              clearable
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="异常状态" prop="status">
            <el-select v-model="searchForm.status" placeholder="请选择" clearable>
              <el-option label="全部" value="" />
              <el-option label="异常" :value="-1" />
              <el-option label="正常" :value="1" />
            </el-select>
          </el-form-item>
        </div>
        <div class="btns">
          <div>
            <el-button @click="handleReset">重置</el-button>
            <el-button type="primary" @click="handleSearch">搜索</el-button>
          </div>
        </div>
      </div>
    </el-form>
    
    <div v-if="mypowerlimits.ycyh" class="table-container">
      <div class="toolbar">
        <span>数据列表</span>
        <el-button @click="getTableData(true)">导出</el-button>
      </div>
      
      <el-table
        class="table"
        v-loading="tableLoading"
        :data="tableData"
        border
        :header-cell-style="{backgroundColor: 'rgb(245, 247, 250)'}"
      >
        <template v-for="(column, c) in tableColumns" :key="c">
          <el-table-column
            v-if="column.prop === 'order_fail_num'"
            :prop="column.prop"
            :label="column.label"
            :minWidth="column.minWidth"
          >
            <template #default="{row}">
              <el-button
                v-if="mypowerlimits.ycyh_ck"
                type="text"
                link
                @click="routerTo(row)"
              >{{ row.order_fail_num }}</el-button>
              <span v-else>{{ row.order_fail_num }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :prop="column.prop"
            :label="column.label"
            :minWidth="column.minWidth"
            :width="column.width"
            :fixed="column.fixed"
            :formatter="column.formatter"
          />
        </template>
        <el-table-column v-if="mypowerlimits.ycyh_jc" label="操作" fixed="right" width="140">
          <template #default="{row}">
            <el-button
              v-if="row.status === -1"
              type="primary"
              @click="openDialog(row.uid)"
            >解除异常</el-button>
          </template>
        </el-table-column>
      </el-table>
      
      <div class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          v-model:current-page="pagination.page.current"
          v-model:page-size="pagination.page.size"
          :total="pagination.total"
          @sizeChange="getTableData"
          @currentChange="getTableData"
        />
      </div>
    </div>
    
    <el-dialog
      v-model="dialogVisible"
      title="解除异常"
      width="800px"
      append-to-body
      @close="closeDialog"
    >
      <el-form
        ref="dialogFormEle"
        :model="dialogData"
        label-position="top"
        size="small"
      >
        <el-form-item label="解除异常原因" prop="remark" :rules="{required: true, message: '请输入异常原因'}">
          <el-input
            class="reason-input"
            v-model="dialogData.remark"
            type="textarea"
            placeholder="解除异常原因"
            show-word-limit
            maxlength="120"
            :rows="8"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="submitDialog">确定解除</el-button>
      </template>
    </el-dialog>
  
  </div>
</template>

<script setup>
import {reactive, ref} from "vue";
import axios from "axios";
import {ElMessage} from 'element-plus'
import {useRouter} from 'vue-router'

const mypowerlimits = reactive(JSON.parse(localStorage.getItem('powerlimits')))

const router = useRouter()
const searchFormEle = ref()
const searchForm = ref({
  // orderNo: '',
  keywords: '',
  addtime: [],
  status: '',
})

const tableLoading = ref(false)
const tableData = ref([])
const tableColumns = ref([
  {
    label: '用户ID',
    prop: 'uid',
    minWidth: 100,
    fixed: 'left'
  },
  {
    label: '用户昵称',
    prop: 'nickname',
    minWidth: 100
  },
  {
    label: '手机号',
    prop: 'mobile',
    minWidth: 140
  },
  {
    label: '异常订单',
    prop: 'order_fail_num',
    minWidth: 140,
  },
  {
    label: '应退打印余额（元）',
    prop: 'shouldrefundCredit',
    minWidth: 160
  },
  {
    label: '实退打印余额（元）',
    prop: 'refundCredit',
    minWidth: 160
  },
  {
    label: '所欠打印余额（元）',
    prop: 'unrefundCredit',
    minWidth: 160
  },
  {
    label: '异常时间',
    prop: 'addtime',
    width: 180,
    // formatter: (row) => {
    //   return dayjs.unix(row.addtime).format('YYYY-MM-DD HH:mm:ss')
    // }
  },
  {
    label: '异常状态',
    prop: 'status',
    minWidth: 100,
    formatter: (row) => {
      return row.status === 1 ? '正常' : '异常'
    }
  },
  {
    label: '解除异常原因',
    prop: 'remark',
    minWidth: 155
  },
])
const pagination = ref({
  total: 0,
  page: {
    current: 1,
    size: 10
  }
})
const getTableData = (isExport) => {
  const params = {
    ...searchForm.value,
    addtime: '',
    size: pagination.value.page.size,
    page: pagination.value.page.current
  }
  
  if(searchForm.value.addtime.length > 0) {
    const dateRange = [
      +searchForm.value.addtime[0],
      +searchForm.value.addtime[1]+(24*60*60*1000)-1
    ]
    const addtime = Array.from(dateRange).map(i => i/1000)
    params.addtime = addtime.join(' ~ ')
  }
  if(isExport) params.export = 1
  if(!mypowerlimits.ycyh) return
  tableLoading.value = true
  axios.post(`/admin/member/blackList`,{
    ...params
  }, {
    headers: {
      Authentication: localStorage.getItem('token')
    }
  }).then(res => {
    if(res.data.code === 0) {
      if(!isExport) {
        const {total, data} = res.data.result
        tableData.value = data
        pagination.value.total = total
      }
    } else {
      pagination.value.total = 0
    }
  }).finally(() => {
    tableLoading.value = false
  })
}

const handleSearch = () => {
  pagination.value.page.current = 1
  pagination.value.page.size = 10
  getTableData()
}
const handleReset = () => {
  searchFormEle.value.resetFields()
  pagination.value.page.current = 1
  pagination.value.page.size = 10
  getTableData()
}

const dialogVisible = ref(false)
const dialogFormEle = ref()
const dialogData = ref({
  remark: '',
  uid: ''
})
const openDialog = (uid) => {
  dialogData.value.uid = uid
  dialogVisible.value = true
}
const closeDialog = () => {
  dialogData.value.uid = ''
  dialogData.value.remark = ''
  dialogVisible.value = false
  dialogFormEle.value.clearValidate()
}
const submitLoading = ref(false)
const submitDialog = () => {
  dialogFormEle.value.validate((valid) => {
    if(valid) {
      submitLoading.value = true
      axios.post(`/admin/member/changeStatus`, {
        uid: dialogData.value.uid,
        remark: dialogData.value.remark
      }, {
        headers: {
          Authentication: localStorage.getItem('token')
        }
      }).then(res => {
        if(res.data.code === 0) {
          ElMessage.success('操作成功')
          closeDialog()
          getTableData()
        }
      }).finally(() => {
        submitLoading.value = false
      })
    }
  })
}

getTableData()

// 异常订单跳转
const routerTo = (row) => {
  router.push({
    path: `/activityValidOrder`,
    query: {
      uid: row.uid,
      sendstatus: 2, // 异常退回
    }
  })
}
</script>

<style lang="scss" scoped>
$base-bg-color: #fff;
$base-radius: 4px;
.flex-row{
  display: flex;
  flex-direction: row;
}
.flex-column{
  display: flex;
  flex-direction: column;
}
.search-form{
  border-radius: $base-radius;
  padding: 10px 20px;
  margin: 10px 0;
  background-color: $base-bg-color;
  :deep(.el-form-item){
    margin-bottom: 10px;
    margin-top: 10px;
    .el-input{
      width: 215px;
    }
  }
  .search-container{
    @extend .flex-row;
    justify-content: space-between;
    .form{
      width: calc(100% - 180px);
    }
    .btns{
      @extend .flex-row;
      justify-content: flex-end;
      width: 180px;
      margin-top: 10px;
    }
  }
}

.table-container{
  @extend .flex-column;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  border-radius: $base-radius;
  background-color: $base-bg-color;
  .toolbar{
    @extend .flex-row;
    justify-content: space-between;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .pagination{
    @extend .flex-row;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.reason-input{

}
</style>
