<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">添加业务用户</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="7">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                                <el-form-item label="登录用户名：" prop="username">
                                    <el-input v-model="ruleForm.username" clearable maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item label="姓名：" prop="truename">
                                    <el-input v-model="ruleForm.truename" clearable maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item label="所属区域：" prop="area">
                                    <el-input v-model="ruleForm.area" clearable maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item label="直属上级：">
                                    <div>
                                        <treeCascaderBox
                                            :ModelValue="ruleForm.pid"
                                            :Placeholder="'请选择直属上级'"
                                            :Options="pid_options"
                                            :Props="pid_options_props"
                                            @changedatas="changedatasEvent"
                                        />
                                    </div>
                                </el-form-item>
                                <el-form-item label="登录密码：" prop="pass">
                                    <el-input v-model="ruleForm.pass" type="password" autocomplete="off" show-password clearable maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item label="确认密码：" prop="checkPass">
                                    <el-input v-model="ruleForm.checkPass" type="password" autocomplete="off" show-password clearable maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item label="备注信息：" prop="remark">
                                    <el-input v-model="ruleForm.remark" type="textarea"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'vuseradd',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '权限'
                },
                {
                    name: '权限管理'
                },
                {
                    name: '业务用户管理',
                    path: '/serviceuser'
                },
                {
                    name: '添加业务用户'
                }
            ], // 当前页-【面包屑导航】

            ruleForm: {
                username: '',
                truename: '',
                area: '',
                pid: '',
                pass: '',
                checkPass: '',
                remark: ''
            }, //form表单
            rules: {
                username: [
                    {
                        required: true,
                        message: '登录用户名为必填项',
                        trigger: 'blur'
                    }
                ],
                truename: [
                    {
                        required: true,
                        message: '姓名为必填项',
                        trigger: 'blur'
                    }
                ],
                area: [
                    {
                        required: true,
                        message: '所属区域为必选项',
                        trigger: 'change'
                    }
                ],
                pid: [
                    {
                        required: false,
                        message: '直属上级为必选项',
                        trigger: 'change'
                    }
                ],
                pass: [
                    {
                        required: true,
                        validator: this.validatePass,
                        trigger: 'blur'
                    }
                ],
                checkPass: [
                    {
                        required: true,
                        validator: this.validatePass2,
                        trigger: 'blur'
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '',
                        trigger: 'blur'
                    }
                ]
            }, //form表单-必填规则

            pid_options_props: { value: 'id', label: 'truename', children: 'child', multiple: false, checkStrictly: true, emitPath: false },
            pid_options: [], // 直属上级id下拉

            loading: false,
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token
        }
    },
    methods: {
        //选择上级
        changedatasEvent(data) {
            this.ruleForm.pid = data
        },
        //提交第一个密码
        validatePass(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入登录密码'))
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass')
                }
                callback()
            }
        },
        //校验-第二个密码
        validatePass2(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入确认密码'))
            } else if (value !== this.ruleForm.pass) {
                callback(new Error('2次输入的密码不同！请重新输入'))
            } else {
                callback()
            }
        },
        //提交form表单-并判断是否必填
        submitForm(formName) {
            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/admin/winUser/userAdd',
                            {
                                username: getdatas.username,
                                truename: getdatas.truename,
                                area: getdatas.area,
                                pid: getdatas.pid && getdatas.pid != null ? getdatas.pid.pop() : 0,
                                password: getdatas.pass,
                                confirm_password: getdatas.checkPass,
                                remark: getdatas.remark
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功',
                                    duration: 1000,
                                    onClose: () => {
                                        // this.reload(); //刷新
                                        this.$router.go(-1) // 返回上一页
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.ruleForm.pass = ''
            this.ruleForm.checkPass = ''
            this.ruleForm.pid = []
        },
        finduserPidOptions() {
            axios
                .post(
                    '/admin/winUser/list',
                    {},
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.pid_options = response.data.result
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.finduserPidOptions()
        }
    },
    components: {
        mainone
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
