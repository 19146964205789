<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">添加</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="7">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm">
                                <!--收款方名称-->
                                <el-form-item label="收款方名称：" prop="name">
                                    <el-input v-model="ruleForm.name" maxlength="100"></el-input>
                                </el-form-item>
                                <!--收款支付宝账户-->
                                <el-form-item label="收款支付宝账户：" prop="account">
                                    <el-input v-model="ruleForm.account" maxlength="100"></el-input>
                                </el-form-item>
                                <!--打款总金额-->
                                <el-form-item label="打款总金额：" prop="amount">
                                    <el-input v-model="ruleForm.amount" clearable maxlength="100"></el-input>
                                </el-form-item>
                                <!--打款周期-->
                                <el-form-item label="打款周期：" prop="cycle">
                                    <el-row>
                                        <el-col :span="15">
                                            <el-input v-model="ruleForm.cycle" clearable maxlength="100"></el-input>
                                        </el-col>
                                        <el-col :span="1"></el-col>
                                        <el-col :span="8">
                                            <el-select v-model="ruleForm.payType" placeholder="请选择">
                                                <el-option
                                                    v-for="item in payTypeOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value"
                                                    style="width: 100%"
                                                >
                                                </el-option>
                                            </el-select>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <!--开始日期-->
                                <el-form-item label="开始日期：" prop="startTime">
                                    <el-row>
                                        <el-col :span="11">
                                            <el-date-picker 
                                                v-model="ruleForm.startTime" 
                                                type="date" 
                                                placeholder="选择日期" :disabled-date="disabledDate"
                                                style="width: 100%"
                                                value-format="YYYY-MM-DD"
                                                @change="datePickerChange"
                                            />
                                        </el-col>
                                        <el-col :span="2"></el-col>
                                        <el-col :span="11">
                                            <el-time-select
                                                v-model="ruleForm.startHours"
                                                :start="timeSelectstart"
                                                step="01:00"
                                                end="23:00"
                                                placeholder="选择时间"
                                                style="width: 100%"
                                            />
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <!--button按钮-->
                                <el-form-item style="margin-top: 50px" v-if="this.mypowerlimits.zhsz_tj">
                                    <el-button type="primary" @click="submitForm('ruleForm')"
                                        style="width: 40%">提交</el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import mainone from "@/components/Mainone.vue";
import { ElMessage } from "element-plus";
import axios from "axios";

export default {
    name: "vcorporatetransferadd",
    data() {
        return {
            mainonebread: [
                {
                    name: "财务",
                },
                {
                    name: "提现管理",
                },
                {
                    name: "对公打款",
                },
                {
                    name: "添加",
                },
            ], // 当前页-【面包屑导航】
            
            //form表单数据
            ruleForm: {
                name: "",
                account: "",
                amount: "",
                cycle: "",
                payType: "1",
                startTime: "",
                startHours: "",
            },

            rules: {
                name: [
                    {
                        required: true,
                        message: "请填写收款方名称",
                        trigger: "blur",
                    },
                ],
                account: [
                    {
                        required: true,
                        message: "请填写收款支付宝账户",
                        trigger: "blur",
                    },
                ],
                amount: [
                    {
                        required: true,
                        message: "请填写打款总金额",
                        trigger: "blur",
                    },
                ],
                cycle: [
                    {
                        required: true,
                        message: "请填写打款周期",
                        trigger: "blur",
                    },
                ],
                startTime: [
                    {
                        required: true,
                        message: "请填写日期",
                        trigger: "change",
                    },
                ],
            }, //form表单填写规则-前端判断是否必填

            payTypeOptions: [{
                value: '1',
                label: '日'
            }, {
                value: '2',
                label: '周'
            }, {
                value: '3',
                label: '月'
            }, {
                value: '4',
                label: '季'
            }, {
                value: '5',
                label: '年'
            }],

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token
            timeSelectstart: '00:00', // 时间选择，起始时间
        };
    },
    methods: {
        //提交form表单-并判断是否必填
        submitForm(formName) {
            const time = `${this.ruleForm.startTime} ${this.ruleForm.startHours}`
            const begin_time = (new Date(time)) / 1000
            const date = new Date()
            console.log({time})
            if (begin_time < (date / 1000)) {
                ElMessage({
                    type: "warning",
                    message: "当前时间已超过开始时间，请修改",
                });
                return
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post("/admin/fund/companyPaymentAdd",
                            {
                                name: this.ruleForm.name,
                                user: this.ruleForm.account,
                                total_money: this.ruleForm.amount * 100,
                                pay_type: this.ruleForm.payType,
                                total_num: this.ruleForm.cycle,
                                begin_time
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: "success",
                                    message: "提交成功！",
                                });
                                this.$router.go(-1)
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        //重置form表单中填写的数据
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        disabledDate(time) {
            return time.getTime() < (Date.now() - 86400000)
        },

        datePickerChange() {
            const begin_time = (new Date(this.ruleForm.startTime)) / 1000
            const date = new Date()
            const hour = date.getHours()
            if(begin_time > (date/1000)) {
                this.timeSelectstart = "00:00"
            } else {
                this.timeSelectstart = `${String(hour + 1).padStart(2, '0')}:00`
                this.ruleForm.startHours = this.timeSelectstart
            }
        }
    },

    created() {
    },
    components: {
        mainone,
    },
};
</script>
  
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #97a0c3;
    padding: 5px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
}
</style>
  