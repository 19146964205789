<template>
    <div id="mymain">
        <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font"><i class="el-icon-info"></i> 推广方案详情</div>
                    <div class="box_title_button">
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <maindetails :detailsdata="mainpagedata.details" style="margin-top: 20px;"></maindetails>
                    <el-divider></el-divider>
                    <maindetails :detailsdata="mainpagedata.details1" style="margin-top: 20px;" v-if="datas.type == 1"></maindetails>
                    <!--<el-divider v-if="datas.extend_type == '宣传页'"></el-divider>-->
                    <maindetails :detailsdata="mainpagedata.details2" style="margin-top: 20px;" v-if="datas.type == 2"></maindetails>
                    <div style="margin-bottom: 20px" v-if="datas.type == 2">
                        <span style="font-size: 14px;color: #616e9d">商家宣传海报:</span>
                    </div>
                    <mainimgesshow :imgesdata="mainpagedata.imgesdata" v-if="datas.type == 2"></mainimgesshow>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import mainone from '@/components/Mainone.vue'
    import maindetails from '@/components/Maindetails'
    import mainimgesshow from '@/components/Mainimgesshow'
    import axios from "axios";

    export default {
        name: "advspreadinfo",
        data() {
            return {
                datas:"",
                mainpagedata: {
                    mainonebread: [
                        {
                            name: '订单',

                        },
                        {
                            name: '广告主管理',

                        },
                        {
                            name: '广告推广方案',
                            path: '/advertisersspread'
                        },
                        {
                            name: '广告推广方案详情',
                        }
                    ], // 当前页-【面包屑导航】

                    details: {
                        showstatus: 1, //确定样式模块
                        title: '广告主信息',
                        button: {
                            isshow: false,
                            name: '',
                            jump: '' //点击跳转
                        },
                         content: [
                        //     {
                        //         name: '推广编号:',
                        //         value: 'PO35435'
                        //     },
                        //     {
                        //         name: '状态:',
                        //         value: '进行中'
                        //     },
                        //     {
                        //         name: '商家名称:',
                        //         value: '丹尼斯东风路店',
                        //         jumpbt: '[查看]',
                        //         jump: '/advertiserslist'
                        //     },
                        //     {
                        //         name: '方案名称:',
                        //         value: '新店开业'
                        //     },
                        //     {
                        //         name: '推送规则:',
                        //         value: '系统智能推荐'
                        //     },
                        //     {
                        //         name: '已打印:',
                        //         value: '60',
                        //         jumpbt: '[查看]',
                        //         jump: '/printwriteoff'
                        //     },
                        //     {
                        //         name: '打印花费:',
                        //         value: '6.20'
                        //     },
                        //     {
                        //         name: '已核销:',
                        //         value: '60',
                        //         jumpbt: '[查看]',
                        //         jump: '/printwriteoff'
                        //     },
                        //     {
                        //         name: '核销花费:',
                        //         value: '6.20'
                        //     },
                        //     {
                        //         name: '总花费:',
                        //         value: '6.20',
                        //     },
                        //     {
                        //         name: '创建时间:',
                        //         value: '2020-07-20 15:47:04'
                        //     },
                        ]

                    },//关于详情信息的组件
                    details1: {
                        showstatus: 1, //确定样式模块
                        title: '优惠券推广',
                        button: {
                            isshow: false,
                            name: '',
                            jump: '' //点击-弹出弹框
                        },
                        content: [
                            {
                                name: '推广方式:',
                                value: '优惠券'
                            },
                            {
                                name: '券的面额:',
                                value: '10'
                            },
                            {
                                name: '使用条件:',
                                value: '不限制',
                            },
                            {
                                name: '开始时间:',
                                value: '2020-07-19 14:48:38'
                            },
                            {
                                name: '结束时间:',
                                value: '2020-07-19 14:48:38'
                            },

                        ]

                    },//关于详情信息的组件
                    details2: {
                        showstatus: 1, //确定样式模块
                        title: '宣传页推广',
                        button: {
                            isshow: false,
                            name: '',
                            jump: '' //点击-弹出弹框
                        },
                        content: [
                            {
                                name: '推广方式:',
                                value: '宣传页'
                            },
                            {
                                name: '开始时间:',
                                value: '2020-07-19 14:48:38'
                            },
                            {
                                name: '结束时间:',
                                value: '2020-07-19 14:48:38',
                            },
                        ]

                    },//关于详情信息的组件
                    imgesdata: {
                        showstatus: 2,
                        content: [
                            {
                                url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                                preview: ['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg']
                            }
                        ]
                    },//关于图片展示的组件


                }, // 当前Pagedata所需的data数据与控制
                mytoken: localStorage.getItem('token'),  //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

            }
        },
        methods: {
            routeraddrClick(routeraddr) { //点击进入路由页面
                this.$router.push(routeraddr).catch(error => error)
                console.log(routeraddr)
            },

        },
        created() {
            if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
                this.isshow = false
                this.$router.push('/').catch(error => error)
            } else {
                //加载loading
                this.loading = true      //通过Ajax向后台获取数据
                //通过Ajax向后台获取数据
                axios.post('/admin/Advertiser/viewPlan',
                    {
                        id: this.$route.query.id,
                    },
                    {
                        headers: {
                            'Authentication': this.mytoken
                        }
                    })
                    .then(response => {
                        console.log(response.data.result)
                        this.datas = response.data.result
                        var content1 = []  //广告主推广方案信息
                        content1 = [
                            {
                                name: '推广编号:',
                                value: response.data.result.extend_no
                            },
                            {
                                name: '状态:',
                                value: response.data.result.status
                            },
                            {
                                name: '商家名称:',
                                value: response.data.result.company,
                                jumpbt: '[查看]',
                                jump: '/advertiserslist',
                                jumpdatas: {userid:response.data.result.uid},

                            },
                            {
                                name: '方案名称:',
                                value: response.data.result.title,
                            },
                            {
                                name: '推送规则:',
                                value:  response.data.result.rule,
                            },
                            {
                                name: '已打印:',
                                value:  response.data.result.print,
                                jumpbt: '[查看]',
                                jump: '/printwriteoff',
                                jumpdatas: { extend_no: response.data.result.extend_no},
                            },
                            {
                                name: '打印花费:',
                                value:  response.data.result.print_money,
                            },
                            {
                                name: '已核销:',
                                value:  response.data.result.check,
                                jumpbt: '[查看]',
                                jump: '/printwriteoff',
                                jumpdatas: { extend_no:response.data.result.extend_no},
                            },
                            {
                                name: '核销花费:',
                                value:  response.data.result.check_money,
                            },
                            {
                                name: '总花费:',
                                value:  response.data.result.total_money,
                            },
                            {
                                name: '创建时间:',
                                value:  this.$utils.formatDate(response.data.result.addtime),
                            }]
                        this.mainpagedata.details.content = content1
                        //
                        //
                        var content2 = []   //广告主价格
                        content2 = [
                            {
                                name: '推广方式:',
                                value: '优惠券'
                            },
                            {
                                name: '券的面额:',
                                value: response.data.result.amount,
                            },
                            {
                                name: '使用条件:',
                                value: response.data.result.min_order_amount,
                            },
                            {
                                name: '开始时间:',
                                value: response.data.result.begin_time,
                            },
                            {
                                name: '结束时间:',
                                value: response.data.result.end_time,
                            }]
                        this.mainpagedata.details1.content = content2

                        var content3 = []   //宣传页推广
                        content3 = [
                            {
                                name: '推广方式:',
                                value: '宣传页'
                            },
                            {
                                name: '开始时间:',
                                value: response.data.result.begin_time,
                            },
                            {
                                name: '结束时间:',
                                value: response.data.result.end_time,
                            }]
                        this.mainpagedata.details2.content = content3


                        //
                        var content5 = []   //宣传海报
                        content5 = [
                            {
                                url: response.data.result.image,
                                preview: [response.data.result.image],
                            }]
                        this.mainpagedata.imgesdata.content = content5
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
            // window.addEventListener('resize', this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
            // this.getwindowinfo()
        },
        components: {
            mainone,
            maindetails,
            mainimgesshow,
        }
    }
</script>

<style scoped>
    .box {
        background-color: #ffffff;
        border-radius: 4px;
        color: #97A0C3;
        /*line-height: 60px;*/
        padding: 10px 30px;
        margin-top: 20px;
        margin-bottom: 80px
    }

    .box_title {
        line-height: 40px;
        height: 40px;
        margin-top: 10px
    }

    .box_title_font {
        float: left;
        font-weight: 400;
        font-size: 14px;
        color: #616e9d;
    }

    .box_title_button {
        float: right;
        font-weight: 400;
        font-size: 14px;
        color: #94a3d2;
    }

    .box_title_button:hover {
        cursor: pointer;
    }

    .box_content {
        border-top: 1px solid #eeeeee;
        margin-top: 10px
    }

    /*******以下是form表单中button的控件css **************************************/
    .main_one_button {
        float: right;
    }

    .main_one_button .el-button:hover, .myaddbutton .el-button:hover {
        background-color: #ffffff;
        border: 1px solid #94a3d2;
        color: #94a3d2;
        font-size: 14px;
    }

    .main_one_button .el-button, .myaddbutton .el-button {
        border: 1px solid #002EB7;
        color: #002EB7;
        background-color: #ffffff;
    }

    .el-button {
        border-radius: 8px;
        letter-spacing: 1px !important;
    }

    .el-button i {
        font-size: 14px;
        font-weight: bold;
    }

    .el-button:active {
        color: #ffffff !important;
        background-color: #002EB7 !important;
    }

    .main_two_search {
        float: right;
        background-color: #002EB7 !important;
        color: #ffffff !important;
        border-radius: 8px;
    }

    .main_two_search:hover {
        background-color: #ffffff !important;
        border: 1px solid #94a3d2;
        color: #94a3d2 !important;
        font-size: 14px;
    }

    .main_two_search:active {
        color: #ffffff !important;
        background-color: #002EB7 !important;
    }

</style>