<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div style="border-top: 1px solid #eeeeee; margin-top: 10px">
            <!--主list列表-->
            <div style="background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 10px 20px">
                <div class="box-list-box" style="font-size: 14px; line-height: 60px; margin-bottom: 10px">
                    <!--左侧名称-->
                    <div><span>数据列表</span></div>
                    <!--右侧按钮-->
                    <div class="button-box">
                        <el-button v-if="mypowerlimits.sbbzsz_tj" type="primary" size="small" plain @click="tanEvent()">添加</el-button>
                        <!-- <el-button type="warning" size="small" plain @click="exportDatas()">导出</el-button> -->
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <dpwTable
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        ref="myTableDataRef"
                        :tabletitle="tabletitle"
                        :tabledata="tableData"
                        :totalcount="totalCount"
                        :currentpage="currentPage"
                        :pagesize="PageSize"
                        :Lastpage="lastpage"
                        :Operation="{ isshow: true, width: 300 }"
                    >
                        <template #content="{ scope, prop }">
                            <div>
                                <span>{{ scope.row[prop] }}</span>
                            </div>
                        </template>
                        <template #special="{ scope }">
                            <div>
                                <el-button v-if="mypowerlimits.sbbzsz_sc" type="danger" size="mini" @click="delEvent(scope.row)">删除</el-button>
                            </div>
                        </template>
                    </dpwTable>

                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 添加 -->
        <addUserOpenLock
            ref="addUserOpenLock"
            :ModelValue="add_form.shopIds"
            PostAxios="get"
            PostUrl="/admin/Equipment/mzClientList?type=1"
            :SearchModel="agent_forminfo"
            :TableTitle="agent_tabletitle"
            @modelValue="modelValueEvent"
        />
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import dpwTable from '../../operate/memberBenefits/memberSet/dpw-table.vue'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import addUserOpenLock from './acommon/addUserOpenLock.vue'

export default defineComponent({
    name: 'vuserOpenLockSet',
    data() {
        return {
            mainonebread: [
                {
                    name: '购物'
                },
                {
                    name: '开锁管理'
                },
                {
                    name: '设备补纸设置'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '110px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '购物设备编号:',
                            placeholder: '请输入购物设备编号',
                            name: 'relate_id',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '原始设备编号:',
                            placeholder: '请输入原始设备编号',
                            name: 'meizhi_id',
                            content: ''
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 240, // 数据列表中-操作-宽度
            tabletitle: [
                {
                    fixedstatu: false,
                    prop: 'relate_id',
                    label: '购物设备编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'meizhi_id',
                    label: '原始设备编号',
                    width: ''
                }
            ], // 数据列表中-数据表头
            tableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,

            // 添加
            agent_forminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '100px', //标题宽度
                    inputwidth: '180px', //input宽度
                    inputinterval: '10px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '购物设备编号:',
                            placeholder: '请输入购物设备编号',
                            name: 'relate_id',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '原始设备编号:',
                            placeholder: '请输入原始设备编号',
                            name: 'meizhi_id',
                            content: ''
                        }
                    ]
                }
            },
            // 代理商-弹窗-table表头
            agent_tabletitle: [
                {
                    fixed: false,
                    prop: 'relate_id',
                    label: '购物设备编号',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'meizhi_id',
                    label: '原始设备编号',
                    width: ''
                }
            ],

            add_form: {
                shopIds: {}
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            loading: false
        }
    },
    methods: {
        tanEvent() {
            this.$refs.addUserOpenLock.clickAddEvent()
        },
        // 添加-多选购物设备编号
        modelValueEvent(datas) {
            let ids = Object.keys(datas)

            axios
                .post(
                    '/admin/Equipment/mzPaperSetAdd',
                    {
                        id: ids
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        ElMessage({
                            type: 'success',
                            message: '添加成功！',
                            duration: 1000,
                            onClose: () => {
                                this.handleCurrentChange(this.currentPage)
                            }
                        })
                    }
                    this.$refs.addUserOpenLock.handleClose()
                })
                .catch(function (error) {
                    ElMessage({ type: 'error', message: '添加失败！' + error })
                })
        },

        // 删除
        delEvent(row) {
            ElMessageBox.confirm('删除后将无法恢复，是否确认删除[' + row.relate_id + ']', '删除：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/Equipment/mzPaperSetDel',
                            {
                                id: row.id
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '删除成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.handleCurrentChange(this.currentPage)
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            ElMessage({ type: 'error', message: '删除失败！' + error })
                        })
                })
                .catch(() => {
                    ElMessage({ type: 'info', message: '取消' })
                })
        },

        // 导出
        // exportDatas() {
        //     this.pagesdatas = {
        //         relate_id: this.pagesdatas.relate_id,
        //         clientid: this.pagesdatas.clientid,
        //         export: '1'
        //     }
        //     this.postAxiosEvent(this.pagesdatas, 1)
        // },

        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                relate_id: this.pagesdatas.relate_id,
                meizhi_id: this.pagesdatas.meizhi_id,
                page: 1,
                page_size: val
            }
            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                relate_id: this.pagesdatas.relate_id,
                meizhi_id: this.pagesdatas.meizhi_id,
                page: val,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        },

        //提交等相关event事件函数
        // 获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组：', data)
            this.pagesdatas = {
                relate_id: data.relate_id,
                meizhi_id: data.meizhi_id,
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            this.tableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        postAxiosEvent(data, flag) {
            if (!flag) this.loading = true
            axios
                .get('/admin/Equipment/mzPaperSetList', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: data
                })
                .then((response) => {
                    if (!flag) this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        } else {
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone,
        maintwo,
        dpwTable,
        addUserOpenLock
    }
})
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

#mysections :deep(.el-drawer__body) {
    overflow-y: auto;
}

.box-list-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.button-box {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
</style>
