<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div style="border-top: 1px solid #eeeeee; margin-top: 10px">
            <!--主list列表-->
            <div style="background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 10px 20px">
                <div class="box-list-box" style="font-size: 14px; line-height: 60px; margin-bottom: 10px">
                    <!--左侧名称-->
                    <div><span>数据列表</span></div>
                    <!--右侧按钮-->
                    <div class="button-box">
                        <el-button type="warning" size="small" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <dpwTable
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        ref="myTableDataRef"
                        :tabletitle="tabletitle"
                        :tabledata="tableData"
                        :totalcount="totalCount"
                        :currentpage="currentPage"
                        :pagesize="PageSize"
                        :Lastpage="lastpage"
                        :Operation="{ isshow: true, width: 300 }"
                    >
                        <template #content="{ scope, prop }">
                            <div v-if="prop == 'clientid'">
                                <span>{{ scope.row[prop] != 0 ? scope.row[prop] : '' }}</span>
                            </div>
                            <div v-else>
                                <span>{{ scope.row[prop] }}</span>
                            </div>
                        </template>
                        <template #special="{ scope }">
                            <div>
                                <el-button
                                    v-if="(scope.row.clientid == '' || scope.row.clientid == 0) && mypowerlimits.sbgl_gl"
                                    type="primary"
                                    size="mini"
                                    @click="tanEvent(scope.row, 1)"
                                >
                                    关联打印机
                                </el-button>
                                <el-button
                                    v-if="scope.row.clientid != '' && scope.row.clientid != 0 && mypowerlimits.sbgl_jc"
                                    type="primary"
                                    size="mini"
                                    @click="disassociationEvent(scope.row)"
                                >
                                    解除关联
                                </el-button>
                                <el-button v-if="mypowerlimits.sbgl_ks" type="primary" size="mini" @click="openFastEvent(scope.row)">远程开门</el-button>
                                <el-button
                                    v-if="scope.row.clientid != '' && scope.row.clientid != 0 && mypowerlimits.sbgl_ewm"
                                    type="primary"
                                    size="mini"
                                    @click="tanEvent(scope.row, 2)"
                                >
                                    组合二维码
                                </el-button>
                            </div>
                        </template>
                    </dpwTable>

                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 关联打印机 -->
        <el-dialog v-model="dialog_01" title="关联打印机" @before-close="(done) => handlerCloseEvent(done, 'formdata_01')">
            <el-form ref="formdata_01" :model="formdata_01" :rules="rules_01" label-width="170px">
                <el-form-item label="打印机设备编号:" prop="clientid">
                    <el-select
                        v-model="formdata_01.clientid"
                        class="m-2"
                        placeholder="请选择"
                        style="width: 100%"
                        filterable
                        remote
                        :remote-method="remoteMethod"
                        reserve-keyword
                        remote-show-suffix
                        :loading="loading_select"
                    >
                        <el-option v-for="(item, index) in this.client_options" :key="index" :label="item.client" :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item style="text-align: right">
                    <el-button @click="resultEvent('formdata_01')">取消</el-button>
                    <el-button type="primary" @click="saveEvent('formdata_01')" :disabled="disabled_01">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!-- 组合码 -->
        <el-dialog v-model="dialog_02" title="组合码" width="700px">
            <div style="text-align: center">
                <el-image :src="formdata_02.image" style="width: 300px"></el-image>
            </div>
            <!-- <div style="text-align: center; font-size: 16px; color: #333; line-height: 26px">
                <div style="font-size: 18px; font-weight: bold; line-height: 40px"><span>扫码购物 + 打印</span></div>
                <div>
                    <span>购物设备编号：</span><span>{{ formdata_02.relate_id }}</span>
                </div>
                <div>
                    <span>打印设备编号：</span><span>{{ formdata_02.clientid }}</span>
                </div>
            </div> -->
            <div style="padding: 20px 0">
                <el-form ref="formdata_02" :model="formdata_02" :rules="rules_02" label-width="100px">
                    <el-form-item label="选择尺寸:" prop="size">
                        <!-- <el-checkbox-group v-model="formdata_02.size" size="small">
                            <el-checkbox :label="1" border>6寸</el-checkbox>
                            <el-checkbox :label="2" border>7.5x7.5cm</el-checkbox>
                            <el-checkbox :label="3" border>4.5x4.5cm</el-checkbox>
                            <el-checkbox :label="4" border>2.6x2.6cm</el-checkbox>
                        </el-checkbox-group> -->
                        <el-radio-group v-model="formdata_02.size" size="small">
                            <el-radio :label="1" border>6寸</el-radio>
                            <el-radio :label="2" border>7.5x7.5cm</el-radio>
                            <el-radio :label="3" border>4.5x4.5cm</el-radio>
                            <el-radio :label="4" border>2.6x2.6cm</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
            <div style="text-align: center">
                <el-button type="primary" @click="playEvent('formdata_02')" :disabled="disabled_02" style="margin-right: 20px">打印</el-button>
                <el-button type="primary" @click="playEvent('formdata_02', 'download')" :disabled="disabled_03">下载</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import dpwTable from '../../operate/memberBenefits/memberSet/dpw-table.vue'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'

export default defineComponent({
    name: 'vdeviceAssociation',
    data() {
        return {
            mainonebread: [
                {
                    name: '购物'
                },
                {
                    name: '设备关联'
                },
                {
                    name: '设备关联'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '110px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '购物设备编号:',
                            placeholder: '请输入购物设备编号',
                            name: 'relate_id',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '原始设备编号:',
                            placeholder: '请输入原始设备编号',
                            name: 'meizhi_id',
                            content: ''
                        }
                    ]
                },
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '110px', //标题宽度
                    inputwidth: '220px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '打印设备编号：',
                            placeholder: '请输入打印设备编号', //此name为键值
                            name: 'clientid',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/Equipment/mzClientListSelect',
                            posttype: 'get',
                            inputval: { client: '' } //这里记录需要传的参数
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 240, // 数据列表中-操作-宽度
            tabletitle: [
                {
                    fixedstatu: false,
                    prop: 'relate_id',
                    label: '购物设备编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'meizhi_id',
                    label: '原始设备编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'client',
                    label: '打印设备编号',
                    width: ''
                }
            ], // 数据列表中-数据表头
            tableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,

            // 关联打印机
            dialog_01: false,
            formdata_01: {
                id: '',
                clientid: ''
            },
            rules_01: {
                clientid: {
                    required: true,
                    message: '打印机设备编号为必填项',
                    trigger: 'blur'
                }
            },
            client_options: [],
            loading_select: false,
            disabled_01: false,

            // 组合二维码
            dialog_02: false,
            formdata_02: {
                image: '',
                relate_id: '',
                clientid: '',
                id: '',
                size: []
            },
            rules_02: {
                size: {
                    required: true,
                    message: '尺寸大小为必选项',
                    trigger: 'blur'
                }
            },

            disabled_02: false,
            disabled_03: false,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            loading: false
        }
    },
    methods: {
        tanEvent(data, flag) {
            if (flag == 1) {
                this.dialog_01 = true
                this.formdata_01 = {
                    id: data.id,
                    clientid: ''
                }
                this.disabled_01 = false
                this.remoteMethod()
            } else if (flag == 2) {
                axios
                    .get('/admin/Equipment/mzClientQrcode', {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: {
                            id: data.id
                        }
                    })
                    .then((response) => {
                        if (response.data.code == 0) {
                            this.dialog_02 = true
                            this.formdata_02 = {
                                image: response.data.result.src,
                                relate_id: data.relate_id,
                                clientid: data.clientid,
                                id: data.id,
                                size: []
                            }
                            this.disabled_02 = false
                            this.disabled_03 = false
                        }
                    })
                    .catch(function (error) {
                        ElMessage({ type: 'error', message: error })
                    })
            }
        },
        // 获取设备
        remoteMethod(query) {
            this.loading_select = true
            axios
                .get('/admin/Equipment/mzClientListSelect?type=1', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        client: query
                    }
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        this.client_options = response.data.result
                    } else {
                        this.client_options = []
                    }

                    this.loading_select = false
                })
                .catch(function (error) {
                    ElMessage({ type: 'error', message: error })

                    this.loading_select = false
                })
        },
        // 提交关联
        saveEvent(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.disabled_01 = true
                    axios
                        .post(
                            '/admin/Equipment/mzClientRelate',
                            {
                                id: this.formdata_01.id,
                                clientid: this.formdata_01.clientid
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialog_01 = false
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.disabled_01 = false
                                        this.handleCurrentChange(this.currentPage)
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                            this.disabled_01 = false
                        })
                } else {
                    return false
                }
            })
        },
        //弹出框-取消
        resultEvent(formName) {
            this.$refs[formName].resetFields()
            this.dialog_01 = false
        },

        handlerCloseEvent(done, formName) {
            done()
            this.resultEvent(formName)
        },

        // 组合二维码-打印&下载
        playEvent(formName, flag) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (flag) {
                        this.disabled_03 = true
                    } else {
                        this.disabled_02 = true
                    }

                    let data = {
                        id: this.formdata_02.id,
                        size: [this.formdata_02.size]
                    }

                    if (flag) {
                        data = { ...data, ...{ type: flag } }
                    }

                    axios
                        .post('/admin/Equipment/mzClientQrcodePrint', data, {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        if (flag) {
                                            let images = response.data.result.srcSize
                                            var myArray = images // 原始数组
                                            var index = 0 // 当前索引
                                            var intervalId = setInterval(function () {
                                                if (index < myArray.length) {
                                                    var element = myArray[index]
                                                    window.open(element)

                                                    index++ // 增加索引
                                                } else {
                                                    clearInterval(intervalId) // 如果超出数组长度，清除定时器
                                                }
                                            }, 500)

                                            this.disabled_03 = false
                                            this.dialog_02 = false
                                        } else {
                                            this.disabled_02 = false
                                            this.dialog_02 = false
                                        }
                                    }
                                })
                            } else {
                                if (flag) {
                                    this.disabled_03 = false
                                } else {
                                    this.disabled_02 = false
                                }
                            }
                        })
                        .catch(function (error) {
                            if (flag) {
                                this.disabled_03 = false
                            } else {
                                this.disabled_02 = false
                            }
                        })
                } else {
                    return false
                }
            })
        },
        // 组合二维码-下载(前端自定义尺寸下载)
        // downEvent(formName) {
        //     this.$refs[formName].validate((valid) => {
        //         if (valid) {
        //             this.disabled_03 = true
        //             let image = this.formdata_02.image
        //             let images = this.formdata_02.size.map((n) => {
        //                 if (n == 1) {
        //                     return image
        //                 } else if (n == 2) {
        //                     return image + '&x-oss-process=image/resize,w_283'
        //                 } else if (n == 3) {
        //                     return image + '&x-oss-process=image/resize,w_170'
        //                 } else if (n == 4) {
        //                     return image + '&x-oss-process=image/resize,w_98'
        //                 }
        //             })
        //             let num = 0
        //             images.forEach((element) => {
        //                 window.open(element)
        //             })
        //             if (num == images.length) {
        //                 this.disabled_03 = false
        //             }
        //         } else {
        //             return false
        //         }
        //     })
        // },

        // 解除关联
        disassociationEvent(row) {
            ElMessageBox.confirm('是否确定解除关联该打印机【' + row.clientid + '】', '解除关联：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/Equipment/mzClientNoRelate',
                            {
                                id: row.id
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '解除关联成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.handleCurrentChange(this.currentPage)
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            ElMessage({ type: 'error', message: '解除关联失败！' + error })
                        })
                })
                .catch(() => {
                    ElMessage({ type: 'info', message: '取消' })
                })
        },
        // 远程开门
        openFastEvent(row) {
            ElMessageBox.confirm('确认远程开门么？<br/> <div style="color:red">风险提示：此次操作可能导致设备内物体丢失，请谨慎操作</div>', '远程开门：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/Equipment/mzClientOpen',
                            {
                                id: row.id
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '远程开门成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.handleCurrentChange(this.currentPage)
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            ElMessage({ type: 'error', message: '远程开门失败！' + error })
                        })
                })
                .catch(() => {
                    ElMessage({ type: 'info', message: '取消' })
                })
        },

        // 导出
        exportDatas() {
            this.pagesdatas = {
                relate_id: this.pagesdatas.relate_id,
                meizhi_id: this.pagesdatas.meizhi_id,
                clientid: this.pagesdatas.clientid,
                export: '1'
            }
            this.postAxiosEvent(this.pagesdatas, 1)
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                relate_id: this.pagesdatas.relate_id,
                meizhi_id: this.pagesdatas.meizhi_id,
                clientid: this.pagesdatas.clientid,
                page: 1,
                page_size: val
            }
            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                relate_id: this.pagesdatas.relate_id,
                meizhi_id: this.pagesdatas.meizhi_id,
                clientid: this.pagesdatas.clientid,
                page: val,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        },

        //提交等相关event事件函数
        // 获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组：', data)
            this.pagesdatas = {
                relate_id: data.relate_id,
                meizhi_id: data.meizhi_id,
                clientid: data.clientid,
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            this.tableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        postAxiosEvent(data, flag) {
            if (!flag) this.loading = true
            axios
                .get('/admin/Equipment/mzClientList', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: data
                })
                .then((response) => {
                    if (!flag) this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        } else {
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)

            this.remoteMethod()
        }
    },
    components: {
        mainone,
        maintwo,
        dpwTable
    }
})
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

#mysections :deep(.el-drawer__body) {
    overflow-y: auto;
}

.box-list-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.button-box {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
</style>
