<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">编辑抽奖活动</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
                    </div>
                </div>
                <!--内容-->

                <div class="box_content">
                    <div class="box_title_font">基本信息</div>
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="6">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="10">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="160px"
                                class="demo-ruleForm">
                                <el-form-item label="活动名称：" prop="title">
                                    <el-input v-model="ruleForm.title"></el-input>
                                </el-form-item>
                                <el-form-item label="开始时间：">
                                    <el-date-picker v-model="ruleForm.begin_time" type="datetime" placeholder="请选择开始时间"
                                        style="width: 100%" @change="(value) => changeTimes(value, 'begin_time')">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="到期时间：">
                                    <el-date-picker v-model="ruleForm.end_time" type="datetime" placeholder="请选择到期时间"
                                        style="width: 100%" @change="(value) => changeTimes(value, 'end_time')">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="显示位置：" prop="position">
                                    <el-select v-model="ruleForm.position" placeholder="请选择" style="width: 100%"
                                        @change="changeselect">
                                        <el-option :label="gettypeid.name" :value="gettypeid.id"
                                            v-for="(gettypeid, index) in mytyppid" :key="index"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="活动类型：">
                                    <el-radio-group v-model="ruleForm.consume_type">
                                        <el-radio :label="-1">无限制抽奖</el-radio>
                                        <el-radio :label="1" v-if="ruleForm.position == 5">京币抽奖，消耗
                                            <el-input v-model="ruleForm.consume_num" style="width: 50px"></el-input>
                                            京币
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="参与次数：">
                                    <el-radio-group v-model="ruleForm.cishu">
                                        <el-radio :label="1">每人每天限抽
                                            <el-input v-model="ruleForm.times" style="width: 50px"></el-input>
                                            次
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>

                                <el-form-item label="奖品设置：">
                                    <el-button type="primary" @click="add(dialogVisible = true)">添加奖品
                                    </el-button>
                                </el-form-item>

                                <el-form-item>
                                    <el-table :data="tableData" border style="width: 100%">
                                        <el-table-column prop="name" label="名称">
                                        </el-table-column>
                                        <el-table-column prop="typename" label="奖品">
                                        </el-table-column>
                                        <el-table-column prop="total_num" label="数量">
                                        </el-table-column>
                                        <el-table-column prop="weight" label="中奖概率">
                                        </el-table-column>
                                        <el-table-column label="操作">
                                            <template v-slot="scope">
                                                <el-button @click="handleClick2(scope.row, scope.$index)" type="text"
                                                    size="small">编辑
                                                </el-button>
                                                <el-button @click="handleClick(scope.row, scope.$index)" type="text"
                                                    size="small">删除
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>


                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')"
                                        style="width: 40%; margin-right: 10px">确认
                                    </el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>


            <!--添加弹出框-->
            <el-dialog v-model="dialogVisible" title="添加奖品" :width="searchwidth" :before-close="handleCloseSenior">
                <el-form ref="seniorform" :model="seniorform" :rules="ruless" label-width="100px"
                    style="padding-right: 20px">
                    <!--以下是左侧字-->
                    <el-form-item label="名称：" prop="name">
                        <el-input v-model="seniorform.name"></el-input>
                    </el-form-item>
                    <el-form-item label="奖品：">
                        <el-radio-group v-model="seniorform.type">
                            <el-radio :label="2">聚邻社优惠券
                                <el-select v-model="value" placeholder="请选择">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-radio>
                            <el-radio :label="3">印工厂优惠券
                                <el-select v-model="value" placeholder="请选择">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-radio>
                            <el-radio :label="4">省省团优惠券
                                <el-select v-model="value" placeholder="请选择">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-radio>
                            <el-radio :label="1">京币
                                <el-input v-model="seniorform.yb" style="width: 100px"></el-input>
                                京币
                            </el-radio>
                            <el-radio :label="5">实物
                                <el-input v-model="seniorform.sw"></el-input>
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="数量：" prop="total_num">
                        <el-input v-model="seniorform.total_num" style="width: 100px"></el-input>
                        份
                    </el-form-item>
                    <el-form-item label="中奖概率：" prop="weight">
                        <el-input v-model="seniorform.weight" style="width: 100px"></el-input>
                    </el-form-item>
                    <div style="text-align: right">
                        <el-button @click="resetFormSenior('seniorform')">取消
                        </el-button>
                        <el-button type="primary" @click="onSubmitSenior('seniorform')">确定
                        </el-button>
                    </div>
                </el-form>
            </el-dialog>


            <!--编辑弹出框-->
            <el-dialog v-model="dialogVisible2" title="编辑奖品" :width="searchwidth" :before-close="handleCloseSenior">
                <el-form ref="seniorform" :model="seniorform" :rules="ruless" label-width="100px"
                    style="padding-right: 20px">
                    <!--以下是左侧字-->
                    <el-form-item label="名称：" prop="name">
                        <el-input v-model="seniorform.name"></el-input>
                    </el-form-item>
                    <el-form-item label="奖品：">
                        <el-radio-group v-model="seniorform.type">
                            <el-radio :label="2">聚邻社优惠券
                                <el-select v-model="value" placeholder="请选择">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-radio>
                            <el-radio :label="3">印工厂优惠券
                                <el-select v-model="value" placeholder="请选择">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-radio>
                            <el-radio :label="4">省省团优惠券
                                <el-select v-model="value" placeholder="请选择">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-radio>
                            <el-radio :label="1">京币
                                <el-input v-model="seniorform.yb" style="width: 100px"></el-input>
                                京币
                            </el-radio>
                            <el-radio :label="5">实物
                                <el-input v-model="seniorform.sw"></el-input>
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="数量：" prop="total_num">
                        <el-input v-model="seniorform.total_num" style="width: 100px"></el-input>
                        份
                    </el-form-item>
                    <el-form-item label="中奖概率：" prop="weight">
                        <el-input v-model="seniorform.weight" style="width: 100px"></el-input>
                    </el-form-item>
                    <div style="text-align: right">
                        <el-button @click="resetFormSenior('seniorform')">取消
                        </el-button>
                        <el-button type="primary" @click="onSubmitSenior2('seniorform')">确定
                        </el-button>
                    </div>
                </el-form>
            </el-dialog>

        </div>
    </div>
</template>

<script>
import mainone from "@/components/Mainone";
// import mainimgesup from "@/components/Mainimgesup.vue";
import { ref } from "vue";
// import {ElMessage} from "element-plus";
import { ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";

export default {
    name: "vluckydrawrecordadd",
    data() {
        return {
            draw_id: "",
            rowindex: "",
            ruless: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                ],
                total_num: [
                    {
                        required: true,
                        message: "请填写中奖数量",
                        trigger: "change",
                    },
                ],
                weight: [
                    {
                        required: true,
                        message: "请填写中奖概率",
                        trigger: "change",
                    },
                ],
            },
            coupon1: [],
            coupon2: [],
            coupon3: [],
            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }, {
                value: '选项3',
                label: '蚵仔煎'
            }, {
                value: '选项4',
                label: '龙须面'
            }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            value: '',
            dialogVisible: false,
            dialogVisible2: false,
            searchwidth: "580px",
            //弹框-form表单数据
            seniorform: {
                id: "",
                name: "",
                type: 1,
                total_num: "",
                weight: "",
                extra_id1: "",
                extra_id2: "",
                extra_id3: "",
                yb: "",
                sw: ""
            },
            tableData: [],
            mainonebread: [
                {
                    name: '运营',

                },
                {
                    name: '营销玩法',

                },
                {
                    name: '幸运大抽奖',
                    path: '/luckydraw'
                },
                {
                    name: '编辑抽奖活动',
                    // path: '/luckydraw'
                }
            ], // 当前页-【面包屑导航】
            mytyppid: [], //广告位-配置
            mywebview: [], //webview跳转链接-配置
            mywechat: [], //H5/公众号跳转链接-配置
            myouter: [], //小程序APPID-配置
            ruleForm: {
                title: "",
                position: "",
                consume_type: -1,
                consume_num: "",
                times: "",
                cishu: 1,
                begin_time: "",
                end_time: "",
                id: ""
            }, //form表单
            rules: {
                title: [
                    {
                        required: true,
                        message: "请填写活动名称",
                        trigger: "blur",
                    },
                ],
                position: [
                    {
                        required: true,
                        message: "请选择活动位置",
                        trigger: "change",
                    },
                ],
                times: [
                    {
                        required: true,
                        message: "请填写参与次数",
                        trigger: "change",
                    },
                ],
            }, //form表单-必填规则
            loading: false,
            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token
        };
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {
            // 获取相关配置配置-广告位
            axios
                .post(
                    "/admin/config/info",
                    {
                        key_name: "lucky_draw_position",
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    // this.mytyppid = response.data.result;
                    console.log(response.data.result)
                    console.log(response.data.result.length)
                    var arrs = response.data.result
                    var arrchang = []
                    for (let i in arrs) {
                        let o = {}
                        o.id = i - 0
                        o.name = arrs[i]
                        // console.log(i)
                        // console.log(arrs[i])
                        arrchang.push(o)
                    }
                    console.log(arrchang)
                    this.mytyppid = arrchang;
                    //通过Ajax向后台获取数据
                    axios.post('/admin/luckyDraw/info',
                        {
                            id: this.$route.query.id,
                            // page:1,
                            // page_size:20
                        },
                        {
                            headers: {
                                'Authentication': this.mytoken
                            }
                        })
                        .then(response => {
                            // this.myaxiosfunc(response.data.result)
                            console.log(response.data.result)
                            this.draw_id = response.data.result.draw.id;
                            this.ruleForm = response.data.result.draw;
                            // var infos = response.data.result.draw.position;
                            this.ruleForm.begin_time = this.$utils.formatDate(response.data.result.draw.begin_time);
                            this.ruleForm.end_time = this.$utils.formatDate(response.data.result.draw.end_time);
                            // this.ruleForm.positionname = this.mytyppid[infos];
                            this.ruleForm.cishu = 1;
                            this.ruleForm.position = response.data.result.draw.position - 0;
                            // this.tableData = response.data.result.item;
                            this.changearr(response.data.result.item)
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                })
                .catch(function (error) {
                    console.log(error);
                });



            axios
                .post(
                    "/admin/Coupon/luck",
                    {},
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data.result);
                    this.coupon1 = response.data.result
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    mounted() {
    },
    methods: {
        changearr(successdata) {
            var newdata = successdata
            for (var i = 0; i < newdata.length; i++) {
                //已发放
                // newdata[i].yi = (newdata[i].total_num -0) - (newdata[i].remain_num-0)
                var types = newdata[i].type;
                if (types == 1) {
                    newdata[i].typename = "京币"

                } else if (types == 2) {
                    newdata[i].typename = "聚邻社优惠券"
                } else if (types == 3) {
                    newdata[i].typename = "印工厂优惠券"
                } else if (types == 4) {
                    newdata[i].typename = "省省团"
                } else if (types == 5) {
                    newdata[i].typename = "实物"
                }
                newdata[i].draw_item_id = newdata[i].id
            }
            this.tableData = newdata;
        },
        //下拉框监听
        changeselect(e) {
            console.log(e)
            console.log(this.ruleForm)
            this.ruleForm.consume_type = -1
        },
        //编辑弹出框-确认
        onSubmitSenior2(formData) {
            // console.log(formData);
            // console.log(formData.type);
            // if (!formData.name){
            //     this.$message({
            //         message: '请填写奖品名称',
            //         type: 'warning'
            //     });
            // }else if(!formData.type){
            //     this.$message({
            //         message: '请选择奖品类型',
            //         type: 'warning'
            //     });
            // }
            this.$refs[formData].validate((valid) => {
                if (valid) {
                    console.log(valid);
                    console.log(this.$refs[formData].model);
                    if (this.$refs[formData].model.type == 1 && !this.$refs[formData].model.yb) {
                        this.$message({
                            message: '请填写京币数量',
                            type: 'warning'
                        });
                    } else if (this.$refs[formData].model.type == 5 && !this.$refs[formData].model.sw) {
                        this.$message({
                            message: '请填写实物',
                            type: 'warning'
                        });
                    } else {
                        console.log('编辑');
                        var types = this.$refs[formData].model.type;
                        var extra = "";
                        var typename = "";
                        if (types == 1) {
                            extra = this.$refs[formData].model.yb
                            typename = "京币"
                        } else if (types == 2) {
                            extra = this.$refs[formData].model.extra_id1
                            typename = "聚邻社优惠券"
                        } else if (types == 3) {
                            extra = this.$refs[formData].model.extra_id2
                            typename = "印工厂优惠券"
                        } else if (types == 4) {
                            extra = this.$refs[formData].model.extra_id3
                            typename = "省省团"
                        } else if (types == 5) {
                            extra = this.$refs[formData].model.sw
                            typename = "实物"
                        }
                        axios
                            .post("/admin/luckyDraw/itemEdit", {
                                id: this.$refs[formData].model.id,
                                name: this.$refs[formData].model.name,
                                type: this.$refs[formData].model.type,
                                total_num: this.$refs[formData].model.total_num,
                                weight: this.$refs[formData].model.weight,
                                extra_id: extra,
                                // draw_id: this.draw_id,
                            }, {
                                headers: {
                                    Authentication: localStorage.getItem("token"),
                                },
                            })
                            .then((response) => {
                                console.log(response.data);
                                if (response.data.code === 0) {
                                    ElMessage({
                                        type: "success",
                                        message: "编辑成功！",
                                        duration: 1000,
                                        onClose: () => {
                                            var add = {
                                                name: this.$refs[formData].model.name,
                                                type: types,
                                                extra_id: extra,
                                                typename: typename,
                                                total_num: this.$refs[formData].model.total_num,
                                                weight: this.$refs[formData].model.weight,
                                                draw_item_id: response.data.result.id,
                                            }
                                            this.tableData[this.rowindex] = add
                                            console.log(this.tableData)
                                            // this.draw_id = response.data.result.draw_id
                                            this.dialogVisible2 = ref(false); //关闭弹窗
                                            this.$refs[formData].resetFields();//重置弹窗内容
                                        },
                                    });
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //奖项编辑
        handleClick2(row, index) {
            console.log(row, index);
            console.log(row.draw_item_id, index);
            var seniorform = {
                id: row.draw_item_id,
                name: row.name,
                type: row.type,
                total_num: row.total_num,
                weight: row.weight,
                extra_id1: row.type == 2 ? row.extra_id : '',
                extra_id2: row.type == 3 ? row.extra_id : '',
                extra_id3: row.type == 4 ? row.extra_id : '',
                yb: row.type == 1 ? row.extra_id : '',
                sw: row.type == 5 ? row.extra_id : ''
            }
            console.log(seniorform)
            this.seniorform = seniorform
            this.rowindex = index
            this.dialogVisible2 = ref(true); //打开弹窗
        },
        //奖项删除
        handleClick(row, index) {
            console.log(row, index);
            console.log(row.draw_item_id, index);
            ElMessageBox.confirm(
                "确定删除该条 ' " + row.name + " ' 奖项吗?",
                "提示：",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    axios
                        .post(
                            "/admin/luckyDraw/itemDelete",
                            {
                                id: row.draw_item_id,
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: "success",
                                    message: "删除成功",
                                });
                                this.tableData.splice(index, 1);
                            }
                        })
                        .catch(function (error) {
                            // 请求失败处理
                            ElMessage({
                                type: "error",
                                message: "删除失败！" + error,
                            });
                        });
                })
                .catch(() => {
                    ElMessage({
                        type: "info",
                        message: "取消删除",
                    });
                });
        },
        //添加弹出框-确认
        onSubmitSenior(formData) {
            // console.log(formData);
            // console.log(formData.type);
            // if (!formData.name){
            //     this.$message({
            //         message: '请填写奖品名称',
            //         type: 'warning'
            //     });
            // }else if(!formData.type){
            //     this.$message({
            //         message: '请选择奖品类型',
            //         type: 'warning'
            //     });
            // }
            this.$refs[formData].validate((valid) => {
                if (valid) {
                    console.log(valid);
                    console.log(this.$refs[formData].model);
                    if (this.$refs[formData].model.type == 1 && !this.$refs[formData].model.yb) {
                        this.$message({
                            message: '请填写京币数量',
                            type: 'warning'
                        });
                    } else if (this.$refs[formData].model.type == 5 && !this.$refs[formData].model.sw) {
                        this.$message({
                            message: '请填写实物',
                            type: 'warning'
                        });
                    } else {
                        console.log('添加');
                        var types = this.$refs[formData].model.type;
                        var extra = "";
                        var typename = "";
                        if (types == 1) {
                            extra = this.$refs[formData].model.yb
                            typename = "京币"
                        } else if (types == 2) {
                            extra = this.$refs[formData].model.extra_id1
                            typename = "聚邻社优惠券"
                        } else if (types == 3) {
                            extra = this.$refs[formData].model.extra_id2
                            typename = "印工厂优惠券"
                        } else if (types == 4) {
                            extra = this.$refs[formData].model.extra_id3
                            typename = "省省团"
                        } else if (types == 5) {
                            extra = this.$refs[formData].model.sw
                            typename = "实物"
                        }
                        axios
                            .post("/admin/luckyDraw/itemAdd", {
                                name: this.$refs[formData].model.name,
                                type: this.$refs[formData].model.type,
                                total_num: this.$refs[formData].model.total_num,
                                weight: this.$refs[formData].model.weight,
                                extra_id: extra,
                                draw_id: this.draw_id,
                            }, {
                                headers: {
                                    Authentication: localStorage.getItem("token"),
                                },
                            })
                            .then((response) => {
                                console.log(response.data);
                                if (response.data.code === 0) {
                                    ElMessage({
                                        type: "success",
                                        message: "提交成功！",
                                        duration: 1000,
                                        onClose: () => {
                                            var add = {
                                                name: this.$refs[formData].model.name,
                                                type: types,
                                                extra_id: extra,
                                                typename: typename,
                                                total_num: this.$refs[formData].model.total_num,
                                                weight: this.$refs[formData].model.weight,
                                                draw_item_id: response.data.result.draw_item_id,
                                            }
                                            this.tableData = [...this.tableData, add]
                                            console.log(this.tableData)
                                            this.draw_id = response.data.result.draw_id
                                            this.dialogVisible = ref(false); //关闭弹窗
                                            this.$refs[formData].resetFields();//重置弹窗内容
                                        },
                                    });
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //弹出框-取消
        resetFormSenior(formData) {
            this.dialogVisible = ref(false); //添加奖品弹窗
            this.dialogVisible2 = ref(false); //添加奖品弹窗
            //弹框-form表单数据
            var seniorform = {
                id: "",
                name: "",
                type: 1,
                total_num: "",
                weight: "",
                extra_id1: "",
                extra_id2: "",
                extra_id3: "",
                yb: "",
                sw: ""
            }
            this.seniorform = seniorform
            this.$refs[formData].resetFields();//重置弹窗内容
        },
        add() {
            this.dialogVisible = ref(true); //添加奖品弹窗
        },
        //点击关闭-弹窗
        handleCloseSenior(done) {
            done();
            this.$refs['seniorform'].resetFields();//重置弹窗内容
            //弹框-form表单数据
            var seniorform = {
                id: "",
                name: "",
                type: 1,
                total_num: "",
                weight: "",
                extra_id1: "",
                extra_id2: "",
                extra_id3: "",
                yb: "",
                sw: ""
            }
            this.seniorform = seniorform
        },
        //选择时间并转换
        changeTimes(data, chart) {
            console.log("当前获取的时间：" + data);
            this.ruleForm[chart] = new Date(data).getTime();

            //比较开始时间与结束时间
            if (this.ruleForm.begin_time == "") {
                ElMessage({
                    type: "warning",
                    message: "请先选择开始时间！",
                });
                this.ruleForm.end_time = "";
            } else {
                if (this.ruleForm.end_time != "") {
                    if (this.ruleForm.begin_time > this.ruleForm.end_time) {
                        ElMessage({
                            type: "warning",
                            message: "选择的结束时间必须大于开始时间！",
                        });
                        this.ruleForm.end_time = "";
                    }
                }
            }
        },
        //获取upload子组件回传的数据
        getUploadFileEvent(data) {
            console.log("当前获取的回传数据：" + JSON.stringify(data));
            this.ruleForm.image = data.id;
        },
        //提交form表单-并判断是否必填
        submitForm(formName) {
            var formdatas = this.$refs[formName].model;
            var datas = ""
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(formdatas)
                    // var datas = null;
                    // if (formdatas.begin_time == 0) {
                    //     formdatas.begin_time = "";
                    //     formdatas.my_begin_time = "";
                    // } else {
                    //     formdatas.my_begin_time = formdatas.begin_time / 1000;
                    // }
                    // if (formdatas.end_time == 0) {
                    //     formdatas.end_time = "";
                    //     formdatas.my_end_time = "";
                    // } else {
                    //     formdatas.my_end_time = formdatas.end_time / 1000;
                    // }
                    // console.log("提交的form表单：" + JSON.stringify(formdatas));

                    if (formdatas.position == 5 && formdatas.consume_type == 1 && !formdatas.consume_num) {
                        this.$message({
                            message: '请填写京币数量',
                            type: 'warning'
                        });
                    } else if (!formdatas.begin_time) {
                        this.$message({
                            message: '请选择开始时间',
                            type: 'warning'
                        });
                    } else if (!formdatas.end_time) {
                        this.$message({
                            message: '请选择结束时间',
                            type: 'warning'
                        });
                    } else if (!formdatas.times) {
                        this.$message({
                            message: '请填写参与次数',
                            type: 'warning'
                        });
                    } else if (this.tableData.length == 0) {
                        this.$message({
                            message: '请添加奖品',
                            type: 'warning'
                        });
                    } else {
                        datas = {
                            title: formdatas.title,
                            begin_time: formdatas.begin_time ? formdatas.begin_time / 1000 : '',
                            end_time: formdatas.end_time ? formdatas.end_time / 1000 : '',
                            position: formdatas.position,
                            consume_type: formdatas.consume_type,
                            consume_num: formdatas.consume_num,
                            times: formdatas.times,
                            id: this.draw_id
                        };
                        console.log(datas);
                        this.subDatas(datas);
                    }


                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        //重置form表单中填写的数据
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        //提交API-函数
        subDatas(datas) {
            axios
                .post("/admin/luckyDraw/update", datas, {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                })
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: "success",
                            message: "提交成功！",
                            duration: 1000,
                            onClose: () => {
                                this.$router.push("/luckydraw").catch((error) => error);
                            },
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
    components: {
        mainone,
        // mainimgesup,
    },
};
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>