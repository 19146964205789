<template>
    <div class="active-addedit-box">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="200px" class="demo-ruleForm">
            <el-form-item label="活动名称：" prop="name">
                <el-input v-model="ruleForm.name" placeholder="活动名称" clearable maxlength="20" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="活动商品：" prop="goods_id">
                <el-select v-model="ruleForm.goods_id" placeholder="请选择活动商品" style="width: 100%" filterable>
                    <el-option :label="item.goods_name" :value="item.goods_id" v-for="(item, index) in goods_options" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="活动内容：" prop="gift_info">
                <div>
                    <div><el-button type="primary" style="width: 100px" @click="addEvent">添加</el-button></div>
                    <div class="gift-info-box-all" style="margin-top: 10px" v-if="ruleForm.gift_info?.length > 0">
                        <div v-for="(item, index) in ruleForm.gift_info" :key="index" class="gift-info-b">
                            <div class="gift-info-box">
                                <div class="gift-info-num">
                                    <div><span>商品数量：</span></div>
                                    <div class="gift-info-item">
                                        <div><span>>=</span></div>
                                        <div>
                                            <el-form-item :prop="'gift_info.' + index + '.goods_num'" :rules="rules.goods_num">
                                                <el-input v-model="item.goods_num" clearable maxlength="20"></el-input>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </div>
                                <div class="gift-info-num">
                                    <div><span>赠送打印余额：</span></div>
                                    <div class="gift-info-item">
                                        <div>
                                            <el-form-item :prop="'gift_info.' + index + '.gift_price'" :rules="rules.gift_price">
                                                <el-input v-model="item.gift_price" clearable maxlength="20"></el-input>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <el-button type="danger" @click="delEvent(index)">删除</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="活动页面：" prop="content">
                <TEditor ref="editorfile" v-model="ruleForm.content" @geteditordatas="getEditorDatasEvent" />
            </el-form-item>
            <el-form-item label="我要购物(背景图)：" prop="shopping_back_img">
                <mainimgesup
                    ref="myimges_1"
                    v-model="ruleForm.shopping_back_img"
                    :myimgesup="myimgesup_1"
                    :myimgesupisshow="myimgesupisshow_1"
                    :filelist="ruleForm.shopping_back_img != '' ? [{ name: '', url: ruleForm.shopping_back_img }] : []"
                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 1)"
                    v-on:deluploadfile="(data) => deluploadfileEvent(data, 1)"
                    v-on:getfiletype="getfiletypeEvent"
                >
                </mainimgesup>
            </el-form-item>
            <el-form-item label="领取打印优惠(背景图)：" prop="print_gift_img">
                <mainimgesup
                    ref="myimges_2"
                    v-model="ruleForm.print_gift_img"
                    :myimgesup="myimgesup_2"
                    :myimgesupisshow="myimgesupisshow_2"
                    :filelist="ruleForm.print_gift_img != '' ? [{ name: '', url: ruleForm.print_gift_img }] : []"
                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 2)"
                    v-on:deluploadfile="(data) => deluploadfileEvent(data, 2)"
                    v-on:getfiletype="getfiletypeEvent"
                >
                </mainimgesup>
            </el-form-item>
            <el-form-item label="适用设备：" prop="is_all">
                <el-radio-group v-model="ruleForm.is_all" @change="allChange">
                    <el-radio label="1">全部设备</el-radio>
                    <el-radio label="2"><span @click="allChange(2)">部分设备</span></el-radio>
                </el-radio-group>
                <el-form-item style="padding-right: 12px" prop="meizhi_ids" v-if="Object.keys(this.modelvalue).length > 0 && ruleForm.is_all == 2">
                    <el-select v-model="ruleForm.meizhi_ids" multiple allow-create style="min-width: 200px; width: 100%" placeholder="请选择" @change="eleChange">
                        <el-option v-for="(item, index) in modelvalue" :key="index" :label="index" :value="index"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form-item>
            <el-form-item label="每人限领次数：" prop="limits">
                <el-radio-group v-model="ruleForm.limits">
                    <el-radio label="0">无限制</el-radio>
                    <el-radio label="1">
                        <div class="limits-box">
                            <div><span>每人限领&nbsp;</span></div>
                            <div v-if="ruleForm.limits != 0">
                                <el-form-item prop="limitsN">
                                    <el-input-number v-model="ruleForm.limitsN" :min="1" :max="100000000" style="width: 200px" />
                                </el-form-item>
                            </div>
                            <div v-if="ruleForm.limits != 0"><span>&nbsp;次</span></div>
                        </div>
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="活动时间：" prop="active_time">
                <el-date-picker v-model="ruleForm.active_time" type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" />
            </el-form-item>
            <el-form-item label="状态：" prop="status">
                <el-radio-group v-model="ruleForm.status">
                    <el-radio label="1">开启</el-radio>
                    <el-radio label="-1">关闭</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item>
                <div style="padding: 20px 0">
                    <el-button type="primary" @click="submitForm('ruleFormRef')" style="width: 20%" :disabled="disabled_btn">保存</el-button>
                </div>
            </el-form-item>
        </el-form>

        <!-- 部分设备弹窗 -->
        <modelDialog :isshow="dialogVisible" :title="'选择设备'" v-on:beforeclose="handleClose">
            <template v-slot:search>
                <modelSearch :gaoisshow="false" :forminfo="maintwoforminfo" v-on:getsearchinfo="getSeachinfoEvent" @reload="reloadEvent()">
                    <template #searchModelDab>
                        <div style="display: flex; flex-direction: row; justify-content: left; align-items: center; flex-wrap: wrap; gap: 20px">
                            <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; color: #000; font-size: 14px; margin-right: 10px">
                                <div><span>设备商品：</span></div>
                                <div>
                                    <el-select v-model="form_goods_id" placeholder="请选择设备商品" @change="clickDataSourceEvent">
                                        <el-option v-for="(item, index) in form_goods_id_options" :key="index" :label="item.goods_name" :value="item.goods_id"></el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                    </template>
                </modelSearch>
            </template>
            <template v-slot:table>
                <modelTable
                    v-loading="loading"
                    element-loading-text="正在飞速加载中..."
                    ref="mymultipleTableRef"
                    :tabletitle="mainthreetabletitle"
                    :tabledata="mainthreetableData"
                    :totalcount="totalCount"
                    :currentpage="currentPage"
                    :pagesize="PageSize"
                    :Lastpage="lastpage"
                    @handleSelection="handleSelectionEvent"
                    @handleSelectionOnline="handleSelectionChangeOnline"
                />
            </template>
            <template v-slot:pages>
                <modelPages
                    :totalCount="totalCount"
                    :currentPage="currentPage"
                    :pageSizes="pageSizes"
                    :PageSize="PageSize"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </template>
            <template v-slot:footbtn>
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submitEvent(this.multipleSelection, (dialogVisible = false))">确定</el-button>
            </template>
        </modelDialog>
    </div>
</template>

<script>
import dict from '@/assets/js/dict.js'
import axios from 'axios'
import TEditor from '@/components/Maineditor.vue'
import mainimgesup from '@/components/Mainimgesup.vue'
import { ElMessage } from 'element-plus'
import modelDialog from '../../../../operate/wecom/minipluswecom/modelDialog.vue'
import modelSearch from './modelSearch.vue'
import modelTable from '../../../../operate/wecom/minipluswecom/modelTable.vue'
import modelPages from '../../../../operate/wecom/minipluswecom/modelPages.vue'

export default {
    name: 'ActivityAddEdit',
    props: {
        flag: {
            type: [String, Number],
            default: 1
        },
        formData: {
            type: Object,
            default() {
                return {}
            }
        },
        goodsOptions: {
            type: Array,
            default() {
                return []
            }
        },
        meizhiIdsOptions: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    watch: {
        formData: {
            handler(newVal) {
                this.ruleForm = newVal

                // Dom加载后执行
                this.$nextTick(() => {
                    this.postValueEvent(this.ruleForm.content)
                })
            },
            deep: true,
            immediate: true
        },
        meizhiIdsOptions: {
            handler(newVal) {
                this.modelvalue = newVal
            },
            deep: true,
            immediate: true
        },
        goodsOptions: {
            handler(newVal) {
                this.goods_options = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            dict_options: dict,
            ruleForm: {
                id: '',
                name: '',
                goods_id: '',
                goods_name: '',
                gift_info: [],
                content: '',
                shopping_back_img: '',
                print_gift_img: '',
                is_all: '',
                limits: '',
                limitsN: '',
                active_time: [],
                status: '',
                meizhi_id: [],
                meizhi_ids_options: {}
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '活动名称为必填项',
                        trigger: 'change'
                    }
                ],
                goods_id: [
                    {
                        required: true,
                        message: '活动商品为必选项',
                        trigger: 'change'
                    }
                ],
                gift_info: [
                    {
                        required: true,
                        message: '活动内容为必填项',
                        trigger: 'change'
                    }
                ],
                goods_num: [
                    {
                        required: true,
                        message: '商品数量为必填项',
                        trigger: 'change'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '必须为正整数',
                        trigger: 'change'
                    }
                ],
                gift_price: [
                    {
                        required: true,
                        message: '赠送打印余额为必填项',
                        trigger: 'change'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '必须为正整数',
                        trigger: 'change'
                    }
                ],
                content: [
                    {
                        required: true,
                        message: '活动页面为必填项',
                        trigger: 'change'
                    }
                ],
                shopping_back_img: [
                    {
                        required: true,
                        message: '我要购物(背景图)为必填项',
                        trigger: 'change'
                    }
                ],
                print_gift_img: [
                    {
                        required: true,
                        message: '领取打印优惠(背景图)为必填项',
                        trigger: 'change'
                    }
                ],
                is_all: [
                    {
                        required: true,
                        message: '适用设备为必选项',
                        trigger: 'change'
                    }
                ],
                meizhi_ids: [
                    {
                        required: true,
                        message: '部分设备为必填项',
                        trigger: 'change'
                    }
                ],
                limits: [
                    {
                        required: true,
                        message: '每人限领次数为必选项',
                        trigger: 'change'
                    }
                ],
                limitsN: [
                    {
                        required: true,
                        message: '次数为必填项',
                        trigger: ['change', 'blur']
                    }
                ]
            },
            goods_options: [],

            //上传-【处理】-imgesuploade上传图片
            myimgesupisshow_1: true,
            // 上传-【处理】-上传留言图片
            myimgesup_1: {
                uploadsize: 1, //选择上传图片的展现框-1oss,2传后端
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '上传图片', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '建议尺寸：375mm x 120mm', //提示&建议文字 建议尺寸46px*46px
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'] //文件格式
                    }
                }
            },

            //上传-【处理】-imgesuploade上传图片
            myimgesupisshow_2: true,
            // 上传-【处理】-上传留言图片
            myimgesup_2: {
                uploadsize: 1, //选择上传图片的展现框-1oss,2传后端
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '上传图片', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '建议尺寸：375mm x 120mm', //提示&建议文字 建议尺寸46px*46px
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'] //文件格式
                    }
                }
            },
            disabled_btn: false,
            loading_table: false,

            // 弹窗相关
            dialogVisible: false,
            maintwoforminfo: {
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '100px', //标题宽度
                    inputwidth: '180px', //input宽度
                    inputinterval: '10px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '打印设备编号:',
                            placeholder: '请输入打印设备编号', //此name为键值
                            name: 'clientid',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/getClientList',
                            inputval: { client: '' } //这里记录需要传的参数
                        }
                    ]
                },
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '100px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '10px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '购物设备编号:',
                            placeholder: '请输入购物设备编号',
                            name: 'relate_id',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '原始设备编号:',
                            placeholder: '请输入原始设备编号',
                            name: 'meizhi_id',
                            content: ''
                        }
                    ]
                },
                myselectcityleftisshow: true, //筛选查询-selectcity选框
                myselectcityleft: {
                    selectwidth: '100%',
                    select: {
                        ismust: false,
                        title: '选择区域：',
                        name: 'area_code',
                        area_code: '',
                        valuesheng: '',
                        valueshi: '',
                        valuequ: '',
                        disshi: true,
                        disqu: true
                    }
                }
            },
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: 'relate_id',
                    label: '购物设备编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'meizhi_id',
                    label: '原始设备编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'client',
                    label: '打印设备编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'area_code_name',
                    label: '设备地址',
                    width: ''
                }
            ],
            mainthreetableData: [],
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            currentPage: 1,
            pageSizes: [10, 20, 50, 100],
            PageSize: 10,
            multipleSelection: [], //多选的数据
            modelvalue: {}, //确定选中的数据
            posturl: '/admin/MeizhiActivity/meizhiClientIndex',
            postaxios: 'post',
            pagesdatas: '',

            form_goods_id: '',
            form_goods_id_options: []
        }
    },
    methods: {
        // ------------------------------------------------
        // 点击-添加更多按钮-打开弹窗allChange
        allChange(e) {
            if (e == 2) {
                this.dialogVisible = true

                this.pagesdatas = {
                    page: 1,
                    page_size: this.PageSize
                }
                this.postAxios(this.pagesdatas)

                this.$nextTick(() => {
                    this.multipleSelection = this.objectToArray(this.modelvalue)
                    this.moreDataBackShow(this.multipleSelection)
                })

                this.getGoodsOptions()
            }
        },
        // 将已有数据转换成数组
        objectToArray(data) {
            let key_array = Object.keys(data)
            if (key_array.length > 0) {
                return key_array.map((n) => {
                    return { id: n, client: data[n] }
                })
            } else {
                return []
            }
        },
        // 弹窗-关闭弹窗
        handleClose(done) {
            done()
            this.dialogVisible = false
        },
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },
        // 去重
        reduce(datas) {
            let obj = {}
            let reduce = []
            reduce = datas.reduce(function (item, next) {
                //item为没有重复id的数组，next为当前对象
                obj[next.id] ? '' : (obj[next.id] = true && item.push(next))
                return item
            }, [])

            return reduce
        },
        // 弹窗-多选数据-选中
        handleSelectionEvent(datas) {
            let all_datas = [...this.multipleSelection, ...this.deepcopy(datas)]
            // 去重
            this.multipleSelection = this.reduce(all_datas)

            if (datas.length == 0) {
                this.multipleSelection = []
            }
        },
        // 弹窗-多选数据-取消
        handleSelectionChangeOnline({ datas, row }) {
            this.multipleSelection = this.multipleSelection.filter((n) => n.id != row.id)
            this.handleSelectionEvent(datas)
        },
        // 弹窗-确定
        submitEvent(datas) {
            let data = {}
            datas.map((n) => {
                data[n.id] = n.client
                return
            })
            this.modelvalue = data
            this.ruleForm.meizhi_ids = Object.keys(data)
        },

        // 弹窗-多选-检查反显
        moreDataBackShow(datas) {
            if (this.$refs.mymultipleTableRef) {
                this.$refs.mymultipleTableRef.backShow(datas, this.mainthreetableData)
            }
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            this.pagesdatas.page = 1
            this.pagesdatas.page_size = val

            this.postAxios(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            this.currentPage = val

            //加载loading
            this.loading = true
            this.pagesdatas.page = val
            this.pagesdatas.page_size = this.PageSize

            this.postAxios(this.pagesdatas)
        },
        reloadEvent() {
            this.form_goods_id = ''
            this.pagesdatas.goods_id = this.form_goods_id
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            this.pagesdatas = data
            this.loading = true
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }

            this.pagesdatas.meizhi_id = data.meizhi_id
            this.pagesdatas.relate_id = data.relate_id
            this.pagesdatas.clientid = data.clientid
            this.pagesdatas.area_code = data.area_code
            this.pagesdatas.goods_id = this.form_goods_id

            this.postAxios(this.pagesdatas)
        },
        // 接口
        postAxios(datas, index) {
            axios
                .post(this.posturl, datas, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if (!index) {
                        this.myaxiosfunc(response.data.result)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                newdata[i]['id'] = newdata[i]['meizhi_id']
            }
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page
            this.lastpage = successdata.last_page
            this.loading = false

            this.moreDataBackShow(this.multipleSelection)
        },
        // 获取商品下拉
        getGoodsOptions(goodsname) {
            axios
                .post(
                    '/admin/MeizhiActivity/goodsIndex',
                    { goods_name: goodsname, page: 1, page_size: 10000000 },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        this.form_goods_id_options = response.data.result.data
                    }
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        clickDataSourceEvent(data) {
            this.form_goods_id = data
        },
        // ------------------------------------------------
        // 富文本-数据返显
        postValueEvent(data) {
            this.$refs.editorfile.postValue(data)
        },
        //  富文本反显
        getEditorDatasEvent(datas) {
            this.ruleForm.content = datas
        },
        // 添加-活动内容
        addEvent() {
            this.ruleForm.gift_info.push({ goods_num: '', gift_price: '' })
        },
        // 删除-活动内容
        delEvent(index) {
            this.ruleForm.gift_info.splice(index, 1)
        },
        getUploadFileEvent(data, flag) {
            if (flag == 1) {
                this.ruleForm.shopping_back_img = data.url
                this.$refs['ruleFormRef'].validateField('shopping_back_img')
            } else {
                this.ruleForm.print_gift_img = data.url
                this.$refs['ruleFormRef'].validateField('print_gift_img')
            }
        },
        deluploadfileEvent(data, flag) {
            if (flag == 1) {
                this.ruleForm.shopping_back_img = ''
                this.$refs['ruleFormRef'].validateField('shopping_back_img')
            } else {
                this.ruleForm.print_gift_img = ''
                this.$refs['ruleFormRef'].validateField('print_gift_img')
            }
        },
        // 保存
        submitForm(formName) {
            let getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    getdatas.goods_name = this.goods_options.find((n) => n.goods_id == getdatas.goods_id)['goods_name']
                    let gift_info = this.yuanTofenEvent(getdatas.gift_info)

                    let postdatas = {
                        name: getdatas.name,
                        goods_id: getdatas.goods_id,
                        goods_name: getdatas.goods_name,
                        gift_info: gift_info,
                        content: getdatas.content,
                        shopping_back_img: getdatas.shopping_back_img,
                        print_gift_img: getdatas.print_gift_img,
                        is_all: getdatas.is_all,
                        limits: getdatas.limits == 0 ? 0 : getdatas.limitsN,
                        begin_time: getdatas.active_time != null ? getdatas.active_time[0] / 1000 : '',
                        end_time: getdatas.active_time != null ? getdatas.active_time[1] / 1000 : '',
                        status: getdatas.status,
                        meizhi_ids: getdatas.is_all == 2 ? getdatas.meizhi_ids : []
                    }
                    let posturl = ''
                    if (this.$props.flag == 1) {
                        posturl = '/admin/MeizhiActivity/add'
                    } else if (this.$props.flag == 2) {
                        posturl = '/admin/MeizhiActivity/edit'
                        postdatas['id'] = this.$props.formData.id
                    }

                    this.disabled_btn = true
                    axios
                        .post(posturl, postdatas, {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$emit('close', true)
                                        this.disabled_btn = false
                                    }
                                })
                            } else {
                                this.disabled_btn = false
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                            this.disabled_btn = false
                        })
                } else {
                    return false
                }
            })
        },
        yuanTofenEvent(data_array) {
            if (!data_array) data_array = []

            return data_array.map((n) => {
                return { goods_num: n.goods_num, gift_price: n.gift_price * 100 }
            })
        }
    },
    components: {
        TEditor,
        mainimgesup,
        modelDialog,
        modelSearch,
        modelTable,
        modelPages
    }
}
</script>

<style lang="scss" scoped>
.active-addedit-box {
    .gift-info-box-all {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .gift-info-b {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: flex-start;
            gap: 20px;
            padding: 10px;
            border-radius: 4px;
            border: 1px dashed #999;
            max-width: 560px;
        }

        .gift-info-box {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }

        .gift-info-num {
            padding: 0 10px 20px;
            border-radius: 4px;
            border: 1px dashed #999;

            .gift-info-item {
                display: flex;
                flex-direction: row;
                gap: 10px;
            }
        }
    }

    .tan-item-box {
        display: flex;
        flex-direction: row;
        justify-items: left;
        align-items: center;
        gap: 20px;
    }

    .tan-item-wrap {
        flex-wrap: wrap;
    }

    .limits-box {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        gap: 10px;
    }
}
</style>
