<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font"><i class="el-icon-s-operation"></i>编辑问题</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="7">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm">
                                <el-form-item label="常见问题：" prop="title">
                                    <el-input v-model="ruleForm.title"></el-input>
                                </el-form-item>
                                <el-form-item label="问题图标：" prop="icon">
                                    <mainimgesup
                                        v-model="ruleForm.icon"
                                        :myimgesup="myimgesup"
                                        :myimgesupisshow="myimgesupisshow"
                                        :filelist="ruleForm.image_list"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                                    ></mainimgesup>
                                </el-form-item>
                                <el-form-item label="问题描述：" prop="content">
                                    <TEditor
                                        ref="editor"
                                        v-model="ruleForm.content"
                                        v-on:geteditordatas="
                                            (data) => {
                                                getEditorDatasEvent(data)
                                            }
                                        "
                                    />
                                </el-form-item>
                                <el-form-item label="排序：" prop="sort">
                                    <el-input v-model="ruleForm.sort" placeholder="数字越大排序越靠前"></el-input>
                                </el-form-item>
                                <el-form-item label="状态：" prop="status">
                                    <el-radio-group v-model="ruleForm.status">
                                        <el-radio label="1">发布</el-radio>
                                        <el-radio label="2">不发布</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="发布平台：" prop="platform">
                                    <el-checkbox-group v-model="ruleForm.platform">
                                        <el-checkbox label="1">微信自助版</el-checkbox>
                                        <el-checkbox label="2">支付宝自助版</el-checkbox>
                                        <el-checkbox label="3">微信企业版</el-checkbox>
                                        <el-checkbox label="4">微信家庭版</el-checkbox>
                                        <el-checkbox label="5">支付宝企业版</el-checkbox>
                                        <el-checkbox label="6">支付宝家庭版</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                    <el-button @click="resetForm()">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import mainimgesup from '@/components/Mainimgesup.vue'
import { ref } from 'vue'
import TEditor from '@/components/Maineditor.vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default {
    name: 'vcommonproblemedit',
    data() {
        return {
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '服务中心'
                },
                {
                    name: '常见问题',
                    path: '/commonproblem'
                },
                {
                    name: '编辑问题',
                    path: '/commonproblemedit'
                }
            ], // 当前页-【面包屑导航】
            myimgesupisshow: true, //form表单-imgesuploade上传图片
            myimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/file', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '选择上传文件', //button-按钮文字
                tips: '', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/png' //文件格式提示规则
                    }
                }
            },
            ruleForm: {
                title: '',
                icon: '',
                content: '',
                sort: '',
                status: ref('1'),
                platform: ref(['1']),
                image_list: [
                    {
                        name: '',
                        url: ''
                    }
                ]
            },
            rules: {
                title: [
                    {
                        required: true,
                        message: '常见问题为必填项',
                        trigger: 'blur'
                    }
                ],
                icon: [
                    {
                        required: true,
                        message: '问题图标为必填项',
                        trigger: 'blur'
                    }
                ],
                content: [
                    {
                        required: true,
                        message: '问题描述为必填项',
                        trigger: 'blur'
                    }
                ],
                sort: [
                    {
                        required: true,
                        message: '排序为必填项',
                        trigger: 'blur'
                    }
                ],
                status: [
                    {
                        required: false,
                        message: '请选择选项',
                        trigger: 'change'
                    }
                ],
                platform: [
                    {
                        required: false,
                        message: '请选择发布平台',
                        trigger: 'change'
                    }
                ]
            }, //form表单-必填规则

            loading: false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            // //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/question/info',
                    {
                        id: this.$route.query.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    var routedatas = response.data.result.info
                    var ruleform = this.ruleForm
                    ruleform.title = routedatas.title //通过路由获取的名称
                    ruleform.icon = routedatas.icon
                    ruleform.status = String(routedatas.status)
                    ruleform.content = this.$refs.editor.postValue(routedatas.content)
                    ruleform.sort = routedatas.sort
                    ruleform.platform = routedatas.platform

                    ruleform.image_list = [
                        {
                            name: '',
                            url: routedatas.icon
                        }
                    ]

                    this.loading = false
                })
                .catch(function(error) {
                    console.log(error)
                    this.loading = false
                })
        }
    },
    methods: {
        //提交form表单-并判断是否必填
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/admin/question/update',
                            {
                                id: this.$route.query.id,
                                title: this.$refs[formName].model.title,
                                icon: this.$refs[formName].model.icon,
                                content: this.$refs[formName].model.content,
                                sort: this.$refs[formName].model.sort,
                                status: this.$refs[formName].model.status,
                                platform: this.$refs[formName].model.platform
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.go(-1) // 返回上一页
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm() {
            this.$router.go(-1) // 返回上一页
            // this.$refs[formName].resetFields();
        },
        // 会员权益配置-获取子组件数据并赋值给form表单-(富文本)
        getEditorDatasEvent(datas) {
            this.ruleForm.content = datas
        },
        //获取upload子组件回传的数据
        getUploadFileEvent(data) {
            console.log('当前获取的回传数据：' + JSON.stringify(data))
            this.ruleForm.icon = data.url
        },
        handleEdit(index, row) {
            console.log(index, row)
        },
        handleDelete(index, row) {
            console.log(index, row)
        }
    },
    components: {
        mainone,
        mainimgesup,
        TEditor
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 60px;
    padding: 10px 30px;
    margin-top: 20px;
    margin-bottom: 80px;
}

.box_title {
    line-height: 40px;
    height: 40px;
    margin-top: 10px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}

.demo-table-expand {
    font-size: 0;
}

.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}

.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>
