<template>
    <div>
        <el-table
            :header-cell-style="{ background: '#F5F7FA' }"
            ref="multipleTableRef"
            :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
            style="width: 100%"
            min-height="900"
            border
            v-loading="loading"
            element-loading-text="正在飞速加载中..."
            @select-all="(data) => handleSelectionChange(data, mainthreetableData)"
            @select="handleSelectionChangeOnline"
            @cell-click="handleOneData"
        >
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" :fixed="true" v-if="selection_isshow" />
            <el-table-column
                v-for="(mytabletitle, i) in mainthreetabletitle"
                :key="i"
                :fixed="mytabletitle.fixed"
                :prop="mytabletitle.prop"
                :label="mytabletitle.label"
                :width="mytabletitle.width"
            >
                <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                <template #default="scope">
                    <slot name="content" :scope="scope" :prop="mytabletitle.prop"></slot>
                </template>
            </el-table-column>
            <el-table-column
                v-if="operation.isshow"
                :fixed="operation.fixed ? operation.fixed : 'right'"
                :label="operation.label ? operation.label : '操作'"
                :width="operation.width ? operation.width : 100"
                prop="myoperation"
                id="operation"
            >
                <template #default="scope">
                    <slot name="special" :scope="scope"></slot>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'dpw-table',
    inject: ['reload'],
    props: ['tabletitle', 'tabledata', 'totalcount', 'currentpage', 'pagesize', 'Lastpage', 'Operation', 'selectionIsShow'],
    watch: {
        tabletitle: {
            handler(newVal) {
                this.mainthreetabletitle = newVal
            },
            deep: true,
            immediate: true
        },
        tabledata: {
            handler(newVal) {
                this.mainthreetableData = newVal
            },
            deep: true,
            immediate: true
        },
        totalcount: {
            handler(newVal) {
                this.totalCount = newVal
            },
            deep: true,
            immediate: true
        },
        currentpage: {
            handler(newVal) {
                this.currentPage = newVal
            },
            deep: true,
            immediate: true
        },
        pagesize: {
            handler(newVal) {
                this.PageSize = newVal
            },
            deep: true,
            immediate: true
        },
        Lastpage: {
            handler(newVal) {
                this.lastpage = newVal
            },
            deep: true,
            immediate: true
        },
        Operation: {
            handler(newVal) {
                this.operation = newVal ? newVal : {}
            },
            deep: true,
            immediate: true
        },
        selectionIsShow: {
            handler(newVal) {
                this.selection_isshow = newVal ? newVal : false
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            loading: false, //初始化-loading加载动效，默认是false关闭

            mainthreetabletitle: [], // 数据列表中-数据表头
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            currentPage: 1,
            PageSize: 10,
            multipleSelection: [],
            selection_isshow: false
        }
    },
    methods: {
        handleOneData(){

        },
        // 多选-全选
        handleSelectionChange(data) {
            this.$emit('handleSelection', data)
        },
        // 多选-单选
        handleSelectionChangeOnline(data, row) {
            if (data.length > 0) {
                let has_ids = data.map((n) => {
                    return n.id
                })

                if (has_ids.indexOf(row.id) != -1) {
                    // 选中
                    this.$emit('handleSelection', data)
                } else {
                    // 取消
                    let end_data = data.filter((n) => n.id != row.id)
                    this.$emit('handleSelectionOnline', { datas: end_data, row: row })
                }
            } else {
                this.$emit('handleSelection', [])
            }
        },
        // 反显
        backShow(datas) {
            this.$nextTick(() => {
                if (datas.length > 0) {
                    let key_array = datas
                        .map((n) => {
                            return parseInt(n.id)
                        })
                        .filter((n) => n)

                    let backshow = this.mainthreetableData
                        .map((n) => {
                            if (key_array.indexOf(n.id) > -1) {
                                return n
                            } else {
                                return
                            }
                        })
                        .filter((n) => n)

                    // 赋值前，先清空
                    this.$refs.multipleTableRef.clearSelection()

                    backshow.forEach((element) => {
                        // 单行-返显赋值
                        this.$refs.multipleTableRef.toggleRowSelection(element, true)
                    })
                } else {
                    this.$refs.multipleTableRef.clearSelection()
                }
            })
        }
    },
    created() {},
    components: {}
})
</script>

<style scoped>
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>
