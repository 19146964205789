<template>
    <div class="active-addedit-box">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="200px" class="demo-ruleForm">
            <el-form-item label="活动名称：" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入活动名称" clearable maxlength="20" :disabled="isDisabled"
                    show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="活动类型：" prop="type" required>
                <el-radio-group v-model="ruleForm.type" @change="changeType" :disabled="isDisabled">
                    <el-radio label="1">满额赠送</el-radio>
                    <el-radio label="2">按订单金额赠送</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="活动内容：" prop="gift_info">
                <div v-if="ruleForm.type == '1'">
                    <div class="tips">优惠券名称组成: 活动名称(优惠券面额)</div>
                    <el-table :data="ruleForm.gift_info" :span-method="objectSpanMethod" border
                        style="width: 100%; margin-top: 20px">
                        <el-table-column prop="name" label="订单金额" width="240">
                            <template #default="scope">
                                <el-form-item :prop="'gift_info.' + scope.$index + '.order_amount'"
                                    :rules="rules.order_amount">
                                    <el-input v-model="scope.row.order_amount" @input="changeOrderAmout(scope.row)" :disabled="isDisabled"
                                        placeholder="正整数，最多1000">
                                        <template #prepend>>=</template>
                                    </el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="value" label="优惠券面额">
                            <template #default="scope">
                                <el-form-item :prop="'gift_info.' + scope.$index + '.value'" :rules="rules.value">
                                    <el-input v-model="scope.row.value" placeholder="正整数，最多1000" :disabled="isDisabled"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="condition" label="使用门槛" width="400">
                            <template #default="scope">
                                <el-form-item :prop="'gift_info.' + scope.$index + '.condition'"
                                    :rules="rules.condition">
                                    <el-radio-group v-model="scope.row.conditionVal" class="ml-4" :disabled="isDisabled"
                                        @change="changeConditionVal(scope.row)">
                                        <el-radio size="small" label="1">不限制</el-radio>
                                        <el-radio label="2" size="small">
                                            <slot name="default">
                                                满
                                                <el-input v-model="scope.row.condition" size="small" style="width:150px"
                                                    :disabled="scope.row.conditionVal == '1' || isDisabled"
                                                    placeholder="正整数，最多1000"></el-input>
                                                元可使用
                                            </slot>
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="num" label="赠送数量">
                            <template #default="scope">
                                <el-form-item :prop="'gift_info.' + scope.$index + '.num'" :rules="rules.num">
                                    <el-input v-model="scope.row.num" placeholder="正整数，最多100000" :disabled="isDisabled"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="150">
                            <template #default="scope">
                                <el-button size="small" v-if="scope.row.add" :disabled="isDisabled"
                                    @click="addHandleEvent(scope.$index, scope.row)">
                                    添加
                                </el-button>
                                <el-button size="small" type="danger" :disabled="isDisabled"
                                    @click="handleDelete(scope.$index, scope.row)">
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div><el-button type="primary" style=" margin-top: 10px" @click="addEvent" :disabled="isDisabled">添加</el-button></div>
                </div>
                <div v-if="ruleForm.type == '2'">
                    <div class="tips">优惠券名称组成: 活动名称(优惠券面额)</div>
                    <div class="tips">优惠券面额由订单金额和赠送数量计算</div>
                    <el-table :data="ruleForm.gift_info" border style="width: 100%; margin-top: 20px">
                        <el-table-column prop="name" label="订单金额">
                            <template #default="scope">
                                <el-form-item :prop="'gift_info.' + scope.$index + '.order_amount'"
                                    :rules="rules.order_amount">
                                    <el-input v-model.number="scope.row.order_amount" placeholder="正整数，最多1000" :disabled="isDisabled">
                                        <template #prepend>>=</template>
                                    </el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="condition" label="使用门槛" width="400">
                            <template #default="scope">
                                <el-form-item :prop="'gift_info.' + scope.$index + '.condition'"
                                    :rules="rules.condition">
                                    <el-radio-group v-model="scope.row.conditionVal" class="ml-4" :disabled="isDisabled"
                                        @change="changeConditionVal(scope.row)">
                                        <el-radio size="small" label="1">不限制</el-radio>
                                        <el-radio label="2" size="small">
                                            <slot name="default">
                                                优惠券面额 X
                                                <el-input v-model="scope.row.condition" size="small" style="width:150px"
                                                    :disabled="scope.row.conditionVal == '1' || isDisabled"
                                                    placeholder="倍数"></el-input>

                                            </slot>
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="num" label="赠送数量">
                            <template #default="scope">
                                <el-form-item :prop="'gift_info.' + scope.$index + '.num'" :rules="rules.num">
                                    <el-input v-model="scope.row.num" placeholder="正整数，最多100000" :disabled="isDisabled"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="100">
                            <template #default="scope">
                                <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)" :disabled="isDisabled">
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div><el-button type="primary" style=" margin-top: 10px" @click="addEvent" :disabled="isDisabled">添加</el-button></div>
                </div>
            </el-form-item>
            <el-form-item label="发放时间：" prop="give_day">
                <el-input v-model.number="ruleForm.give_day" placeholder="数据为0时实时发放" style="width: 350px" :disabled="isDisabled">
                    <template #prepend>购物成功</template>
                    <template #append>天后</template>
                </el-input>
            </el-form-item>
            <el-form-item label="用券时间：" prop="coupon_day">
                <el-input v-model.number="ruleForm.coupon_day" placeholder="领取后开始计算到期天数" style="width: 350px" :disabled="isDisabled">
                    <template #append>天</template>
                </el-input>
            </el-form-item>
            <el-form-item label="适用商品：" required>
                <div>全部商品</div>
                <div class="tips" style="line-height: 22px">
                    1、默认全部商品可用 <br>
                    2、如需改为部分指定商品可用，请至商城后台调整
                </div>
            </el-form-item>
            <el-form-item label="活动页图片：" prop="content">
                <el-image
                    style="width: 100px; height: 100px; border: 1px solid #ccc; "
                    :src="ruleForm.content"
                    :zoom-rate="1.2"
                    :max-scale="7"
                    :min-scale="0.2"
                    :preview-src-list="[ruleForm.content]"
                    fit="contain"
                    v-if="isDisabled"
                    />
                <mainimgesup v-else ref="myimges_3" v-model="ruleForm.content" :myimgesup="myimgesup_3"
                    :myimgesupisshow="myimgesupisshow_3"
                    :filelist="ruleForm.content != '' ? [{ name: '', url: ruleForm.content }] : []"
                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 3)"
                    v-on:deluploadfile="(data) => deluploadfileEvent(data, 3)" v-on:getfiletype="getfiletypeEvent">
                </mainimgesup>
            </el-form-item>
            <el-form-item label="扫码购物(背景图)：" prop="shopping_back_img">
                <el-image
                    style="width: 100px; height: 100px; border: 1px solid #ccc; "
                    :src="ruleForm.shopping_back_img"
                    :zoom-rate="1.2"
                    :max-scale="7"
                    :min-scale="0.2"
                    :preview-src-list="[ruleForm.shopping_back_img]"
                    fit="contain"
                    v-if="isDisabled"
                    />
                <mainimgesup v-else ref="myimges_1" v-model="ruleForm.shopping_back_img" :myimgesup="myimgesup_1"
                    :myimgesupisshow="myimgesupisshow_1"
                    :filelist="ruleForm.shopping_back_img != '' ? [{ name: '', url: ruleForm.shopping_back_img }] : []"
                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 1)"
                    v-on:deluploadfile="(data) => deluploadfileEvent(data, 1)" v-on:getfiletype="getfiletypeEvent">
                </mainimgesup>
            </el-form-item>
            <el-form-item label="领取商城优惠券(背景图)：" prop="print_gift_img">
                <el-image
                    style="width: 100px; height: 100px; border: 1px solid #ccc; "
                    :src="ruleForm.print_gift_img"
                    :zoom-rate="1.2"
                    :max-scale="7"
                    :min-scale="0.2"
                    :preview-src-list="[ruleForm.print_gift_img]"
                    fit="contain"
                    v-if="isDisabled"
                    />
                <mainimgesup v-else ref="myimges_2" v-model="ruleForm.print_gift_img" :myimgesup="myimgesup_2"
                    :myimgesupisshow="myimgesupisshow_2"
                    :filelist="ruleForm.print_gift_img != '' ? [{ name: '', url: ruleForm.print_gift_img }] : []"
                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 2)"
                    v-on:deluploadfile="(data) => deluploadfileEvent(data, 2)" v-on:getfiletype="getfiletypeEvent">
                </mainimgesup>
            </el-form-item>
            <el-form-item label="适用设备：" prop="is_all">
                <el-radio-group v-model="ruleForm.is_all">
                    <el-radio label="1">全部设备</el-radio>
                    <el-radio @click="allChange(2)" label="2">部分设备</el-radio>
                </el-radio-group>
                <el-form-item style="padding-right: 12px" prop="meizhi_ids"
                    v-if="Object.keys(this.modelvalue).length > 0 && ruleForm.is_all == 2">
                    <el-select v-model="ruleForm.meizhi_ids" multiple allow-create style="min-width: 200px; width: 100%"
                        placeholder="请选择">
                        <el-option v-for="(item, index) in modelvalue" :key="index" :label="index" :value="index">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form-item>
            <el-form-item label="活动时间：" prop="active_time">
                <el-date-picker v-model="ruleForm.active_time" type="datetimerange" range-separator="至" :disabled="isDisabled"  :default-time="defaultTime2"
                    start-placeholder="开始时间" end-placeholder="结束时间" />
            </el-form-item>
            <el-form-item label="状态：" prop="status">
                <el-switch v-model="ruleForm.status" :active-value="1" :inactive-value="-1" :disabled="isDisabled"/>
            </el-form-item>
            <el-form-item>
                <div style="padding: 20px 0">
                    <el-button @click="cancelDrawer" style="width: 150px">取消</el-button>
                    <el-button type="primary" @click="submitForm('ruleFormRef')" style="width: 150px"
                        :disabled="disabled_btn">保存</el-button>
                </div>
            </el-form-item>
        </el-form>

        <!-- 部分设备弹窗 -->
        <modelDialog :isshow="dialogVisible" :title="'选择设备'" v-on:beforeclose="handleClose">
            <template v-slot:search>
                <modelSearch :gaoisshow="false" :forminfo="maintwoforminfo" v-on:getsearchinfo="getSeachinfoEvent">
                </modelSearch>
            </template>
            <template v-slot:table>
                <modelTable v-loading="loading" element-loading-text="正在飞速加载中..." ref="mymultipleTableRef"
                    :tabletitle="mainthreetabletitle" :tabledata="mainthreetableData" :totalcount="totalCount"
                    :currentpage="currentPage" :pagesize="PageSize" :Lastpage="lastpage"
                    @handleSelection="handleSelectionEvent" @handleSelectionOnline="handleSelectionChangeOnline" />
            </template>
            <template v-slot:pages>
                <modelPages :totalCount="totalCount" :currentPage="currentPage" :pageSizes="pageSizes"
                    :PageSize="PageSize" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
            </template>
            <template v-slot:footbtn>
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"
                    @click="submitEvent(this.multipleSelection, (dialogVisible = false))">确定</el-button>
            </template>
        </modelDialog>
    </div>
</template>

<script>
import dict from '@/assets/js/dict.js'
import axios from 'axios'
import _ from "lodash";
import mainimgesup from '@/components/Mainimgesup.vue'
import { ElMessage } from 'element-plus'
import modelDialog from '../../../../operate/wecom/minipluswecom/modelDialog.vue'
import modelSearch from './modelSearch.vue'
import modelTable from '../../../../operate/wecom/minipluswecom/modelTable.vue'
import modelPages from '../../../../operate/wecom/minipluswecom/modelPages.vue'

export default {
    name: 'ShoppingActivityAddEdit',
    props: {
        flag: {
            type: [String, Number],
            default: 1
        },
        formData: {
            type: Object,
            default() {
                return {}
            }
        },
        goodsOptions: {
            type: Array,
            default() {
                return []
            }
        },
        meizhiIdsOptions: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    watch: {
        formData: {
            handler(newVal) {
                this.ruleForm = newVal;
                if(newVal.order_num >= 1){
                    this.isDisabled = true;
                }else{
                    this.isDisabled = false;
                }
                setTimeout(this.getRowSpan, 300)
            },
            deep: true,
            immediate: true
        },
        meizhiIdsOptions: {
            handler(newVal) {
                this.modelvalue = newVal
            },
            deep: true,
            immediate: true
        },
    },
    computed: {

    },
    data() {
        return {
            dict_options: dict,
            defaultTime2: [
                new Date(2000, 1, 1, 0, 0, 0),
                new Date(2000, 2, 1, 23, 59, 59),
            ],
            ruleForm: {
                id: '',
                name: '',
                type: '1',
                gift_info: [],
                give_day: '',
                coupon_day: '',
                suit_goods: '1',
                content: '',
                shopping_back_img: '',
                print_gift_img: '',
                is_all: '',
                active_time: [],
                status: '',
                meizhi_id: [],
                meizhi_ids_options: {}
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '活动名称为必填项',
                        trigger: 'change'
                    }
                ],
                gift_info: [
                    {
                        required: true,
                        message: '活动内容为必填项',
                        trigger: 'change'
                    }
                ],
                order_amount: [
                    {
                        required: true,
                        message: '订单金额为必填项',
                        trigger: 'change'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\+?[0-9]\d*$/,
                        message: '必须为正整数',
                        trigger: 'change'
                    }
                ],
                value: [
                    {
                        required: true,
                        message: '优惠券面额为必填项',
                        trigger: 'change'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '必须为正整数',
                        trigger: 'change'
                    }
                ],
                condition: [
                    {
                        required: true,
                        message: '使用门槛为必填项',
                        trigger: 'change'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '必须为正整数',
                        trigger: 'change'
                    }
                ],
                num: [
                    {
                        required: true,
                        message: '赠送数量为必填项',
                        trigger: 'change'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '必须为正整数',
                        trigger: 'change'
                    }
                ],
                // gift_price: [
                //     {
                //         required: true,
                //         message: '赠送打印余额为必填项',
                //         trigger: 'change'
                //     },
                //     {
                //         // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                //         pattern: /^\d\d*$/,
                //         message: '必须为正整数',
                //         trigger: 'change'
                //     }
                // ],
                give_day: [
                    { required: true, message: '发放时间为必填项', trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            if (!value && value != 0) {
                                return callback(new Error('请输入天数'));
                            }
                            if (!Number.isInteger(value) || value < 0 || value > 365) {
                                return callback(new Error('请输入正整数且不大于365'));
                            }
                            callback();
                        },
                        trigger: 'blur'
                    }
                ],
                coupon_day: [
                    {
                        required: true,
                        message: '用券时间为必填项',
                        trigger: 'blur'
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (!value && value != 0) {
                                return callback(new Error('请输入天数'));
                            }
                            if (!Number.isInteger(value) || value < 0 || value > 365) {
                                return callback(new Error('请输入正整数且不大于365'));
                            }
                            callback();
                        },
                        trigger: 'blur'
                    }
                ],
                content: [
                    {
                        required: true,
                        message: '活动页图片为必填项',
                        trigger: 'change'
                    }
                ],
                shopping_back_img: [
                    {
                        required: true,
                        message: '扫码购物(背景图)为必填项',
                        trigger: 'change'
                    }
                ],
                print_gift_img: [
                    {
                        required: true,
                        message: '领取商城优惠券(背景图)为必填项',
                        trigger: 'change'
                    }
                ],
                is_all: [
                    {
                        required: true,
                        message: '适用设备为必选项',
                        trigger: 'change'
                    }
                ],
                // active_time: [
                //     {
                //         required: true,
                //         message: '活动时间为必选项',
                //         trigger: 'change'
                //     }
                // ],
                meizhi_ids: [
                    {
                        required: true,
                        message: '部分设备为必填项',
                        trigger: 'change'
                    }
                ],
            },
            goods_options: [],

            //上传-【处理】-imgesuploade上传图片
            myimgesupisshow_1: true,
            // 上传-【处理】-上传留言图片
            myimgesup_1: {
                uploadsize: 1, //选择上传图片的展现框-1oss,2传后端
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '上传图片', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '建议尺寸：375mm x 120mm', //提示&建议文字 建议尺寸46px*46px
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'] //文件格式
                    }
                }
            },

            //上传-【处理】-imgesuploade上传图片
            myimgesupisshow_2: true,
            // 上传-【处理】-上传留言图片
            myimgesup_2: {
                uploadsize: 1, //选择上传图片的展现框-1oss,2传后端
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '上传图片', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '建议尺寸：375mm x 120mm', //提示&建议文字 建议尺寸46px*46px
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'] //文件格式
                    }
                }
            },

            //上传-【处理】-imgesuploade上传图片
            myimgesupisshow_3: true,
            // 上传-【处理】-活动页图片
            myimgesup_3: {
                uploadsize: 1, //选择上传图片的展现框-1oss,2传后端
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '上传图片', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '', //提示&建议文字 建议尺寸46px*46px
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'] //文件格式
                    }
                }
            },
            disabled_btn: false,
            loading_table: false,

            // 弹窗相关
            dialogVisible: false,
            maintwoforminfo: {
                // myinputonefullisshow: true, //筛选查询-自动建议下拉框
                // myinputonefull: {
                //     divinterval: '0', //框右侧距离
                //     titlewidth: '100px', //标题宽度
                //     inputwidth: '180px', //input宽度
                //     inputinterval: '10px', //input右侧距离
                //     input: [
                //         {
                //             ismust: false, //是否必填，true表示必填
                //             title: '打印设备编号:',
                //             placeholder: '请输入打印设备编号', //此name为键值
                //             name: 'clientid',
                //             content: '',
                //             id: '', //此id为给后台的id值
                //             url: '/admin/getClientList',
                //             inputval: { client: '' } //这里记录需要传的参数
                //         }
                //     ]
                // },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: "none",
                    divinterval: "", //框右侧距离
                    selectwidth: "200px",
                    selectinterval: "20px", //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: "设备类型：",
                            placeholder: "全部",
                            name: "meizhi_type",
                            value: "",
                            options: [
                                {
                                    label: "全部",
                                    value: "",
                                },
                                {
                                    label: "一体机",
                                    value: "1",
                                },
                                {
                                    label: "小卖柜",
                                    value: "2",
                                },
                            ],
                        },
                    ],
                },
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '100px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '10px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '购物设备编号:',
                            placeholder: '请输入购物设备编号',
                            name: 'relate_id',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '原始设备编号:',
                            placeholder: '请输入原始设备编号',
                            name: 'meizhi_id',
                            content: ''
                        }
                    ]
                },
                myselectcityleftisshow: true, //筛选查询-selectcity选框
                myselectcityleft: {
                    selectwidth: '100%',
                    select: {
                        ismust: false,
                        title: '选择区域：',
                        name: 'area_code',
                        area_code: '',
                        valuesheng: '',
                        valueshi: '',
                        valuequ: '',
                        disshi: true,
                        disqu: true
                    }
                }
            },
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: 'relate_id',
                    label: '购物设备编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'meizhi_id',
                    label: '原始设备编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'client',
                    label: '打印设备编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'area_code_name',
                    label: '设备地址',
                    width: ''
                }
            ],
            mainthreetableData: [],
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            currentPage: 1,
            pageSizes: [10, 20, 50, 100],
            PageSize: 10,
            multipleSelection: [], //多选的数据
            modelvalue: {}, //确定选中的数据
            // posturl: '/admin/MeizhiActivity/meizhiClientIndex',
            posturl: '/admin/MeizhiJicaiActivity/meizhiClientIndex', // 使用这个接口
            // postaxios: 'post',
            pagesdatas: {},
            isDisabled: false,
            rowSpanArr: [],
            position: 0
        }
    },
    mounted(){ // DOM挂载后
    },
    methods: {
        // 解决警告
        getfiletypeEvent(data) {
            console.log('文件后缀：', data)
        },
        // 修改订单金额同步修改同类项
        changeOrderAmout(row) {
            this.ruleForm.gift_info.forEach(item => {
                if (item.name == row.name) {
                    item.order_amount = row.order_amount
                }
            })
        },
        changeType(val) {
            if (val) {
                this.ruleForm.gift_info = [];
            }
        },
        changeConditionVal(row) {
            if (row.conditionVal == '1') {
                row.condition = 0
            } else {
                row.condition = ''
            }
        },
        getRowSpan() {
            this.rowSpanArr = [];
            if(!this.ruleForm.gift_info){
                return false;
            }
            this.ruleForm.gift_info.sort((a, b) => a.name - b.name);
            this.ruleForm.gift_info.forEach((item, index) => {
                if (index == 0) {
                    this.rowSpanArr.push(1);
                    this.position = 0;
                } else {
                    if (this.ruleForm.gift_info[index].name == this.ruleForm.gift_info[index - 1].name) {
                        this.rowSpanArr[this.position] += 1; //项目名称相同，合并到同一个数组中
                        this.rowSpanArr.push(0);
                        this.ruleForm.gift_info[index].name = this.ruleForm.gift_info[index - 1].name;
                    } else {
                        this.rowSpanArr.push(1);
                        this.position = index;
                    }
                }
            });
        },
        handleDelete(index, row) {
            this.ruleForm.gift_info.splice(index, 1);
            this.getRowSpan();
            this.$message({
                message: `删除成功`,
                type: 'success',
            });
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                const _row = this.rowSpanArr[rowIndex];
                return {
                    rowspan: _row, //行
                    colspan: 1 //列
                };
            }
        },
        // ------------------------------------------------
        // 点击-添加更多按钮-打开弹窗allChange
        async allChange(e) {
            if (e == 2) {
                this.dialogVisible = true

                this.pagesdatas = {
                    page: 1,
                    page_size: this.PageSize
                }
                this.postAxios(this.pagesdatas)

                this.$nextTick(() => {
                    this.multipleSelection = this.objectToArray(this.modelvalue)
                    this.moreDataBackShow(this.multipleSelection)
                })
            }
        },
        // 将已有数据转换成数组
        objectToArray(data) {
            let key_array = Object.keys(data)
            if (key_array.length > 0) {
                return key_array.map((n) => {
                    return { id: n, client: data[n] }
                })
            } else {
                return []
            }
        },
        // 弹窗-关闭弹窗
        handleClose(done) {
            done()
            this.dialogVisible = false
        },
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },
        // 去重
        reduce(datas) {
            let obj = {}
            let reduce = []
            reduce = datas.reduce(function (item, next) {
                //item为没有重复id的数组，next为当前对象
                obj[next.id] ? '' : (obj[next.id] = true && item.push(next))
                return item
            }, [])

            return reduce
        },
        // 弹窗-多选数据-选中
        handleSelectionEvent(datas) {
            let all_datas = [...this.multipleSelection, ...this.deepcopy(datas)]
            // 去重
            this.multipleSelection = this.reduce(all_datas)

            if (datas.length == 0) {
                this.multipleSelection = []
            }
        },
        // 弹窗-多选数据-取消
        handleSelectionChangeOnline({ datas, row }) {
            this.multipleSelection = this.multipleSelection.filter((n) => n.id != row.id)
            this.handleSelectionEvent(datas)
        },
        // 弹窗-确定
        submitEvent(datas) {
            let data = {}
            datas.map((n) => {
                data[n.id] = n.client
                return
            })
            this.modelvalue = data
            this.ruleForm.meizhi_ids = Object.keys(data)
        },

        // 弹窗-多选-检查反显
        moreDataBackShow(datas) {
            if (this.$refs.mymultipleTableRef) {
                this.$refs.mymultipleTableRef.backShow(datas, this.mainthreetableData)
            }
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            this.pagesdatas.page = 1
            this.pagesdatas.page_size = val

            this.postAxios(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            this.currentPage = val

            //加载loading
            this.loading = true
            this.pagesdatas.page = val
            this.pagesdatas.page_size = this.PageSize

            this.postAxios(this.pagesdatas)
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            this.pagesdatas = data
            this.loading = true
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }

            this.pagesdatas.meizhi_id = data.meizhi_id
            this.pagesdatas.relate_id = data.relate_id
            this.pagesdatas.meizhi_type = data.meizhi_type
            this.pagesdatas.area_code = data.area_code
            this.postAxios(this.pagesdatas)
        },
        // 接口
        postAxios(datas, index) {
            axios
                .post(this.posturl, datas, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if (!index) {
                        this.myaxiosfunc(response.data.result)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                newdata[i]['id'] = newdata[i]['meizhi_id']
            }
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page
            this.lastpage = successdata.last_page
            this.loading = false

            this.moreDataBackShow(this.multipleSelection)
        },
        // 添加同类项
        addHandleEvent(index, row) {
            this.ruleForm.gift_info.push({
                name: row.name,
                order_amount: row.order_amount,
                value: '',
                conditionVal: '1',
                condition: '0',
                num: '',
                add: false,
                del: true
            })
            this.getRowSpan();
        },
        // 添加-活动内容
        addEvent() {
            this.ruleForm.gift_info.push({
                name: this.ruleForm.gift_info.length ? this.ruleForm.gift_info[this.ruleForm.gift_info.length - 1].name + 1 : 1,
                order_amount: '',
                value: '',
                conditionVal: '1',
                condition: '0',
                num: '',
                add: true,
                del: false
            })
            this.getRowSpan();
        },
        // 删除-活动内容
        delEvent(index) {
            this.ruleForm.gift_info.splice(index, 1)
        },
        getUploadFileEvent(data, flag) {
            if (flag == 1) {
                this.ruleForm.shopping_back_img = data.url
                this.$refs['ruleFormRef'].validateField('shopping_back_img')
            } else if (flag == 3) {
                this.ruleForm.content = data.url
                this.$refs['ruleFormRef'].validateField('content')
            } else {
                this.ruleForm.print_gift_img = data.url
                this.$refs['ruleFormRef'].validateField('print_gift_img')
            }
        },
        deluploadfileEvent(data, flag) {
            if (flag == 1) {
                this.ruleForm.shopping_back_img = ''
                this.$refs['ruleFormRef'].validateField('shopping_back_img')
            } else if (flag == 3) {
                this.ruleForm.content = ''
                this.$refs['ruleFormRef'].validateField('content')
            } else {
                this.ruleForm.print_gift_img = ''
                this.$refs['ruleFormRef'].validateField('print_gift_img')
            }
        },
        cancelDrawer() {
            this.$emit('close')
        },
        // 保存
        submitForm(formName) {
            let getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let gift_info = this.yuanTofenEvent(getdatas.gift_info)
                    let postdatas = {
                        name: getdatas.name,
                        type: getdatas.type,
                        gift_info: gift_info,
                        give_day: getdatas.give_day,
                        coupon_day: getdatas.coupon_day,
                        suit_goods: getdatas.suit_goods,
                        content: getdatas.content,
                        shopping_back_img: getdatas.shopping_back_img,
                        print_gift_img: getdatas.print_gift_img,
                        is_all: getdatas.is_all,
                        begin_time: getdatas.active_time != null ? getdatas.active_time[0] / 1000 : '',
                        end_time: getdatas.active_time != null ? getdatas.active_time[1] / 1000 : '',
                        status: getdatas.status,
                        meizhi_ids: getdatas.is_all == 2 ? getdatas.meizhi_ids : []
                    }
                    console.dir(postdatas);
                    let posturl = ''
                    if (this.$props.flag == 1) {
                        posturl = '/admin/MeizhiJicaiActivity/add'
                    } else if (this.$props.flag == 2) {
                        posturl = '/admin/MeizhiJicaiActivity/edit'
                        postdatas['id'] = this.$props.formData.id
                    }

                    this.disabled_btn = true
                    axios
                        .post(posturl, postdatas, {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$emit('close', true)
                                        this.disabled_btn = false
                                    }
                                })
                            } else {
                                this.disabled_btn = false
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                            this.disabled_btn = false
                        })
                } else {
                    return false
                }
            })
        },
        yuanTofenEvent(data_array) {
            if (!data_array) data_array = [];
            let orderAmountObj = {};
            let new_data_array = _.cloneDeep(data_array);

            new_data_array.forEach(item => {
                item.order_amount = Number(item.order_amount) * 100;
                item.value = Number(item.value) * 100;
                item.condition = Number(item.condition) * 100;
                item.num = Number(item.num);

                if (item.order_amount) {
                    if (orderAmountObj[item.order_amount]) {
                        orderAmountObj[item.order_amount].push({
                            value: item.value,
                            condition: item.conditionVal == 1 ? 0: item.condition,
                            num: item.num,
                        })
                    } else {
                        orderAmountObj[item.order_amount] = [{
                            value: item.value,
                            condition: item.conditionVal == 1 ? 0: item.condition,
                            num: item.num,
                        }]
                    }
                }
            })

            return orderAmountObj
        }
    },
    components: {
        mainimgesup,
        modelDialog,
        modelSearch,
        modelTable,
        modelPages
    }
}
</script>

<style lang="scss" scoped>
.tips {
    font-size: 14px;
    color: rgb(153, 153, 153);
    white-space: pre-wrap;
}

.active-addedit-box {
    .gift-info-box-all {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .gift-info-b {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: flex-start;
            gap: 20px;
            padding: 10px;
            border-radius: 4px;
            border: 1px dashed #999;
            max-width: 960px;
        }

        .gift-info-box {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }

        .gift-info-num {
            padding: 0 10px 20px;
            border-radius: 4px;
            border: 1px dashed #999;

            .gift-info-item {
                display: flex;
                flex-direction: row;
                gap: 10px;
            }
        }
    }

    .tan-item-box {
        display: flex;
        flex-direction: row;
        justify-items: left;
        align-items: center;
        gap: 20px;
    }

    .tan-item-wrap {
        flex-wrap: wrap;
    }

    .limits-box {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        gap: 10px;
    }
}
</style>
