<template>
  <el-dialog
    v-model="visible"
    title="选择优惠券"
    width="700px"
    @close="closeDialog"
  >
    <div class="coupons-list">
      <div class="search">
        <div class="l">
          <span class="label">优惠券名：</span>
          <el-input v-model="query.title" placeholder="请输入" clearable />
        </div>
        <div class="r">
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>
      
      <el-table
        style="margin: 15px 0"
        ref="tableEle"
        height="400px"
        :data="tableData"
        border
        v-loading="query.loading"
        @selectionChange="couponSelection"
      >
        <el-table-column type="selection" width="45" fixed="left" />
        <template v-for="(column, c) in tableJSON" :key="c">
          <el-table-column
            :label="column.label"
            :prop="column.prop"
            :width="column.width"
          />
        </template>
      </el-table>
      
      <div class="pagination flex-row">
        <el-pagination
          v-model:page-size="query.pagination.page_size"
          v-model:current-page="query.pagination.page"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :total="query.total"
          @currentChange="getAllCoupons"
          @sizeChange="getAllCoupons"
        />
      </div>
    
    </div>
    <template #footer>
      <el-button @click="closeDialog">返回</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {ref} from 'vue'
import http from "@/assets/js/http";

const visible = ref(false)
const tableEle = ref()
const tableJSON = ref([
  {
    label: 'ID',
    prop: 'id',
    width: 50
  },
  {
    label: '优惠券名称',
    prop: 'title',
    width: 120
  },
  {
    label: '名称备注',
    prop: 'name',
    width: 80
  },
  {
    label: '适用渠道',
    prop: 'type_name',
    width: 100
  },
  {
    label: '优惠券状态',
    prop: 'status_name',
    width: 120
  },
  {
    label: '发行量',
    prop: 'number',
    width: 100
  },
  {
    label: '面值',
    prop: 'money_name',
    width: 90
  },
  {
    label: '有效时间',
    prop: 'use_time',
    width: 210
  },
])
const tableData = ref([])
const query = ref({
  pagination: {
    page: 1,
    page_size: 10
  },
  total: 0,
  title: '',
  loading: false
})

const currentContent = ref()
const getAllCoupons = () => {
  query.value.loading = true
  http.post(`/Coupon/index`, {
    type: [1,2],
    title: query.value.title,
    ...query.value.pagination
  }).then(res => {
    const { total, data } = res
    query.value.total = total
    tableData.value = data
    
    tableData.value.forEach(i => {
      if(currentContent.value.coupon_ids.includes((i.id).toString())) {
        tableEle.value.toggleRowSelection(i)
      }
    })
  }).finally(() => {
    query.value.loading = false
  })
}

const couponSelection = (selection) => {
  selection.forEach(s => {
    const has = currentContent.value.coupon_ids.find(i => Number(i) === s.id)
    if(!has) {
      if(!currentContent.value.coupon_names) currentContent.value.coupon_names = []
      currentContent.value.coupon_ids.push(s.id)
      currentContent.value.coupon_names.push(s.title)
    }
  })
}

const onSearch = () => {
  query.value.pagination.page = 1
  query.value.total = 0
  getAllCoupons()
}
const onReset = () => {
  query.value.pagination.page = 1
  query.value.pagination.page_size = 10
  query.value.total = 0
  query.value.title = ''
  getAllCoupons()
}

const emits = defineEmits(['submit'])
const onSubmit = () => {
  emits('submit', currentContent.value)
  closeDialog()
}

const openDialog = (row) => {
  visible.value = true
  currentContent.value = row
  getAllCoupons()
}
const closeDialog = () => {
  visible.value = false
  query.value.title = ''
  query.value.loading = false
  query.value.total = 0
  query.value.pagination.page = 1
  query.value.pagination.page_size = 10
}

defineExpose({
  open: openDialog,
  close: closeDialog
})
</script>

<style lang="scss" scoped>
@use "../style.scss";
</style>
