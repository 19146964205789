<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <el-tabs class="demo-tabs" @tab-click="handleClick">
                    <!-- 微信 -->
                    <el-tab-pane label="微信">
                        <el-form ref="wechatformdata" :model="wechatformdata" label-width="160px" class="demo-ruleForm">
                            <el-form-item :label="item.label" v-for="(item, index) in wechatlayoutdatas" :key="index"
                                required="true">
                                <el-radio-group v-model="item.radio.value"
                                    @change="(data) => changeRadioEvent(data, 'wechat', item.radio.prop)">
                                    <el-radio v-for="(jtem, jndex) in item.radio.radiodatas" :key="jndex"
                                        :label="jtem.value">{{ jtem.label }}</el-radio>
                                </el-radio-group>
                                <!-- 子级-判断 -->
                                <div v-if="item.radio.child && item.radio.value == 1">
                                    <el-form-item required="true">
                                        <el-radio-group v-model="item.radio.child.value"
                                            @change="(data) => changeRadioEvent(data, 'wechat', item.radio.child.prop)">
                                            <el-radio :label="ktem.value"
                                                v-for="(ktem, kndex) in item.radio.child.childdatas" :key="kndex">{{
                                                        ktem.label
                                                }}</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="submitForm('wechatformdata')"
                                    style="width: 20%; min-width: 80px; margin-right: 10px">
                                    提交
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                    <!-- 支付宝 -->
                    <el-tab-pane label="支付宝">
                        <el-form ref="alipayformdata" :model="alipayformdata" :rules="formdatarules" label-width="160px"
                            class="demo-ruleForm">
                            <el-form-item :label="item.label" v-for="(item, index) in alipaylayoutdatas" :key="index"
                                required="true">
                                <el-radio-group v-model="item.radio.value"
                                    @change="(data) => changeRadioEvent(data, 'alipay', item.radio.prop)">
                                    <el-radio v-for="(jtem, jndex) in item.radio.radiodatas" :key="jndex"
                                        :label="jtem.value">{{ jtem.label }}</el-radio>
                                </el-radio-group>
                                <!-- 子级-判断 -->
                                <div v-if="item.radio.child && item.radio.value == 1">
                                    <el-form-item required="true">
                                        <el-radio-group v-model="item.radio.child.value"
                                            @change="(data) => changeRadioEvent(data, 'alipay', item.radio.child.prop)">
                                            <el-radio :label="ktem.value"
                                                v-for="(ktem, kndex) in item.radio.child.childdatas" :key="kndex">{{
                                                        ktem.label
                                                }}</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="submitForm('alipayformdata')"
                                    style="width: 20%; min-width: 80px; margin-right: 10px">
                                    提交
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from "@/components/Mainone";
import { ElMessage } from "element-plus";
import axios from "axios";

export default {
    name: "vminilayout",
    data() {
        return {
            mainonebread: [
                {
                    name: "店铺",
                },
                {
                    name: "装修",
                },
                {
                    name: "首页布局",
                    path: "/personalinfo",
                },
            ], // 当前页-【面包屑导航】

            // 微信-数据结构
            wechatlayoutdatas: [
                {
                    label: '打印区域风格设置:',
                    radio: {
                        value: '1',
                        prop: 'printstyle',
                        radiodatas: [
                            {
                                value: '1',
                                label: '3图样式',
                            },
                            {
                                value: '2',
                                label: '5图样式',
                            },
                            {
                                value: '3',
                                label: '6图样式',
                            },
                            {
                                value: '4',
                                label: 'tab样式',
                            }
                        ]
                    }
                },
                {
                    label: '文库位置:',
                    radio: {
                        value: '1',
                        prop: 'fileset',
                        radiodatas: [
                            {
                                value: '1',
                                label: '显示',
                            },
                            {
                                value: '2',
                                label: '隐藏',
                            }
                        ]
                    }
                },
                {
                    label: '课程位显示风格:',
                    radio: {
                        value: '1',
                        prop: 'keishow',
                        radiodatas: [
                            {
                                value: '1',
                                label: '显示',
                            },
                            {
                                value: '2',
                                label: '隐藏',
                            }
                        ],
                        child: {
                            value: '1',
                            prop: 'kestyle',
                            childdatas: [
                                {
                                    value: '1',
                                    label: '组合样式',
                                },
                                {
                                    value: '2',
                                    label: '竖版样式',
                                },
                                {
                                    value: '3',
                                    label: '横版样式',
                                }
                            ]
                        }
                    }
                },
                {
                    label: 'PLUS会员模式:',
                    radio: {
                        value: '1',
                        prop: 'plusmodel',
                        radiodatas: [
                            {
                                value: '1',
                                label: '邀请送礼模式',
                            },
                            {
                                value: '2',
                                label: '商户分佣模式',
                            }
                        ]
                    }
                },
                {
                    label: 'PLUS会员入口:',
                    radio: {
                        value: '1',
                        prop: 'plusputin',
                        radiodatas: [
                            {
                                value: '1',
                                label: '显示',
                            },
                            {
                                value: '2',
                                label: '隐藏',
                            }
                        ]
                    }
                }
            ],

            // 支付宝-数据结构
            alipaylayoutdatas: [
                {
                    label: '打印区域风格设置:',
                    radio: {
                        value: '1',
                        prop: 'printstyle',
                        radiodatas: [
                            {
                                value: '1',
                                label: '3图样式',
                            },
                            {
                                value: '2',
                                label: '5图样式',
                            },
                            {
                                value: '3',
                                label: '6图样式',
                            },
                            {
                                value: '4',
                                label: 'tab样式',
                            }
                        ]
                    }
                },
                {
                    label: '文库位置:',
                    radio: {
                        value: '1',
                        prop: 'fileset',
                        radiodatas: [
                            {
                                value: '1',
                                label: '显示',
                            },
                            {
                                value: '2',
                                label: '隐藏',
                            }
                        ]
                    }
                },
                {
                    label: '课程位显示风格:',
                    radio: {
                        value: '1',
                        prop: 'keishow',
                        radiodatas: [
                            {
                                value: '1',
                                label: '显示',
                            },
                            {
                                value: '2',
                                label: '隐藏',
                            }
                        ],
                        child: {
                            value: '1',
                            prop: 'kestyle',
                            childdatas: [
                                {
                                    value: '1',
                                    label: '组合样式',
                                },
                                {
                                    value: '2',
                                    label: '竖版样式',
                                },
                                {
                                    value: '3',
                                    label: '横版样式',
                                }
                            ]
                        }
                    }
                },
                {
                    label: 'PLUS会员模式:',
                    radio: {
                        value: '1',
                        prop: 'plusmodel',
                        radiodatas: [
                            {
                                value: '1',
                                label: '邀请送礼模式',
                            },
                            {
                                value: '2',
                                label: '商户分佣模式',
                            }
                        ]
                    }
                },
                {
                    label: 'PLUS会员入口:',
                    radio: {
                        value: '1',
                        prop: 'plusputin',
                        radiodatas: [
                            {
                                value: '1',
                                label: '显示',
                            },
                            {
                                value: '2',
                                label: '隐藏',
                            }
                        ]
                    }
                }
            ],

            // 微信-form表单
            wechatformdata: {
                printstyle: '1',
                fileset: '2',
                keishow: '1',
                kestyle: '2',
                plusmodel: '1',
                plusputin: '2'
            },
            // 支付宝-form表单
            alipayformdata: {
                printstyle: '2',
                fileset: '1',
                keishow: '1',
                kestyle: '2',
                plusmodel: '1',
                plusputin: '1'
            },

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token
        };
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {
            // 获取相关配置配置-微信
            axios
                .post(
                    "/admin/Diy/minilayout",
                    {
                        key_name: "mini_layout_wx",
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    var getdatas = response.data.result;
                    this.wechatformdata = {
                        printstyle: getdatas.print_area_style.toString(),
                        fileset: getdatas.print_source_show.toString(),
                        keishow: getdatas.course_show.toString(),
                        kestyle: getdatas.course_show_style.toString(),
                        plusmodel: getdatas.plus_type.toString(),
                        plusputin: getdatas.plus_show.toString(),
                    }
                    this.wechatlayoutdatas = this.getInvertDataEvent(this.wechatlayoutdatas, this.wechatformdata);

                })
                .catch(function (error) {
                    console.log(error);
                });

            // 获取相关配置配置-支付宝
            axios
                .post(
                    "/admin/Diy/minilayout",
                    {
                        key_name: "mini_layout_zfb",
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    var getdatas = response.data.result;
                    this.alipayformdata = {
                        printstyle: getdatas.print_area_style.toString(),
                        fileset: getdatas.print_source_show.toString(),
                        keishow: getdatas.course_show.toString(),
                        kestyle: getdatas.course_show_style.toString(),
                        plusmodel: getdatas.plus_type.toString(),
                        plusputin: getdatas.plus_show.toString(),
                    }
                    this.alipaylayoutdatas = this.getInvertDataEvent(this.alipaylayoutdatas, this.alipayformdata);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    methods: {
        // 反显数据
        getInvertDataEvent(data, formdata) {
            var getprop = Object.keys(formdata)
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                if (element.radio.child) {
                    for (let kndex = 0; kndex < getprop.length; kndex++) {
                        const kelelemt = getprop[kndex];
                        if (kelelemt == element.radio.child.prop) {
                            element.radio.child.value = formdata[element.radio.child.prop]
                        }
                    }
                }
                for (let jndex = 0; jndex < getprop.length; jndex++) {
                    const jelelemt = getprop[jndex];
                    if (jelelemt == element.radio.prop) {
                        element.radio.value = formdata[element.radio.prop]
                    }
                }
            }
            return data
        },

        // 选中-事件
        changeRadioEvent(data, flag, prop) {
            if (flag == 'wechat') {
                this.wechatformdata[prop] = data;
            } else {
                this.alipayformdata[prop] = data;
            }
        },
        //提交form表单-并判断是否必填
        submitForm(formName) {
            let getdatas = this.$refs[formName].model;
            console.log(getdatas)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post("/admin/Diy/minilayoutUpdate",
                            {
                                key_name: formName == 'wechatformdata' ? 'mini_layout_wx' : 'mini_layout_zfb',
                                contents: {
                                    print_area_style: getdatas.printstyle,
                                    print_source_show: getdatas.fileset,
                                    course_show: getdatas.keishow,
                                    course_show_style: getdatas.kestyle,
                                    plus_type: getdatas.plusmodel,
                                    plus_show: getdatas.plusputin,
                                }
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem("token"),
                                },
                            })
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: "success",
                                    message: "提交成功！",
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
    components: {
        mainone,
    },
};
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}

.demo-ruleForm {
    margin-top: 20px;
}
</style>