<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">设置</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="5">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="11">
                            <el-form ref="ruleFormHhr" :model="ruleFormHhr" :rules="rulesHhr" label-width="200px"
                                class="demo-ruleForm">
                                <el-form-item label="金融产品名称：" prop="name">
                                    <el-input v-model="ruleFormHhr.name" placeholder="请输入金融产品名称" clearable
                                        maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item label="公司名称：" prop="companyids">
                                    <el-select v-model="ruleFormHhr.companyids" filterable multiple
                                        placeholder="请选择公司名称" style="width:100%">
                                        <el-option v-for="(item, index) in this.company_options" :key="index"
                                            :label="item.name" :value="item.id" :disabled="item.disabled" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="设备型号：" prop="printname">
                                    <el-select v-model="ruleFormHhr.printname" filterable placeholder="请选择设备型号"
                                        style="width:100%" @change="findDvcTypeEvent(ruleFormHhr.printname)">
                                        <el-option v-for="(item, index) in this.dvctype_option" :key="index"
                                            :label="item.name" :value="item.name" :disabled="item.disabled" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="租赁设备价格：" prop="lease_price">
                                    <el-input v-model="ruleFormHhr.lease_price" clearable maxlength="20"
                                        @input="changeBackmoneyEvent">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="投资租期设置：" required="true">
                                    <div class="lease-box">
                                        <div class="lease-box-day">
                                            <el-form-item prop="lease_day">
                                                <el-input v-model.number="ruleFormHhr.lease_day" maxlength="20"
                                                    clearable @input="changeBackmoneyEvent">
                                                </el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="lease-box-unit">
                                            <el-form-item prop="day_unit">
                                                <el-select v-model="ruleFormHhr.day_unit" placeholder="单位"
                                                    style="width:140px" @change="changeBackmoneyEvent">
                                                    <el-option v-for="(item, index) in this.day_unit_option"
                                                        :key="index" :label="item.label" :value="item.value" />
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item label="返还金额(总)：" prop="back_money">
                                    <el-input v-model="ruleFormHhr.back_money" clearable maxlength="20"
                                        @input="changeBackmoneyEvent">
                                    </el-input>
                                    <div class="flag-class">
                                        <!-- 天 -->
                                        <div v-if="this.ruleFormHhr.day_unit == 1">
                                            <div class="flag-class-div">每天返还金额：
                                                <span class="flag-class-span">
                                                    {{ this.backmonay_day_back_money }}
                                                </span> 元
                                            </div>
                                            <div v-if="this.isshow_last_backmonay == true" class="flag-class-div">
                                                最后一天返还金额：
                                                <span class="flag-class-span">
                                                    {{ this.backmonay_last_day_back_money }}
                                                </span>
                                                元
                                            </div>
                                        </div>
                                        <!-- 周 -->
                                        <div v-if="this.ruleFormHhr.day_unit == 2">
                                            <div class="flag-class-div">每周返还金额：
                                                <span class="flag-class-span">
                                                    {{ this.backmonay_week_back_money }}
                                                </span> 元
                                            </div>
                                            <div v-if="this.isshow_last_backmonay == true" class="flag-class-div">
                                                最后一周返还金额：
                                                <span class="flag-class-span">
                                                    {{ this.backmonay_last_week_back_money }}
                                                </span>
                                                元
                                            </div>
                                        </div>
                                        <!-- 月 -->
                                        <div v-if="this.ruleFormHhr.day_unit == 3">
                                            <div class="flag-class-div">每月返还金额：
                                                <span class="flag-class-span">
                                                    {{ this.backmonay_month_back_money }}
                                                </span> 元
                                            </div>
                                            <div v-if="this.isshow_last_backmonay == true" class="flag-class-div">
                                                最后一月返还金额：
                                                <span class="flag-class-span">
                                                    {{ this.backmonay_last_month_back_money }}
                                                </span>
                                                元
                                            </div>
                                        </div>

                                    </div>
                                </el-form-item>
                                <el-form-item label="邀请分佣比例(%)：" prop="invite_ratio">
                                    <el-input v-model="ruleFormHhr.invite_ratio" clearable maxlength="10"
                                        @input="changeBackmoneyEvent"></el-input>
                                    <div class="flag-class">
                                        <!-- 天 -->
                                        <div v-if="this.ruleFormHhr.day_unit == 1">
                                            <div class="flag-class-div">每天返还金额：
                                                <span class="flag-class-span">{{ this.bili_day_back_money }}</span> 元
                                            </div>
                                            <div v-if="this.isshow_last_bili == true" class="flag-class-div">
                                                最后一天返还金额：
                                                <span class="flag-class-span">{{ this.bili_last_day_back_money }}</span>
                                                元
                                            </div>
                                        </div>
                                        <!-- 周 -->
                                        <div v-if="this.ruleFormHhr.day_unit == 2">
                                            <div class="flag-class-div">每周返还金额：
                                                <span class="flag-class-span">{{ this.bili_week_back_money }}</span> 元
                                            </div>
                                            <div v-if="this.isshow_last_bili == true" class="flag-class-div">
                                                最后一周返还金额：
                                                <span class="flag-class-span">{{
                                                    this.bili_last_week_back_money
                                                }}</span>
                                                元
                                            </div>
                                        </div>
                                        <!-- 月 -->
                                        <div v-if="this.ruleFormHhr.day_unit == 3">
                                            <div class="flag-class-div">每月返还金额：
                                                <span class="flag-class-span">{{ this.bili_month_back_money }}</span> 元
                                            </div>
                                            <div v-if="this.isshow_last_bili == true" class="flag-class-div">
                                                最后一月返还金额：
                                                <span class="flag-class-span">{{
                                                    this.bili_last_month_back_money
                                                }}</span>
                                                元
                                            </div>
                                        </div>

                                    </div>
                                </el-form-item>
                                <el-form-item label="邀请二级分佣比例(%)：" prop="invite_two_ratio">
                                    <el-input v-model="ruleFormHhr.invite_two_ratio" clearable maxlength="10"
                                        @input="changeBackmoneyEvent"></el-input>
                                    <div class="flag-class">
                                        <!-- 天 -->
                                        <div v-if="this.ruleFormHhr.day_unit == 1">
                                            <div class="flag-class-div">每天返还金额：
                                                <span class="flag-class-span">{{ this.bili_two_day_back_money }}</span>
                                                元
                                            </div>
                                            <div v-if="this.isshow_last_bili_two == true" class="flag-class-div">
                                                最后一天返还金额：
                                                <span class="flag-class-span">{{
                                                    this.bili_two_last_day_back_money
                                                }}</span>
                                                元
                                            </div>
                                        </div>
                                        <!-- 周 -->
                                        <div v-if="this.ruleFormHhr.day_unit == 2">
                                            <div class="flag-class-div">每周返还金额：
                                                <span class="flag-class-span">{{ this.bili_two_week_back_money }}</span>
                                                元
                                            </div>
                                            <div v-if="this.isshow_last_bili_two == true" class="flag-class-div">
                                                最后一周返还金额：
                                                <span class="flag-class-span">{{
                                                    this.bili_two_last_week_back_money
                                                }}</span>
                                                元
                                            </div>
                                        </div>
                                        <!-- 月 -->
                                        <div v-if="this.ruleFormHhr.day_unit == 3">
                                            <div class="flag-class-div">每月返还金额：
                                                <span class="flag-class-span">{{
                                                    this.bili_two_month_back_money
                                                }}</span> 元
                                            </div>
                                            <div v-if="this.isshow_last_bili_two == true" class="flag-class-div">
                                                最后一月返还金额：
                                                <span class="flag-class-span">{{
                                                    this.bili_two_last_month_back_money
                                                }}</span>
                                                元
                                            </div>
                                        </div>

                                    </div>
                                </el-form-item>
                                <el-form-item label="邀请保护天数：" prop="valid_day">
                                    <el-input v-model.number="ruleFormHhr.valid_day" clearable maxlength="20">
                                    </el-input>
                                </el-form-item>
                                <!-- <el-form-item label="生效区域：" prop="valid_area">
                                  <el-select v-model="ruleFormHhr.valid_area" multiple filterable
                                      placeholder="请选择生效区域" style="width: 100%;" @focus="changeAreaEvent">
                                      <el-option v-for="item in valid_area_options" :key="item.code"
                                          :label="item.name" :value="item.code" :disabled="item.disabled" />
                                  </el-select>
                              </el-form-item> -->
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleFormHhr')"
                                        style="width: 40%; margin-right: 10px;min-width: 120px;"
                                        :disabled="buttondisable">保存
                                    </el-button>
                                    <el-button @click="resetForm('ruleFormHhr')">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from "@/components/Mainone.vue";
import { ElMessage } from "element-plus";
import axios from "axios";

export default {
    name: "vsetderivativesadd",
    data() {
        // 金额&&价格
        var checkDevicePrice = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("必填项不能为空"));
            }
            if (value.toString().indexOf(" ") != -1) {
                return callback(new Error("值中不能有空格"));
            }
            setTimeout(() => {
                // 判断字符串是否为文字
                if (isNaN(value)) {
                    callback(new Error("请输入数字"));
                } else {
                    let result = value.toString().indexOf(".");
                    if (result != -1) {
                        let getdata = value.toString().split(".");
                        if (getdata[getdata.length - 1].length > 2) {
                            callback(new Error("请保留小数点后两位"));
                        }
                    }
                    if (value < 0) {
                        callback(new Error("值需大于0"));
                    } else {
                        callback();
                    }
                }
            }, 500);
        };

        // 邀请保护天数 & 投资租期天数
        var checkBaohuDays = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("必填项不能为空"));
            }
            if (value.toString().indexOf(" ") != -1) {
                return callback(new Error("值中不能有空格"));
            }
            setTimeout(() => {
                // 判断字符串是否存在小数点
                let result = value.toString().indexOf(".");
                if (result != -1) {
                    callback(new Error("请输入整数"));
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error("请输入整数"));
                    } else {
                        // 判断字符串是否为数字
                        if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
                            callback(new Error("请输入整数"));
                        } else {
                            if (value < 0) {
                                callback(new Error("值需大于等于0"));
                            } else {
                                callback();
                            }
                        }
                    }
                }
            }, 500);
        };

        // 邀请分佣比例0~100
        var checkBackMoney = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("比例值不能为空"));
            }
            if (value.toString().indexOf(" ") != -1) {
                return callback(new Error("值中不能有空格"));
            }
            setTimeout(() => {
                // 判断字符串是否存在小数点
                let result = value.toString().indexOf(".");
                if (result != -1) {
                    callback(new Error("请输入整数"));
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error("请输入整数"));
                    } else {
                        // 判断字符串是否为数字
                        if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
                            callback(new Error("请输入整数"));
                        } else {
                            if (value > 100) {
                                callback(new Error("值需小于等于100"));
                            } else {
                                if (value < 0) {
                                    callback(new Error("值需大于等于0"));
                                } else {
                                    callback();
                                }
                            }
                        }
                    }
                }
            }, 500);
        };

        return {
            mainonebread: [
                {
                    name: "金融产品",
                },
                {
                    name: "设置",
                },
                {
                    name: "金融产品设置",
                    path: "/setderivativeslist",
                },
                {
                    name: "添加",
                },
            ], // 当前页-【面包屑导航】

            // 打印机类型-下拉选项
            dvctype_option: [],
            // form表单
            ruleFormHhr: {
                name: '',
                companyids: [],
                printname: "",
                lease_price: "",
                lease_day: "",
                day_unit: "",
                back_money: "",
                invite_ratio: "",
                invite_two_ratio: "",
                valid_day: "",
                // valid_area: "",
            },
            // form表单-必填规则
            rulesHhr: {
                name: [
                    {
                        required: true,
                        message: "金融产品名称不能为空",
                        trigger: "blur",
                    },
                ],
                companyids: [
                    {
                        required: true,
                        message: "公司名称不能为空",
                        trigger: "blur",
                    },
                ],
                printname: [
                    {
                        required: true,
                        message: "设备型号不能为空",
                        trigger: "change",
                    },
                ],
                lease_price: [
                    {
                        required: true,
                        validator: checkDevicePrice,
                        trigger: "blur",
                    },
                ],
                lease_day: [
                    {
                        required: true,
                        validator: checkBaohuDays,
                        trigger: "blur",
                    },
                ],
                day_unit: [
                    {
                        required: true,
                        message: "投资租期单位为必选项",
                        trigger: "blur",
                    },
                ],
                back_money: [
                    {
                        required: true,
                        validator: checkDevicePrice,
                        trigger: "blur",
                    },
                ],
                invite_ratio: [
                    {
                        required: true,
                        validator: checkBackMoney,
                        trigger: "blur",
                    },
                ],
                invite_two_ratio: [
                    {
                        required: true,
                        validator: checkBackMoney,
                        trigger: "blur",
                    },
                ],
                valid_day: [
                    {
                        required: true,
                        validator: checkBaohuDays,
                        trigger: "blur",
                    },
                ],
                valid_area: [
                    {
                        required: true,
                        message: "生效区域为必选项",
                        trigger: "blur",
                    },
                ],
            },

            // 投资租期-单位
            day_unit_option: [
                {
                    label: '天',
                    value: '1',
                },
                {
                    label: '周',
                    value: '2',
                },
                {
                    label: '月',
                    value: '3',
                }
            ],

            // 生效区域
            valid_area_options: [
                {
                    name: 'Option2',
                    code: 'Option2',
                },
            ],

            // 公司名称-下拉
            company_options: [],

            // 【返还金额】-每天返还金额
            backmonay_day_back_money: '0.00',
            // 【返还金额】-最后一天返还金额
            backmonay_last_day_back_money: '0.00',
            // 【返还金额】-每周返还金额
            backmonay_week_back_money: '0.00',
            // 【返还金额】-最后一周返还金额
            backmonay_last_week_back_money: '0.00',
            // 【返还金额】-每月返还金额
            backmonay_month_back_money: '0.00',
            // 【返还金额】-最后一月返还金额
            backmonay_last_month_back_money: '0.00',


            // 【邀请分佣比例】-每天返还金额
            bili_day_back_money: '0.00',
            // 【邀请分佣比例】-最後一天返还金额
            bili_last_day_back_money: '0.00',
            // 【邀请分佣比例】-每天返还金额
            bili_week_back_money: '0.00',
            // 【邀请分佣比例】-最後一天返还金额
            bili_last_week_back_money: '0.00',
            // 【邀请分佣比例】-每天返还金额
            bili_month_back_money: '0.00',
            // 【邀请分佣比例】-最後一天返还金额
            bili_last_month_back_money: '0.00',

            // 【邀请二级分佣比例】-每天返还金额
            bili_two_day_back_money: '0.00',
            // 【邀请二级分佣比例】-最後一天返还金额
            bili_two_last_day_back_money: '0.00',
            // 【邀请二级分佣比例】-每天返还金额
            bili_two_week_back_money: '0.00',
            // 【邀请二级分佣比例】-最後一天返还金额
            bili_two_last_week_back_money: '0.00',
            // 【邀请二级分佣比例】-每天返还金额
            bili_two_month_back_money: '0.00',
            // 【邀请二级分佣比例】-最後一天返还金额
            bili_two_last_month_back_money: '0.00',

            // 控制最后一天返还金额是否显示
            isshow_last_backmonay: false,
            isshow_last_bili: false,
            isshow_last_bili_two: false,

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token
            myuserinfo: localStorage.getItem("userinfo"), //获取存储的用户信息

            buttondisable: false, // 前端防抖
        };
    },
    methods: {
        // 返还金额&&邀请分佣比例-input事件
        changeBackmoneyEvent() {
            this.$refs['ruleFormHhr'].validateField(['lease_day', 'day_unit'], (valid) => {
                if (valid == '') {
                    this.$refs['ruleFormHhr'].validateField('back_money', (myvalid) => {
                        if (myvalid == '') {
                            // 天
                            if (this.ruleFormHhr.day_unit == 1) {
                                let pingjun = this.ruleFormHhr.back_money / this.ruleFormHhr.lease_day;
                                let day_back_money = this.dataJieEvent(pingjun, 2);
                                this.backmonay_day_back_money = parseFloat(day_back_money).toFixed(2);
                                this.backmonay_last_day_back_money = (this.ruleFormHhr.back_money - (parseFloat(day_back_money) * (parseInt(this.ruleFormHhr.lease_day) - 1))).toFixed(2);

                                if (this.backmonay_day_back_money == this.backmonay_last_day_back_money) {
                                    this.isshow_last_backmonay = false;
                                } else {
                                    this.isshow_last_backmonay = true;
                                }
                            }

                            // 周
                            if (this.ruleFormHhr.day_unit == 2) {
                                let pingjun = this.ruleFormHhr.back_money / this.ruleFormHhr.lease_day;
                                let day_back_money = this.dataJieEvent(pingjun, 2);
                                this.backmonay_week_back_money = parseFloat(day_back_money).toFixed(2);
                                this.backmonay_last_week_back_money = (this.ruleFormHhr.back_money - (parseFloat(day_back_money) * (parseInt(this.ruleFormHhr.lease_day) - 1))).toFixed(2);

                                if (this.backmonay_week_back_money == this.backmonay_last_week_back_money) {
                                    this.isshow_last_backmonay = false;
                                } else {
                                    this.isshow_last_backmonay = true;
                                }
                            }

                            // 月
                            if (this.ruleFormHhr.day_unit == 3) {
                                let pingjun = this.ruleFormHhr.back_money / this.ruleFormHhr.lease_day;
                                let day_back_money = this.dataJieEvent(pingjun, 2);
                                this.backmonay_month_back_money = parseFloat(day_back_money).toFixed(2);
                                this.backmonay_last_month_back_money = (this.ruleFormHhr.back_money - (parseFloat(day_back_money) * (parseInt(this.ruleFormHhr.lease_day) - 1))).toFixed(2);

                                if (this.backmonay_month_back_money == this.backmonay_last_month_back_money) {
                                    this.isshow_last_backmonay = false;
                                } else {
                                    this.isshow_last_backmonay = true;
                                }
                            }
                        }
                    })

                }
            })

            this.$refs['ruleFormHhr'].validateField(['lease_day', 'lease_price', 'day_unit'], (valid) => {
                if (valid == '') {
                    this.$refs['ruleFormHhr'].validateField('invite_ratio', (myvalid) => {
                        if (myvalid == '') {
                            // 天
                            if (this.ruleFormHhr.day_unit == 1) {
                                let all_fy = this.ruleFormHhr.lease_price * this.ruleFormHhr.invite_ratio / 100;
                                let pingjun = all_fy / this.ruleFormHhr.lease_day;
                                let one_1_23_back_money = this.dataJieEvent(pingjun, 2);
                                this.bili_day_back_money = parseFloat(one_1_23_back_money).toFixed(2);
                                this.bili_last_day_back_money = (all_fy - (one_1_23_back_money * (this.ruleFormHhr.lease_day - 1))).toFixed(2);

                                if (this.bili_day_back_money == this.bili_last_day_back_money) {
                                    this.isshow_last_bili = false;
                                } else {
                                    this.isshow_last_bili = true;
                                }
                            }
                            // 周
                            if (this.ruleFormHhr.day_unit == 2) {
                                let all_fy = this.ruleFormHhr.lease_price * this.ruleFormHhr.invite_ratio / 100;
                                let pingjun = all_fy / this.ruleFormHhr.lease_day;
                                let one_1_23_back_money = this.dataJieEvent(pingjun, 2);
                                this.bili_week_back_money = parseFloat(one_1_23_back_money).toFixed(2);
                                this.bili_last_week_back_money = (all_fy - (one_1_23_back_money * (this.ruleFormHhr.lease_day - 1))).toFixed(2);

                                if (this.bili_week_back_money == this.bili_last_week_back_money) {
                                    this.isshow_last_bili = false;
                                } else {
                                    this.isshow_last_bili = true;
                                }
                            }
                            // 月
                            if (this.ruleFormHhr.day_unit == 3) {
                                let all_fy = this.ruleFormHhr.lease_price * this.ruleFormHhr.invite_ratio / 100;
                                let pingjun = all_fy / this.ruleFormHhr.lease_day;
                                let one_1_23_back_money = this.dataJieEvent(pingjun, 2);
                                this.bili_month_back_money = parseFloat(one_1_23_back_money).toFixed(2);
                                this.bili_last_month_back_money = (all_fy - (one_1_23_back_money * (this.ruleFormHhr.lease_day - 1))).toFixed(2);

                                if (this.bili_month_back_money == this.bili_last_month_back_money) {
                                    this.isshow_last_bili = false;
                                } else {
                                    this.isshow_last_bili = true;
                                }
                            }
                        }
                    })

                    this.$refs['ruleFormHhr'].validateField('invite_two_ratio', (myvalid1) => {
                        if (myvalid1 == '') {
                            // 天
                            if (this.ruleFormHhr.day_unit == 1) {
                                let all_fy = this.ruleFormHhr.lease_price * this.ruleFormHhr.invite_two_ratio / 100;
                                let pingjun = all_fy / this.ruleFormHhr.lease_day;
                                let one_1_23_back_money = this.dataJieEvent(pingjun, 2);
                                this.bili_two_day_back_money = parseFloat(one_1_23_back_money).toFixed(2);
                                this.bili_two_last_day_back_money = (all_fy - (one_1_23_back_money * (this.ruleFormHhr.lease_day - 1))).toFixed(2);

                                if (this.bili_two_day_back_money == this.bili_two_last_day_back_money) {
                                    this.isshow_last_bili_two = false;
                                } else {
                                    this.isshow_last_bili_two = true;
                                }
                            }
                            // 周
                            if (this.ruleFormHhr.day_unit == 2) {
                                let all_fy = this.ruleFormHhr.lease_price * this.ruleFormHhr.invite_two_ratio / 100;
                                let pingjun = all_fy / this.ruleFormHhr.lease_day;
                                let one_1_23_back_money = this.dataJieEvent(pingjun, 2);
                                this.bili_two_week_back_money = parseFloat(one_1_23_back_money).toFixed(2);
                                this.bili_two_last_week_back_money = (all_fy - (one_1_23_back_money * (this.ruleFormHhr.lease_day - 1))).toFixed(2);

                                if (this.bili_two_week_back_money == this.bili_two_last_week_back_money) {
                                    this.isshow_last_bili_two = false;
                                } else {
                                    this.isshow_last_bili_two = true;
                                }
                            }
                            // 月
                            if (this.ruleFormHhr.day_unit == 3) {
                                let all_fy = this.ruleFormHhr.lease_price * this.ruleFormHhr.invite_two_ratio / 100;
                                let pingjun = all_fy / this.ruleFormHhr.lease_day;
                                let one_1_23_back_money = this.dataJieEvent(pingjun, 2);
                                this.bili_two_month_back_money = parseFloat(one_1_23_back_money).toFixed(2);
                                this.bili_two_last_month_back_money = (all_fy - (one_1_23_back_money * (this.ruleFormHhr.lease_day - 1))).toFixed(2);

                                if (this.bili_two_month_back_money == this.bili_two_last_month_back_money) {
                                    this.isshow_last_bili_two = false;
                                } else {
                                    this.isshow_last_bili_two = true;
                                }
                            }
                        }
                    })
                }
            })
        },


        // 数据截取-非四色五人
        dataJieEvent(data, length) {
            let result = data.toString().indexOf(".");
            if (result != -1) {
                var data_array = data.toString().split('.');
                var last_2 = data_array[1].slice(0, length);
                var getdata = data_array[0] + '.' + last_2;
                return getdata;
            } else {
                return data;
            }
        },

        //from表单-提交表单
        submitForm(formName) {
            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
            this.buttondisable = true;
            var getdatas = this.$refs[formName].model;
            console.log("当前获取的formdata：" + JSON.stringify(getdatas));
            console.log(getdatas.companyids.join(','))
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 判断分佣比例&二级分佣比例-想加不能超过100
                    if (((parseInt(getdatas.invite_ratio * 100)/100 ) + (parseInt(getdatas.invite_two_ratio * 100)/100)) > 100) {
                        ElMessage({ type: "error", message: "邀请一二级分佣比例之和不能超过100！" });
                        this.buttondisable = false;
                        return
                    }

                    axios
                        .post(
                            "/admin/Lease/config",
                            {
                                name: getdatas.name,
                                companyids: getdatas.companyids.join(','),
                                printname: getdatas.printname,
                                lease_price: getdatas.lease_price * 100,
                                lease_day: getdatas.lease_day,
                                day_unit: getdatas.day_unit,
                                back_money: getdatas.back_money * 100,
                                invite_ratio: getdatas.invite_ratio,
                                invite_two_ratio: getdatas.invite_two_ratio,
                                valid_day: getdatas.valid_day,
                                // valid_area: getdatas.valid_area.join(','),
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: "success",
                                    message: "保存成功！",
                                    duration: 1000,
                                    onClose: () => {
                                        // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                        this.buttondisable = false;
                                        this.$router.go(-1);
                                    },
                                });
                            } else {
                                // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                this.buttondisable = false;
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                            this.buttondisable = false;
                        });
                } else {
                    console.log("error submit!!");
                    // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                    this.buttondisable = false;
                    return false;
                }
            });
        },
        //from表单-重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields();

            this.backmonay_day_back_money = '0.00';
            this.backmonay_last_day_back_money = '0.00';
            this.backmonay_week_back_money = '0.00';
            this.backmonay_last_week_back_money = '0.00';
            this.backmonay_month_back_money = '0.00';
            this.backmonay_last_month_back_money = '0.00';

            this.bili_day_back_money = '0.00';
            this.bili_last_day_back_money = '0.00';
            this.bili_week_back_money = '0.00';
            this.bili_last_week_back_money = '0.00';
            this.bili_month_back_money = '0.00';
            this.bili_last_month_back_money = '0.00';

            this.bili_two_day_back_money = '0.00';
            this.bili_two_last_day_back_money = '0.00';
            this.bili_two_week_back_money = '0.00';
            this.bili_two_last_week_back_money = '0.00';
            this.bili_two_month_back_money = '0.00';
            this.bili_two_last_month_back_money = '0.00';

            this.isshow_last_backmonay = false;
            this.isshow_last_bili = false;
            this.isshow_last_bili_two = false;
        },

        // 选择设备型号-触发
        findDvcTypeEvent(data) {
            // 加载-生效区域-省-下拉数据
            var p1 = axios
                .get("/admin/getArea", {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                    params: {},
                })
                .then((response) => {
                    this.valid_area_options = response.data.result.list;
                })
                .catch(function (error) {
                    console.log(error);
                });


            Promise.all([p1]).then(() => {
                setTimeout(() => {
                    axios
                        .get("/admin/lease/configInfo", {
                            headers: {
                                Authentication: localStorage.getItem("token"),
                            },
                            params: {
                                printname: data,
                            },
                        })
                        .then((response) => {
                            if (response.data.code == 0) {
                                let getdata = response.data.result;

                                // 生效区域-禁选
                                if (getdata.notUseArea && getdata.notUseArea.length > 0) {
                                    getdata.notUseArea.forEach(element => {
                                        this.valid_area_options.map(n => {
                                            if (n.code == element) {
                                                let data = n;
                                                data['disabled'] = true;
                                                return data;
                                            }
                                        })
                                    });
                                }

                                // // 设备型号-禁选
                                // if (getdata.notUsePrintName && getdata.notUsePrintName.length > 0) {
                                //     getdata.notUsePrintName.forEach(element => {
                                //         this.dvctype_option.map(n => {
                                //             if (n.name == element) {
                                //                 let data = n;
                                //                 data['disabled'] = true;
                                //                 return data;
                                //             }
                                //         })
                                //     });
                                // }

                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }, 100);
            }).catch((error) => {
                console.log('error:失败:', error)
            })
        },

        // 选择生效区域-触发
        changeAreaEvent() {
            this.$refs['ruleFormHhr'].validateField(['printname'], (valid) => {
                if (valid == '') {
                    // console.log(this.valid_area_options)
                } else {
                    this.valid_area_options = [];
                    ElMessage({ type: "warning", message: "请选择设备型号后，再选择生效区域！" });
                }
            })
        },

        // 获取生效区域下拉
        getAreaData() {
            // 加载-生效区域-省-下拉数据
            axios
                .get("/admin/getArea", {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                    params: {},
                })
                .then((response) => {
                    this.valid_area_options = response.data.result.list;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        // 获取公司名称-下拉
        getCompanyData() {
            // 加载-公司名称-下拉数据
            axios
                .get("/admin/Lease/config", {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                    params: {},
                })
                .then((response) => {
                    let companyoptins = response.data.result;
                    this.company_options = companyoptins.map(n=>{n.id = n.id.toString(); return n});
                })
                .catch(function (error) {
                    console.log(error);
                });
        }


    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        }
        else {
            // 加载-公司名称-下拉数据
            this.getCompanyData();

            // 加载-设备型号-下拉数据
            axios
                .get("/admin/Equipment/getPrintName", {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                    params: {},
                })
                .then((response) => {
                    this.dvctype_option = response.data.result.map(n => {
                        let data = {
                            name: n
                        };
                        return data;
                    })
                })
                .catch(function (error) {
                    console.log(error);
                });

        }
    },
    components: {
        mainone,
    },
};
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #97a0c3;
    padding: 5px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
}

.flag-class {
    line-height: 20px;
    margin-top: 2px;
}

.flag-class-div {
    font-size: 12px;
    color: #888888;
}

.flag-class-span {
    color: #e6b33c;
    font-size: 14px;
    font-weight: 600;
}

.lease-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.lease-box-day {
    width: 100%;
    margin-right: 20px;
}
</style>
