<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">编辑</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="7">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="150px" class="demo-ruleForm">
                                <el-form-item label="标题：<title>" prop="title">
                                    <el-input v-model.trim="ruleForm.title" maxlength="20"></el-input>
                                </el-form-item>
                                <el-form-item label="描述：<description>" prop="description">
                                    <el-input v-model="ruleForm.description" type="textarea" maxlength="50" show-word-limit :autosize="{ minRows: 4 }" placeholder="请输入详情说明" />
                                </el-form-item>
                                <el-form-item label="图片：<image>" prop="image">
                                    <mainimgesup
                                        v-model="ruleForm.image"
                                        :myimgesup="myimgesup"
                                        :myimgesupisshow="myimgesupisshow"
                                        :filelist="ruleForm.image_list"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                                        v-on:deluploadfile="deluploadfileEvent"
                                    >
                                    </mainimgesup>
                                </el-form-item>
                                <el-form-item label="生成链接：">
                                    <div>{{ ruleForm.create_url }}</div>
                                </el-form-item>
                                <el-form-item label="要跳转链接：" prop="jump_url">
                                    <el-radio-group v-model="ruleForm.jump_way">
                                        <el-radio label="1">微信小程序</el-radio>
                                        <el-radio label="2">普通链接</el-radio>
                                    </el-radio-group>
                                    <el-input v-model="ruleForm.jump_url" maxlength="500" placeholder="请输入跳转链接"/>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import mainimgesup from "@/components/Mainimgesup.vue"
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'vlinktitleedit',
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '企业微信'
                },
                {
                    name: '链接标题语'
                },
                {
                    name: '编辑'
                }
            ], // 当前页-【面包屑导航】

            myimgesup: {
                uploadsize: 2, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: "/admin/upload/file", //upload上传API地址
                headers: { Authentication: localStorage.getItem("token") }, //upload上传headers
                title: "点击上传", //button-按钮文字
                tips: "建议尺寸：200*200px像素，png、jpg、jpeg格式", //提示&建议文字
                limit: "1", //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 2000, //文件大小（单位kb）
                        type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
                        typechart: "jpg/png", //文件格式提示规则
                    },
                },
            },

            ruleForm: {
                id: "",
                title: "",
                description: "",
                image: "",
                image_list: [],
                jump_way: "1",
                jump_url: "",
                create_url: "",
            }, //form表单
            rules: {
                title: [
                    {
                        required: true,
                        message: "标题为必填项",
                        trigger: "blur",
                    },
                ],
                description: [
                    {
                        required: true,
                        message: "描述为必填项",
                        trigger: "blur",
                    },
                ],
                jump_url: [
                    {
                        required: true,
                        message: "跳转链接为必填项",
                        trigger: "blur",
                    },
                ],
                image: [
                    {
                        required: true,
                        message: "请上传图片",
                        trigger: "change",
                    },
                ],
            }, //form表单-必填规则

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token
        }
    },

    methods: {
        //from表单-提交表单
        submitForm(formName) {
            var getdata = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/admin/passiveReply/edit',
                            {
                                id: this.ruleForm.id,
                                title: getdata.title,
                                description: getdata.description,
                                image: getdata.image,
                                jump_way: getdata.jump_way,
                                jump_url: getdata.jump_url,
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.go(-1) // 返回上一页
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        getDetail() {
            const { id } = this.$route.query
            axios.post('/admin/passiveReply/info',
                {
                    id,
                },
                {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                }
            )
            .then((response) => {
                const res = response.data.result
                Object.assign(this.ruleForm, res)
                this.ruleForm.jump_way = String(res.jump_way)
                this.ruleForm.image_list = [{ name: '', url: res.image_url}]
            })
            .catch(function(error) {
                console.log(error)
            })
        },

        //获取upload子组件回传的数据
        getUploadFileEvent(data) {
            this.ruleForm.image = data.id
        },

        //删除上传的文件-事件
        deluploadfileEvent(data) {
            this.ruleForm.image_list = data
            this.ruleForm.image = data.id ? data.id : ''
        },

        //from表单-重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields()
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        }
        this.getDetail();
    },
    components: {
        mainone,
        mainimgesup,
    }
})
</script>
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
