<template>
  <!--柱状图-->

  <div v-if="getData.columnisshow" class="first">

    <div class="btn_group">
      <a class="btn" style="cursor: pointer" @click="setyestoday()">昨日</a>
      <a class="btn" style="cursor: pointer" @click="setDay('onlion')">本日</a>
      <a class="btn" style="cursor: pointer" @click="setWeek('onlion')">本周</a>
      <a class="btn" style="cursor: pointer" @click="setMonth('onlion')"
        >本月</a
      >
      <div>
      <mainselect
        :myselectleftisshow="myselectleftisshow"
        :myselectleft="myselectleft"
        v-on:gettimesrange="gettimesrangeEvent"
        @getData="getoverview"
      ></mainselect>
    </div>
    </div>
    
    <div v-for="(mycolumdatainfo, i) in Columndata" :key="i" class="second">
      <div
        :id="mycolumdatainfo.idname"
        :style="{
          width: mycolumdatainfo.width,
          height: mycolumdatainfo.height,
        }"
      ></div>
    </div>
  </div>
  <!--仪表盘-半圆-->
  <div v-if="getData.mypanelisshow">
    <div v-for="(mypaneldatainfo, j) in myPaneldata" :key="j">
      <div
        :id="mypaneldatainfo.idname"
        :style="{
          width: mypaneldatainfo.width,
          height: mypaneldatainfo.height,
        }"
      ></div>
    </div>
  </div>
  <!--仪表盘-圆盘-->
  <div v-if="getData.mycustompanelisshow">
    <div v-for="(mycustompaneldatainfo, n) in myCustomPaneldata" :key="n">
      <div
        :id="mycustompaneldatainfo.idname"
        :style="{
          width: mycustompaneldatainfo.width,
          height: mycustompaneldatainfo.height,
        }"
      ></div>
    </div>
  </div>
  <!--饼状图-圆角-->
  <div v-if="getData.filletcakeisshow" class="first">
    <div class="btn_group">
      <a class="btn" style="cursor: pointer" @click="setyestoday()">昨日</a>
      <a class="btn" style="cursor: pointer" @click="setDay('onlion')">本日</a>
      <a class="btn" style="cursor: pointer" @click="setWeek('onlion')">本周</a>
      <a class="btn" style="cursor: pointer" @click="setMonth('onlion')"
        >本月</a
      >
      <mainselect
        :myselectleftisshow="myselectleftisshow"
        :myselectleft="myselectleft"
        v-on:gettimesrange="gettimesrangeEvent"
      ></mainselect>
    </div>
    <div></div>
    <div
      v-for="(filletcakedatainfo, k) in filletCakedata"
      :key="k"
      class="second"
    >
      <div
        :id="filletcakedatainfo.idname"
        :style="{
          width: filletcakedatainfo.width,
          height: filletcakedatainfo.height,
        }"
      ></div>
    </div>
  </div>
  <!--饼状图-自定义样式-->
  <div v-if="getData.customcakeisshow">
    <div v-for="(customcakedatainfo, l) in Customcakedata" :key="l">
      <div
        :id="customcakedatainfo.idname"
        :style="{
          width: customcakedatainfo.width,
          height: customcakedatainfo.height,
        }"
      ></div>
    </div>
  </div>
  <!--折现图-渐变堆叠-->
  <div v-if="getData.linegradualisshow" class="first">
    <div class="btn_group">
      <a class="btn" style="cursor: pointer" @click="setDay()">本日</a>
      <a class="btn" style="cursor: pointer" @click="setWeek()">本周</a>
      <a class="btn" style="cursor: pointer" @click="setMonth()">本月</a>
      <a class="btn" style="cursor: pointer" @click="setYear()">本年</a>
      <mainselect
        :myselectleftisshow="myselectleftisshow"
        :myselectleft="myselectleft"
        v-on:gettimesrange="gettimesrangeEvent"
      ></mainselect>
    </div>
    <div
      v-for="(linegradualdatainfo, m) in lineGradualdata"
      :key="m"
      class="second"
    >
      <div
        :id="linegradualdatainfo.idname"
        :style="{
          width: linegradualdatainfo.width,
          height: linegradualdatainfo.height,
        }"
      ></div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { onMounted, reactive } from "vue";
import mainselect from "./Mainselect";
import { ref } from "vue";
// import dayjs from "dayjs";
export default {
  name: "Mainecharts",
  props: [
    "columnisshow",
    "columndata",
    "mypanelisshow",
    "mypaneldata",
    "mycustompanelisshow",
    "mycustompaneldata",
    "filletcakeisshow",
    "filletcakedata",
    "customcakeisshow",
    "customcakedata",
    "linegradualisshow",
    "linegradualdata",
    "linegradualdatauser",
  ],
  components: {
    mainselect
  },
  setup(props) {
    const Columnisshow = reactive(props["columnisshow"]);
    const Columndata = reactive(props["columndata"]);
    const myPanelisshow = reactive(props["mypanelisshow"]);
    const myPaneldata = reactive(props["mypaneldata"]);
    const myCustompanelisshow = reactive(props["mycustompanelisshow"]);
    const myCustomPaneldata = reactive(props["mycustompaneldata"]);
    const filletCakeisshow = reactive(props["filletcakeisshow"]);
    const filletCakedata = reactive(props["filletcakedata"]);
    const Customcakeisshow = reactive(props["customcakeisshow"]);
    const Customcakedata = reactive(props["customcakedata"]);
    const lineGradualisshow = reactive(props["linegradualisshow"]);
    const lineGradualdata = reactive(props["linegradualdata"]);
    //定义数据数组等
    const datePicker = ref("");
    let getData = reactive({
      columnisshow: false, //柱状图
      mypanelisshow: false, //仪表盘-半圆
      mycustompanelisshow: false, //仪表盘-圆盘
      filletcakeisshow: false, //饼状图-圆角
      customcakeisshow: false, //饼状图-自定义样式
      linegradualisshow: false, //折现图-渐变堆叠
    });

    //柱状图
    if (Columnisshow) {
      getData.columnisshow = Columnisshow;
      if (Columndata) {
        onMounted(() => {
          if (Columndata.length) {
            for (var i = 0; i < Columndata.length; i++) {
              echartInit(Columndata, i);
            }
          }
        });
      }
    } else {
      getData.columnisshow = false;
    }
    //仪表盘-半圆
    if (myPanelisshow) {
      getData.mypanelisshow = myPanelisshow;
      if (myPaneldata) {
        onMounted(() => {
          if (myPaneldata.length) {
            for (var i = 0; i < myPaneldata.length; i++) {
              echartPanel(myPaneldata, i);
            }
          }
        });
      }
    } else {
      getData.mypanelisshow = false;
    }
    //仪表盘-圆盘
    if (myCustompanelisshow) {
      getData.mycustompanelisshow = myCustompanelisshow;
      if (myCustomPaneldata) {
        onMounted(() => {
          if (myCustomPaneldata.length) {
            for (var i = 0; i < myCustomPaneldata.length; i++) {
              echartCustomPanel(myCustomPaneldata, i);
            }
          }
        });
      }
    } else {
      getData.mycustompanelisshow = false;
    }
    //饼状图-圆角
    if (filletCakeisshow) {
      getData.filletcakeisshow = filletCakeisshow;
      if (filletCakedata) {
        onMounted(() => {
          if (filletCakedata.length) {
            for (var i = 0; i < filletCakedata.length; i++) {
              echartFillet(filletCakedata, i);
            }
          }
        });
      }
    } else {
      getData.filletcakeisshow = false;
    }
    //饼状图-自定义样式
    if (Customcakeisshow) {
      getData.customcakeisshow = Customcakeisshow;
      if (Customcakedata) {
        onMounted(() => {
          if (Customcakedata.length) {
            for (var i = 0; i < Customcakedata.length; i++) {
              echartCustom(Customcakedata, i);
            }
          }
        });
      }
    } else {
      getData.customcakeisshow = false;
    }
    //折现图-渐变堆叠
    if (lineGradualisshow) {
      getData.linegradualisshow = lineGradualisshow;
      if (lineGradualdata) {
        onMounted(() => {
          if (lineGradualdata.length) {
            for (var i = 0; i < lineGradualdata.length; i++) {
              echartGradual(lineGradualdata, i);
            }
          }
        });
      }
    } else {
      getData.linegradualisshow = false;
    }

    //柱状图
    const echartInit = (datas, ide) => {
      var myChart = echarts.init(document.getElementById(datas[ide].idname));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: datas[ide].text,
          subtext: datas[ide].subtext,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["Rainfall"],
        },
        toolbox: {
          show: true,
          feature: {
            // dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            // data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            data: datas[ide].subtextx,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "在线时长",
            type: "bar",
            // data: [9.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
            data: datas[ide].subdata,
            markPoint: {
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
            itemStyle: {
              color: datas[ide].subcolor,
            },
          },
        ],
      };
      myChart.setOption(option); // 使用刚指定的配置项和数据显示图表。
      window.onresize = myChart.resize;
    };
    // 仪表盘-半圆
    const echartPanel = (datas, ide) => {
      var myChart = echarts.init(document.getElementById(datas[ide].idname));
      var option = {
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            progress: {
              show: true,
              width: 10,
            },
            axisLine: {
              lineStyle: {
                width: 10,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              length: 4,
              lineStyle: {
                width: 1,
                color: "#999",
              },
            },
            axisLabel: {
              distance: -35,
              color: "#999",
              fontSize: 12,
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 15,
              itemStyle: {
                borderWidth: 4,
                borderColor: datas[ide].subcolor,
              },
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              color: datas[ide].subcolor,
              fontSize: 30,
              offsetCenter: [0, "40%"],
              formatter: function (value) {
                return Math.round(value) + "%";
              },
            },
            itemStyle: {
              color: datas[ide].subcolor,
            },
            data: [
              {
                value: datas[ide].data,
              },
            ],
          },
        ],
      };

      myChart.setOption(option);
      window.onresize = myChart.resize;
    };
    // 仪表盘-圆盘-（待更新）
    const echartCustomPanel = (datas, ide) => {
      var myChart = echarts.init(document.getElementById(datas[ide].idname));
      var option = {
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            progress: {
              show: true,
              width: 18,
            },
            axisLine: {
              lineStyle: {
                width: 18,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              length: 10,
              lineStyle: {
                width: 1,
                color: "#999",
              },
            },
            axisLabel: {
              distance: -45,
              color: "#999",
              fontSize: 14,
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 20,
              itemStyle: {
                borderWidth: 6,
                borderColor: datas[ide].subcolor,
              },
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              color: datas[ide].subcolor,
              fontSize: 40,
              offsetCenter: [0, "40%"],
              formatter: function (value) {
                return Math.round(value) + "%";
              },
            },
            itemStyle: {
              color: datas[ide].subcolor,
            },
            data: [
              {
                value: datas[ide].data,
              },
            ],
          },
        ],
      };

      myChart.setOption(option);
      window.onresize = myChart.resize;
    };
    //饼状图-圆角
    const echartFillet = (datas, ide) => {
      var myChart = echarts.init(document.getElementById(datas[ide].idname));
      var option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "bottom",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: true,
              position: "outside",
            },

            labelLine: {
              normal: {
                show: true,
                length: 20,
                length2: 20,
              },
            },

            data: datas[ide].data,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = myChart.resize;
    };
    //饼状图-自定义
    const echartCustom = (datas, ide) => {
      console.log("获取的饼状图自定义" + datas);
      var myChart = echarts.init(document.getElementById(datas[ide].idname));
      var option = {
        // backgroundColor: '#2c343c',
        title: {
          text: datas[ide].text, //标题
          left: "center",
          top: 20,
          textStyle: {
            color: datas[ide].textcolor, //字体颜色
          },
        },
        tooltip: {
          trigger: "item",
        },
        visualMap: {
          show: false,
          min: 80,
          max: 600,
          inRange: {
            colorLightness: [0, 1],
          },
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "55%",
            center: ["50%", "50%"],
            data: datas[ide].data.sort(function (a, b) {
              return a.value - b.value;
            }),
            roseType: "radius",
            label: {
              color: datas[ide].subcolor, //解释字颜色
            },
            labelLine: {
              smooth: 0.2,
              length: 10,
              length2: 20,
            },
            itemStyle: {
              color: datas[ide].subcolor, //板块颜色
            },
            animationType: "scale",
            animationEasing: "elasticOut",
          },
        ],
      };

      myChart.setOption(option);
      window.onresize = myChart.resize;
    };
    //折线-渐变堆叠
    const echartGradual = (datas, ide) => {
      console.log("获取的折线-渐变堆叠:" + datas);
      var myChart = echarts.init(document.getElementById(datas[ide].idname));
      var option = {
        color: datas[ide].maincolor,
        title: {
          text: datas[ide].text,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985", //选中-下标模块颜色
            },
          },
        },
        legend: {
          data: datas[ide].legenddata,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: datas[ide].gridleft,
          right: datas[ide].gridright,
          bottom: datas[ide].gridbottom,
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: datas[ide].subtextx,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: datas[ide].data,
      };

      myChart.setOption(option);
      window.onresize = myChart.resize;
    };

    return {
      datePicker,
      getData,
      Columnisshow,
      Columndata,
      myPanelisshow,
      myPaneldata,
      myCustompanelisshow,
      myCustomPaneldata,
      filletCakeisshow,
      filletCakedata,
      Customcakeisshow,
      Customcakedata,
      lineGradualisshow,
      lineGradualdata,

      echartInit,
      echartPanel,
      echartCustomPanel,
      echartFillet,
      echartCustom,
      echartGradual,
    };
  },
  data() {
    return {
      myselectleftisshow: true, //筛选查询-左侧字+select单框||select时间
      myselectleft: {
        width: "none",
        divinterval: "20px", //框右侧距离
        selectwidth: "200px",
        selectinterval: "", //select右侧距离
        select: [
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "addtime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            selectmaxwidth: "100%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
    };
  },
  methods: {
    gettimesrangeEvent(data) {
      // let currentDay= data.valuestart.valueOf() / 1000 +
      //     " ~ " +
      //     data.valueend.valueOf() / 1000
          if(data.valueend.valueOf() != 0){
            
            this.$emit('myorderline',data)
          }
    },

    setyestoday() {
      
       const currentDay=this.getyestoday(new Date())
      // console.log(curentDay);
      this.$emit("setyestoday", { currentDay:currentDay,timeType: "-1",dateType:"昨日" });
    },
    setDay(val) {
      if (val === "onlion") {
        this.$emit("setDay", {timeType: "1",dateType:"本日" });
      } 
      else {
        const currentDay = this.getdefaultday(new Date());
        console.log(currentDay);
        this.$emit("setDay", { currentDay: currentDay, timeType: "1",dateType:"本日" });
      }
    },
    setWeek() {
      const currentDay = this.getdefaultweek(new Date());
      console.log(currentDay);
      this.$emit("setWeek", { currentDay: currentDay, timeType: "7",dateType:"本周" });
    },
    setMonth() {
      const currentDay = this.getdefaultmonth(new Date());
      console.log(currentDay);
      this.$emit("setMonth", { currentDay: currentDay, timeType: "30",dateType:"本月" });
    },
    setYear() {
      const currentDay = this.getdefaultyear(new Date());
      console.log(currentDay);
      this.$emit("setYear", {currentDay: currentDay,dateType:"本年"});
    },
    getdefaultyear(now) {
      var nowYear = now.getFullYear(); //当前年
      var firstDay = new Date(nowYear, 1, 1).getTime() / 1000; // 本月开始时间
      var LastDay = new Date(nowYear + 1, 1, 1).getTime() / 1000; // 本月结束时间
      console.log("---current year:---");
      console.log(firstDay);
      console.log(LastDay);
      return firstDay.toString() + " ~ " + LastDay.toString();
    },
    getdefaultmonth(now) {
      var nowYear = now.getFullYear(); //当前年
      var nowMonth = now.getMonth(); //当前月 （值为0~11）注意此处范围
      var firstDay = new Date(nowYear, nowMonth, 1).getTime() / 1000; // 本月开始时间
      var LastDay = new Date(nowYear, nowMonth + 1, 0).getTime() / 1000; // 本月结束时间
      console.log("---current month:---");
      console.log(firstDay);
      console.log(LastDay);
      return firstDay.toString() + " ~ " + LastDay.toString();
    },
    getdefaultday(now) {
      var nowYear = now.getFullYear(); //当前年
      var nowMonth = now.getMonth(); //当前月 （值为0~11）注意此处范围
      var nowday = now.getDate();
      var firstDay = new Date(nowYear, nowMonth, nowday).getTime() / 1000; // 本月开始时间
      var LastDay = new Date(nowYear, nowMonth, nowday + 1).getTime() / 1000; // 本月结束时间
      console.log("---current day:---");
      console.log(firstDay);
      console.log(LastDay);
      return firstDay.toString() + " ~ " + LastDay.toString();
    },
    getyestoday(now) {
      var nowYear = now.getFullYear(); //当前年
      var nowMonth = now.getMonth(); //当前月 （值为0~11）注意此处范围
      var nowday = now.getDate();
      var firstDay = new Date(nowYear, nowMonth, nowday - 1).getTime() / 1000; // 本月开始时间
      var LastDay = new Date(nowYear, nowMonth, nowday).getTime() / 1000; // 本月结束时间
      console.log("---yestoday:---");
      console.log(firstDay);
      console.log(LastDay);
      return firstDay.toString() + " ~ " + LastDay.toString();
    },
    getdefaultweek(now) {
      var nowYear = now.getFullYear(); //当前年
      var nowMonth = now.getMonth(); //当前月 （值为0~11）注意此处范围
      var nowday = now.getDate();
      var dayOfWeek = now.getDay();
      var firstDay =
        new Date(nowYear, nowMonth, nowday - (dayOfWeek - 1)).getTime() / 1000; // 本周开始时间
      var LastDay =
        new Date(nowYear, nowMonth, nowday + (7 - dayOfWeek)).getTime() / 1000; // 本周结束
      console.log("---current week:---");
      console.log(firstDay);
      console.log(LastDay);
      return firstDay.toString() + " ~ " + LastDay.toString();
    },
  },
};
</script>
<style scoped>
</style>