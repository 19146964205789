<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <!-- 营收概览 -->
        <div class="yinsou-box">
            <div class="yinsou-box-title">
                <div class="yinsou-box-title-text">营收概览</div>
                <div class="yinsou-box-title-select">
                    <div>
                        <el-select v-model="this.yinsouselect.platform" @change="(data) => getyinsouinfoEvent(data, this.yinsouselect, 'platform')" placeholder="请选择平台">
                            <el-option v-for="item in platforms" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-select v-model="this.yinsouselect.paytype" @change="(data) => getyinsouinfoEvent(data, this.yinsouselect, 'paytype')" placeholder="请选择支付方式">
                            <el-option v-for="item in paytypes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-date-picker v-model="this.changetime" @change="(data) => getyinsouinfoEvent(data, this.yinsouselect, 'paytime')" type="month" placeholder="请选择月份">
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <!-- 样式-card -->
            <div class="yinsou-box-card">
                <div class="yinsou-box-card-box" v-for="(item, index) in this.yinsoudata" :key="index">
                    <div class="card-box-icon">
                        <el-image :src="require('@/assets/imgs/account/' + item.icon + '.jpg')"></el-image>
                    </div>
                    <div class="card-box-content">
                        <div class="card-box-content-title">{{ item.title }}</div>
                        <div
                            class="card-box-content-money"
                            :class="item.jump && item.powerlimit == true ? 'mytabletext' : null"
                            @click="item.jump && item.powerlimit == true ? routeraddrClick(item.jumppath, item.jumpquery) : null"
                        >
                            <div>
                                {{ (item.number / 100).toFixed(2) }}
                            </div>
                            <!-- <div v-if="item.jump && item.powerlimit == true">
                                <el-image style="width: 20px; height: 20px" :src="require('@/assets/imgs/account/' + item.jump + '.png')"></el-image>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 代理商账户概览 -->
        <div class="agentuser-box">
            <div class="box">
                <div class="box_title" style="">
                    <div class="box_title_font">代理商账户概览</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <div class="box_content_flex">
                        <div class="flex_box">
                            <div class="echartsbox">
                                <mainecharts ref="mypanel" :mypanelisshow="mypanelisshow" :mypaneldata="mypanel"></mainecharts>
                            </div>
                            <div class="money_box">
                                <span class="money_box_title">代理商可提现金额（元）</span>
                                <!-- <div class="hoverhand money_box_content" @click="this.mypowerlimits.dlsdz == true ? routeraddrClick('/recagent') : ''"> -->
                                <div class="hoverhand money_box_content">
                                    <div class="money_box_content_div">
                                        <!-- <span class="money_box_content_div_span" :class="this.mypowerlimits.dlsdz == true ? 'mytabletext' : ''"> -->
                                        <span class="money_box_content_div_span"> {{ (this.agentdata.shop_in / 100).toFixed(2) }}</span>
                                    </div>
                                    <!-- <div style="margin-top: 5px">
                                        <img src="@/assets/imgs/account/jiantou_black.png" style="width: 20px; height: 20px" />
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="flex_box">
                            <div class="echartsbox">
                                <mainecharts ref="mypanel1" :mypanelisshow="mypanelisshow" :mypaneldata="mypanel1"></mainecharts>
                            </div>
                            <div class="money_box">
                                <span class="money_box_title">代理商已提现金额（元）</span>
                                <!-- <div class="hoverhand money_box_content" @click="this.mypowerlimits.txjl == true ? routeraddrClick('/withdrawalrecord', this.yinsouselect) : ''"> -->
                                <div class="hoverhand money_box_content">
                                    <div class="money_box_content_div">
                                        <!-- <span class="money_box_content_div_span" :class="this.mypowerlimits.txjl == true ? 'mytabletext' : ''"> -->
                                        <span class="money_box_content_div_span"> {{ (this.agentdata.shop_out / 100).toFixed(2) }}</span>
                                    </div>
                                    <!-- <div style="margin-top: 5px">
                                        <img src="@/assets/imgs/account/jiantou_black.png" style="width: 20px; height: 20px" />
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--本月第三方平台入账概况-table表单-->
        <div v-if="isshow_fd">
            <!--主list列表-->
            <div class="table-box">
                <div class="table-box-title">
                    <!--左侧名称-->
                    <div style="font-size: 14px; display: flex; align-items: center">
                        <p style="margin-right: 15px">本月第三方平台入账概况</p>
                        <div>
                            <el-select v-model="this.otherselect.platform" @change="(data) => getotherinfoEvent(data, this.otherselect, 'platform')" placeholder="请选择平台">
                                <el-option v-for="item in platforms" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                            </el-select>
                        </div>
                    </div>
                    <!--右侧按钮-->
                    <div>
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <el-button type="primary" plain @click="routeraddrClick('/statementuser', 1, 1)" v-if="this.mypowerlimits.dsfptdzdmx">查看更多</el-button>
                    </div>
                </div>
                <div class="table-box-center">
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'income' || mytabletitle.prop === 'outlay'">
                                <!--收入-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'income'">
                                    <div style="color: #66c139; font-size: 15px">+{{ scope.row.income / 100 }}</div>
                                    <div style="color: #999999; font-size: 15px">({{ scope.row.income_num }}笔)</div>
                                </div>
                                <!--支出-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'outlay'">
                                    <div style="color: #f56c6c; font-size: 15px">-{{ scope.row.outlay / 100 }}</div>
                                    <div style="color: #999999; font-size: 15px">({{ scope.row.outlay_num }}笔)</div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column :fixed="false" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-代理商列表-->
                                <div v-if="mainthreetableope === 2">
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/statementuser', scope.row, 2)" v-if="this.mypowerlimits.dsfptdzdmx"
                                        >查看详情
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import mainecharts from '@/components/Mainecharts.vue'
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'vaccountfd',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '财务'
                },
                {
                    name: '资金流水对账'
                },
                {
                    name: '平台总览'
                }
            ], // 当前页-【面包屑导航】

            // 【营收概览】-下拉数据-平台来源
            platforms: componentProperties.get('thirdpartyoption').options,

            // 【营收概览】-下拉数据-支付方式
            paytypes: [
                {
                    value: '0',
                    label: '不限支付方式'
                },
                {
                    value: '1',
                    label: '余额'
                },
                {
                    value: '2',
                    label: '微信'
                },
                {
                    value: '3',
                    label: '支付宝'
                },
                {
                    value: '5',
                    label: '余额代付'
                }
            ],

            // 【营收概览】-卡片数据
            yinsoudata: [
                {
                    icon: 'icon1',
                    title: '平台营收（元）',
                    explain: '平台所有的支付方式收入总览',
                    number: '0',
                    powerlimit: JSON.parse(localStorage.getItem('powerlimits')).ptjymx
                    // jump: 'jiantou_black',
                    // jumppath: '/jiaoyimingxi',
                    // jumpquery: { thirdparty: 1 }
                },
                {
                    icon: 'icon2',
                    title: '打印入账（元）',
                    explain: '打印支付的订单扣除打印退款的订单',
                    number: '0',
                    powerlimit: JSON.parse(localStorage.getItem('powerlimits')).ptjymx
                    // jump: 'jiantou_black',
                    // jumppath: '/jiaoyimingxi',
                    // jumpquery: { source: 1, thirdparty: 1 }
                },
                {
                    icon: 'icon3',
                    title: '平台打印佣金（元）',
                    number: '0'
                },
                {
                    icon: 'icon4',
                    title: '教育入账（元）',
                    number: '0',
                    powerlimit: JSON.parse(localStorage.getItem('powerlimits')).ptjymx
                    // jump: 'jiantou_black',
                    // jumppath: '/jiaoyimingxi',
                    // jumpquery: { source: 6, thirdparty: 1 }
                },
                {
                    icon: 'icon5',
                    title: '广告入账（元）',
                    number: '0',
                    powerlimit: JSON.parse(localStorage.getItem('powerlimits')).ptjymx
                    // jump: 'jiantou_black',
                    // jumppath: '/jiaoyimingxi',
                    // jumpquery: { source: 3, thirdparty: 1 }
                },
                {
                    icon: 'icon6',
                    title: '会员入账（元）',
                    number: '0',
                    powerlimit: JSON.parse(localStorage.getItem('powerlimits')).ptjymx
                    // jump: 'jiantou_black',
                    // jumppath: '/jiaoyimingxi',
                    // jumpquery: { source: 4, thirdparty: 1 }
                }
            ],
            // 【营收概览】-搜索字段
            yinsouselect: {
                platform: '1',
                paytype: '',
                paytime: ''
            },
            // 【营收概览】-选中的时间字段
            changetime: '',

            // 【代理商账户概览】-仪表盘
            mypanelisshow: true,
            mypanel: [
                {
                    idname: 'mypanel1',
                    width: '300px',
                    height: '250px',
                    subcolor: '#f34c4c',
                    data: '0'
                }
            ],
            mypanel1: [
                {
                    idname: 'mypanel2',
                    width: '300px',
                    height: '250px',
                    subcolor: '#465EFF',
                    data: '0'
                }
            ],
            // 【代理商账户概览】- 可提现||已提现数据
            agentdata: {
                shop_in: '0',
                shop_out: '0'
            },

            // 【本月第三方平台入账概况】
            otherselect: {
                platform: '1'
            },

            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 2, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 150,
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: 'date',
                    label: '日期',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'income',
                    label: '收入',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'outlay',
                    label: '支出',
                    width: ''
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 30,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用
            tableData: [],

            // 临时任务-迎合资金方
            isshow_fd: false
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // 获取-营收概览-数据
        getyinsouinfoEvent(data, selectdatas, ide) {
            console.log('123123:', data, selectdatas, ide)
            // 将选中的时间赋值给paytime
            selectdatas.paytime = this.changetime
            // 获取-筛选条件数据等
            let datas = selectdatas
            if (this.changetime != '') {
                let gettime = this.$utils.formatDate(datas.paytime.valueOf() / 1000)
                let now = new Date(gettime.substr(0, 11))
                let nowYear = now.getFullYear() //当前年
                let nowMonth = now.getMonth() //当前月 （值为0~11）注意此处范围
                let firstDay = new Date(nowYear, nowMonth, 1).getTime() / 1000 // 本月开始时间
                let LastDay = new Date(nowYear, nowMonth + 1, 1).getTime() / 1000 - 1 // 本月结束时间
                datas.paytime = firstDay + ' ~ ' + LastDay
            }

            // Ajax-获取数据
            axios
                .post(
                    '/admin/cashflow/overview',
                    {
                        platform: datas.platform,
                        paytype: datas.paytype,
                        paytime: datas.paytime
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        let getdatas = response.data.result
                        // 渲染-营收概览数据
                        this.yinsoudata[0].number = getdatas.plat_in //平台营收
                        this.yinsoudata[1].number = getdatas.print_in //打印入账
                        this.yinsoudata[2].number = getdatas.draw_cash_in //平台打印佣金
                        this.yinsoudata[3].number = getdatas.course_in //教育入账
                        this.yinsoudata[4].number = getdatas.advert_in //广告入账
                        this.yinsoudata[5].number = getdatas.plus_in //会员入账

                        //渲染-代理商账户概览数据
                        this.agentdata.shop_in = getdatas.shop_in //代理商可提现金额
                        this.agentdata.shop_out = getdatas.withdraw_shop //代理商已提现金额

                        // 【代理商账户-金额string转float】
                        if (typeof getdatas.shop_in == 'string') {
                            getdatas.shop_in = parseFloat(getdatas.shop_in)
                        }
                        if (typeof getdatas.withdraw_shop == 'string') {
                            getdatas.withdraw_shop = parseFloat(getdatas.withdraw_shop)
                        }

                        // 【计算仪表盘数据-可提款金额】
                        if (getdatas.shop_in != 0 || getdatas.withdraw_shop != 0) {
                            this.mypanel[0].data = (getdatas.shop_in / (getdatas.shop_in + getdatas.withdraw_shop)) * 100
                        } else {
                            this.mypanel[0].data = 0
                        }
                        this.$refs.mypanel.echartPanel(this.mypanel, 0)
                        // 计算仪表盘数据-已提款金额
                        if (getdatas.withdraw_shop != 0 || getdatas.shop_in != 0) {
                            this.mypanel1[0].data = (getdatas.withdraw_shop / (getdatas.shop_in + getdatas.withdraw_shop)) * 100
                        } else {
                            this.mypanel1[0].data = 0
                        }
                        this.$refs.mypanel1.echartPanel(this.mypanel1, 0)

                        // // 渲染-本月第三方平台入账概况-table表单
                        // this.myaxiosfunc(getdatas.month.list);
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },

        // // 获取-本月第三方平台入账概况-数据-使用下拉选择器
        // getotherinfoEvent(data, selectdatas, ide) {
        //     console.log(data, selectdatas, ide)

        //     //加载loading
        //     this.loading = true //通过Ajax向后台获取数据
        //     axios
        //         .post(
        //             '/admin/cashflow/platEntryBill',
        //             {
        //                 platform: data
        //             },
        //             {
        //                 headers: {
        //                     Authentication: localStorage.getItem('token')
        //                 }
        //             }
        //         )
        //         .then((response) => {
        //             if (response.data.code == 0) {
        //                 this.myaxiosfunc(response.data.result.list)
        //             }
        //             this.loading = false
        //         })
        //         .catch(function (error) {
        //             console.log(error)
        //         })
        // },

        // 获取本月开始时间戳~今日结束时间戳
        getdefaultmonth() {
            let now = new Date()
            // 获取今天的时间戳 13位
            let toData = new Date(new Date().toLocaleDateString()).getTime() / 1000
            //今天结束时间
            let todayEnd = toData + 24 * 60 * 60 - 1
            //当前年
            let nowYear = now.getFullYear()
            //当前月
            let nowMonth = now.getMonth()
            //本月开始时间戳
            let firstDay = new Date(nowYear, nowMonth, 1).getTime() / 1000
            return firstDay + ' ~ ' + todayEnd
        },

        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata, flag) {
            // 携带参数跳转
            if (rowdata) {
                // 跳转-第三方平台对账单明细
                if (routeraddr == '/statementuser') {
                    if (flag == 1) {
                        if (this.yinsouselect.paytime == '') {
                            this.yinsouselect.paytime = this.getdefaultmonth()
                        }
                        this.$router
                            .push({
                                path: routeraddr,
                                query: {
                                    addtime: this.yinsouselect.paytime,
                                    thirdparty: this.otherselect.platform
                                }
                            })
                            .catch((error) => error)
                    } else {
                        // 获取当前行这一天的时间戳 13位
                        let toData = new Date(new Date(rowdata.date).toLocaleDateString()).getTime() / 1000
                        let todayStart = toData //开始时间
                        let todayEnd = todayStart + 24 * 60 * 60 - 1 //结束时间

                        this.$router
                            .push({
                                path: routeraddr,
                                query: {
                                    addtime: todayStart + ' ~ ' + todayEnd,
                                    thirdparty: this.otherselect.platform
                                }
                            })
                            .catch((error) => error)
                    }
                }
                // 跳转-平台交易记录
                if (routeraddr == '/jiaoyimingxi') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                source: rowdata.source,
                                thirdparty: rowdata.thirdparty
                            }
                        })
                        .catch((error) => error)
                }
                // 跳转-提现记录
                if (routeraddr == '/withdrawalrecord') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                status: 1,
                                thirdparty: rowdata.platform
                            }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata
            this.mainthreetableData = newdata
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            // 首次加载数据
            this.getyinsouinfoEvent(1, this.yinsouselect, 'platform')
            // 首次加载-本月第三方平台入账概况
            // this.getotherinfoEvent(1)
        }
    },
    components: {
        mainone,
        mainecharts
    }
})
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

/* ---------------------------- */
.mytabletext {
    cursor: pointer;
}

.mytabletext:hover {
    color: var(--el-color-primary);
}

/* -------营收概览------------- */

.yinsou-box {
    background-color: #ffffff;
    margin: 10px 0;
    padding: 20px 0 10px 20px;
    border-radius: 4px;
}

.yinsou-box-title,
.yinsou-box-card,
.yinsou-box-card-box,
.yinsou-box-title-select {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.yinsou-box-title {
    align-items: center;
}

.yinsou-box-title-text {
    font-size: 16px;
    color: #333333;
    margin-right: 20px;
}

.yinsou-box-title-select div {
    margin-right: 10px;
}

.yinsou-box-card {
    margin: 10px 0;
    justify-content: space-between;
}

.yinsou-box-card-box {
    border: 1px solid #eaedf7;
    padding: 30px;
    border-radius: 8px;
    min-width: 300px;
    margin: 10px 0;
    flex-grow: 1;
    margin: 10px 20px 10px 0;
}

.card-box-icon {
    margin-right: 30px;
}

.card-box-icon {
    width: 60px;
    height: 60px;
}

.card-box-content-title {
    font-size: 16px;
    color: #999999;
    font-weight: 400;
    margin-bottom: 8px;
}

.card-box-content-money {
    font-size: 26px;
    font-weight: bold;
    color: #333333;
    display: flex;
    flex-direction: row;
}

.card-box-content-money .el-image {
    margin-left: 20px;
}

/* --------------代理商账户概览---------------- */
.box {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 5px 20px;
    margin: 5px 0;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    font-weight: 400;
    font-size: 16px;
    color: #333333;
}

.box_content_flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.flex_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
}

.echartsbox {
    width: 300px;
    height: 180px;
    overflow-y: hidden;
}

.hoverhand {
    /* cursor: pointer; */
}

.money_box {
    display: flex;
    flex-direction: column;
    width: 200px;
}

.money_box_title {
    color: #999999;
    font-size: 16px;
    margin-bottom: 10px;
}

.money_box_content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.money_box_content_div {
    margin-right: 10px;
}

.money_box_content_div_span {
    color: #333333;
    font-size: 26px;
    font-weight: bold;
}

/* 本月第三方平台入账概况 */
.table-box {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}

.table-box-title {
    line-height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.table-box-center {
    padding-bottom: 20px;
}
</style>
