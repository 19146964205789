<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <div class="box_title" style="">
          <div class="box_title_font">添加合同</div>
          <div class="box_title_button">
            <!--可添加按钮-->
            <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
          </div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin-top: 20px">
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="7">
              <div style="width: 100%"></div>
            </el-col>
            <!--主表样式-->
            <el-col :span="24" :xs="24" :sm="24" :lg="9">
              <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                label-width="160px"
                class="demo-ruleForm"
              >
                <el-form-item label="姓名：" prop="name">
                  <el-input
                    v-model="ruleForm.name"
                    placeholder="请填写真实姓名"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号：" prop="mobile">
                  <el-input v-model="ruleForm.mobile" clearable></el-input>
                </el-form-item>
                <el-form-item label="身份证号：" prop="id_card">
                  <el-input v-model="ruleForm.id_card" clearable></el-input>
                </el-form-item>
                <el-form-item label="签约类型：" prop="sign_type">
                  <el-select
                    v-model="ruleForm.sign_type"
                    placeholder="请选择"
                    style="width: 100%"
                    :disabled="this.signtypedisable"
                  >
                    <el-option label="投资（收益权）" value="1"></el-option>
                    <el-option label="设备（购买权）" value="2"></el-option>
                    <el-option label="设备（购买权分期）" value="3"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="关联平台/订单编号："
                  prop="alipay_no"
                  v-if="ruleForm.sign_type == 3"
                >
                  <el-input v-model="ruleForm.alipay_no" clearable></el-input>
                </el-form-item>
                <el-form-item
                  label="投资设备："
                  required="true"
                  v-if="ruleForm.sign_type == 1"
                >
                  <div>
                    <el-button
                      type="primary"
                      icon="el-icon-upload"
                      style="margin-bottom: 10px"
                      size="small"
                      @click="chuliTanClick((dialogVisible = true))"
                      id="trueButton"
                    >
                      请选择设备</el-button
                    >
                  </div>
                  <div>
                    <el-form-item prop="client_ids" :rules="rules.client_ids">
                      <el-input
                        v-model="ruleForm.client_ids"
                        v-show="hiddeninput"
                      ></el-input>
                      <div class="myinput-box">
                        <div class="myinput-box-tag">
                          <el-tag
                            v-for="(item, index) in push_alldvc.slice(
                              0,
                              tagshownum
                            )"
                            :key="index"
                            closable
                            style="margin-left: 5px; color: #333333"
                            type="info"
                            @close="handleTagClose(index)"
                            >{{ item.client }}
                          </el-tag>
                          <el-tag
                            style="
                              margin-left: 5px;
                              color: #333333;
                              cursor: pointer;
                            "
                            type="info"
                            v-if="push_alldvc.length > tagshownum"
                            @click="clickTagBtnEvent(tagshownum)"
                            >+{{ push_alldvc.length - tagshownum }}</el-tag
                          >
                        </div>
                      </div>
                    </el-form-item>
                  </div>
                </el-form-item>
                <el-form-item label="购买台数：" prop="buy_num">
                  <el-input
                    v-model="ruleForm.buy_num"
                    min="1"
                    :v-model="
                      ruleForm.sign_type == 1
                        ? (ruleForm.buy_num = this.push_alldvc.length)
                        : ruleForm.buy_num
                    "
                    :disabled="ruleForm.sign_type == 1 ? true : false"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="合同金额：" prop="money">
                  <el-input v-model="ruleForm.money" clearable></el-input>
                </el-form-item>
                <el-form-item label="上级用户ID：" prop="sponsor_uid">
                  <el-input v-model="ruleForm.sponsor_uid" clearable></el-input>
                </el-form-item>
                <el-form-item label="合同签约时间：" prop="signtime">
                  <el-date-picker
                    v-model="ruleForm.signtime"
                    type="datetime"
                    placeholder="请选择合同签约时间"
                    style="width: 100%"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  label="合同期限："
                  prop="deadline"
                  v-if="ruleForm.sign_type != 1"
                >
                  <el-select
                    v-model="ruleForm.deadline"
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option label="1年" value="1"></el-option>
                    <el-option label="2年" value="2"></el-option>
                    <el-option label="3年" value="3"></el-option>
                    <el-option label="4年" value="4"></el-option>
                    <el-option label="5年" value="5"></el-option>
                  </el-select>
                </el-form-item>
                <!--提现微信-->
                <el-form-item label="提现微信：" prop="openid">
                  <mainwechat
                    ref="wechat"
                    v-model="ruleForm.openid"
                    :mycashoutisshow="mycashoutisshow"
                    :mycashout="mycashout"
                    v-on:getpayaccount="
                      (data) => getzhanghuCodeEvent(data, 'wechat')
                    "
                    v-on:getchangepaytype="getChangePaytypeEvent"
                    v-on:getisshow="changeGetisshowEvent"
                  ></mainwechat>
                </el-form-item>

                <el-form-item>
                  <el-button
                    type="primary"
                    @click="submitForm('ruleForm')"
                    style="width: 40%; margin-right: 10px"
                    >确认
                  </el-button>
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="8">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 弹出框-投资设备 -->
    <el-dialog
      v-model="dialogVisible"
      title="选择设备"
      :width="searchwidth"
      :before-close="handleCloseSenior"
    >
      <el-form ref="seniorForm" :model="seniorForm" :rules="rules">
        <!--查询条件-->
        <el-form-item>
          <div class="tan-search-box">
            <div class="tan-search-box-info">
              <div class="tan-search-box-info-title">输入搜索：</div>
              <div>
                <el-input
                  v-model="seniorForm.keywords"
                  placeholder="设备编号/代理商名称"
                  clearable
                ></el-input>
              </div>
            </div>
            <div class="tan-search-box-info">
              <div class="tan-search-box-info-title">选择区域：</div>
              <div>
                <mainselect
                  v-model="seniorForm.area_code"
                  :myselectcityleftisshow="tanpages.myselectcityisshow"
                  :myselectcity="tanpages.myselectcity"
                  v-on:getcitycode="getCityCodeEvent"
                ></mainselect>
              </div>
            </div>
            <div class="tan-search-box-button">
              <el-button type="primary" @click="onSubmitTan('seniorForm')"
                >查询</el-button
              >
            </div>
          </div>
        </el-form-item>
        <!-- table表单 -->
        <el-form-item>
          <el-table
            :data="
              tableData.slice(
                (tanpages.currentPage - tanpages.lastpage) * tanpages.PageSize,
                tanpages.currentPage * tanpages.PageSize
              )
            "
            ref="multipleTable"
            style="width: 100%"
            max-height="420"
            border
            v-loading="tanpages.loading"
            element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column label="设备编号" prop="client" width="150" />
            <el-table-column label="投放商家" prop="shop_name" />
            <el-table-column label="投放地址" prop="address" />
            <el-table-column label="投放场景" prop="push_type" width="150" />
            <el-table-column label="状态" prop="status" width="150">
              <template #default="scope">
                <div class="tan-table-status">
                   <el-button
                    :type="scope.row.printstatus == 0 ? 'primary' : (scope.row.printstatus == 1 ? 'success' : 'danger')"
                    size="mini">{{ this.$utils.printStatusData( this.myprintstatusoption ,scope.row.printstatus != 0 ? scope.row.printstatus : '0') }}
                    </el-button>
                    <el-button :type="scope.row.is_online == 1 ? 'success' : 'danger'" size="mini">{{ scope.row.is_online
                        == 1 ? '在线' : '离线'
                    }}</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-row>
          <!-- 分页 -->
          <el-col :span="24" :xs="24" :sm="20">
            <div style="margin-top: 5px">
              <el-pagination
                background
                @size-change="handleSizeChangetan"
                @current-change="handleCurrentChangetan"
                pager-count="4"
                :current-page="tanpages.currentPage"
                :page-sizes="tanpages.pageSizes"
                :page-size="tanpages.PageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tanpages.totalCount"
              >
              </el-pagination>
            </div>
          </el-col>
          <!-- 确定&取消按钮 -->
          <el-col :span="24" :xs="24" :sm="4">
            <div style="text-align: right">
              <el-button
                @click="
                  resetFormSenior(tableseniorForm, (dialogVisible = false))
                "
                >取消</el-button
              >
              <el-button
                type="primary"
                @click="
                  onSubmitSenior(tableseniorForm, (dialogVisible = false))
                "
                :disabled="tanpages.tandisabled"
                >确定</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import mainselect from "@/components/Mainselect";
import mainwechat from "@/components/Mainwechat.vue";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import axios from "axios";
import { ElMessage } from "element-plus";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vcontractlistadd",
  data() {
    return {
      heightgetdata: 40,

      mainonebread: [
        {
          name: "合作伙伴",
        },
        {
          name: "设备合伙人",
        },
        {
          name: "合同列表",
          path: "/contractlist",
        },
        {
          name: "添加合同",
          path: "/contractlistadd",
        },
      ], // 当前页-【面包屑导航】

      //form表单
      ruleForm: {
        name: "",
        mobile: "",
        id_card: "",
        sign_type: "",
        alipay_no: "",
        client_ids: "",
        buy_num: "",
        money: "",
        sponsor_uid: "",
        signtime: "",
        deadline: "",
        openid: "",
      },
      //form表单-必填规则
      rules: {
        name: [
          {
            required: true,
            message: "真实姓名为必填项",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "手机号为必填项",
            trigger: "blur",
          },
        ],
        id_card: [
          {
            required: true,
            message: "身份证号为必填项",
            trigger: "blur",
          },
        ],
        sign_type: [
          {
            required: true,
            message: "签约类型为必填项",
            trigger: "blur",
          },
        ],
        alipay_no: [
          {
            required: true,
            message: "关联平台/订单编号为必填项",
            trigger: "blur",
          },
        ],
        client_ids: [
          {
            required: true,
            message: "投资设备为必填项",
            trigger: "change",
          },
        ],
        buy_num: [
          {
            required: true,
            message: "购买台数为必填项",
            trigger: "blur",
          },
        ],
        money: [
          {
            required: true,
            message: "合同金额为必填项",
            trigger: "blur",
          },
        ],
        sponsor_uid: [
          {
            required: false,
            message: "上级用户ID为必填项",
            trigger: "blur",
          },
        ],
        signtime: [
          {
            required: true,
            message: "合同签约时间为必填项",
            trigger: "change",
          },
        ],
        deadline: [
          {
            required: true,
            message: "合同期限为必填项",
            trigger: "change",
          },
        ],
        openid: [
          {
            required: true,
            message: "提现微信为必填项",
            trigger: "blur",
          },
        ],
      },
      // 签约类型：是否禁用，默认为启用false
      signtypedisable: false,

      hiddeninput: false, //隐藏选择设备中的el-input标签

      mycashoutisshow: true, //form表单-提现微信/支付宝（单个组件）
      mycashout: {
        ide: "wechat", // 必填标识：微信（'wechat'）,支付宝（'alipay'）
        titlewidth: "180px",
        cashout: [
          {
            title: "提现微信：",
            ismust: true, //是否显示必填红标
            isshowedit: false, //控制-首次进入页面时-编辑用户信息模块
            isshowadd: true, //控制-首次进入页面时-添加提现信息的模块
            avatarinfo: {
              id: 1,
              avatar:
                "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
              nickname: "用户李四",
              mobile: "18738573256",
            }, //显示-首次进入页面时-提现用户信息
            tantitle: "请选择提现微信", //弹窗-title名称
            tantable: {
              mainnick: "微信昵称",
              searchpder: "请输入 微信昵称 / 手机号 查询",
            }, //弹窗-table中的需改变的中文字段
            addtitle: "添加提现微信", //添加-按钮的中文
            addcolor: "#04BE02", //添加-按钮的颜色
            paytype: 0, //默认提现方式 - 0表示无，1微信，2支付宝
          },
        ],
      }, //提现微信

      //tag显示数量，默认是10条
      tagshownum: 2,
      //获取的投放设备-与tableseniorForm中的alldvc同步，通过弹窗【确认】获得
      push_alldvc: [],
      //弹框-查询设备-form表单数据
      seniorForm: {
        keywords: "",
        area_code: "",
      },
      dialogVisible: false,
      searchwidth: "1024px",
      //弹窗中-table表单
      tableData: [],
      tableseniorForm: {
        agreement_id: "",
        dvcids: [],
        alldvc: [],
        is_all: 0,
      },
      tanpages: {
        //弹窗中-selectcity选框
        myselectcityisshow: true,
        myselectcity: {
          selectwidth: "100%",
          maxwidth: "500px",
          marginright: "0",
          select: {
            ismust: false,
            name: "area_code",
            valuesheng: "",
            valueshi: "",
            valuequ: "",
            disshi: true,
            disqu: true,
          },
        },
        //弹窗中-table表单-分页器
        totalCount: 0, //数据列表中-数据总条数
        currentpage: 1, //数据列表中-数据当前页数
        lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
        currentPage: 1,
        pageSizes: [10, 20, 50, 100, 1000],
        PageSize: 10,
        loading: false, //初始化-loading加载动效，默认是false关闭

        sign_type: "", //弹出框中签约类型
        pagesdatas: [], //存储(弹窗中)最近一次的筛选条件，供分页使用

        tandisabled: false, //控制弹窗中的确定按钮
      },

      // 设备打印状态数据
      myprintstatusoption: componentProperties.get('printstatusoption').options,

      // 通过-机器控制-编辑-创建合同跳转而来-设备id - 前端维护
      clientid:'',

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
    };
  },
  methods: {
    //添加/删除后切换默认提现方式
    getChangePaytypeEvent(val) {
      console.log("回传的值：" + val);
      if (
        this.mycashout.cashout[0].isshowadd == true &&
        this.mycashout1.cashout[0].isshowadd == true
      ) {
        this.ruleForm.paytype = 0;
        this.mycashout.cashout[0].paytype = 0;
        this.mycashout1.cashout[0].paytype = 0;
      } else {
        this.ruleForm.paytype = val;
        this.mycashout.cashout[0].paytype = val;
        this.mycashout1.cashout[0].paytype = val;
      }
      console.log("之后：" + this.ruleForm.paytype);
    },
    //判断默认提现方式是否都为添加状态
    changeGetisshowEvent(val) {
      console.log("changeGetisshowEvent:" + val);
      if (val.ide == "wechat") {
        this.mycashout.cashout[0].isshowedit = false;
        this.mycashout.cashout[0].isshowadd = true;
      } else {
        this.mycashout1.cashout[0].isshowedit = false;
        this.mycashout1.cashout[0].isshowadd = true;
      }
    },
    //获取支付账号公共组件
    getzhanghuCodeEvent(data, payaccount) {
      if (payaccount == "wechat") {
        console.log("获取返回的（微信）数据：" + JSON.stringify(data));
        this.ruleForm.openid = data.id;
      }
      // else {
      //   console.log("获取返回的（支付宝）数据：" + JSON.stringify(data));
      //   this.ruleForm.alipay_user_id = data.alipay_user_id;
      // }
    },
    // ----------------弹出框---------------------------------------------------
    //点击按钮-弹出弹框-仅（投资收益权）弹出该按钮-且可选择投资设备
    chuliTanClick() {
      this.tanpages.pagesdatas.keywords = "";
      this.tanpages.pagesdatas.area_code = "";
      this.tanpages.pagesdatas.page = 1;
      this.tanpages.pagesdatas.page_size = 10;
      this.tanpages.PageSize = 10;

      this.tanGetInfoEvent(this.tanpages.pagesdatas);
    },
    // 弹窗中-获取数据的函数
    tanGetInfoEvent(pagesdatas) {
      console.log(pagesdatas.sign_type);
      //点击进入弹窗-加载tabledata
      this.tanpages.loading = true;
      axios
        .post(
          "/admin/partner/getInvestClient",
          {
            keywords: pagesdatas.keywords,
            area_code: pagesdatas.area_code,
            page: pagesdatas.page,
            page_size: pagesdatas.page_size,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          var successdata = response.data.result;
          var newdata = successdata.data;
          for (var i = 0; i < newdata.length; i++) {
            //渲染-状态-该字段表示设备状态按钮
            newdata[i].devstatus = 1;
          }
          this.tableData = newdata;
          this.tanpages.totalCount = successdata.total;
          this.tanpages.currentpage = successdata.current_page; //当前页面页数
          this.tanpages.lastpage = successdata.last_page;
          this.tanpages.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //弹窗中-分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.tanpages.PageSize = val;
      this.tanpages.currentPage = 1;

      this.tanpages.pagesdatas.page = 1;
      this.tanpages.pagesdatas.page_size = val;
      this.tanGetInfoEvent(this.tanpages.pagesdatas);
    },
    //弹窗中-分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.tanpages.currentPage = val;

      this.tanpages.pagesdatas.page = val;
      this.tanpages.pagesdatas.page_size = this.tanpages.PageSize;
      this.tanGetInfoEvent(this.tanpages.pagesdatas);
    },
    //弹窗中-table表单-多选按钮
    handleSelectionChange(val) {
      console.log("获取的 val is: " + val);

      // 存储所选择的设备
      this.tableseniorForm.alldvc = val;
    },
    //获取公共组件回传的值-citycode
    getCityCodeEvent(data) {
      console.log("当前获取的citycode值为：" + data);
      this.seniorForm.area_code = data;
    },
    //弹窗中-查询按钮-提交
    onSubmitTan(formName) {
      var getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.tanpages.pagesdatas = getdatas;
          this.tanpages.pagesdatas.page = 1;
          this.tanpages.pagesdatas.page_size = this.tanpages.PageSize;
          this.tanGetInfoEvent(this.tanpages.pagesdatas);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹出框-选择设备-确认
    onSubmitSenior(formData) {
      this.push_alldvc = formData.alldvc;
      console.log(formData);
    },
    //弹出框-选择设备-取消
    resetFormSenior() {
      this.seniorForm = {
        keywords: "",
        area_code: "",
      };
      this.myselectcity.select = {
        ismust: false,
        name: "area_code",
        valuesheng: "",
        valueshi: "",
        valuequ: "",
        disshi: true,
        disqu: true,
      };
    },

    //form表单中-【投资设备】-tag-删除设备
    handleTagClose(tag) {
      this.push_alldvc.splice(tag, 1);
      if (this.push_alldvc.length == 0) {
        this.ruleForm.client_ids = ""; //清除做的标记
      }
    },
    //form表单中-【投资设备】-tag-点击显示+10条
    clickTagBtnEvent(numbers) {
      this.tagshownum = numbers + 1;
    },
    //from表单-提交表单
    submitForm(formName) {
      // 存储所选择设备的id，并用逗号隔开
      var getdvcids = [];
      this.push_alldvc.forEach((element) => {
        getdvcids.push(element.id);
      });
      this.ruleForm.client_ids = getdvcids.join(",");

      var getdata = this.$refs[formName].model;
      // console.log('signtime: '+getdata.signtime.valueOf() / 1000,)
      // console.log(getdata,getdata.openid);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post(
              "/admin/agreement/add",
              {
                name: getdata.name,
                mobile: getdata.mobile,
                id_card: getdata.id_card,
                sign_type: getdata.sign_type,
                alipay_no: getdata.alipay_no,
                client_ids: getdata.client_ids,
                buy_num: getdata.buy_num,
                money: getdata.money,
                sponsor_uid: getdata.sponsor_uid,
                signtime: getdata.signtime.valueOf() / 1000,
                deadline: getdata.deadline,
                wechat_user_id: getdata.openid,
                client_id:this.clientid, // 通过-机器控制-编辑-创建合同跳转而来-设备id - 前端维护
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                  duration: 1000,
                  onClose: () => {
                    // 跳转合同列表
                    this.$router
                      .push({
                        path: "/contractlist",
                        query: { name: getdata.name },
                      })
                      .catch((error) => error);
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //from表单-重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      // 清空投资设备
      this.push_alldvc = [];
      // 清空提现微信/支付宝[仅限于一个微信，一个支付宝（待优化）]
      this.$refs.wechat.delWechatClient(0);
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      // 实时获取页面长宽-用于弹窗
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 1100) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "1024px";
      }
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      // 通过-机器控制-编辑-创建合同跳转而来-代理商id
      let agentid = this.$route.query.agentid;
      if (agentid) {
        console.log(agentid);
        this.ruleForm.sign_type = "3";
        this.signtypedisable = true;

        // 解析代理商&商户-组合成数组
        let getshopcode = agentid.split(",");
        let datas = [];
        getshopcode.forEach((element) => {
          datas.push(parseInt(element));
        });
        // // 匹配上级用户ID
        // if (datas.length > 1) {
        //   this.ruleForm.sponsor_uid = datas[datas.length - 2];
        // } else {
        //   this.ruleForm.sponsor_uid = "";
        // }
        // 获取代理商相关数据
        axios
          .post(
            "/admin/shopUser/info",
            {
              id: datas[datas.length - 1],
            },
            {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            var successdata = response.data.result;
            console.log(successdata);
            // 代理商-姓名
            this.ruleForm.name = successdata.info.name;
            // 代理商-手机号(在本接口中代理商账号就是手机号)
            this.ruleForm.mobile = successdata.info.username;
            // 代理商-签约台数
            this.ruleForm.buy_num = 1;
            // 代理商-提现微信返显示
            let routedatas = successdata.info;
            if (routedatas.wechat) {
              if (routedatas.wechat == null || routedatas.wechat == "") {
                this.mycashout.cashout[0].isshowedit = false;
                this.mycashout.cashout[0].isshowadd = true;
                this.ruleForm.openid = ''; // 将openid存储
              } else {
                this.mycashout.cashout[0].isshowedit = true;
                this.mycashout.cashout[0].isshowadd = false;
                this.mycashout.cashout[0].avatarinfo = routedatas.wechat;
                this.mycashout.cashout[0].paytype = routedatas.default_pay; // 返显-默认提现方式
                this.ruleForm.openid = routedatas.wechat_user_id; // 将openid存储
              }
            } else {
              this.mycashout.cashout[0].isshowedit = false;
              this.mycashout.cashout[0].isshowadd = true;
              this.ruleForm.openid = ''; // 将openid存储
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      // 通过-机器控制-编辑-创建合同跳转而来-设备id
      let clientid = this.$route.query.clientid;
      if(clientid){
        this.clientid = clientid
      }

    }
  },
  components: {
    mainone,
    mainselect,
    mainwechat,
  },
});
</script>
<style scoped>
/* 弹出框-搜索栏 */
.tan-search-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.tan-search-box-info {
  display: flex;
  flex-direction: row;
  margin: 0 10px 10px 0;
}
.tan-search-box-info-title {
  min-width: 80px;
}
.tan-table-status {
  display: flex;
  flex-direction: row;
}
/* 投资设备-设备显示框 */
.myinput-box {
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  width: 100%;
  min-height: 40px;
}

/* box */
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
}
</style>