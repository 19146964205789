<template>
    <div>
        <maintwo
            ref="Refmaintwo"
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectcity="myselectcity"
            :myselectcityleftisshow="maintwoforminfo.myselectcityleftisshow"
            :myselectcityleft="maintwoforminfo.myselectcityleft"
            :isTrueReload="true"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
            @clickReload="clickReloadEvent"
        >
            <template v-slot:searchModel>
                <div>
                    <slot name="searchModelDab"></slot>
                </div>
            </template>
        </maintwo>
    </div>
</template>

<script>
import maintwo from '@/components/Maintwo'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'modelSearch',
    inject: ['reload'],
    props: ['gaoisshow', 'forminfo'],
    watch: {
        gaoisshow: {
            handler(newVal) {
                this.maintwoiconshow = newVal
            },
            deep: true,
            immediate: true
        },
        forminfo: {
            handler(newVal) {
                this.maintwoforminfo = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            maintwoiconshow: true, // 筛选-【高级筛选按钮】
            maintwoforminfo: {} // 筛选-控件信息
        }
    },
    methods: {
        getSeachinfoEvent(data) {
            this.$emit('getsearchinfo', data)
        },
        searchFormEvent(data) {
            this.$emit('searchForm', data)
        },
        clear(myinputone, myselectleft, myinputonefull, myselecttop, myselectcityleft) {
            this.$refs.Refmaintwo.resetForm(myinputone, myselectleft, myinputonefull, myselecttop, myselectcityleft)
        },
        clickReloadEvent(data) {
            this.$emit('reload', data)
        }
    },
    components: {
        maintwo
    }
})
</script>

<style scoped></style>
