<template>
  <div id="mymain">
    <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <div class="box_title" style="">
          <div class="box_title_font">
            <i class="el-icon-info"></i> 广告主详情
          </div>
          <div class="box_title_button"></div>
        </div>
        <!--内容-->
        <div class="box_content">
          <maindetails :detailsdata="mainpagedata.details" style="margin-top: 20px"></maindetails>
          <!-- <div style="margin-bottom: 20px">
                      <span style="font-size: 14px;color: #616e9d">门头:</span>
                    </div>
                    <mainimgesshow :imgesdata="mainpagedata.imgesdata"></mainimgesshow> -->

          <div style="margin-bottom: 20px; margin-top: 10px">
            <div style="min-width: 80px; float: left; margin-right: 20px">
              <span style="font-size: 14px; color: #333333; margin-left: 38px">门头:</span>
            </div>
            <div>
              <mainimgesshow :imgesdata="mainpagedata.imgesdata"></mainimgesshow>
            </div>
          </div>

          <el-divider></el-divider>
          <!--[由于牵扯特殊弹框组件-且只用一次，因此未使用公用组件]-->
          <el-row>
            <el-col style="font-weight: 400; margin-bottom: 40px">
              <div style="font-size: 14px; color: #616e9d; margin-bottom: 10px">
                {{ mainpagedata.details1.title }}
                <el-button v-if="mainpagedata.details1.button.isshow" style="
                    margin-left: 30px;
                    border-radius: 4px;
                    background-color: #002eb7;
                    border-color: #002eb7;
                  " size="small" type="primary" @click="
                    chuliTanClick(
                      mainpagedata.tancontent,
                      (dialogVisible = true)
                    )
                  ">
                  {{ mainpagedata.details1.button.name }}
                </el-button>
              </div>
              <div v-for="(detailsdatainfo, i) in mainpagedata.details1.content" :key="i">
                <div style="font-size: 12px; color: #94a3d2; line-height: 40px">
                  <div style="float: left; min-width: 500px">
                    <div style="float: left; margin-right: 20px">
                      {{ detailsdatainfo.name }}
                    </div>
                    <div style="float: left; margin-right: 20px">
                      {{ detailsdatainfo.value }}
                    </div>
                    <div style="color: #002eb7; cursor: pointer" @click="routeraddrClick(detailsdatainfo.jump)">
                      {{ detailsdatainfo.jumpbt }}
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!--弹出框-->
    <el-dialog v-model="dialogVisible" :title="mainpagedata.tancontent.title" :width="this.searchwidth"
      :before-close="handleCloseSenior">
      <el-form ref="seniorform" :model="seniorform">
        <div style="
            max-height: 600px;
            overflow: hidden;
            overflow-y: visible;
            padding-left: 5%;
            margin: 10px 0;
          ">
          <el-form-item>
            <el-form-item>
              <mainradio :myradioisshow="mainpagedata.tancontent.content.myradioisshow"
                :myradio="mainpagedata.tancontent.content.myradio" v-on:getradioinfo="getRadioinfoEvent"></mainradio>
            </el-form-item>
            <div v-if="showthiscontent">
              <el-form-item>
                <maininput :myinputishow="mainpagedata.tancontent.content.myinputishow"
                  :myinput="mainpagedata.tancontent.content.myinput"></maininput>
              </el-form-item>
            </div>
            <div v-if="!showthiscontent">
              <el-form-item>
                <maininput :myinputishow="mainpagedata.tancontent.content.myinputishow"
                  :myinput="mainpagedata.tancontent.content.myinput1"></maininput>
              </el-form-item>
            </div>
          </el-form-item>
        </div>
        <el-form-item>
          <div style="text-align: center" class="main_one_button">
            <!--<el-button-->
            <!--@click="resetFormSenior()">-->
            <!--<i class="el-icon-refresh-left"></i> 重置-->
            <!--</el-button>-->
            <el-button type="primary" @click="onSubmitSenior()" class="main_two_search">确定
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import mainone from "@/components/Mainone.vue";
import maindetails from "@/components/Maindetails";
import mainimgesshow from "@/components/Mainimgesshow";
import maininput from "@/components/Maininput";
import mainradio from "@/components/Mainradio.vue";
import { ElMessage } from "element-plus";
import axios from "axios";
import { ref } from "vue";

export default {
  name: "advtiserinfo",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainpagedata: {
        datas: "",
        mainonebread: [
          {
            name: "订单",
          },
          {
            name: "广告主管理",
          },
          {
            name: "广告主列表",
            path: "/advertiserslist",
          },
          {
            name: "广告主详情",
          },
        ], // 当前页-【面包屑导航】

        details: {
          showstatus: 1, //确定样式模块
          title: "广告主信息",
          button: {
            isshow: false,
            name: "",
            jump: "", //点击跳转
          },
          content: [
            {
              name: "用户ID:",
              value: "",
            },
            {
              name: "平台来源:",
              value: "",
            },
            {
              name: "商家名称:",
              value: "",
            },
            {
              name: "商家业态:",
              value: "",
            },
            {
              name: "联系电话:",
              value: "",
            },
            {
              name: "地址:",
              value: "",
            },
            {
              name: "审核状态:",
              value: "",
            },
            {
              name: "审核人:",
              value: "",
            },
            {
              name: "审核备注:",
              value: "",
            },
            {
              name: "是否启用:",
              value: "",
            },
            {
              name: "修改时间:",
              value: "",
            },
          ],
        }, //关于详情信息的组件
        details1: {
          showstatus: 1, //确定样式模块
          title: "广告主价格",
          button: {
            isshow: true,
            name: "修改价格",
            jump: "", //点击-弹出弹框
          },
          content: [
            {
              name: "价格类型:",
              value: "",
            },
            {
              name: "优惠券打印单价:",
              value: "",
            },
            {
              name: "优惠券核销单价:",
              value: "",
            },
            {
              name: "宣传页打印单价:",
              value: "",
            },
            {
              name: "宣传页核销单价:",
              value: "",
            },
          ],
        }, //关于详情信息的组件-[由于牵扯特殊弹框组件-且只用一次，因此未使用公用组件]
        imgesdata: {
          showstatus: 2,
          content: [
            // {
            //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
            //   preview: [
            //     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
            //   ],
            // },
          ],
        }, //关于图片展示的组件

        tancontent: {
          title: "修改价格", //弹框-标题
          content: {
            myradioisshow: true, //form表单-radio单选框
            myradio: {
              radio: [
                {
                  titlewidth: "120px",
                  ismust: true,
                  title: "价格类型：",
                  name: "type",
                  value: ref("1"),
                  radio: [
                    {
                      content: "平台统一价格",
                      label: "1",
                    },
                    {
                      content: "使用单独价格",
                      label: "2",
                    },
                  ],
                },
              ],
            }, //form表单-radio选框
            myinputishow: true, //上方字-input单框
            myinput: {
              inputwidth: "100%",
              input: [
                {
                  ismust: true, //是否必填，true表示必填
                  title: "优惠券打印单价：",
                  placeholder: "",
                  disabled: true,
                  name: "keywords",
                  content: "",
                },
                {
                  ismust: true, //是否必填，true表示必填
                  title: "优惠券核销单价：",
                  placeholder: "",
                  disabled: true,
                  name: "keywords",
                  content: "",
                },
                {
                  ismust: true, //是否必填，true表示必填
                  title: " 宣传页打印单价：",
                  placeholder: "",
                  disabled: true,
                  name: "keywords",
                  content: "",
                },
                {
                  ismust: true, //是否必填，true表示必填
                  title: "宣传页核销单价：",
                  placeholder: "",
                  disabled: true,
                  name: "keywords",
                  content: "",
                },
              ],
            },
            myinput1: {
              inputwidth: "100%",
              input: [
                {
                  ismust: true, //是否必填，true表示必填
                  title: "优惠券打印单价：",
                  placeholder: "",
                  name: "keywords",
                  content: "",
                },
                {
                  ismust: true, //是否必填，true表示必填
                  title: "优惠券核销单价：",
                  placeholder: "",
                  name: "keywords",
                  content: "",
                },
                {
                  ismust: true, //是否必填，true表示必填
                  title: " 宣传页打印单价：",
                  placeholder: "",
                  name: "keywords",
                  content: "",
                },
                {
                  ismust: true, //是否必填，true表示必填
                  title: "宣传页核销单价：",
                  placeholder: "",
                  name: "keywords",
                  content: "",
                },
              ],
            },
          },
        },
        searchwidth: "580px",
      }, // 当前Pagedata所需的data数据与控制
      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

      dialogVisible: false,
      searchwidth: "580px",
      showthiscontent: true,
      thisradiochange: 1,
    };
  },
  methods: {
    //确定修改价格
    onSubmitSenior() {
      // console.log(this.mainpagedata.tancontent.content)
      let price1 = this.mainpagedata.tancontent.content.myinput.input;
      let price2 = this.mainpagedata.tancontent.content.myinput1.input;
      console.log(price1, price2);
      var datas = {};
      if (this.thisradiochange == 1) {
        datas = {
          id: this.mainpagedata.datas.id,
          coupon_print_price: price1[0].content - 0,
          coupon_writeoff_price: price1[1].content - 0,
          extend_print_price: price1[2].content - 0,
          extend_writeoff_price: price1[3].content - 0,
        };
      } else {
        datas = {
          id: this.mainpagedata.datas.id,
          coupon_print_price: price2[0].content - 0,
          coupon_writeoff_price: price2[1].content - 0,
          extend_print_price: price2[2].content - 0,
          extend_writeoff_price: price2[3].content - 0,
        };
      }
      console.log(datas);
      axios
        .post("/admin/Advertiser/edit", datas, {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data.result);
          if (response.data.code === 0) {
            ElMessage({
              type: "success",
              message: "修改成功！",
              duration: 1000,
              onClose: () => {
                this.reload(); //刷新
              },
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    routeraddrClick(routeraddr) {
      //点击进入路由页面
      this.$router.push(routeraddr).catch((error) => error);
      console.log(routeraddr);
    },
    chuliTanClick(taninfo) {
      //点击按钮-弹出弹框
      console.log(taninfo);
      console.log(this.mainpagedata.datas);
      // this.mainpagedata.tancontent.content = taninfo.content
      // this.mainpagedata.tancontent.title = taninfo.title
      axios
        .post(
          "/admin/Advertiser/viewConfig",
          {
            id: this.$route.query.id,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.result);
          var content1 = []; //弹窗平台统一价钱
          content1 = [
            {
              ismust: true, //是否必填，true表示必填
              title: "优惠券打印单价：",
              placeholder: response.data.result.coupon_price,
              disabled: true,
              name: "keywords",
              content: response.data.result.coupon_price,
            },
            {
              ismust: true, //是否必填，true表示必填
              title: "优惠券核销单价：",
              placeholder: response.data.result.coupon_check,
              disabled: true,
              name: "keywords",
              content: response.data.result.coupon_check,
            },
            {
              ismust: true, //是否必填，true表示必填
              title: " 宣传页打印单价：",
              placeholder: response.data.result.extend_price,
              disabled: true,
              name: "keywords",
              content: response.data.result.extend_price,
            },
            {
              ismust: true, //是否必填，true表示必填
              title: "宣传页核销单价：",
              placeholder: response.data.result.extend_check,
              disabled: true,
              name: "keywords",
              content: response.data.result.extend_check,
            },
          ];
          this.mainpagedata.tancontent.content.myinput.input = content1;

          var content2 = []; //单独价钱
          content2 = [
            {
              ismust: true, //是否必填，true表示必填
              title: "优惠券打印单价：",
              placeholder: "",
              name: "coupon_price",
              content: this.mainpagedata.datas.coupon_print_price,
            },
            {
              ismust: true, //是否必填，true表示必填
              title: "优惠券核销单价：",
              placeholder: "",
              name: "coupon_check",
              content: this.mainpagedata.datas.coupon_writeoff_price,
            },
            {
              ismust: true, //是否必填，true表示必填
              title: " 宣传页打印单价：",
              placeholder: "",
              name: "extend_price",
              content: this.mainpagedata.datas.extend_print_price,
            },
            {
              ismust: true, //是否必填，true表示必填
              title: "宣传页核销单价：",
              placeholder: "",
              name: "extend_check",
              content: this.mainpagedata.datas.extend_writeoff_price,
            },
          ];
          this.mainpagedata.tancontent.content.myinput1.input = content2;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getRadioinfoEvent(data) {
      console.log("从radio获取的值：" + data);
      this.thisradiochange = data;
    },
    getwindowinfo() {
      //获取当前windows页面信息-实时修改【高级筛选】的宽度
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Advertiser/view",
          {
            id: this.$route.query.id,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.result);
          this.mainpagedata.datas = response.data.result;
          var content1 = []; //广告主信息
          content1 = [
            {
              name: "用户ID:",
              value: response.data.result.uid,
            },
            {
              name: "平台来源:",
              value:
                response.data.result.platform == 1
                  ? "微信自助版"
                  : "支付宝自助版",
            },
            {
              name: "商家名称:",
              value: response.data.result.company,
              // jumpbt:'查看状态',
              // jump:'/lookstatus'
            },
            {
              name: "商家业态:",
              value: response.data.result.category,
            },
            {
              name: "联系电话:",
              value: response.data.result.tel,
            },
            {
              name: "地址:",
              value: response.data.result.address,
            },
            {
              name: "审核状态:",
              value: response.data.result.status,
            },
            {
              name: "审核人:",
              value: response.data.result.checker,
            },
            {
              name: "审核备注:",
              value: response.data.result.check_note,
            },
            {
              name: "是否启用:",
              value: response.data.result.is_use == 1 ? "启用" : "禁用",
            },
            {
              name: "修改时间:",
              value: this.$utils.formatDate(response.data.result.addtime),
            },
          ];
          this.mainpagedata.details.content = content1;

          var content2 = []; //广告主价格
          content2 = [
            {
              name: "价格类型:",
              value: response.data.result.price_type_name,
            },
            {
              name: "优惠券打印单价:",
              value: response.data.result.coupon_print_price,
            },
            {
              name: "优惠券核销单价:",
              value: response.data.result.coupon_writeoff_price,
            },
            {
              name: "宣传页打印单价:",
              value: response.data.result.extend_print_price,
            },
            {
              name: "宣传页核销单价:",
              value: response.data.result.extend_writeoff_price,
            },
          ];
          this.mainpagedata.details1.content = content2;

          var content5 = []; //门头照片
          content5 = [
            {
              url: response.data.result.head_img,
              preview: [response.data.result.head_img],
            },
          ];
          this.mainpagedata.imgesdata.content = content5;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
    this.getwindowinfo();
  },
  watch: {
    thisradiochange: {
      handler(newVal) {
        //监听form的数据变化
        console.log(newVal);
        if (newVal === "1") {
          this.showthiscontent = true;
        } else {
          this.showthiscontent = false;
        }
      },
      deep: true,
    },
  },
  components: {
    mainone,
    maindetails,
    mainimgesshow,
    maininput,
    mainradio,
  },
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #97a0c3;
  /*line-height: 60px;*/
  padding: 10px 30px;
  margin-top: 20px;
  margin-bottom: 80px;
}

.box_title {
  line-height: 40px;
  height: 40px;
  margin-top: 10px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #616e9d;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
}

/*******以下是form表单中button的控件css **************************************/
.main_one_button {
  float: right;
}

.main_one_button .el-button:hover,
.myaddbutton .el-button:hover {
  background-color: #ffffff;
  border: 1px solid #94a3d2;
  color: #94a3d2;
  font-size: 14px;
}

.main_one_button .el-button,
.myaddbutton .el-button {
  border: 1px solid #002eb7;
  color: #002eb7;
  background-color: #ffffff;
}

.el-button {
  border-radius: 8px;
  letter-spacing: 1px !important;
}

.el-button i {
  font-size: 14px;
  font-weight: bold;
}

.el-button:active {
  color: #ffffff !important;
  background-color: #002eb7 !important;
}

.main_two_search {
  float: right;
  background-color: #002eb7 !important;
  color: #ffffff !important;
  border-radius: 8px;
}

.main_two_search:hover {
  background-color: #ffffff !important;
  border: 1px solid #94a3d2;
  color: #94a3d2 !important;
  font-size: 14px;
}

.main_two_search:active {
  color: #ffffff !important;
  background-color: #002eb7 !important;
}

/*******以下是弹框的css**************************************/
#mymain :deep(.el-dialog__body) {
  padding: 10px var(--el-dialog-padding-primary) var(--el-dialog-padding-primary) var(--el-dialog-padding-primary) !important;
  /*padding: 10px 0 !important;*/
}

#mymain :deep(.el-dialog__title) {
  font-size: 16px;
  color: #002eb7;
}

#mymain :deep(.el-dialog__header) {
  border-bottom: 1px solid #eaedf7;
}

#mymain :deep(.el-dialog__close) {
  font-size: 18px !important;
}

#mymain :deep(.el-dialog) {
  border-radius: 8px;
}
</style>