<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <div class="box_title" style="">
          <div class="box_title_font">
            <!-- <i class="el-icon-video-camera"></i> -->
            <span style="margin-right: 40px"> 出入提现提醒</span>
          </div>
          <div class="box_title_button"></div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin-top: 20px">
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="7">
              <div style="width: 100%"></div>
            </el-col>
            <!--主表样式-->
            <el-col :span="24" :xs="24" :sm="24" :lg="11">
              <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
                <el-form-item label="推送微信：">
                  <el-button type="primary"
                    @click="this.mypowerlimits.crtxtx_bc == true ? resetFormSenior((dialogVisible = true)) : ''" plain
                    size="small" :disabled="!this.mypowerlimits.crtxtx_bc">选择微信
                  </el-button>
                </el-form-item>
                <!-- 微信头像信息显示 -->
                <el-form-item v-if="ruleForm.union.length != 0">
                  <div style="float: left; margin-right: 20px" v-for="(getavarars, i) in ruleForm.union" :key="i">
                    <div>
                      <el-avatar :src="getavarars.avatar" :size="48" style="border: 1px solid #dcdada"></el-avatar>
                      <i class="el-icon-error" style="
                        color: red;
                        float: right;
                        font-weight: 400;
                        cursor: pointer;
                      " @click="delWechatClient(i)"></i>
                    </div>
                    <div style="text-align: center;">
                      <span>{{ getavarars.nickname }}</span>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitFormSave('ruleForm')" v-if="this.mypowerlimits.crtxtx_bc"
                    style="width: 40%; margin-right: 10px; min-width: 60px">保存
                  </el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="6">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <el-dialog v-model="dialogVisible" title="选择微信" :width="searchwidth" :before-close="handleCloseSenior">
      <el-form ref="seniorform" v-model="seniorform">
        <el-form-item>
          <el-input v-model="seniorform.search" placeholder="请输入微信昵称/手机号" style="max-width: 200px; margin-right: 20px"
            clearable></el-input>
          <el-button type="primary" @click="searchMytableData(seniorform)">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" style="width: 100%" min-height="200" border v-loading="loading"
        element-loading-text="正在飞速加载中..." @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />
        <el-table-column label="ID" prop="id" />
        <el-table-column label="头像" prop="avatar">
          <template #header>
            <div>头像</div>
          </template>
          <template #default="scope">
            <el-avatar :src="scope.row.avatar" style="border: 1px solid #dcdada"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column label="昵称" prop="nickname" />
        <el-table-column label="手机号" prop="mobile" width="150" />
      </el-table>
      <el-row style="margin-top: 10px">
        <el-col :span="24">
          <div style="text-align: right">
            <el-button @click="resetFormSenior((dialogVisible = false))">取消</el-button>
            <el-button type="primary" @click="
              onSubmitSenior(
                tableseniorForm,
                ruleForm,
                (dialogVisible = false)
              )
            ">确定</el-button>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import axios from "axios";
import { ElMessage } from "element-plus";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vwithdrawalremind",
  data() {
    return {
      mainonebread: [
        {
          name: "财务",
        },
        {
          name: "提现管理",
        },
        {
          name: "出入提现提醒",
          path: "/withdrawalremind",
        },
      ], // 当前页-【面包屑导航】

      ruleForm: {
        union: [
          // {
          //   unionid: 3,
          //   avatar:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
          //   nickname: "张三",
          // },
        ],
      }, //form表单
      rules: {
        name: [
          {
            required: true,
            message: "推送微信为必填项",
            trigger: "blur",
          },
        ],
      }, //form表单-必填规则

      //弹出框
      dialogVisible: false,
      searchwidth: "920px",
      //弹出框中-搜索条件
      seniorform: {
        search: "",
      },
      //弹出框中-table 表单
      tableData: [
        // {
        //   unionid: 3,
        //   avatar:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        //   nickname: "张三",
        // },
      ],
      tableseniorForm: {
        union: [],
      },
      loading: false, //弹出框中-table表单-加载动效

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
    };
  },
  methods: {
    //删除【逻辑】-微信
    delWechatClient(ide) {
      this.ruleForm.union.splice(ide, 1);
    },
    //保存-出入提现提醒
    submitFormSave(formName) {
      var getunionids = [];
      this.$refs[formName].model.union.forEach((element) => {
        getunionids.push(element.unionid);
      });
      console.log("unionids is :" + getunionids);
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/plat/warnUser",
          {
            unionid: getunionids,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code === 0) {
            ElMessage({
              type: "success",
              message: "保存成功！",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 弹窗中-搜索查询
    searchMytableData(datas) {
      //table表单加载动效
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/ajax/getWechatUser",
          {
            keywords: datas.search,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code === 0) {
            this.tableData = response.data.result.list;
            this.loading = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //弹窗中-table表单-多选按钮
    handleSelectionChange(val) {
      // 存储所选择的设备
      this.tableseniorForm.union = val;
    },
    //弹窗中-确认-提交按钮
    onSubmitSenior(datas, ruledatas) {
      // 判断所选-微信是否重复-并将重复的去除
      for (let i = 0; i < datas.union.length; i++) {
        const getdatas = datas.union[i];
        for (let j = 0; j < ruledatas.union.length; j++) {
          const getunion = ruledatas.union[j];
          if (getdatas.unionid == getunion.unionid) {
            ElMessage({
              type: "warning",
              message: "微信为(" + getunion.nickname + " )已存在，无需重复添加",
              offset: (j + 1) * 60,
            });
            //去除重复-并补位为null
            datas.union.splice(i, 1, null)
          }
        }
      }
      //去除数组中的null值
      datas.union = datas.union.filter(n => n)
      //将去重之后的数据-存入
      for (let index = 0; index < datas.union.length; index++) {
        const element = datas.union[index];
        this.ruleForm.union.push(element)
      }
    },
    //弹窗中-取消【重置】-提交按钮
    resetFormSenior() {
      this.tableData = [];
      this.seniorform.search = "";
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 950) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "920px";
      }
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/plat/warnUser",
          {},
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.ruleForm.union = response.data.result.list;
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    }
  },
  components: {
    mainone,
  },
});
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
}
</style>