<template>
    <div class="info-box-all">
        <div v-for="(item, index) in details" :key="index" class="info-box">
            <div>
                <span>{{ item.name }}</span>
            </div>
            <div>
                <div v-if="item.image">
                    <div class="image-box" v-for="(jtem, jndex) in item.value" :key="jndex">
                        <el-image :src="jtem" :preview-src-list="item.value" style="width: 100px; height: 100px"></el-image>
                    </div>
                </div>
                <div v-else>
                    <slot name="valueSlot" :scope="item">
                        <span>{{ item.value }}</span>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActivityInfo',
    props: {
        details: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.info-box-all {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .info-box {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: flex-start;
        gap: 10px;
    }

    .image-box {
        border: 1px dashed #999;
        border-radius: 4px;
        padding: 2px;
    }
}
</style>
