<template>
    <div id="mymain">
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">合同编号：<span>{{ this.derinfo.trade_no }}</span></div>
                    <div class="box_title_button">
                        <el-button type="primary" v-if="this.derinfo.status == -1"
                            @click="clickDealEvent(this.derinfo)">处理</el-button>
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <!-- 签约信息 -->
                    <div>
                        <maindetails :detailsdata="details" style="margin-top: 20px"></maindetails>
                        <div class="fujia-box">
                            <div class="fujia-box-content">
                                <span class="fujia-box-content-span">签约设备:</span>
                            </div>
                            <div class="fujia-box-info">
                                <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
                                    :data="tableData" style="width: 100%" min-height="900" border>
                                    <!--多选框-用于用户选择性导出数据-->
                                    <el-table-column v-for="(mytabletitle, i) in tableDataTitle" :key="i"
                                        :fixed="mytabletitle.fixedstatu" :prop="mytabletitle.prop"
                                        :label="mytabletitle.label" :width="mytabletitle.width"
                                        :min-width="mytabletitle.minwidth">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <!-- 履约信息 -->
                    <div>
                        <div>
                            <span class="box-content-title">履约信息</span><span class="shuoming">（数据更新时间截止自 {{
                                    this.$utils.formatDate(this.derinfo['appoint']['addtime'])
                            }}）</span>
                        </div>
                        <div class="ly-box">
                            <el-descriptions title="" :column="4" border direction="vertical">
                                <el-descriptions-item>
                                    <div class="ly-box-title">
                                        <div class="ly-box-title-div" v-for="(item, index) in this.lytitle"
                                            :key="index">{{ item }}</div>
                                    </div>
                                </el-descriptions-item>
                                <el-descriptions-item label="合计" label-align="center" align="center">
                                    <div class="ly-box-title-center">
                                        <div class="ly-box-title-div-center" v-for="(item, index) in this.lytitle_heji"
                                            :key="index">{{ item }}</div>
                                    </div>
                                </el-descriptions-item>
                                <el-descriptions-item label="已生效" label-align="center" align="center">
                                    <div class="ly-box-title-center">
                                        <div class="ly-box-title-div-center"
                                            v-for="(item, index) in this.lytitle_shengxiao" :key="index">{{ item }}
                                        </div>
                                    </div>
                                </el-descriptions-item>
                                <el-descriptions-item label="待失效" label-align="center" align="center">
                                    <div class="ly-box-title-center">
                                        <div class="ly-box-title-div-center"
                                            v-for="(item, index) in this.lytitle_shixiao" :key="index">{{ item }}</div>
                                    </div>
                                </el-descriptions-item>
                            </el-descriptions>

                        </div>
                        <div class="ly-box fujia-box-content-span" style="margin-bottom: 10px;">
                            <span>退款金额：</span><span style="color: red;">{{ this.return_money }} 元</span>
                        </div>
                    </div>

                    <!-- 处理信息 -->
                    <div v-if="this.derinfo.auditInfo">
                        <el-divider></el-divider>
                        <maindetails :detailsdata="details2" style="margin-top: 20px"></maindetails>
                        <div class="fujia-box">
                            <div class="fujia-box-content">
                                <span class="fujia-box-content-span">图片附件:</span>
                            </div>
                            <div>
                                <mainimgesshow :imgesdata="this.details2.imgesdata"></mainimgesshow>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- 弹窗-处理 -->
        <derContractToExamine :Id="this.$route.query.id" :ReturnMoney="this.return_money"
            :DialogVisible="this.dialogVisible" v-on:istruedialog="istruedialogEvent" />

    </div>
</template>
  
<script>
import mainone from "@/components/Mainone.vue";
import maindetails from "@/components/Maindetails";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import mainimgesshow from "@/components/Mainimgesshow";
import axios from "axios";
import { ref } from "vue";

export default {
    name: "vuserinfolist",
    inject: ["reload"], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: "金融产品",
                },
                {
                    name: "合伙人",
                },
                {
                    name: "合同终止审批",
                    path: '/dercontractstop'
                },
                {
                    name: "合同详情",
                },
            ], // 当前页-【面包屑导航】

            // 签约信息
            details: {
                showstatus: 1, //确定样式模块
                title: "签约信息",
                button: {
                    isshow: false,
                    name: "",
                    jump: "", //点击跳转
                },
                content: [
                    {
                        label: 'money',
                        name: "合同金额:",
                    },
                    {
                        label: 'name',
                        name: "姓名:",
                    },
                    {
                        label: 'deadline',
                        name: "租赁时长:",
                    },
                    {
                        label: 'id_card',
                        name: "身份证号:",
                    },
                    {
                        label: 'back_money',
                        name: "满期获得:",
                    },
                    {
                        label: 'mobile',
                        name: "手机号:",
                    },
                    {
                        label: 'signtime',
                        name: "签约日期:",
                    },
                ],
            },
            // 处理信息
            details2: {
                showstatus: 1, //确定样式模块
                title: "处理信息",
                button: {
                    isshow: false,
                    name: "",
                    jump: "", //点击跳转
                },
                content: [
                    {
                        label: 'admin_info',
                        name: "处理人:",
                    },
                    {
                        label: 'status',
                        name: "处理方式:",
                    },
                    {
                        label: 'addtime',
                        name: "处理时间:",
                    },
                    {
                        label: 'remark',
                        name: "备注信息:",
                    },
                ],
                imgesdata: {
                    showstatus: 2,
                    content: [],
                },
            }, // 当前Pagedata所需的data数据与控制
            // 处理状态
            dealstatus: [
                {
                    label: '同意终止合同',
                    value: '1'
                },
                {
                    label: '驳回',
                    value: '2'
                }
            ],
            // 单位
            unit: componentProperties.get('dercontractset').unit,

            // 签约设备
            tableDataTitle: [
                {
                    fixedstatu: false,
                    prop: "client",
                    label: "设备编号",
                    minwidth: "180",
                },
                {
                    fixedstatu: false,
                    prop: "shop_code_end",
                    label: "商户名称",
                    minwidth: "200",
                },
                {
                    fixedstatu: false,
                    prop: "shop_code_begin",
                    label: "代理商",
                    minwidth: "200",
                },
                {
                    fixedstatu: false,
                    prop: "printname",
                    label: "设备型号",
                    minwidth: "200",
                },
                {
                    fixedstatu: false,
                    prop: "address",
                    label: "设备地址",
                    minwidth: "400",
                }
            ],
            tableData: [],

            // 履约信息
            // title
            lytitle: ['时间', '合同本金', '返还金额'],
            // 合计
            lytitle_heji: ['-', '-', '-'],
            // 已生效
            lytitle_shengxiao: ['-', '-', '-'],
            // 已失效
            lytitle_shixiao: ['-', '-', '-'],
            // 退款金额
            return_money: '0.00',

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

            // 获取的数据
            derinfo: {
                appoint: {
                    addtime: '',
                }
            },

            // 处理弹窗
            dialogVisible: ref(false), //弹窗显示&隐藏

        };
    },
    methods: {
        // 点击按钮-处理
        clickDealEvent(data) {
            this.return_money = data.return_money;
            this.dialogVisible = true;
        },
        // 弹窗-反显
        istruedialogEvent(data) {
            this.dialogVisible = data;
        },

        //点击进入路由页面
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                this.$router
                    .push({ path: routeraddr, query: rowdata })
                    .catch((error) => error);
            } else {
                this.$router.push(routeraddr).catch((error) => error);
            }
        },

        // 获取当前页面相关数据
        getAlldatas(data) {
            this.derinfo = data;
            // 签约信息
            this.details.content.forEach(element => {
                if (element.label == 'id_card') {
                    element['value'] = this.$utils.hiddenCardId(data['id_card'])
                } else if (element.label == 'money') {
                    element['value'] = (Math.floor(data['money'] * 100) / 100).toFixed(2) + ' 元'
                } else if (element.label == 'deadline') {
                    element['value'] = data['deadline'] + this.unit.filter(n => n.value == data.unit)[0]['label']
                } else if (element.label == 'back_money') {
                    element['value'] = (Math.floor(data['back_money'] * 100) / 100).toFixed(2) + ' 元'
                } else if (element.label == 'signtime') {
                    element['value'] = this.$utils.formatDate(data['signtime'])
                } else {
                    element['value'] = data[element.label];
                }
            });
            // 签约设备
            this.tableData = data['clientInfos'];

            // 履约信息
            this.lytitle_heji[0] = data['appoint']['time'] + this.unit.filter(n => n.value == data['appoint'].unit)[0]['label']
            this.lytitle_heji[1] = (Math.floor(data['appoint']['agree'] * 100) / 100).toFixed(2) + ' 元'
            this.lytitle_heji[2] = (Math.floor(data['appoint']['back'] * 100) / 100).toFixed(2) + ' 元'
            this.lytitle_shengxiao[0] = data['appoint']['timeOn'] + this.unit.filter(n => n.value == data['appoint'].unit)[0]['label']
            this.lytitle_shengxiao[1] = (Math.floor(data['appoint']['agreeOn'] * 100) / 100).toFixed(2) + ' 元'
            this.lytitle_shengxiao[2] = (Math.floor(data['appoint']['backOn'] * 100) / 100).toFixed(2) + ' 元'
            this.lytitle_shixiao[0] = data['appoint']['timeOff'] + this.unit.filter(n => n.value == data['appoint'].unit)[0]['label']
            this.lytitle_shixiao[1] = (Math.floor(data['appoint']['agreeOff'] * 100) / 100).toFixed(2) + ' 元'
            this.lytitle_shixiao[2] = (Math.floor(data['appoint']['backOff'] * 100) / 100).toFixed(2) + ' 元'
            // 退款金额
            this.return_money = (Math.floor(data['appoint']['return_money'] * 100) / 100).toFixed(2)


            // 处理信息相关
            if (data.auditInfo) {
                // 处理信息
                this.details2.content.forEach(element => {
                    if (element.label == 'status') {
                        element['value'] = this.dealstatus.filter(n => n.value == data['auditInfo'].status)[0]['label']
                    } else if (element.label == 'addtime') {
                        element['value'] = this.$utils.formatDate(data['auditInfo']['addtime'])
                    } else {
                        element['value'] = data['auditInfo'][element.label];
                    }
                });

                // 处理图片（回复图片）
                let getimages = [];
                if (data['auditInfo'].iamges) {
                    let preview = [];
                    data['auditInfo'].iamges.split(',').forEach((element) => {
                        preview.push(element);
                        let data = {
                            url: element,
                            preview: preview,
                        };
                        getimages.push(data);
                    });
                }
                this.details2.imgesdata.content = getimages;
            }


        }
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {
            // 获取当前页面数据
            axios
                .get("/admin/lease/auditInfo", {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                    params: { id: this.$route.query.id },
                })
                .then((response) => {
                    this.getAlldatas(response.data.result)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    components: {
        mainone,
        maindetails,
        mainimgesshow,
    },
};
</script>
  
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}

.tan-box {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 5% 0 0;
}

.box-content-title {
    font-size: 14px;
    color: #333333;
    font-weight: 600;
}

.fujia-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px 0 20px 0;
}

.fujia-box-content {
    min-width: 80px;
    margin-right: 20px
}

.fujia-box-content-span {
    font-size: 14px;
    color: #333333;
    margin-left: 38px
}

.fujia-box-info {
    width: calc(100% - 160px);
}

.shuoming {
    font-size: 14px;
    margin-left: 20px;
}

.ly-box {
    margin: 20px 0 0 0;
    padding: 0 40px;
}

.cell-item {
    background-color: #94a3d2;
}

.ly-box-title {
    width: 100%;
    min-width: 100px;
    background-color: var(--el-descriptions-item-bordered-label-background);
    color: var(--el-text-color-secondary);
    border: var(--el-descriptions-table-border);
    border-block-end: none;
}

.ly-box-title-div {
    border-block-end: var(--el-descriptions-table-border);
    padding: 12px 10px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 600;
    line-height: 1.5;
}

.ly-box-title-center {
    width: 100%;
    min-width: 100px;
    color: var(--el-text-color-secondary);
    border: var(--el-descriptions-table-border);
    border-block-end: none;
}

.ly-box-title-div-center {
    border-block-end: var(--el-descriptions-table-border);
    padding: 12px 10px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 400;
    line-height: 1.5;
    color: #333333;
}
</style>