<template>
	<el-drawer title="优惠券发放明细" v-model="dialogDisplay" @close="onCancel" direction="rtl" size="70%">
		<el-table :border="true" :data="detail" v-loading="loading" :header-cell-style="{backgroundColor: 'rgb(245, 247, 250)'}" >
			<el-table-column prop="cr_receive_time" label="发放时间" >
				<template #default="{ row }">
					<span>{{ row.cr_receive_time ? $utils.formatDate(row.cr_receive_time) : '' }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="cl_name" label="优惠券名称" />
			<el-table-column prop="cl_face_val" label="优惠券面额" >
				<template #default="{ row }">
					<span>{{ formatPrice(row.cl_face_val) }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="skuNum" label="消费状态">
				<template #default="{ row }">
					<span>{{ row.cr_is_used == '1' ? '已消费': row.cr_is_used == '0' ? '待消费' : row.cr_receive_time ? '已领取':'未领取' }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="cr_expire_time" label="作废时间" >
				<template #default="{ row }">
					<span>{{ row.cr_expire_time ? $utils.formatDate(row.cr_expire_time) : '' }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="cr_is_used" label="作废状态">
				<template #default="{ row }">
					<span>{{ row.cr_is_used == '-1' ? '已作废':'未作废' }}</span>
				</template>
			</el-table-column>
		</el-table>
	</el-drawer>
</template>

<script setup name="SendCreditDetail">
import { ref, reactive, watch, defineProps, defineEmits, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import http from '@/assets/js/http'

const dialogDisplay = ref(false)
const emit = defineEmits(['cancel'])
const loading = ref(false)

const props = defineProps({
	id: {
		type: Number,
		default: ()=> {
			return undefined
		}
	},
	dialog: {
		type: Boolean,
		default: ()=> {
			return false
		}
	}
})

watch(props, async (newVal)=> {
	if(newVal.dialog) {
		dialogDisplay.value = true
		getDetail()
	} 
}, { deep: true })

const detail = ref([])

const getDetail = async ()=> {
	const { id } = props
    detail.value = await http.post('meizhiJicaiOrder/userCouponList', { id })
}

const formatPrice = (data)=> {
	return data ?  '￥'+ (+data*100 / 100).toFixed(2) : '0'
}

const onCancel = ()=> {
	emit('cancel')
}
</script>

<style lang="scss" scoped>

.line {
	margin-top: 20px;
}
</style>