<template>
  <el-dialog
    v-model="visible"
    width="700px"
    title="优惠券"
    @close="closeDialog"
  >
    <div class="issue-coupon">
      <div class="t-line">
        <h4>注：发放失败状态，可能由于优惠券规则导致的失败。</h4>
        <el-button type="warning" :loading="loading" plain @click="getIssueList(1)">导出</el-button>
      </div>
      <el-table
        :data="tableData"
        border
        height="400px"
        v-loading="loading"
      >
        <template v-for="(column, index) in tableJSON" :key="index">
          <el-table-column
            :prop="column.prop"
            :label="column.label"
            :formatter="column.formatter"
          />
        </template>
      </el-table>
      
      <div class="pagination-box">
        <el-pagination
          v-model:page-size="pagination.page_size"
          v-model:current-page="pagination.page"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :total="pagination.total"
          @sizeChange="getIssueList"
          @currentChange="getIssueList"
        />
      </div>
    </div>
    <template #footer>
      <el-button @click="closeDialog">返回</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {ref} from 'vue'
import http from '@/assets/js/http'
import utils from '@/assets/js/utils'
import {ElMessage} from 'element-plus'

const visible = ref(false)
const loading = ref(false)
const tableData = ref([])
const tableJSON = ref([
  {
    prop: 'title',
    label: '优惠券名称'
  },
  {
    prop: 'status',
    label: '发放状态',
    formatter: (row) => {
      const k2v = {
        '-1': '未发放',
        '1': '已发放',
        '2': '发放失败'
      }
      return k2v[row.status.toString()]
    }
  },
  {
    prop: 'ask_grant_time',
    label: '预计发放时间',
    formatter: (row) => {
      return row.ask_grant_time ? utils.formatDate(row.ask_grant_time) : ''
    }
  },
  {
    prop: 'grant_time',
    label: '实际发放时间',
    formatter: (row) => {
      return row.grant_time ? utils.formatDate(row.grant_time) : ''
    }
  },
])
const pagination = ref({
  page: 1,
  page_size: 10,
  total: 0
})
const orderId = ref('')

const getIssueList = (isExport = 0) => {
  loading.value = true
  http.post(`/Futu/couponGrantIndex`, {
    order_id: orderId.value,
    export: isExport
  }).then(res => {
    if(!isExport) {
      const {total, data} = res
      tableData.value = data
      pagination.value.total = total
    } else {
      if(res.code === 0) ElMessage.warning(res.message)
      else ElMessage.error(res.message)
    }
  }).finally(() => {
    loading.value = false
  })
}

const openDialog = (row) => {
  visible.value = true
  orderId.value = row.orderid
  getIssueList()
}
const closeDialog = () => {
  visible.value = false
}

defineExpose({
  open: openDialog
})
</script>

<style lang="scss" scoped>
@use "../style.scss";
</style>
