<template>
  <div>
    <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <div class="box_title" style="">
          <div class="box_title_font">
            <i class="el-icon-s-operation"></i> 添加课程
          </div>
          <div class="box_title_button">
            <!--可添加按钮-->
            <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
          </div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin-top: 20px">
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="7">
              <div style="width: 100%"></div>
            </el-col>
            <!--主表样式-->
            <el-col :span="24" :xs="24" :sm="24" :lg="9">
              <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                label-width="140px"
                class="demo-ruleForm"
              >
                <el-form-item label="课程名称：" prop="name">
                  <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入课程名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="课程分类：" prop="classify_id">
                  <el-select
                    v-model="ruleForm.classify_id"
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option
                      :label="getsales.sort_key"
                      :value="getsales.id"
                      v-for="(getsales, i) in this.catalog"
                      :key="i"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="课程封面：" prop="cover_img">
                  <mainimgesup
                    :myimgesup="mainpagedata.myimgesup"
                    v-model="ruleForm.cover_img"
                    :myimgesupisshow="mainpagedata.myimgesupisshow"
                    v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                  ></mainimgesup>
                </el-form-item>
                <el-form-item label="课程价格：" prop="price">
                  <el-input
                    v-model="ruleForm.price"
                    placeholder="请输入课程价格"
                  ></el-input>
                </el-form-item>
                <el-form-item label="PLUS课程价格：" prop="vip_price">
                  <el-input
                    v-model="ruleForm.vip_price"
                    placeholder="请输入PLUS课程价格"
                  ></el-input>
                </el-form-item>
                <el-form-item label="虚拟学习人数：" prop="empty_count">
                  <el-input
                    v-model="ruleForm.empty_count"
                    placeholder="请输入虚拟学习人数"
                  ></el-input>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                  <el-input
                    v-model="ruleForm.sort"
                    placeholder="数字越大排序越靠前"
                  ></el-input>
                </el-form-item>
                <el-form-item label="课程介绍：" prop="content">
                  <el-input
                    v-model="ruleForm.content"
                    type="textarea"
                    :rows="10"
                    placeholder="请输入课程介绍"
                  ></el-input>
                </el-form-item>
                <el-form-item label="课程内容：" prop="course_chapter">
                  <el-table
                    row-key="id"
                    border
                    :data="tableData"
                    style="width: 100%; border-radius: 8px"
                  >
                    <el-table-column prop="name" label="章节名称">
                      <template #header>
                        <div class="myaddbutton">
                          <span style="line-height: 40px">章节目录</span>
                          <el-button type="primary" @click="addCapter()"
                            >新增章节
                          </el-button>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="250">
                      <template #default="scope">
                        <el-button
                          size="mini"
                          type="danger"
                          @click="
                            handleDelete(scope.$index, tableData, scope.row.id)
                          "
                          v-if="scope.row.showdel"
                          >删除
                        </el-button>
                        <el-button
                          size="mini"
                          type="primary"
                          @click="
                            handleEdit(scope.$index, tableData, scope.row.id)
                          "
                          v-if="scope.row.showedit"
                          >编辑
                        </el-button>
                        <el-button
                          size="mini"
                          type="warning"
                          v-if="scope.row.showadd"
                          @click="
                            addCourse(
                              scope.$index,
                              tableData,
                              scope.row.id,
                              (dialogVisible = true)
                            )
                          "
                          >添加课件
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
                <el-form-item label="师资介绍：" prop="teacher_content">
                  <el-input
                    v-model="ruleForm.teacher_content"
                    type="textarea"
                    :rows="10"
                    placeholder="请输入师资介绍"
                  ></el-input>
                </el-form-item>
                <el-form-item label="上架：" prop="is_up">
                  <el-radio-group v-model="ruleForm.is_up">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="2">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="推荐：" prop="is_push">
                  <el-radio-group v-model="ruleForm.is_push">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="2">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="submitForm()"
                    style="width: 40%; margin-right: 10px"
                    >确认
                  </el-button>
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="8">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="添加课件">
      <div>
        <el-input
          v-model="courseware_name"
          style="width: 200px"
          placeholder="请输入课件名称"
        ></el-input>

        <el-select v-model="courseware_cat" placeholder="请选择">
          <el-option
            :label="getsales.sort_key"
            :value="getsales.id"
            v-for="(getsales, i) in this.courseware_cat_list"
            :key="i"
          ></el-option>
        </el-select>
        <el-button @click="searchCourseware()">搜索</el-button>
      </div>
      <div>
        <el-table @selection-change="changeFun" :data="this.mainpagedata.coursedata">
          <el-table-column type="selection"> </el-table-column>
          <el-table-column label="课件名称" prop="title"></el-table-column>
          <el-table-column label="课程" prop="category"></el-table-column>
          <el-table-column label="作者" prop="add_user"></el-table-column>
          <el-table-column label="创建时间" prop="createtime"></el-table-column>
        </el-table>
        <div class="block" style="text-align: center;margin: 30px 0">
          <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount">
          </el-pagination>
        </div>
      </div>
      <el-button @click="doAddCourse()">确定</el-button>
    </el-dialog>
  </div>
</template>

<script>
import mainone from "@/components/Mainone.vue";
import mainimgesup from "@/components/Mainimgesup.vue";
import { ref } from "vue";
import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "vcurriculumlistadd",
  data() {
    return {
      dialogVisible: false,
      current_id: 0,
      checkBoxData: [],
      courseware_cat: "",
      courseware_cat_list: [],
      courseware_name: "",
      currentPage:1,
      PageSize:10,
      totalCount:0,
      mainpagedata: {
        mainonebread: [
          {
            name: "教育",
          },
          {
            name: "课程管理",
          },
          {
            name: "课程列表",
            path: "/curriculumlist",
          },
          {
            name: "添加课程",
            path: "/curriculumlistadd",
          },
        ], // 当前页-【面包屑导航】
        myinputishow: true, //form表单-input单框
        myinput: {
          inputwidth: "100%",
          input: [
            {
              ismust: true, //是否必填，true表示必填
              title: "课程名称：",
              placeholder: "请输入课程名称",
              name: "name",
              content: "",
            },
          ],
        },
        catalog: [],
        coursedata: [],

        myinput1: {
          inputwidth: "100%",
          input: [
            {
              ismust: true, //是否必填，true表示必填
              title: "课程价格：",
              placeholder: "请输入课程价格",
              name: "price",
              content: "",
            },
            {
              ismust: true, //是否必填，true表示必填
              title: "PLUS课程价格：",
              placeholder: "请输入PLUS课程价格",
              name: "price_plus",
              content: "",
            },
            {
              ismust: true, //是否必填，true表示必填
              title: "虚拟学习人数：",
              placeholder: "请输入虚拟学习人数",
              name: "studynum",
              content: "",
            },
            {
              ismust: true, //是否必填，true表示必填
              title: "排序：",
              placeholder: "数字越大排序越靠前",
              name: "level",
              content: "",
            },
          ],
        },
        myinput2: {
          inputwidth: "100%",
          input: [
            {
              ismust: true, //是否必填，true表示必填
              title: "课程介绍：",
              placeholder: "请输入课程介绍",
              name: "keywords",
              content: "",
              textarea: "textarea",
              textareaheight: 3,
            },
            {
              ismust: true, //是否必填，true表示必填
              title: "师资介绍：",
              placeholder: "请输入师资介绍",
              name: "keywords",
              content: "",
              textarea: "textarea",
              textareaheight: 3,
            },
          ],
        },
        myselectisshow: true, //form表单-select单框
        myselect: {
          selectwidth: "100%",
          select: [
            {
              selectisshow: true,
              ismust: true,
              title: "课程分类：",
              placeholder: "全部",
              name: "fenlei",
              value: "",
              options: [
                {
                  value: "0",
                  label: "全部",
                },
                {
                  value: "1",
                  label: "一级代理",
                },
                {
                  value: "2",
                  label: "二级代理",
                },
                {
                  value: "3",
                  label: "三级代理",
                },
              ],
            },
          ],
        },
        myimgesupisshow: true, //form表单-imgesuploade上传图片
        myimgesup: {
          uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
          listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
          ismust: true, //是否必填，true表示必填
          action: "/api/admin/upload/file", //upload上传API地址
          headers: {
            Authentication: localStorage.getItem("token"),
          }, //upload上传headers
          title: "上传图片", //button-按钮文字
          tips: "(1)建议尺寸：1070*1830像素; (2)视频仅持格式：*.mp4; (3)图片支持格式：*.jpg; (4)最大支持上传20M文件", //提示&建议文字
          limit: "1", //上传数量限制
          target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
          rules: {
            images: {
              image_width: 0, //图片宽度
              image_height: 0, //图片高度
              size: 2000, //文件大小（单位kb）
              type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
              typechart: "jpg/png", //文件格式提示规则
            },
            video: {
              size: 10000, //文件大小（单位kb）
              type: ["video/mp4"], //文件格式
              typechart: "mp4", //文件格式提示规则
            },
          },
        },
        myradioisshow: true, //form表单-radio单选框
        myradio: {
          radio: [
            {
              titlewidth: "100px",
              ismust: false,
              title: "上架：",
              name: "status",
              value: ref("1"),
              radio: [
                {
                  content: "是",
                  label: "1",
                },
                {
                  content: "否",
                  label: "-1",
                },
              ],
            },
            {
              titlewidth: "100px",
              ismust: false,
              title: "推荐：",
              name: "status",
              value: ref("1"),
              radio: [
                {
                  content: "是",
                  label: "1",
                },
                {
                  content: "否",
                  label: "-1",
                },
              ],
            },
          ],
        }, //form表单-radio选框
      }, // 当前页面所需的data数据与控制
      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token

      tableData: [
        // {
        //   id: 1,
        //   showedit: true,
        //   showadd: true,
        //   showdel: true,
        //   name: "章节01",
        //   children: [
        //     {
        //       id: 11,
        //       showdel: true,
        //       name: "初级项目管理课程【视频】",
        //     },
        //     {
        //       id: 12,
        //       showdel: true,
        //       name: "初级项目管理课程【视频】",
        //     },
        //   ],
        // },
      ],
      ruleForm: {
        name: "",
        classify_id: "",
        cover_img: "",
        content: "",
        vip_price: "",
        price: "",
        teacher_content: "",
        empty_count: "",
        course_chapter: [],
        sort: "",
        is_up: ref("1"),
        is_push: ref("1"),
      },
      rules: {
        name: [
          {
            required: true,
            message: "课程名称为必填项",
            trigger: "blur",
          },
        ],
        classify_id: [
          {
            required: true,
            message: "课程分类为必填项",
            trigger: "blur",
          },
        ],
        cover_img: [
          {
            required: true,
            message: "课程封面为必填项",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "课程介绍为必填项",
            trigger: "blur",
          },
        ],
        vip_price: [
          {
            required: true,
            message: "PLUS课程价格为必填项",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "课程价格为必填项",
            trigger: "blur",
          },
        ],
        teacher_content: [
          {
            required: true,
            message: "师资介绍为必填项",
            trigger: "blur",
          },
        ],
        empty_count: [
          {
            required: true,
            message: "虚拟学习人数为必填项",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "排序为必填项",
            trigger: "blur",
          },
        ],
        course_chapter: [
          {
            required: true,
            message: "章节内容为必填项",
            trigger: "blur",
          },
        ],
        is_up: [
          {
            required: true,
            message: "请选择选项",
            trigger: "change",
          },
        ],
        is_push: [
          {
            required: true,
            message: "请选择选项",
            trigger: "change",
          },
        ],
      }, //form表单-必填规则
    };
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      // 加载课程分类列表
      axios
        .post(
          "/admin/Course/indexCourseCate",
          {
            addtime: "2020-10-01~2021-10-31", //该字段为必传项-当空值，表示查询当月数据
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.catalog = response.data.result.data;
          console.log(this.catalog);
        })
        .catch(function (error) {
          console.log(error);
        });
      //加载课件列表
      this.getCourseWare();
      //加载课件分类

      axios
        .post(
          "/admin/Course/indexCoursewareCate",
          {},
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.courseware_cat_list = response.data.result.data;
          this.courseware_cat_list.unshift({ id: 0, sort_key: "全部" });
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    }
  },
  mounted() {},
  methods: {
    handleSizeChange(val){
        this.PageSize = val; 
        this.getCourseWare()
    },
    handleCurrentChange(val){
        this.currentPage = val;
        this.getCourseWare()
    },
      
    getCourseWare() {
  
      var param = {
        page: this.currentPage,
        page_size: this.PageSize,
        content: this.courseware_name,
      };
      console.log("this.courseware_name:" + this.courseware_name);

      if (this.courseware_cat != "0") {
        param["category"] = this.courseware_cat;
      }
      console.log("category:" + param["category"]);
      axios
        .post("/admin/Course/indexCourseware", param, {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("---!!-----");
          console.log(response.data.result);
          this.mainpagedata.coursedata = response.data.result.data;
          this.currentPage=response.data.result.current_page
          this.PageSize=response.data.result.per_page
          this.totalCount=response.data.result.total
          
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    searchCourseware() {
      this.getCourseWare();
    },
    getUploadFileEvent(data) {
      this.ruleForm.cover_img = data.url;
    },
    changeFun(val) {
      this.checkBoxData = val;
    },
    submitForm() {
      console.log("----!!----");
      this.ruleForm.course_chapter = [];
      for (let j = 0; j < this.tableData.length; j++) {
        var item = this.tableData[j];
        this.ruleForm.course_chapter.push({
          name: item.name,
          ids: "",
        });
        if (item.children.length > 0) {
          for (let k = 0; k < item.children.length; k++) {
            this.ruleForm.course_chapter[j].ids += item.children[k].id + ",";
          }
        }
      }

      console.log(this.ruleForm.course_chapter);
      axios
        .post(
          "/admin/Course/addCourse",
          {
            name: this.ruleForm.name,
            classify_id: this.ruleForm.classify_id,
            cover_img: this.ruleForm.cover_img,
            content: this.ruleForm.content,
            vip_price: this.ruleForm.vip_price,
            price: this.ruleForm.price,
            teacher_content: this.ruleForm.teacher_content,
            empty_count: this.ruleForm.empty_count,
            sort: this.ruleForm.sort,
            course_chapter: this.ruleForm.course_chapter,
            is_up: this.ruleForm.is_up,
            is_push: this.ruleForm.is_push,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            ElMessage({
              type: "success",
              message: response.data.message,
              offset: 300,
            });
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    addCapter() {
      ElMessageBox.prompt("课程名称", "请输入课程名称:", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: this.dialoglabel + "不能为空",
      })
        .then(({ value }) => {
          this.tableData.push({
            id: this.tableData.length + 1,
            showedit: true,
            showadd: true,
            showdel: true,
            name: value,
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Input canceled",
          });
        });
    },
    doAddCourse() {
      for (let key = 0; key < this.tableData.length; key++) {
        if (this.current_id == this.tableData[key].id) {
          this.tableData[key]["children"] = [];
          for (let i = 0; i < this.checkBoxData.length; i++) {
            var item = this.checkBoxData[i];
            this.tableData[key]["children"].push({
              id: item.id,
              showdel: true,
              name: item.title,
            });
          }
          console.log(this.tableData[key]["children"]);
        }
      }
      this.dialogVisible = false;
    },
    addCourse(index, rows, ide) {
      console.log(index);
      console.log(rows);
      console.log(ide);
      this.current_id = ide;
    },
    handleEdit(index, rows, ide) {
      ElMessageBox.prompt("课程名称", "请输入课程名称:", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: this.dialoglabel + "不能为空",
      })
        .then(({ value }) => {
          console.log(value);

          for (let key = 0; key < rows.length; key++) {
            if (ide == rows[key].id) {
              rows[key].name = value;
            }
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Input canceled",
          });
        });
    },
    handleDelete(index, rows, ide) {
      for (let key = 0; key < rows.length; key++) {
        if (rows[key]["children"] && rows[key]["children"].length > 0) {
          for (let p = 0; p < rows[key]["children"].length; p++) {
            if (!rows[key]["children"][p]["children"]) {
              if (ide == rows[key]["children"][p].id) {
                rows[key]["children"].splice(p, 1);
              }
            }
          }
        } else {
          if (ide == rows[key].id) {
            rows.splice(key, 1);
          }
        }
      }
    },
  },
  components: {
    mainone,
    mainimgesup,
  },
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #97a0c3;
  line-height: 60px;
  padding: 10px 30px;
  margin-top: 20px;
  margin-bottom: 80px;
}

.box_title {
  line-height: 40px;
  height: 40px;
  margin-top: 10px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #616e9d;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
}

/*******以下是form表单中button的控件css **************************************/
.main_one_button {
  float: right;
}

.main_one_button .el-button:hover,
.myaddbutton .el-button:hover {
  background-color: #ffffff;
  border: 1px solid #94a3d2;
  color: #94a3d2;
  font-size: 14px;
}

.main_one_button .el-button,
.myaddbutton .el-button {
  border: 1px solid #002eb7;
  color: #002eb7;
  background-color: #ffffff;
}

.el-button {
  border-radius: 8px;
  letter-spacing: 1px !important;
}

.el-button i {
  font-size: 14px;
  font-weight: bold;
}

.el-button:active {
  color: #ffffff !important;
  background-color: #002eb7 !important;
}

.main_two_search {
  float: right;
  background-color: #002eb7 !important;
  color: #ffffff !important;
  border-radius: 8px;
}

.main_two_search:hover {
  background-color: #ffffff !important;
  border: 1px solid #94a3d2;
  color: #94a3d2 !important;
  font-size: 14px;
}

.main_two_search:active {
  color: #ffffff !important;
  background-color: #002eb7 !important;
}

/*******the end - 以上是form表单中button的控件css **************************************/

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>