<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <el-button type="primary" plain @click="addtanEvent((dialogVisible = true))" v-if="mypowerlimits.yyrygl_tjyyry">添加运营人员</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'num'">
                                <div v-if="mytabletitle.prop === 'num'">
                                    <div
                                        :class="this.mypowerlimits.yhgl == true ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.yhgl == true ? routeraddrClick('/user', scope.row) : ''"
                                    >
                                        <span style="">{{ scope.row.num }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-权限-权限管理-->
                                <div>
                                    <el-button type="primary" size="mini" @click="addTanClick(scope.row, (divisable = true))" v-if="mypowerlimits.yyrygl_glywyh">关联业务用户</el-button>
                                    <el-button @click.prevent="deleteRow(scope.$index, mainthreetableData)" type="danger" size="mini" icon="el-icon-delete"  v-if="mypowerlimits.yyrygl_sc"></el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>

            <!--弹出框-添加运营人员-->
            <el-dialog v-model="dialogVisible" title="添加运营人员" :width="searchwidth" :before-close="handleCloseSenior">
                <div style="display: flex;flex-direction: row;flex-wrap: nowrap;justify-content: left;gap: 20px;align-items: center;">
                    <div style="min-width: 200px; display: flex;flex-direction: row;flex-wrap: nowrap;justify-content: left;gap: 10px;align-items: center">
                        <div>
                            <span>输入搜索：</span>
                        </div>
                        <div>
                            <el-input v-model="seniorform.keywords" style="width: 100%;" placeholder="请输入用户名 / 姓名" clearable></el-input>
                        </div>
                    </div>
                    <div>
                        <el-button type="primary" @click="SearchEvent(seniorform.keywords)">查询</el-button>
                    </div>
                </div>
                <div>
                    <el-table
                        :data="tantableData.slice((tancurrentPage - tanlastpage) * tanPageSize, tancurrentPage * tanPageSize)"
                        style="width: 100%;margin:20px 0"
                        border
                        :header-cell-style="{ background: '#F5F7FA' }"
                        v-loading="tanloading"
                        element-loading-text="正在飞速加载中..."
                    >
                        <el-table-column prop="id" label="ID" />
                        <el-table-column prop="username" label="用户名" />
                        <el-table-column prop="truename" label="姓名" />
                        <el-table-column prop="dept_id_name" label="所属部门" />
                        <el-table-column prop="group_id_name" label="所属权限" />
                        <el-table-column prop="myoperations" label="操作">
                            <template #default="scope">
                                <div v-if="scope.row.is_winuser == -1">
                                    <el-button type="primary" size="mini" @click="HasChangeEvent(scope.row)">选择</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <modelPages
                        :totalCount="tantotalCount"
                        :currentPage="tancurrentPage"
                        :pageSizes="tanpageSizes"
                        :PageSize="tanPageSize"
                        @size-change="tanhandleSizeChange"
                        @current-change="tanhandleCurrentChange"
                    />
                </div>
            </el-dialog>

            <!-- 弹窗-关联业务用户 -->
            <el-dialog v-model="divisable" title="关联业务用户" :width="searchwidth" :before-close="tan2handleCloseSenior">
                <div style="height: 60px;">
                    <el-input v-model="filterText" placeholder="快速搜索节点" clearable />
                </div>
                <div style="min-height: 200px;max-height:400px; overflow-y: auto;">
                    <el-tree
                        ref="treeRef"
                        :data="pid_options"
                        :default-checked-keys="tanSearchIds"
                        show-checkbox
                        default-expand-all
                        :props="{
                            children: 'child',
                            label: 'truename',
                            id: 'id'
                        }"
                        node-key="id"
                        :check-strictly="this.checkStrictly"
                        :filter-node-method="filterNode"
                        @check-change="handleCheckChange"
                    />
                </div>
                <div style="display: flex;flex-direction: row;flex-wrap: nowrap; justify-content: right; align-items: center;">
                    <el-button type="info" plain @click="tan2handleCloseSenior2()">取消</el-button>
                    <el-button type="primary" @click="tan2HasChangeEvent(this.hasChangeData_id, this.tanSearchIds)">确认</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import modelPages from '@/vspecial/operate/wecom/minipluswecom/modelPages.vue'

export default defineComponent({
    name: 'voperatuser',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '权限'
                },
                {
                    name: '权限管理'
                },
                {
                    name: '运营人员管理'
                }
            ], // 当前页-【面包屑导航】
            mainthreeisshowbut: true, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 39, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 180, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: 'admin_user_id',
                    label: 'ID',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'username',
                    label: '登录账号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'truename',
                    label: '姓名',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'dept_id_name',
                    label: '所属部门',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'group_id_name',
                    label: '所属权限',
                    width: ''
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）

            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '1180px',

            // 添加运营人员-弹窗
            dialogVisible: ref(false), //弹窗控制状态
            seniorform: {
                keywords: ''
            },
            tantableData: [],
            tantotalCount: 1,
            tanlastpage: 1,
            tancurrentPage: 1,
            tanpageSizes: [10, 20, 50, 100],
            tanPageSize: 10,
            tanpagesdatas: [],
            tanloading: false,

            // 关联业务用户-弹窗
            divisable: ref(false),
            hasChangeData_id: '',
            tanSearchIds: [],
            pid_options: [],
            filterText: '',
            checkStrictly: true,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    watch: {
        filterText(val) {
            this.$refs.treeRef.filter(val)
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            ElMessageBox.confirm('删除后将无法恢复，是否确认删除？', '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'primary'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/winUser/manageDelete',
                            {
                                admin_user_id: rows[index].admin_user_id
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        rows.splice(index, 1)
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '已取消'
                    })
                })
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            // console.log('this is:' + val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            let postdata = {
                page: 1,
                page_size: val
            }

            this.postAxiosEvent(postdata)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            let postdata = {
                page: val,
                page_size: this.PageSize
            }

            this.postAxiosEvent(postdata)
        },

        //导出数据
        exportDatas(tableData) {
            console.log(tableData)
        },

        // *****************添加运营人员*********************
        // 弹窗-首次获取数据
        addtanEvent() {
            let postdata = {
                keywords: '',
                search: 1,
                page: 1,
                page_size: this.tanPageSize
            }

            this.tanpostAxiosEvent(postdata)
        },
        // 弹窗-添加运营人员-查询
        SearchEvent(data) {
            let postdata = {
                keywords: data,
                search: 1,
                page: 1,
                page_size: this.tanPageSize
            }

            this.tanpostAxiosEvent(postdata)
        },
        // 弹窗-分页器中-当选择条数时触发该函数
        tanhandleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.tanPageSize = val
            this.tancurrentPage = 1

            let postdata = {
                keywords: this.seniorform.keywords,
                search: 1,
                page: 1,
                page_size: val
            }

            this.tanpostAxiosEvent(postdata)
        },
        // 弹窗-分页器中-当选择分页时触发该函数
        tanhandleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.tancurrentPage = val

            let postdata = {
                keywords: this.seniorform.keywords,
                search: 1,
                page: val,
                page_size: this.tanPageSize
            }

            this.tanpostAxiosEvent(postdata)
        },
        tanpostAxiosEvent(postdata) {
            //加载loading
            this.tanloading = true
            axios
                .get('/admin/Admin/userList', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: postdata
                })
                .then((response) => {
                    this.tanmyaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        tanmyaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.tantableData = newdata
            this.tantotalCount = successdata.total
            this.tancurrentPage = successdata.current_page //当前页面页数
            this.tanlastpage = successdata.last_page
            this.tanloading = false
        },
        // 弹窗-添加运营人员-选中
        HasChangeEvent(data) {
            // console.log(data)
            axios
                .post(
                    '/admin/winUser/userRelation',
                    {
                        admin_user_id: data.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.dialogVisible = false
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message,
                            duration: 1000,
                            onClose: () => {
                                this.reload()
                            }
                        })
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        // 弹窗-点击关闭
        handleCloseSenior(done) {
            this.seniorform.keywords = ''
            this.tantableData = []
            this.tantotalCount = 1
            this.tancurrentPage = 1 //当前页面页数
            this.tanlastpage = 1
            this.dialogVisible = false
            done()
        },

        // ****************关联业务人员*******************
        // 弹窗-关联业务人员-弹出弹窗
        addTanClick(data) {
            this.hasChangeData_id = data.admin_user_id
            this.tanSearchIds = data.win_user_id.map((i) => parseInt(i, 0))
            setTimeout(() => {
                this.$refs.treeRef.setCheckedKeys(this.tanSearchIds)
            }, 1)
        },
        // 弹窗-关联业务人员-关闭
        tan2handleCloseSenior(done) {
            this.tan2handleCloseSenior2()
            done()
        },
        tan2handleCloseSenior2() {
            this.divisable = false
            this.filterText = ''
            this.tanSearchIds = []
            this.$refs.treeRef.setCheckedKeys(this.tanSearchIds)
        },
        // 弹窗-关联业务人员-快速搜索
        filterNode(value, data) {
            if (!value) return true
            return data.truename.indexOf(value) !== -1
        },
        // 弹窗-关联业务人员-点击复选框
        handleCheckChange(data, ischange) {
            if (ischange) {
                // 插入数组
                this.tanSearchIds.push(data.id)
            } else {
                this.tanSearchIds = this.tanSearchIds.filter((n) => n !== data.id)
            }
        },
        // 弹窗-关联业务人员-确认按钮
        tan2HasChangeEvent(id, data) {
            console.log(data)
            axios
                .post(
                    '/admin/winUser/userRelation',
                    {
                        admin_user_id: id,
                        win_user_id: data
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.dialogVisible = false
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message,
                            duration: 1000,
                            onClose: () => {
                                this.reload()
                            }
                        })
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        },

        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 1200) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '1180px'
            }
        },

        //提交等相关event事件函数
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        // 获取数据Axios
        postAxiosEvent(postdata) {
            //加载loading
            this.loading = true
            axios
                .post('/admin/winUser/manageList', postdata, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },

        // 业务tree
        finduserPidOptions() {
            axios
                .post(
                    '/admin/winUser/list',
                    {},
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.pid_options = response.data.result
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            let postdata = {
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(postdata)

            this.finduserPidOptions()
        }
    },
    components: {
        mainone,
        modelPages
    }
})
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}

.tan-box-list {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    padding: 0 3%;
    margin: 20px 0;
}
</style>
