<template>
    <div class="page-container">
        <div class="container-filter">
            <el-form ref="filterRef" :model="params" inline>
                <div>
                    <el-form-item prop="keywords" label="输入搜索：">
                        <el-input v-model="params.keywords" placeholder="用户ID/用户昵称/手机号"></el-input>
                    </el-form-item>
                    <el-form-item prop="activeid" label="活动名称：">
                        <el-select v-model="params.activeid" placeholder="请选择">
    						<el-option v-for="item in activeOpt" :key="item.id" :label="item.label" :value="item.value" />
	    				</el-select>
                    </el-form-item>
                    <el-form-item prop="status" label="订单状态：">
                        <el-select v-model="params.status" placeholder="请选择">
    						<el-option v-for="item in orderStatusOpt" :key="item.value" :label="item.label" :value="item.value" />
	    				</el-select>
                    </el-form-item>
                    <el-form-item prop="payTime" label="购买时间：">
                        <el-date-picker v-model="params.payTime" type="daterange" :clearable="false" start-placeholder="起始" end-placeholder="结束" />
                    </el-form-item>
                    <el-form-item prop="refundtime" label="退款时间：">
                        <el-date-picker v-model="params.refundtime" type="daterange" :clearable="false" start-placeholder="起始" end-placeholder="结束" />
                    </el-form-item>
                    <el-form-item prop="sendstatus" label="赠送状态：">
                        <el-select v-model="params.sendstatus" placeholder="请选择">
    						<el-option v-for="item in giftStatusOpt" :key="item.value" :label="item.label" :value="item.value" />
	    				</el-select>
                    </el-form-item>
                    <el-form-item prop="sendTime" label="赠送时间：">
                        <el-date-picker v-model="params.sendTime" type="daterange" :clearable="false" start-placeholder="起始" end-placeholder="结束" />
                    </el-form-item>
                </div>
                <div class="filter-btn">
                    <el-button @click="resetForm">重置</el-button>
                    <el-button type="primary" @click="getTableData()" >查询</el-button>
                </div>
            </el-form>
        </div>
        <div class="container-table">
            <div class="table-header">
                <div>数据列表</div>
                <div>
                    <el-button type="primary" @click="exportRecord">导出</el-button>
                </div>
            </div>
            <el-table :border="true" :data="table.data" v-loading="loading" :header-cell-style="{backgroundColor: 'rgb(245, 247, 250)'}" :height="tableHeight">
                <el-table-column prop="transactionId" label="订单编号" width="280px" />
                <el-table-column prop="uid" label="用户ID" min-width="100" />
                <el-table-column prop="receive_nickname" label="用户昵称"  min-width="100" />
                <el-table-column prop="orderSkuMoney" label="订单金额（元）" min-width="140" />
                <el-table-column prop="orderMoney" label="实付金额（元）" min-width="140" >
                    <template #default="{ row }">
                        <div>
                            <span v-if="row.orderMoney == 0 || !mypowerlimits.hdyxdd_xq">{{ row.orderMoney }}</span>
                            <span v-else class="skip" @click="orderMoney(row)">{{ row.orderMoney }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="refundMoney" label="退款金额（元）" min-width="140" >
                    <template #default="{ row }">
                        <div>
                            <span v-if="row.refundMoney == 0 || !mypowerlimits.hdyxdd_tk">{{ row.refundMoney }}</span>
                            <span v-else class="skip" @click="refundMoney(row)">{{ row.refundMoney }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="status_name" label="订单状态" min-width="120" />
                <el-table-column prop="payTime" label="购买时间" min-width="180" />
                <el-table-column prop="activity" label="活动名称"  min-width="180" />
                <el-table-column prop="shouldsendCredit" label="应赠送打印余额（元）" min-width="180" />
                <el-table-column prop="sendCredit" label="已赠送打印余额（元）" min-width="180" >
                    <template #default="{ row }">
                        <div>
                            <div>已赠送：{{ row.sendCredit }}</div>
                            <div>应退回：{{ row.shouldrefundCredit }}</div>
                            <div>实退回：{{ row.refundCredit }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="sendstatus_name" label="赠送状态" width="120" />
                <el-table-column prop="sendTime" label="赠送时间" min-width="180" ></el-table-column>
                <el-table-column prop="returnTime" label="退回时间" min-width="180" ></el-table-column>
            </el-table>
            <div class="tabler-pagination">
                <el-pagination
                    @size-change="getTableData()"
                    @current-change="getTableData"
                    :current-page="params.page"
                    :page-sizes="[20, 50, 100, 1000]"
                    v-model:page-size="params.page_size"
                    :total="table.total"
                    layout="total, sizes, prev, pager, next, jumper"
                />
            </div>
        </div>

        <OrderDetail :dialog="orderDialog" :id="orderId" @cancel="orderDialog = false"></OrderDetail>
        <RefundDetail :dialog="refundDialog" :id="refundId" @cancel="refundDialog = false"></RefundDetail>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import http from '@/assets/js/http'
import { useRoute } from 'vue-router'
import OrderDetail from './OrderDetail'
import RefundDetail from './RefundDetail'
import mixTableHeight  from '@/assets/js/tableHeight'
const { tableHeight } = mixTableHeight({ subtractHeight: 480 })

// 获取权限
const mypowerlimits = reactive(JSON.parse(localStorage.getItem('powerlimits')))
console.log(111, mypowerlimits)
const route = useRoute()
// 过滤
const filterRef = ref()
const params = reactive({
    activeid: route.query?.id ? route.query?.id : '',
    payTime: [],
    refundtime: [],
    sendTime: [],
    page: 1,
    page_size: 20,
    keywords: route.query?.uid || '',
    sendstatus: route.query?.sendstatus || ''
})

const activeOpt = ref([])

const orderStatusOpt = reactive([ { label: '全部', value: '' }, { label: '已完成', value: '1' }, { label: '已退款', value: '2' }, { label: '部分退款', value: '3' } ])

const giftStatusOpt = reactive([ { label: '全部', value: '' }, { label: '已赠送', value: '1' }, { label: '未赠送', value: '0' }, { label: '部分退回', value: '4' }, { label: '全部退回', value: '3' }, { label: '异常退回', value: '2' } ])

// 表格
const loading = ref(false)
const table = reactive({
    data: [],
    columns: [],
    total: 0
})

const getTableData = async (page = 1) => {
    params.page = page
    const payTime = formatTime(params.payTime)
    const refundtime = formatTime(params.refundtime)
    const sendTime = formatTime(params.sendTime)
    loading.value = true
    try {
        const res = await http.post('meizhiOrder/index', { ...params, payTime, refundtime, sendTime })
        Object.assign(table, res)
    } catch (e) {
        console.log(e)
    }
    loading.value = false
}

// 导出
const exportRecord = async () => {
    const payTime = formatTime(params.payTime)
    const refundtime = formatTime(params.refundtime)
    const sendTime = formatTime(params.sendTime)
    await http.post('meizhiOrder/index', { ...params, payTime, refundtime, sendTime, export: 1 }, { sMsg: true })
}


const getActive = async ()=> {
    const { data } = await http.post('MeizhiActivity/index', { page: 1,  page_size: 9999 })
    const opt = data.map((item)=> {
        return { value: `${item.id}`, label: item.name }
    })
    activeOpt.value = [ { value: '', label: '全部' }, ...opt]
}

const formatTime = (time)=> {
    if(time.length === 0 || !time) return undefined
    const [start, end] = time
    end.setHours(23, 59, 59, 0)
    return `${start.valueOf() / 1000} ~ ${end.valueOf() / 1000}`
}

// 操作
// 重置查询表单
const resetForm = () => {
    filterRef.value?.resetFields()
    getTableData()
}

// 实付金额
const orderDialog = ref(false)
const orderId = ref(0)

const orderMoney = ({ id })=> {
    orderDialog.value = true
    orderId.value = id
}

// 退款金额
const refundDialog = ref(false)
const refundId = ref(0)

const refundMoney = ({ id })=> {
    refundDialog.value = true
    refundId.value = id
}

getActive()
getTableData()

</script>

<style lang="scss" scoped>
.page-container {
    background-color: #e9eef3;
    .container-filter {
        padding: 12px 24px;
        background-color: #fff;
        .el-form {
            display: flex;
            justify-content: space-between;
        }
        .el-form-item {
            margin-right: 20px;
            margin-bottom: 10px;
        }

        .filter-btn {
            min-width: 160px;
        }
    }

    .container-table {
        margin-top: 4px;
        padding: 24px;
        background-color: #fff;
        .table-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
        }

        .skip {
            cursor: pointer;
            color: var(--el-color-primary);
        }
        .tabler-pagination {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
        }
    }
}

.container-drawer {
    .filter-tree {
        margin-top: 24px;
    }
    .drawer-footer {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }
}

:deep(.el-drawer) {
    overflow-y: auto;
}

</style>
