<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box">
                <div class="box_title" style="">
                    <div class="box_title_font">会员开关设置</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box-content">
                    <div class="box-content-dis" id="switch">
                        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px">
                            <el-form-item label="Plus会员开关：" prop="plus_vip">
                                <el-switch v-model="ruleForm.plus_vip" active-text="开启" inactive-text="关闭"
                                    active-value='1' inactive-value='-1' active-color="#13ce66"
                                    inactive-color="#cbcbcb" />
                            </el-form-item>
                            <el-form-item label="设备会员开关：" prop="client_vip">
                                <el-switch v-model="ruleForm.client_vip" active-text="开启" inactive-text="关闭"
                                    active-value='1' inactive-value='-1' active-color="#13ce66"
                                    inactive-color="#cbcbcb" />
                            </el-form-item>
                            <el-form-item label="充值会员开关：" prop="print_wallet">
                                <el-switch v-model="ruleForm.print_wallet" active-text="开启" inactive-text="关闭"
                                    active-value='1' inactive-value='-1' active-color="#13ce66"
                                    inactive-color="#cbcbcb" />
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" style="width:120px" @click="saveEvent('ruleForm')">保存
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import mainone from "@/components/Mainone";
import { defineComponent } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";

export default defineComponent({
    name: "vmemberswitchset",
    inject: ["reload"], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: "设置",
                },
                {
                    name: "会员设置",
                },
                {
                    name: "会员开关设置",
                },
            ], // 当前页-【面包屑导航】

            ruleForm: {
                plus_vip: "-1",
                client_vip: "-1",
                print_wallet: "-1",
            },
            rules: {
                plus_vip: [
                    {
                        required: true,
                        message: "PLUS会员开关为必填项",
                        trigger: "change",
                    },
                ],
                client_vip: [
                    {
                        required: true,
                        message: "设备会员开关为必填项",
                        trigger: "change",
                    },
                ],
                print_wallet: [
                    {
                        required: true,
                        message: "充值会员开关为必填项",
                        trigger: "change",
                    },
                ],
            },

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
        };
    },
    methods: {
        // 保存
        saveEvent(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            "/admin/config/update",
                            {
                                key_name: "vip_status",
                                contents: this.ruleForm,
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: "success",
                                    message: response.data.message,
                                });
                            }
                        })
                        .catch(function (error) {
                            ElMessage({
                                type: "warning",
                                message: error,
                            });
                        });
                } else {
                    return false;
                }
            });
        }
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.$router.push("/").catch((error) => error);
        } else {
            // 获取配置
            axios
                .post(
                    "/admin/config/info",
                    {
                        key_name: "vip_status",
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        let data = response.data.result;
                        this.ruleForm = {
                            plus_vip: data.plus_vip.toString(),
                            client_vip: data.client_vip.toString(),
                            print_wallet: data.print_wallet.toString(),
                        };
                    }
                })
                .catch(function (error) {
                    ElMessage({
                        type: "warning",
                        message: error,
                    });
                });
        }
    },
    components: {
        mainone,
    },
});
</script>
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
    font-size: 14px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box-content {
    border-top: 1px solid #eeeeee;
}

.box-content-dis {
    max-width: 240px;
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}


/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}
</style>